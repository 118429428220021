<div class='col-sm-10 rightCol'>
   <div class="well well-sm chat-head">
      <h4 class="title"trans="General">General</h4>
      <div class="subtitle"trans="Configure general site settings.">Configure general site settings.</div>
   </div>
   <div class='content-container customTabs'>
      <form [formGroup]="generalForm" class="account-info">
         <div class="row formspace">
            <div class="col-sm-9">
               <div class="row formspace">
                  <div class="col-md-9">
                     <div class="form-group">
                        <label for="site_name"trans="Site Name">Site Name</label>
                        <input type="text" id="site_name" [value]="site_name" placeholder="Enter preferred site name" formControlName="site_name" class="form-control"  >
                     </div>
                     <div *ngFor= "let error of errorMessages.site_name">
                       <ng-container *ngIf="site_name.hasError(error.type) && (site_name.touched)">
                          <small class="error-message">{{error.message}}</small>
                       </ng-container>
                    </div>
                  </div>
                  <div class="col-md-9">
                     <div class="form-group">
                        <label for="site_description" trans="Site Description">Site Description</label>
                        <textarea id="site_description" [value]="site_description" placeholder="Enter description about your site" rows="4"  class="form-control" formControlName="site_description"></textarea>
                     </div>
                  </div>
                  <div class="col-md-9">
                     <div class="form-group">
                        <label  for="selectbasic" trans="Site Logo">Site Logo</label>
                        <label  for="selectbasic" class="pull-right small_small">*PNG,JPG</label>
                        <input  formControlName="site_logo" type="file" accept="image/x-png,image/gif,image/jpeg" class="form-control"  (change)="fileChangeEvent($event)" id="uplodfile" >
                        <a href="" data-toggle="modal" data-target="#viewModal" (click)="selectedImage('logo')">{{site_logo_img}}</a>
                        <div *ngFor= "let error of errorMessages.site_logo">
                         <ng-container *ngIf="site_logo.hasError(error.type) && (site_logo.dirty || site_logo.touched)">
                            <small class="error-message">{{error.message}}</small>
                         </ng-container>
                      </div>
                        <!-- <app-settings-image-input  name="logo_light" type="branding" highlightInPreview=".logo">Logo</app-settings-image-input> -->
                     </div>
                  </div>
                  <div class="col-md-9">
                     <div class="form-group">
                        <label  for="selectbasic" trans="Site Favicon">Site Favicon</label>
                        <label  for="selectbasic" class="pull-right small_small">*PNG,JPG</label>
                        <input  formControlName="site_favicon" type="file" accept="image/x-png,image/gif,image/jpeg" class="form-control"  (change)="file2ChangeEvent($event)" id="uplodfile" >
                        <a href="" data-toggle="modal" data-target="#viewModal" (click) = "selectedImage('favicon')">{{site_favicon_img}}</a>
                        <div *ngFor= "let error of errorMessages.site_favicon">
                         <ng-container *ngIf="site_favicon.hasError(error.type) && (site_favicon.dirty || site_favicon.touched)">
                            <small class="error-message">{{error.message}}</small>
                         </ng-container>
                      </div>
                        <!-- <app-settings-image-input  name="logo_light" type="branding" highlightInPreview=".logo">Logo</app-settings-image-input> -->
                     </div>
                  </div>
                  <!-- <div class="col-md-9">
                     <div class="form-group">
                        <app-settings-image-input  backendUri="uploads/favicon" name="favicon" type="branding" >Favicon</app-settings-image-input>
                     </div>
                  </div> -->
               </div>
            </div>
         </div>
      </form>
      <button (click)="updateSettings()" type="submit" class="btn btn-primary new" trans="Update">
                                                Update
                                            </button>
      <!-- <button class="btn btn-primary new" trans="Update">Update</button> -->
   </div>
</div>
<div class="modal fade" id="viewModal" tabindex="-1" role="dialog"
   aria-hidden="true">
   <div class="modal-dialog topMargin">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            ×
            </button>
            <h4 class="modal-title md-title" id="myModalLabel">
               {{img_name}}
            </h4>
         </div>
         <div class="modal-body">
            <img src={{selectedimage}} style="width: 250px;height: auto;" >
         </div>
      </div>
   </div>
</div>

