import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Bootstrapper } from '../../../core/bootstrapper.service';
import { PaymentDetail } from '../../../auth/paymentdetail';
import { FinanceService } from '../finance.service';

@Component({
  selector: 'app-stripe-settings',
  templateUrl: './stripe-settings.component.html',
  styleUrls: ['./stripe-settings.component.css']
})
export class StripeSettingsComponent implements OnInit {
    public payment_methods;
    public years = [];
    public stripeSettingsForm = this.fb.group({
        stripe_mode: ['',[Validators.required]],        
        stripe_secret_key_live: [''],
        stripe_publishable_key_live: [''],
        stripe_secret_key_sandbox: [''],
        stripe_publishable_key_sandbox: [''],
        stripe_version: [''],
        stripe_client_id_live: [''],        
        stripe_client_id_sandbox: [''],        
    });


  get stripe_mode(){ return this.stripeSettingsForm.get('stripe_mode');}
  get stripe_secret_key_live(){ return this.stripeSettingsForm.get('stripe_secret_key_live');}
  get stripe_publishable_key_live(){ return this.stripeSettingsForm.get('stripe_publishable_key_live');}

	get stripe_secret_key_sandbox(){ return this.stripeSettingsForm.get('stripe_secret_key_sandbox');}
  get stripe_publishable_key_sandbox(){ return this.stripeSettingsForm.get('stripe_publishable_key_sandbox');}

  get stripe_version(){ return this.stripeSettingsForm.get('stripe_version');}
  get stripe_client_id_live(){ return this.stripeSettingsForm.get('stripe_client_id_live');}
  get stripe_client_id_sandbox(){ return this.stripeSettingsForm.get('stripe_client_id_sandbox');}

	public errorMessages = {
	  stripe_mode :   [ { type:'required',message:'Mode is required'}],
	  stripe_secret_key_live :   [ { type:'required',message:'Secret Key(Live) is required'} ],
    stripe_publishable_key_live :   [ { type:'required',message:'Publishable key(Live) is required'} ],
    stripe_secret_key_sandbox :   [ { type:'required',message:'Secret Key(Sandbox) is required'} ],
    stripe_publishable_key_sandbox :   [ { type:'required',message:'Publishable key(Sandbox) is required'} ],

    stripe_version :   [ { type:'required',message:'Secret Key(Sandbox) is required'} ],
    stripe_client_id_live :   [ { type:'required',message:'Client ID(Live) is required'} ],
    stripe_client_id_sandbox :   [ { type:'required',message:'Client ID(Sandbox) is required'} ],
   }

  constructor(private fb: FormBuilder,
              public payment: PaymentDetail,
              public toastr: ToastrService,
              public financeService: FinanceService) {
                let settings = this.financeService.getStripeSettings().subscribe(response => {
                  let res = JSON.parse(atob(response['data']));

                  this.stripeSettingsForm.patchValue({
                    stripe_mode:res.stripe_mode,

                    stripe_secret_key_live:res.stripe_secret_key_live,
                    stripe_publishable_key_live:res.stripe_publishable_key_live,

                    stripe_secret_key_sandbox:res.stripe_secret_key_sandbox,
                    stripe_publishable_key_sandbox:res.stripe_publishable_key_sandbox,

                    stripe_version:res.stripe_version,
                    stripe_client_id_live:res.stripe_client_id_live,
                    stripe_client_id_sandbox:res.stripe_client_id_sandbox,
                  });

                });
                
  }

  ngOnInit(): void {
  this.payment.changepaymentDetails$.subscribe(response=>{
   this.payment_methods = response;
  });
  
  }

   public savestripeSettings(){
      this.stripeSettingsForm.markAllAsTouched();  
       this.financeService.updateStripeSettings(this.stripeSettingsForm.value)
        .subscribe(response => {
            let res = JSON.parse(atob(response['data']));
           
           if ( res.status == 'success' ) {           
             this.toastr.success('Details Updated Successfully');
           } else {
             this.toastr.error(res.message);
           }
        }, err => {
          this.toastr.error('Something went wrong');
        });
        
     
  }
}
