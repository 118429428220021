import { Component, OnInit } from '@angular/core';
import { LessonsMenuService } from '../lessons-menu.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup , AbstractControl } from '@angular/forms';
import { DatatableService } from '../../../core/datatable/datatable.service'; 
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
declare var $;

@Component({
  selector: 'app-active-subjects',
  templateUrl: './active-subjects.component.html',
  styleUrls: ['./active-subjects.component.css'] ,
  providers: [DatatableService]
})
export class ActiveSubjectsComponent implements OnInit {
  
  public searchControl = new FormControl();
  public selectedColumn:any;
  public pagesColumn = [ {'Subject Name':['subject_name'] , 'Created By':['creator', 'username'] , 'Status':['status'] }];
  public obj:any;

  public subjectForm = this.fb.group({
    // subject_name: ['',[Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
    subject_name: ['',[Validators.required]]
  });
  public subjects$ = this.datatable.data$;

  get subject_name(){ return this.subjectForm.get('subject_name')}

  public errorMessages = {
           subject_name :[{ type:'required',message:'Subject is required'},{ type:'pattern',message:'Enter valid text'}]
         };


  constructor(public service: LessonsMenuService,
              private toastr: ToastrService,
              private fb: FormBuilder,public datatable: DatatableService<TransactionLog>) {
                console.log(this.searchControl.valueChanges)
                this.datatable.init({
                    uri: LessonsMenuService.Subjects_BASE_URI
                });
                this.obj = Object.assign({}, ...this.pagesColumn);  
              }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(query => {        
        this.datatable.addFilter('column',this.selectedColumn);
          this.datatable.addFilter('pQuery', query);
    });
  }

  /*
  Search by column
  */
  public selectedPagesColumn(column  , id){
    if(column){
      this.selectedColumn = column;
      $('#'+id).removeAttr('disabled');
    }else{
      this.selectedColumn = '';
      this.datatable.addFilter('column',this.selectedColumn);
      $('#'+id).attr('disabled','disabled');
    }
   $('#'+id).val('');
  }

    /*
  CSV Import of data
  */
  public csvExort(name){
    new AngularCsv(this.subjects$.value , name);
  }

  public addSubject(){
    this.subjectForm.markAllAsTouched();
    if(this.subjectForm.valid){
     this.service.addSubject(this.subjectForm.value)
     .subscribe(response =>{
       if(response.status == 'success'){
       this.subjectForm.reset();
       this.toastr.success('Subject Added');
       this.datatable.addFilter('n','n');
       }else{
       this.toastr.warning('Invalid Subject');
       }
     });
   }
  }

  onCheckboxChange(e , id) {
    let subjectId = { subjectId: id }
    let status;
    if(e.target.checked == true){
      status = {status:'Active'};
    }else{
      status = {status:'IN-Active'};
    }
  
 let subjectStatus = Object.assign(subjectId , status);
  this.service.updateSubjectStatus(subjectId)
  .subscribe(response => {
    this.toastr.success('Subject Staus Updated');
    this.datatable.addFilter('n','n');
  });
}

  public deleteSubject(id){
    let subject = Object();
    Object.assign(subject , {subjectId:id});
     this.service.deleteSubject(subject)
     .subscribe(response => {
       this.toastr.success('Subject Deleted');
       this.datatable.addFilter('n','n');
     });
   }

}