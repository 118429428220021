import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-note',
  templateUrl: './view-note.component.html',
  styleUrls: ['./view-note.component.css']
})
export class ViewNoteComponent implements OnInit {
  
  public note:string;
  constructor() { }

  ngOnInit(): void {
  }

  public noteData(noteData){
    this.note = noteData;
  }

  public closeNoteModal(){
    this.note = '';    
  }


}
