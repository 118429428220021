import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FinanceService {
    static BASE_URI = 'admintrans';

	public reload_records = new BehaviorSubject<any>(null);
    is_reload_records = this.reload_records.asObservable();
  
	constructor(private http: AppHttpClientService){}
	
	public getFinanceData() {
		return this.http.get('getFinanceData')
	}

    public financeDataCount() {
		return this.http.post('financeDataCount');
	}

		// Message Modal send Request
	public sendMessage(payload: object){
		return this.http.post('sendAdminMessage', payload);
	}

	public exportSalesTransactions(){
		return this.http.get('exportSalesTransactions');
	}

	public updatePayout(payload) {
		return this.http.post('updatePayout', payload);
	}

	public resetRecords(value){
		this.reload_records.next(value);
	}

	public updateStripeSettings(payload) {
		return this.http.post('updateStripeSettings', payload);
	}

	public getStripeSettings() {
		return this.http.get('getStripeSettings')
	}
}