import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TemplateService } from '../template.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit {

 public selectedTemplateType;
 public selectedTemplate;
  constructor(private fb: FormBuilder,
  	          public templateService:TemplateService,
              private toastr: ToastrService,
              private _location: Location) {
   this.templateService.sharedTemplate.subscribe(response => {
   this.selectedTemplateType = response;
  });

  this.templateService.sharedTemplateValues.subscribe(id => {
   this.selectedTemplate = id;
   if(this.selectedTemplate){
    this.templateForm.patchValue({
         key: this.selectedTemplate.key,
         title: this.selectedTemplate.title,
         subject:   this.selectedTemplate.subject,
         from_name: this.selectedTemplate.from_name,
         content :this.selectedTemplate.content,
         redirect_url: this.selectedTemplate.redirect_url,
    });
   }
  });
}
   public ckeditorContent;
   public templateForm = this.fb.group({
         key: ['',Validators.required],
         title: ['',Validators.required],
         subject:   ['',[ Validators.required]],
         from_name: ['',Validators.required],
         content :['',Validators.required],
         redirect_url: [''],
    });

  public errorMessages = {
         key : [{ type:'required',message:'Key is Required'}],
         title : [{ type:'required',message:'Title is Required'}],
         subject: [{ type:'required',message:'Subject is Required'}],
         from_name: [ { type:'required',message:'Sender Name is Required'}],
         content: [ { type:'required',message:'Content is Required'}],
         redirect_url: []
  }

  get key() { return this.templateForm.get('key'); }
  get title() { return this.templateForm.get('title'); }
  get subject() { return this.templateForm.get('subject'); }
  get from_name() { return this.templateForm.get('from_name'); }
  get content() { return this.templateForm.get('content'); }
  get redirect_url() { return this.templateForm.get('redirect_url'); }


  ngOnInit(): void {
  }

     public backClicked() {
      this._location.back();
    }


  public updateTemplate() {
    this.templateForm.markAllAsTouched();
       var myFormData = new FormData();
        myFormData.append('key', this.templateForm.get('key').value);
        myFormData.append('title', this.templateForm.get('title').value);
        myFormData.append('subject', this.templateForm.get('subject').value);
        myFormData.append('from_name', this.templateForm.get('from_name').value);
        myFormData.append('content', this.templateForm.get('content').value);
        myFormData.append('type', this.selectedTemplateType);
        myFormData.append('redirect_url' , this.templateForm.get('redirect_url').value);

        if(this.selectedTemplate){
        myFormData.append('selectedTemplateId', this.selectedTemplate.id);
        }
        this.templateService.submitForm(myFormData)
       .subscribe(response => {
         if(response.status == 'success')
         {
           this.templateForm.reset();
           this.templateService.templateId(null);
           this._location.back();
           this.toastr.success('Template has been added successfull');
         }
       });
  }

}
