import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LessonsMenuService } from '../lessons-menu.service';

@Component({
  selector: 'app-experienced-errors',
  templateUrl: './experienced-errors.component.html',
  styleUrls: ['./experienced-errors.component.css']
})
export class ExperiencedErrorsComponent implements OnInit {

  @Output() adminMessageModalData = new EventEmitter();
  public experiencedErrors$:any;
  @Input() 
  public set experiencedErrors(value){
    this.experiencedErrors$ = value;
  }

  constructor(public settings: LessonsMenuService) { }

  ngOnInit(): void {
  }

  clearAll() {
    let data = Object();
    Object.assign(data , {'clear': true});
    this.settings.clearLog(data).subscribe(response => {
      console.log(response);
    });
  }

  deleteFile(fileName){

  }

}
