import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TeachersService } from '../teachers.service';

@Component({
  selector: 'app-suspended-tutors',
  templateUrl: './suspended-tutors.component.html',
  styleUrls: ['./suspended-tutors.component.css']
})
export class SuspendedTutorsComponent implements OnInit {
  
  @Output() reloadTutors = new EventEmitter();
  @Output() selectedApplicationTutorId = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  public suspendedTutors$:any;
  @Input() 
  public set suspendedTutors(value){
  if(value){
    this.suspendedTutors$ = value;
     }
  }

  constructor(public service: TeachersService,private toastr: ToastrService) { }

  ngOnInit(): void {
  }

    public viewApplication(id , $sectionType){
    this.selectedApplicationTutorId.emit({id:id,sectionType:$sectionType});
  }


  public activateUser(tutorId, operation){
    let data = Object();
  
    Object.assign(data , {tutorId:tutorId});
     this.service.activateUser(data).subscribe(response => {
       this.reloadTutors.emit(true);
       this.toastr.success('User activated');
    })
   }

   public deleteuser(tutorId, operation){
    let data = Object();
    
    Object.assign(data , {tutorId:tutorId});
     this.service.deleteUser(data).subscribe(response => {
       this.reloadTutors.emit(true);
       this.toastr.success('User deleted');
    })
   }

  public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }

}
