import { Component, OnInit , Output , EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { randomString } from '../../../core/utils/random-string';
import { Permission } from '../../../core/types/models/Permission';
import { Role } from '../../../core/types/models/Role';
import { flattenArray } from '../../../core/utils/flatten-array';
import { Users } from '../../../auth/users.service';
import { finalize } from 'rxjs/operators';
import { BackendErrorResponse } from '../../../core/types/backend-error-response';
import { User } from '../../../core/types/models/User';
import { ToastrService } from 'ngx-toastr';
declare var $;

@Component({
  selector: 'app-curd-user-modal',
  templateUrl: './curd-user-modal.component.html',
  styleUrls: ['./curd-user-modal.component.css']
})

export class CurdUserModalComponent implements OnInit {
  
  @Output() userUpdate = new EventEmitter();
  @ViewChild('closeUserModal') closeUserModal: ElementRef;
  public permissions$ = new BehaviorSubject<Permission[]>([]);
  public errors$ = new BehaviorSubject<Partial<User>>({});
  public roles$ = new BehaviorSubject<Role[]>([]);
  public allPermissions$ = new BehaviorSubject<{[key: string]: Permission[]}>({});
  get email(){ return this.userForm.get('email');}
  get password(){ return this.userForm.get('password');}
  get first_name(){ return this.userForm.get('first_name');}
  get last_name(){ return this.userForm.get('last_name');}
  public loading$ = new BehaviorSubject<boolean>(false);
  public user_id=null;
  public action=null;
  public user;
  public errorMessages = { email :[{ type:'required', message:'Email is required'},
                                     { type:'pattern',message: 'Enter valid Mail'} ],
                             password :[{ type:'required',message:'Password is required'}],
                             last_name :[{ type:'required',message:'Last Name is required'},{ type:'minlength',message:'Minimum four Characters required'}],
                             first_name :[{ type:'required',message:'First Name is required'},{ type:'minlength',message:'Minimum four Characters required'}], 
                          };
  public userForm = this.fb.group({
        email: ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        password: [''],
        avatar: [''],
        first_name: ['',[Validators.required,Validators.minLength(4)]],
        last_name: ['',[Validators.required,Validators.minLength(4)]],
        email_verified_at: [false],
        available_space: [''],
        roles: ['']
  });

  constructor(
  	private fb: FormBuilder,
    public users: Users,
    private userService: Users,
    private toastr: ToastrService,
    ) { }

  ngOnInit(): void { }
    
    /*
    Close Reset Modal Data
    */
    public close(){
    	this.userForm.reset();
      this.action=null;
      this.user_id=null;
      this.roles$.next([]);
    }

    /*
    Unique Email Validation
    */
    public selectEmail(email){
      if(!this.user_id){
    let validEmail = Object();
    Object.assign(validEmail , {email:this.userForm.value.email});
    if(email.status =='VALID' && this.userForm.value.email != ""){
     this.userService.emailVailidation(validEmail).subscribe(response =>{
        if (response.status == 'danger') {
                 this.toastr.error(response.error);
                 this.userForm.get('email').reset();            
               }else if(response.status == 'success'){
                 // this.status = response.status;
               } 
      });
     } 
    }
  }

    public selectedPermissions($event){
        this.setPermissions($event);
    }

    public selectedRoles($event){
        this.setRoles($event);
    }

    public generateNewPassword() {
        this.userForm.patchValue({password: randomString(15)});
    }


    public setPermissions(permissions: Permission[]) {
        this.permissions$.next(permissions);
    }

     public setRoles(roles) {
        this.roles$.next(roles);
    }

  public togglePassword(id) {
    $('.'+id).toggleClass("fa-eye fa-eye-slash");
    if($('#'+id).get(0).type == 'text'){
      $('#'+id).attr('type', 'password');
    }else{
    $('#'+id).attr('type', 'text');
    }
  }

    /*
    User Create and Edit Method
    */
    public confirm() {
       if(!this.user_id){
        this.userForm.get('password').setValidators([Validators.required]);
        }
        const payload = this.getPayload();
        if(payload){
        this.loading$.next(true);
        let request;
        if (this.user_id) {
            request = this.users.update(this.user_id , payload);
        } else {
            request = this.users.create(payload);
        }

        request
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
              if(response.status == 'success'){
                this.close();
                const action = this.user_id ? 'updated' : 'created';
                this.closeUserModal.nativeElement.click();
                this.toastr.success('User ' + action +' successfully');
                this.userUpdate.emit('reset');
              }
            },error=>{
              console.log(error);
            });
          }
    }

    // (errResponse: BackendErrorResponse) => this.errors$.next(errResponse.messages)



        private getPayload() {
        const payload = {...this.userForm.value};
        if(this.roles$.getValue().length > 0){
        if( this.action != 'edit')
        {
        payload.roles = this.roles$.value.map(role => role.id);
        payload.permissions = (payload.permissions || []);
        if ( ! payload.password) {
            delete payload.password;
        }
        payload.email_verified_at = payload.email_verified_at ? new Date() : null;
        }
        else
        {
          payload.email_verified_at = this.user.email_verified_at ? true : false;
        }
        return payload;
      }else{
        this.toastr.error('Select User Role');
        return;
      }
    }

    public userData(user){
      this.user_id = user.id
      this.user = user
      this.setRoles(user.roles);
      this.userForm.patchValue({
           email : user.email,
           password : user.password,
          avatar : user.avatar.avatar,
          first_name : user.first_name,
          last_name : user.last_name
      });

    }

}
