<div class="row m-auto">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="table-responsive TT-responsiveTable">
      <table class="table table-striped TT-table">
        <thead>
          <tr>
            <th trans="Username">Username</th>
            <th trans="Email">Email</th>
            <th trans="Completed Lessons">Completed Lessons</th>
            <th trans="Location">Location</th>
            <th trans="Actions" colspan="2">Actions</th>
          </tr>
        </thead>


        <tbody>
          <tr *ngFor="let student of topStudents$">
            <td>{{student.username}}</td>
            <td>{{student.email}}</td>
            <td>{{countLessons(student.completed_bookings)}}</td>
            <td>{{student.country}}</td>
            <td class="TT-formGroup">
              <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true" href="#studentProfile" (click)="viewApplication(student.id)"
                class="btn TT-primaryBTN TT-topSpaceZero" value="Attend" data-target="#studentProfile"
                data-toggle="modal">
                View</a>
            </td>
            <td class="dropdown TT-tableDropDownMenuList">
              <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
              <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                <li class="TT-optionsList" (click)="messageModal(student)">
                  <a href="#adminMessageModal" data-toggle="modal" trans="Message Student" class="TT-frameSet">Message
                    Student</a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</div>
<app-datatable-footer-new *ngIf="topStudents$ && topStudents$.length>0"></app-datatable-footer-new>