import { Component, OnInit } from '@angular/core';
import { FinanceService } from '../finance.service'
import { Router } from '@angular/router';
import { CurrentUser } from '../../../auth/current-user';

@Component({
  selector: 'app-finsidenav',
  templateUrl: './finsidenav.component.html',
  styleUrls: ['./finsidenav.component.css']
})
export class FinsidenavComponent implements OnInit {

  public available_balance = 0;
  public unavailable_balance = 0;
  public total_balance = 0;
  public wallet;
  public wallet_balance;

  constructor(public finance: FinanceService, 
    private router: Router,
    public currentuser: CurrentUser) {
    this.finance.is_reload_records.subscribe(response => {         
      this.finance.getFinanceData().subscribe(response => {
          this.wallet = response['data']['wallet_summary'];
          this.wallet_balance = this.wallet.balance;
          this.available_balance = this.wallet.available_balance;
          this.unavailable_balance = this.wallet.unavailable_balance;
          this.total_balance = this.wallet.total_balance;
      });
    });
  }

  ngOnInit(): void {
  	/*
    this.finance.getFinanceData()
    .subscribe(response => {
        this.wallet = response['data']['wallet_summary'];
        this.wallet_balance = this.wallet.balance;
        this.available_balance = this.wallet.available_balance;
        this.unavailable_balance = this.wallet.unavailable_balance;
        this.total_balance = this.wallet.total_balance;
    });
    */
  }

  public redirectTo() {
    this.router.navigateByUrl('admin/finance/admintrans?tab=Sale%20Transactions&page=1')
  }

 

}
