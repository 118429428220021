import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-db-reset',
  templateUrl: './db-reset.component.html',
  styleUrls: ['./db-reset.component.css']
})
export class DbResetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
