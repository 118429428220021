<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->
<div class='content-container customTabs max_Scroll'>
   <div class="row formspace">
      <div class="col-sm-12">
         <div class="table-responsive TT-responsiveTable">
            <table class="ttable table-striped TT-table TT-tableHeight">
               <thead>
               <tr>
                  <th trans="Tutor">
                     Tutor
                  </th>
                  <th trans="Student">
                     Student
                  </th>
                  <th trans="Subject">
                     Subject
                  </th>
                  <th trans="Booing Date">
                     Booing Date
                  </th>
                  <th trans="Booking Time">
                     Booking Time
                  </th>
                  <th trans="Class Status">
                     Class Status
                  </th>
                  <th trans="Booked Time">
                     Booked Time
                  </th>
                  <th trans="Student Attendence">
                     Student Attendence
                  </th>
                  <th>
                     Tutor Attendence
                  </th>
                  <th trans="Rating">
                     Rating
                  </th>
                  <th trans="Actions">
                     Actions
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let lesson of allLessons$">
                  <td>
                     {{lesson.booking_details.tutor_resume.display_name || 'N/A'}}
                  </td>
                  <td>
                     <span *ngIf="lesson.booking_details && lesson.booking_details.student">
                        {{lesson.booking_details.student.username || 'N/A'}}
                     </span>
                  </td>
                  <td *ngIf="lesson.booking_details && lesson.booking_details.subject">
                     {{lesson.booking_details.subject.subject_name || 'N/A'}}
                  </td>
                  <td>
                     {{lesson.slot_date | date: 'MM-dd-yyyy' || 'N/A'}}
                  </td>
                  <td>
                     {{lesson.booked_time || 'N/A'}}
                  </td>
                  <td>
                     {{transform(lesson.class_status)}}
                  </td>
                  <td>
                     {{lesson.created_at | date: 'MM-dd-yyyy h:mm a' || 'N/A'}}
                  </td>
                  <td>
                     {{transform(lesson.student_attendence || 'N/A')}}
                  </td>
                  <td>
                     {{transform(lesson.tutor_attendence || 'N/A')}}
                  </td>
                  <td>
                     {{lesson.rating || '0'}}/5
                  </td>
                  <!--                <td class="lesson-btn-actions dropdown">
                  <a *ngIf="lesson.review" id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" href="#adminReviewModal" (click)="viewReview(lesson.review , lesson.booking_details.student.username)" class="btn btn-teacher_admin" value="Attend" data-target="#adminReviewModal" data-toggle="modal">
                  View Review</a>
                  </td> -->
                  <td class="dropdown TT-tableDropDownMenuList">
                     <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                        <li *ngIf="lesson.cancel_status == 'directCancellation' && lesson.class_status =='In-Complete'"
                           href="#directSingleCancellation" data-target="#directSingleCancellation" data-toggle="modal"
                           (click)="directSingleCancellation$(lesson['booking_details'].booking_id , lesson.booking_dates_id)">
                           <a>Direct Cancellation {{lesson.cancel_status}}</a></li>
                        <li class="divider"></li>
                        <li (click)="messageModal(lesson.booking_details.student , 'student')"><a
                              href="#adminMessageModal" data-toggle="modal" trans="Message Student">Message Student</a>
                        </li>
                        <li class="divider"></li>
                        <li (click)="messageModal(lesson.booking_details.tutor_resume , 'tutor')"><a
                              href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a></li>
                        <li>
                           <a *ngIf="lesson.review" id="staticBackdrop" data-backdrop="static" tabindex="-1"
                              aria-labelledby="staticBackdropLabel" aria-hidden="true" href="#adminReviewModal"
                              (click)="viewReview(lesson.review , lesson.booking_details.student.username)"
                              class="btn btn-teacher_admin" value="Attend" data-target="#adminReviewModal"
                              data-toggle="modal" trans="View Review">
                              View Review</a>
                        </li>
                     </ul>
                  </td>
               </tr>
               <br>
               <tr *ngIf="allLessons$ && allLessons$.length==0" class="textalign" trans="No records found">No records
                  found.</tr>
            </tbody>
         </table>
      </div>
         <!-- <app-datatable-footer-new *ngIf="allLessons$ && allLessons$.length > 0"></app-datatable-footer-new> -->
         <app-datatable-footer-new *ngIf="allLessons$ && allLessons$.length > 0"></app-datatable-footer-new>
      </div>
   </div>