<div class="row m-auto">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
       <table class="table table-striped TT-table">
          <thead>
    
        <tr>
             <th trans="Username">Username</th>
             <th trans="Email">Email</th>
	         <th trans="Lessons">Lessons</th>
	         <th trans="Complete">Complete</th>
	         <th trans="Cancelled">Cancelled</th>
	         <th trans="Complaints">Complaints</th>
	         <th trans="Attendence" >Attendence</th>
	         <th trans="Response">Response</th>
	         <th trans="Students">Students</th>
	         <th trans="Reviews">Reviews</th>
	         <th trans="Actions">Actions</th>
	       </tr>
           </thead>
           <tbody>
        <tr *ngFor="let tutor of topTutors$">
            <td><span *ngIf="tutor.tutor_profile">{{tutor.tutor_profile.username}}</span></td>
            <td><span *ngIf="tutor.tutor_profile">{{tutor.tutor_profile.email}}</span></td>
            <td><span *ngIf="tutor.total_lessons_for_tutor">{{tutor.total_lessons_for_tutor.lessons || 0}}</span></td>
            <td><span *ngIf="tutor.total_lessons_for_tutor">{{tutor.total_lessons_for_tutor.completed || 0 }}</span></td>
            <td><span *ngIf="tutor.total_lessons_for_tutor">{{tutor.total_lessons_for_tutor.cancelled || 0 }}</span></td>
            <td><span *ngIf="tutor.total_lessons_for_tutor">{{tutor.total_lessons_for_tutor.complaints || 0 }}</span></td>
            <td><span *ngIf="tutor.total_lessons_for_tutor">{{tutor.total_lessons_for_tutor.attendance || 0 }}</span></td>
            <td>{{tutor.response_time || 0}}</td>
            <td><span *ngIf="tutor.total_lessons_for_tutor">{{tutor.students || 0}}</span></td>
            <td>{{tutor.all_reviews}}</td>
            <td class="TT-formGroup clearfix">

             <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" href="#modalApproval" (click)="viewApplication(tutor.user_id,'existingProfile')" class="btn TT-primaryBTN pull-right TT-topSpaceZero" value="Attend" data-target="#modalApproval" data-toggle="modal">
                View</a>
            </td>
           </tr>
           <tr *ngIf="topTutors$ && topTutors$.length == 0" class="textalign">
             <td class="text_center" colspan="5" align="center" trans="No Records Found">No Records Found</td>
           </tr>
         </tbody>
       </table>
     </div>
     <app-datatable-footer-new></app-datatable-footer-new>