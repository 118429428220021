

<!-- Payout Request from tutor to admin -->
<div class='content-container customTabs'>
   <div class="row formspace">
      <div class="col-sm-12">
         <table class="table lesson-table">
            <tbody>
               <tr>
                  <th trans="Amount">
                     Amount
                  </th>
                  <th trans="Name">
                     Name
                  </th>
                  <th trans="Timestamp">
                     Timestamp
                  </th>
                  <th trans="Tutor Comment ">
                     Tutor Comment 
                  </th>
                  <th trans="Health Check">
                     Health Check
                  </th>
                  <th trans="Status">
                     Status
                  </th>
                  <th trans="Actions">
                     Actions
                  </th>
               </tr>
               <tr *ngFor="let request of payoutsRequests$ | async">
                  <td>
                     $ {{request.amount_gbp}} - {{request.user_currency_code + ' ' + request.amount}}
                  </td>

                  <td>
                     <span *ngIf="request.user">{{request.user.full_name}}</span>
                  </td>
                  <td>
                     {{request.created_at | date: 'MM-dd-yyyy h:mm a'}}
                  </td>
                  <td>
                     {{request.user_comment || 'N/A'}}
                  </td>
                  <td>
                     <span class="">
                     <span tooltip="transaction_id" [ngClass]="{'Red_check': request.transaction_id==false, 'Green_check' : request.transaction_id==true }">
                     <i class="fa fa-check-circle" > </i>
                     </span>
                     <span tooltip="no_active_complaints" [ngClass]="{'Red_check': request.no_active_complaints==false, 'Green_check' : request.no_active_complaints==true }">
                     <i class="fa fa-check-circle" > </i>
                     </span>
                     <span tooltip="payout_amount" [ngClass]="{'Red_check': request.payout_amount==false, 'Green_check' : request.payout_amount==true }">
                     <i class="fa fa-check-circle" > </i>
                     </span>
                     <span tooltip="tutor_id_verification_count" [ngClass]="{'Red_check': request.tutor_id_verification_count==0, 'Green_check' : request.tutor_id_verification_count>0 }">
                     <i class="fa fa-check-circle" > </i>
                     </span>
                     <span tooltip="previous_payout_approval" [ngClass]="{'Red_check': request.previous_payout_approval==false, 'Green_check' : request.previous_payout_approval==true }">
                     <i class="fa fa-check-circle" > </i>
                     </span>
                     </span>
                  </td>
                  <td>
                     {{request.status}}
                  </td>
                  <td class="lesson-btn-actions dropdown">
                     <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu dropdown-new ">
                        <li *ngIf="request.status !='rejected' && request.status !='self_cancelled_new_request' && request.approveAccess == true" (click)="payoutRequest(request.id, request.user)" href="#paymentOutActions" data-target="#paymentOutActions" data-toggle="modal"><a data-toggle="modal" trans="Approve - Reject">Approve - Reject</a></li>
                        <li class="divider"></li>
                        <li (click)="messageModal(request.user)"><a href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a></li>
                     </ul>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>