import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-top-reviews',
  templateUrl: './top-reviews.component.html',
  styleUrls: ['./top-reviews.component.css']
})
export class TopReviewsComponent implements OnInit {

  @Output() adminMessageModalData = new EventEmitter();
  @Output() reviewModalData = new EventEmitter();
  public topReviews$:any;
  @Input() 
  public set topReviews(value){
    this.topReviews$ = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

  public messageModal(userData , role){
    if(role == 'student'){
    this.adminMessageModalData.emit(userData);
    }else{
    this.adminMessageModalData.emit({username:userData.display_name , id:userData.user_id});
    }
  }

  public viewReview(review , username){
    this.reviewModalData.emit({review:review , name:username});
  }

}
