<div class='col-sm-10 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Student Booking Payments">Student Booking Payments</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <table class="table lesson-table">
         <tr>
            <th table-header-checkbox></th>
            <th table-sort-header trans="Purchase Date">Purchase Date</th>
            <th table-sort-header trans="Student Name">Student Name</th>
            <th table-sort-header trans="Booking Type">Booking Type</th>
            <th table-sort-header trans="Transaction No">Transaction No</th>
            <th table-sort-header trans="Payment Type">Payment Type</th>
            <th table-sort-header trans=">Amount Paid">Amount Paid</th>
         </tr>
         <tr *ngFor="let booking of studentbookingPayments">
         <td table-header-checkbox></td>   
         <td>{{booking.paid_date}}</td>
         <td>{{booking.first_name}}</td>
         <td>{{booking.booking_type}}</td>
         <td>{{booking.transaction_id}}</td>
         <td>{{booking.payment_gateway}}</td>
         <td>{{booking.total_amount}}</td>
      </tr>
      </table>
   </div>
</div>
