<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <form [formGroup]="stripeSettingsForm" class="account-info" (ngSubmit)="savestripeSettings()">
         <div class="row m-auto">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <h4 class="TT-AccSettings" trans="Stripe Settings">Stripe Settings</h4>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="row m-auto">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Stripe Mode" for="stripe_mode">Stripe Mode <small
                              class="text-success">(https://dashboard.stripe.com/apikeys)</small> </label>
                        <select formControlName="stripe_mode" class="TT-FieldGroup TT-SelectIcon" id="stripe_mode">
                           <option value="" trans="Please select"> Please Select</option>
                           <option value="sandbox" trans="Sandbox">Sandbox</option>
                           <option value="live" trans="Live">Live</option>
                        </select>
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Stripe Version" for="stripe_version"> Stripe Version </label>
                        <select formControlName="stripe_version" class="TT-FieldGroup TT-SelectIcon"
                           id="stripe_version">
                           <option value="" trans="Please select"> Please Select</option>
                           <option value="2020-08-27">08-27-2020</option>
                        </select>
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Secret Key(Live)" for="stripe_secret_key_live"> Secret Key(Live)
                        </label>
                        <input type="text" placeholder="Secret Key(Live)" class="TT-FieldGroup"
                           formControlName="stripe_secret_key_live" id="stripe_secret_key_live" />
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Secret Key(Sandbox)" for="stripe_secret_key_sandbox"> Secret
                           Key(Sandbox) </label>
                        <input type="text" placeholder="Secret Key(Sandbox)" class="TT-FieldGroup"
                           formControlName="stripe_secret_key_sandbox" id="stripe_secret_key_sandbox" />
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Publishable key(Live)" for="stripe_publishable_key_live">
                           Publishable Key(Live) </label>
                        <input type="text" placeholder="Publishable Key(Live)" trans-placeholder="Publishable key(Live)"
                           class="TT-FieldGroup" formControlName="stripe_publishable_key_live"
                           id="stripe_publishable_key_live" />
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Publishable key(Sandbox)" for="stripe_publishable_key_sandbox">
                           Publishable Key(Sandbox) </label>
                        <input type="text" placeholder="Publishable Key(Sandbox)"
                           trans-placeholder="Publishable key(Sandbox)" class="TT-FieldGroup"
                           formControlName="stripe_publishable_key_sandbox" id="stripe_publishable_key_sandbox">
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Client Id" for="stripe_client_id_live">Client Id(Live)</label>
                        <input type="text" placeholder="Client Id" class="TT-FieldGroup"
                           formControlName="stripe_client_id_live" id="stripe_client_id_live" />
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Client Id" for="stripe_client_id_sandbox"> Client Id(Sandbox)
                        </label>
                        <input type="text" placeholder="Client Id(Sandbox)" class="TT-FieldGroup"
                           formControlName="stripe_client_id_sandbox" id="stripe_client_id_sandbox" />
                     </div>
                  </div>
               </div>

               <div class="row m-auto">
                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-6 col-xs-12">&nbsp;</div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup TT-responsiveTable clearfix">
                        <button type="submit" class="btn TT-primaryBTN TT-marginRightEffect pull-right"
                           trans="Update">Update </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </div>
</div>