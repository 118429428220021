import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LessonsMenuService } from '../lessons-menu.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
declare var $;
@Component({
  selector: 'app-schedule-settings',
  templateUrl: './schedule-settings.component.html',
  styleUrls: ['./schedule-settings.component.css']
})
export class ScheduleSettingsComponent implements OnInit {

  public scheduleForm = this.fb.group({
        standard_fee: ['',[Validators.pattern("^[0-9]*$")]],
        commission: ['',[Validators.pattern("^[0-9]*$")]],
        admin_discount: ['',[Validators.pattern("^[0-9]*$")]],
        first_lesson_discount: ['',[Validators.pattern("^[0-9]*$")]],
        special_discount: ['',[Validators.pattern("^[0-9]*$")]],
        tutor_discount:['',[Validators.pattern("^[0-9]*$")]]

    });
    public packageForm = this.fb.group({
        package_standard_fee: ['',[Validators.pattern("^[0-9]*$")]],
        package_commission: ['',[Validators.pattern("^[0-9]*$")]],
        package_admin_discount: ['',[Validators.pattern("^[0-9]*$")]],
        package_first_lesson_discount: ['',[Validators.pattern("^[0-9]*$")]],
        package_special_discount: ['',[Validators.pattern("^[0-9]*$")]],
        package_tutor_discount:['',[Validators.pattern("^[0-9]*$")]],
        direct_cancellation_reschedule:['',[Validators.pattern("^[0-9]*$")]],
        minminum_time_for_attendence:['',[Validators.pattern("^[0-9]*$")]]
    });
    public subscriptionForm = this.fb.group({
      free_trial_days: ['',[Validators.pattern("^[0-9]*$")]],
      monthly_subscription_amount: ['',[Validators.pattern("^[0-9]*$")]],
  });
    //schedule form
    get standard_fee() {return this.scheduleForm.get('standard_fee')};
    get commission() {return this.scheduleForm.get('commission')};
    get admin_discount() {return this.scheduleForm.get('admin_discount')};
    get first_lesson_discount() {return this.scheduleForm.get('first_lesson_discount')};
    get special_discount() {return this.scheduleForm.get('special_discount')};
    get tutor_discount() {return this.scheduleForm.get('tutor_discount')};
    //Package form
    get package_standard_fee() {return this.packageForm.get('package_standard_fee')};
    get package_commission() {return this.packageForm.get('package_commission')};
    get package_admin_discount() {return this.packageForm.get('package_admin_discount')};
    get package_first_lesson_discount() {return this.packageForm.get('package_first_lesson_discount')};
    get package_special_discount() {return this.packageForm.get('package_special_discount')};
    get package_tutor_discount() {return this.packageForm.get('package_tutor_discount')};
    get direct_cancellation_reschedule() {return this.packageForm.get('direct_cancellation_reschedule')};
    get minminum_time_for_attendence() {return this.packageForm.get('minminum_time_for_attendence')};
    //Package form
    get free_trial_days() {return this.packageForm.get('free_trial_days')};
    get monthly_subscription_amount() {return this.packageForm.get('monthly_subscription_amount')};

     public errorMessages = {
         standard_fee : [{ type:'required',message:'Standard fee is Required'},{ type:'pattern',message:'Enter valid number'}],
         commission :  [{ type:'required',message:'commission is Required'},{ type:'pattern',message:'Enter valid number'}],
         special_discount : [{ type:'pattern',message:'Enter valid number'}],
         tutor_discount : [{type:'pattern',message:'Enter valid number'}],
         admin_discount : [{type:'pattern',message:'Enter valid number'}],
         first_lesson_discount : [{ type:'pattern',message:'Enter valid number'}],
         package_standard_fee : [{ type:'required',message:'Standard fee is Required'},{ type:'pattern',message:'Enter valid number'}],
         package_commission :  [{ type:'required',message:'commission is Required'},{ type:'pattern',message:'Enter valid number'}],
         package_admin_discount : [{ type:'pattern',message:'Enter valid number'}],
         package_first_lesson_discount : [{ type:'pattern',message:'Enter valid number'}],
         package_special_discount : [{ type:'pattern',message:'Enter valid number'}],
         package_tutor_discount : [{ type:'pattern',message:'Enter valid number'}],
         direct_cancellation_reschedule: [{ type:'pattern',message:'Enter valid number'}],
         minminum_time_for_attendence: [{type:'pattern',message:'Enter Valid number'}]
     }

  constructor(private fb: FormBuilder,
              public settings: LessonsMenuService,
              private toastr: ToastrService,) { }

  ngOnInit(): void {

        $('.form-control').keyup(function () {
    this.value = this.value.replace(/[^0-9\.]/g,'');
});
    this.settings.getScheduleSettings()
      .subscribe(response => {
        this.scheduleForm.patchValue({
        standard_fee:response['settings'].standard_fee,
        commission:response['settings'].commission,
        special_discount : response['settings'].special_discount,
        tutor_discount : response['settings'].tutor_discount,
        admin_discount : response['settings'].admin_discount,
        first_lesson_discount : response['settings'].first_lesson_discount,
      });
      this.packageForm.patchValue({
        package_standard_fee : response['settings'].package_standard_fee,
        package_commission : response['settings'].package_commission,
        package_admin_discount : response['settings'].package_admin_discount,
        package_first_lesson_discount : response['settings'].package_first_lesson_discount,
        package_special_discount : response['settings'].package_special_discount,
        package_tutor_discount : response['settings'].package_tutor_discount,
        direct_cancellation_reschedule: response['settings'].direct_cancellation_reschedule,
        minminum_time_for_attendence: response['settings'].minminum_time_for_attendence
      });
      this.subscriptionForm.patchValue({
        free_trial_days : response['settings'].free_trial_days,
        monthly_subscription_amount : response['settings'].monthly_subscription_amount
      });
    });
  }


  public updateScheduleSettings(){
     this.scheduleForm.markAllAsTouched();
      if(this.scheduleForm.valid){
        this.settings.updateScheduleSettings(this.scheduleForm.value)
        .subscribe(response => {
          if(response.status == 'success')
         {
           this.toastr.success('Scheduled Type Discount Settings Update Successfull');
         }
        });
      }
  }
  public updateSchedulePackageSettings(){
     this.packageForm.markAllAsTouched();
      if(this.packageForm.valid){
        this.settings.updateScheduleSettings(this.packageForm.value)
        .subscribe(response => {
          if(response.status == 'success')
         {
           this.toastr.success('Package Type Discount Settings Update Successfull');
         }

        });
      }
  }
  public updateSubscriptionSettings(){
    this.subscriptionForm.markAllAsTouched();
     if(this.subscriptionForm.valid){
       this.settings.updateScheduleSettings(this.subscriptionForm.value)
       .subscribe(response => {
         if(response.status == 'success')
        {
          this.toastr.success('Subscription Settings Update Successfull');
        }

       });
     }
 }

}
