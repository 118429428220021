<div class="TT-templateDraft">
   <div class="row m-auto">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
         <button (click)="createTemplate()" class="TT-AccUsers"><i aria-hidden="true" class="fa fa-plus"></i> Create </button>
      </div>

      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
        <div class="row">
         <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div class="d-flex TT-formGroup">
               <select (input)="selectedlistColumn($event.target.value , 'listSearch')" class="TT-FieldGroup TT-SelectIcon">
                 <option value="">Select Column</option>
                 <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;"> {{item.key}} </option>
               </select>
            </div>
         </div>
         <div class="col-xl-7 col-lg-7 col-md-7 col-sm-6 col-12 clearfix">
            <div class="d-flex TT-formGroup TT-searchFind pull-right">
               <span class="TT-formGroup">
                <input [formControl]="searchControl" id="listSearch" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search Here">
                <i class="fa fa-search TT-searchIcon" aria-hidden="true"> </i>
               </span>
               <span (click)="csvExort('Templates')" class="TT-csvIcon">
                 <img src="assets/images/csv.png" width="30" class="img-responsive" alt="">
               </span>
            </div>
         </div>
       </div> 
     </div>
   </div>

   <div class="row m-auto">
     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
         <table class="table table-striped TT-table TT-tableHeight">
           <thead> 
            <tr>
               <th>TITLE</th>
               <th>KEY</th>
               <th>SUBJECT</th>
               <th>FROM NAME</th>
               <th>ACTION</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let template of templates$ | async">
               <td>{{template.title}}</td>
               <td>{{template.key}}</td>
               <td>{{template.subject}}</td>
               <td>{{template.from_name}}</td>
               <td class="dropdown TT-tableDropDownMenuList">
                <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                 <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                   <li (click)="edit(template)" class="TT-optionsList"><a class="TT-frameSet">Edit</a></li>
                   <!-- <li (click)="delete(template.id)" class="TT-optionsList"><a class="TT-frameSet">Delete</a></li> -->
                   <li class="TT-optionsList" (click)="changeStatus(template.id, 'active')" *ngIf="template.status == 'inactive'"><a class="TT-frameSet" trans="Enable">Enable</a></li>
                   <li class="TT-optionsList" (click)="changeStatus(template.id, 'inactive')" *ngIf="template.status == 'active'"><a class="TT-frameSet" trans="Disable">Disable</a></li>
                </ul>
               </td>
             </tr>
           </tbody>
         </table>
        
      </div>       
     </div>
   </div>
   <app-datatable-footer-new *ngIf="(templates$ | async).length > 0"></app-datatable-footer-new>
</div>