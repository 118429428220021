<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->
<div class='content-container customTabs'>
   <div class="row formspace">
      <div class="col-sm-12">
         <div class="table-responsive TT-responsiveTable">
            <table class="ttable table-striped TT-table TT-tableHeight">
               <thead>
               <tr>
                  <th trans="Tutor">
                     Tutor
                  </th>
                  <th trans="Student">
                     Student
                  </th>
                  <th trans="Subject">
                     Subject
                  </th>
                  <th trans="Booing Date">
                     Booing Date
                  </th>
                  <th trans="Booking Time">
                     Booking Time
                  </th>
                  <th trans="Class Status">
                     Class Status
                  </th>
                  <th trans="Booked Time">
                     Booked Time
                  </th>
                  <th trans="Student Attendence">
                     Student Attendence
                  </th>
                  <th trans="Tutor Attendence">
                     Tutor Attendence
                  </th>
                  <th trans="Actions">
                     Actions
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let lesson of refundedLessons$">
                  <td>
                     {{lesson.booking_details.tutor_resume.display_name || 'N/A'}}
                  </td>
                  <td>
                     <span *ngIf="lesson.booking_details && lesson.booking_details.student">
                        {{lesson.booking_details.student.username || 'N/A'}}
                     </span>
                  </td>
                  <td *ngIf="lesson.booking_details && lesson.booking_details.subject">
                     {{lesson.booking_details.subject.subject_name || 'N/A'}}
                  </td>
                  <td>
                     {{lesson.slot_date | date: 'MM-dd-yyyy'|| 'N/A'}}
                  </td>
                  <td>
                     {{lesson.booked_time || 'N/A'}}
                  </td>
                  <td>
                     {{transform(lesson.class_status)}}
                  </td>
                  <td>
                     {{lesson.created_at | date: 'MM-dd-yyyy h:mm a' || 'N/A'}}
                  </td>
                  <td>
                     {{transform(lesson.student_attendence || 'N/A')}}
                  </td>
                  <td>
                     {{transform(lesson.tutor_attendence || 'N/A')}}
                  </td>
                  <td class="lesson-btn-actions dropdown">
                     <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu dropdown-new">
                        <li (click)="messageModal(lesson.booking_details.student , 'student')"><a
                              href="#adminMessageModal" data-toggle="modal" trans="Message Student">Message Student</a>
                        </li>
                        <li class="divider"></li>
                        <li (click)="messageModal(lesson.booking_details.tutor_resume , 'tutor')"><a
                              href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a></li>
                     </ul>
                  </td>
               </tr>
               <br>
               <tr *ngIf="refundedLessons$ && refundedLessons$.length==0" class="textalign" trans="No records found">No
                  records found.</tr>
            </tbody>
         </table>
         </div>
         <app-datatable-footer-new *ngIf="refundedLessons$ && refundedLessons$.length > 0"></app-datatable-footer-new>
      </div>
   </div>