import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { CurrentUser } from '../../auth/current-user';
import { LocaleTimeZoneTS } from '../../auth/localetimezone';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AdminMessagemodalComponent } from '../admin-messagemodal/admin-messagemodal.component';
import { HomeService } from './home.service';
import { ToastrService } from 'ngx-toastr';
import { StudentsService } from '../students/students.service';
import { ViewNoteComponent } from '../view-note/view-note.component';
import { LessonsMenuService } from '../lessons-menu/lessons-menu.service';
import { ChatService } from '@athand/chat/chat.service';

declare var $;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild(ViewNoteComponent) noteChild: ViewNoteComponent;
  @ViewChild(AdminMessagemodalComponent) adminMessageChild: AdminMessagemodalComponent;
  public isDataLoaded: boolean = false;
	public refund_requests;
	public refund_requests_count;
	public dashboard;
	public recent_alerts;
	public recent_alerts_count;
	public open_complaints;
	public open_complaints_count;
	public top_tutors;
	public top_tutors_count;
	public recent_messages;
	public recent_messages_count;
	public available_balance = 0;
	public unavailable_balance = 0;
	public total_balance = 0;
	public wallet;
  public wallet_balance;
  public top_subjects;
  public top_subjects_count;
  public top_students;
  public top_students_count;

  public notifications = new Array();
  public usernotifications: any;
  public usernotifications_read: any;
  public usernotifications_read_count: any;
  public usernotifications_unread: any;
  public usernotifications_unread_count: any;
  public usermessages_unread_count: any;

  constructor(public auth: AuthService,
  	          public currentuser: CurrentUser,
              private elementRef: ElementRef,
              private router: Router,
              public localetimezone: LocaleTimeZoneTS,
              private homeService: HomeService,
              private toastr: ToastrService,
              private fb: FormBuilder,
              public service: StudentsService,
              private menuservice: LessonsMenuService,
              public chatService: ChatService,
) { }

  ngOnInit(): void {
      
    this.homeService.adminDashboard()
  	.subscribe(response => {
			this.dashboard =  JSON.parse(atob(response['dashboard']));
			this.recent_alerts = this.dashboard['recent_alerts'];
			this.recent_alerts_count = this.recent_alerts.length;
			this.open_complaints = this.dashboard['open_complaints'];
			this.open_complaints_count = this.open_complaints.length;
			this.top_tutors = this.dashboard['top_tutors'];
			this.top_tutors_count = this.top_tutors.length;
			this.recent_messages = this.dashboard['recent_messages'];
			this.recent_messages_count = this.recent_messages.length;
			this.wallet = this.dashboard['wallet_summary'];
		  this.wallet_balance = this.wallet.balance;
		  this.available_balance = this.wallet.available_balance;
		  this.unavailable_balance = this.wallet.unavailable_balance;
		  this.total_balance = this.wallet.total_balance;
			this.top_subjects = this.dashboard['top_subjects'];
			this.top_subjects_count = this.top_subjects.length;
			this.top_students = this.dashboard['top_students'];
			this.top_students_count = this.top_students.length;
      this.isDataLoaded = true;
  	});

    if ($('#mainSidebar').hasClass('pageSectionBg-collapsable')) {
      $('#pageContent').addClass('pageSectionBg-collapsable');	
    } else if($('#pageContent').hasClass('pageSectionBg-collapsable')) {
      $('#mainSidebar').addClass('pageSectionBg-collapsable')
    }
    $('.collapseToggle').on('click', function() {
      $(".tt-sideArea").toggleClass('sidebar--Collapse');
      $('.pageSectionBg').toggleClass('pageSectionBg-collapsable');
      $(".tt-sideArea li").click(function(){
        $(".tt-sideArea").removeClass("sidebar--Collapse");
      });
    });
   
  }

  public userChat(user){
    this.userChat = user;
    this.chatService.selectedUserChat(user);
    this.router.navigate(['student/student-chat']);
    }

  public editProfile(){
    this.router.navigate(['admin/account/settings']);
  }

  public viewRefundedLessons(){
    this.router.navigate(['admin/lessons-menu/lessons-listing']);
  }

  public viewWallet(){
    this.router.navigate(['admin/finance/admintrans']);
  }

  public viewChat(){
    this.router.navigate(['admin/chat']);
  }

  public viewAlerts(){
    this.router.navigate(['admin/alerts']);
  }

  public viewAlert(notification){

    (notification.redirect_url) ? this.router.navigate([notification.redirect_url]) : '';

   if(notification.is_read == 0){
        this.menuservice.readNotification(notification.id).subscribe(response=>{
        this.usernotifications =JSON.parse(atob(response['notifications']));
        this.usernotifications_read = this.usernotifications.is_read;
        this.usernotifications_read_count = this.usernotifications.is_read.length;

        this.usernotifications_unread = this.usernotifications.is_unread;
        this.usernotifications_unread_count = this.usernotifications.is_unread.length;
      });
      }
   this.removeAlert();
  }

  

  public removeAlert(){
        if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alert-box-alerts").removeClass("activeAlert");
          $("#alerts").removeClass("active");
        }
        this.getNotifications();
    }

  public viewTopTutors(){
    this.router.navigate(['admin/tutors/registration']);
  }
  public viewTopStudents(){
    this.router.navigate(['admin/students']);
  }

  public landingPage(){
    this.router.navigateByUrl('/tutoring');
  }

  public viewAllCompliants(){
  	this.router.navigate(['admin/students']);
  }

  public viewMessages() {
    this.router.navigate(['admin/inbox']);
  }


  public messageModal(userData , role){
    console.log(userData)
    this.adminMessageChild.userData(userData);
  }

     public complaintAction(scheduleId , operation , complaintId){
    let data = Object();
    Object.assign(data,{complaintId:complaintId})
    Object.assign(data,{scheduleId:scheduleId});
    Object.assign(data,{operation:operation});
    Object.assign(data,{from:'Admin'});
      this.service.compliantActon(data).subscribe(response=>{
      this.open_complaints =  JSON.parse(atob(response['compliants']));
      this.open_complaints_count = this.open_complaints.length;
     
       if(response['status'] == "success"){
       this.toastr.success('Complaint Closed');
       }
      });
   }

   public viewComment(comment){
   this.noteChild.noteData(comment);
   }


   public openAlerts(){
    this.router.navigate(['admin/alerts']);
  }

  public getNotifications(){
      this.menuservice.getNotification().subscribe(response=>{
        let data =JSON.parse(atob(response['notifications']));
        this.usernotifications = data['notifications'];
        this.usernotifications_read = this.usernotifications.is_read;
        this.usernotifications_read_count = this.usernotifications.is_read.length;

        this.usernotifications_unread = this.usernotifications.is_unread;
        this.usernotifications_unread_count = this.usernotifications.is_unread.length;
        this.usermessages_unread_count = data['inboxCount'];
      });
  }

  

  public humanize(str) {
    let str_type = typeof str;
    if ( str_type == 'object' ) {
      return str;
    }
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  public displayTextLimit(text) {
    if ( text.length > 150 ) {
      return text.substring(0, 150) + '...';
    } else {
      return text;
    }
  }
  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}

