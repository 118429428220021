import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableService } from '@core/datatable/datatable.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TeachersService } from '../teachers.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { User } from '@core/types/models/User';
import { FormControl } from '@angular/forms';
import { AdminMessagemodalComponent } from '../../admin-messagemodal/admin-messagemodal.component';
import { CsvService } from '@services/csv/csv.service';
declare var $;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'] ,
  providers: [DatatableService , TeachersService] ,
})
export class RegistrationComponent implements OnInit {
  
  @ViewChild(AdminMessagemodalComponent) adminMessageChild: AdminMessagemodalComponent;
	public tabName:any = 'Pending Tutors';
	public pendingTutorsCount:number = 0;
  public changesApprovalCount:number = 0;
	public topTutorsCount:number = 0;
	public acceptedTutorsCount:number = 0;
	public suspendedTutorsCount:number = 0;
	public deletedTutorsCount:number = 0;
	public allTutorsCount:number = 0;
  public tutorIdForResumeView:number;
  public tutorName:string;
	public tutors$ = this.datatable.data$;
	public tabsData:any;
  public pendingTutors:any;
  public changesApproval:any;
  public topTutors:any;
  public acceptedTutors:any;
  public suspendedTutors:any;
  public deletedTutors:any;
  public allTutors:any;
  public selectedTutorId:number;
  public selectedSection:string;
  public selectedTutorApplicationId:number;
  public tutorColumn:any;
  public obj:any;
  public searchControl = new FormControl();

  public pendingTutorsColumn = [ {'Username':['pendingTutors','username'] , 'Email':['pendingTutors','email'] }];
  public topTutorsColumn = [ {'Username':['tutorProfile','username'] , 'Email':['tutorProfile','email'] }];
  public acceptedTutorsColumn = [ {'Username':['acceptedTutors','username'] , 'Email':['acceptedTutors','email'] }];
  public suspendedTutorsColumn = [ {'Username':['suspendedTutors','username'] , 'Display Name':['suspendedTutors','display_name'] }];
  public deletedTutorsColumn = [ {'Username':['deletedTutors','username'] , 'Display Name':['deletedTutors','display_name'] }];
  public chnagesApprovalColumn = [ {'Username':['tutorProfile','username'] , 'Email':['tutorProfile','email'] }];
  public allTutorsColumn =  [ {'Username':['tutorProfile','username'] , 'Email':['tutorProfile','email'] }];

  constructor(public service: TeachersService,public datatable: DatatableService<User>,public csvService: CsvService) { }

  ngOnInit(): void {

            this.searchControl.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.datatable.addFilter('column',this.tutorColumn);
                this.datatable.addFilter('tQuery', query);
            });

      this.pendingTutorsList();

      this.datatable.addFilter('tab',this.tabName);

  	  $('.tab-link').click( function() {
        var tabID = $(this).attr('data-tab');
        $(this).addClass('active').siblings().removeClass('active');
        $('#tab-'+tabID).addClass('active').siblings().removeClass('active');
       });

  this.teachersTabsCount();

  this.tutors$.subscribe(response =>{

   if(this.tabName == 'Pending Tutors'){
    this.pendingTutors = response;
    this.obj = Object.assign({}, ...this.pendingTutorsColumn);
   }else if(this.tabName == 'Top Tutors'){
    this.topTutors = response;
    this.obj = Object.assign({}, ...this.topTutorsColumn);
   }else if(this.tabName == 'Accepted Tutors'){
    this.acceptedTutors = response;
    this.obj = Object.assign({}, ...this.acceptedTutorsColumn);
   }else if(this.tabName == 'Suspended Tutors'){
    this.suspendedTutors = response;
    this.obj = Object.assign({}, ...this.suspendedTutorsColumn);
   }else if(this.tabName == 'Deleted Tutors'){
    this.deletedTutors = response;
    this.obj = Object.assign({}, ...this.deletedTutorsColumn);
   }else if(this.tabName == 'All Tutors'){
    this.allTutors = response;
    this.obj = Object.assign({}, ...this.allTutorsColumn);  
   }else if(this.tabName == 'Changes Approval'){
    this.changesApproval = response;
    this.obj = Object.assign({}, ...this.chnagesApprovalColumn);
   }
  });
  }


  public selectedTutorsColumn(column  , id){
    if(column){
      this.tutorColumn = column;
      $('#'+id).removeAttr('disabled');
    }else{
      this.tutorColumn = '';
      this.datatable.addFilter('column',this.tutorColumn);
      $('#'+id).attr('disabled','disabled');
    }
   $('#'+id).val('');
  }

  public pendingTutorsList(){
        this.datatable.init({
            uri: TeachersService.BASE_URI
        });
  }

  public selectTab(tabName){
    this.obj = '';
    if ( this.tabName != tabName ) { // Let us reset the form when tthe tab changed so that search willbe reset!
      this.searchControl.reset();
      $('.searchInput').attr('disabled','disabled');
      this.tutorColumn = '';
    }
    this.datatable.data$.next(null);
  	this.tabName = tabName;
    this.datatable.addFilter('tab',this.tabName);
    this.datatable.addFilter('column','');
    this.datatable.addFilter('tutorsQuery', '');
    this.datatable.addFilter('page','1');
  }



  public csvExort(name){
    let data = this.csvService.pivot(this.tutors$.value);
    (this.tutors$.value.length>0) ? new AngularCsv(data , name) : null;
  }

  public teachersTabsCount(){
  	this.service.tutorsDataCount().subscribe(response => {
    let res = JSON.parse(atob(response['data']));
    this.tabsData = res;
    this.deletedTutorsCount = this.tabsData['deletedTutorsCount'];
    this.suspendedTutorsCount = this.tabsData['suspendedTutorsCount'];
    this.topTutorsCount = this.tabsData['topTutorsCount'];
    this.acceptedTutorsCount = this.tabsData['acceptedTutorsCount'];
    this.pendingTutorsCount = this.tabsData['pendingTutorsCount'];
    this.allTutorsCount = this.tabsData['allTutorsCount'];
    this.changesApprovalCount = this.tabsData['changesApprovalCount'];
  	});
  }

  public tutorId(event){
    this.selectedTutorId = event
  }

  public tuorViewApplicationId(id){
    this.selectedTutorApplicationId = id;
  }

  public reloadTutors(value){
   if(value == true){
     this.datatable.addFilter('tab',this.tabName);
     this.teachersTabsCount();
   }
  }

  public viewTutorResume(event){
       this.tutorName = event.tutorName;
       this.tutorIdForResumeView = event.tutorId;
       $('#profileTab').addClass('active').siblings().removeClass('active');
  }

   public messageModal(userData){
    this.adminMessageChild.userData(userData);
  }

}
