<!-- Payout Request from tutor to admin -->
<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
     <div class="table-responsive TT-responsiveTable">
       <table class="table table-striped TT-table"> 
             <thead>
                <tr>
                   <th trans="Amount">
                      Amount
                   </th>
                   <th trans="Name">
                      Name
                   </th>
                   <th trans="Timestamp">
                      Timestamp
                   </th>
                   <th trans="Tutor Comment">
                      Tutor Comment 
                   </th>
                   <th trans="Health Check">
                      Health Check
                   </th>
                   <th trans="Status">
                      Status
                   </th>
                   <th trans="Actions">
                      Actions
                   </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let request of payoutsRequests$ | async">
                   <td>
                      {{request.amount}}
                   </td>
                   <td>
                      {{request.user_id}}
                   </td>
                   <td>
                      {{request.created_at | date: 'MM-dd-yyyy h:mm a'}}
                   </td>
                   <td>
                      {{request.user_comment || 'N/A'}}{{request.transaction_id}}
                   </td>
                   <td style="min-width: 120px; padding: 0px;">
                      <span>
                      <span tooltip="transaction_id" [ngClass]="{'Red_check': request.transaction_id==false, 'Green_check' : request.transaction_id==true }">
                      <i class="fa fa-check-circle" > </i>
                      </span>
                      <span tooltip="transaction_id" [ngClass]="{'Red_check': request.transaction_id==false, 'Green_check' : request.transaction_id==true }">
                      <i class="fa fa-check-circle" > </i>
                      </span>
                      <span tooltip="payout_amount" [ngClass]="{'Red_check': request.payout_amount==false, 'Green_check' : request.payout_amount==true }">
                      <i class="fa fa-check-circle" > </i>
                      </span>
                      <span tooltip="tutor_id_verification_count" [ngClass]="{'Red_check': request.tutor_id_verification_count==0, 'Green_check' : request.tutor_id_verification_count>0 }">
                      <i class="fa fa-check-circle" > </i>
                      </span>
                      <span tooltip="previous_payout_approval" [ngClass]="{'Red_check': request.previous_payout_approval==false, 'Green_check' : request.previous_payout_approval==true }">
                      <i class="fa fa-check-circle" > </i>
                      </span>
                      </span>
                   </td>
                   <td>
                      {{request.status}}
                   </td>
                   <td class="dropdown TT-tableDropDownMenuList">
                      <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                      <ul class="dropdown-menu TT-dropDownMenu">
                         <li *ngIf="request.status !='rejected'" class="TT-optionsList" (click)="payoutRequest(request.id)" href="#paymentOutActions" data-target="#paymentOutActions" data-toggle="modal">
                           <a class="TT-frameSet" data-toggle="modal" trans="Approve - Reject">Approve - Reject</a>
                        </li>
                         <li class="TT-optionsList" (click)="messageModal(request.user_id)">
                           <a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a>
                        </li>
                      </ul>
                   </td>
                </tr>
                <tr *ngIf="studentsPayout_count$ == 0" class="TT-noRecord">
                  <td colspan="7" align="center" trans="Coming Soon">Coming Soon</td>
               </tr>
             </tbody>
          </table>
       </div>
    </div>
 </div>