import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {AppHttpClientService} from '../../core/http/app-http-client.service';


@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  static BASE_URI = 'templates';
  constructor(private http: AppHttpClientService) { }


   public templateName = new BehaviorSubject<Object>(null);
   sharedTemplate = this.templateName.asObservable();

   public template(value){
    this.templateName.next(value);
   }

   public templateValues = new BehaviorSubject<Object>(null);
   sharedTemplateValues = this.templateValues.asObservable();

   public templateId(value){
    this.templateValues.next(value);
   }

   public removeTemplate(id){
    return this.http.get('deleteTemplate/'+id);
   }

   public submitForm( payload: Object ){
   	return this.http.post('submitTemplatesData',payload);
   }

   public changeStatus(id, status){
    return this.http.get('changeTemplateStatus/'+id+'/'+status);
   }
}
