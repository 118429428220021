import { ChangeDetectionStrategy, Component, OnInit , Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DisplayImageUploadValidator } from './settings-image-upload-validator';
import { openUploadWindow } from '@athand/uploads/utils/open-upload-window';
import { UploadInputTypes } from '@athand/uploads/upload-input-config';
import { UploadQueueService } from '@athand/uploads/upload-queue/upload-queue.service';
import { finalize } from 'rxjs/operators';
import { randomString } from '../../../core/utils/random-string';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-settings-image-input',
  templateUrl: './settings-image-input.component.html',
  styleUrls: ['./settings-image-input.component.css'],
      host: {'tabindex': '0'},    
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SettingsImageInputComponent,
        multi: true,
    }]
})

export class SettingsImageInputComponent implements ControlValueAccessor {
  @Input() defaultValue: string;
  @Input() backendUri: string;
  @Input() type: string;
  private propagateChange: Function;
  public image$ = new BehaviorSubject(null);
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
  	private validator: DisplayImageUploadValidator,
  	private uploadQueue: UploadQueueService) { }

  ngOnInit(): void {
  }

      public openModal() {
        const params = {
            uri: this.backendUri || 'uploads/images',
            httpParams: {type: this.type},
            validator: this.validator,
        };
        openUploadWindow({types: [UploadInputTypes.image]}).then(files => {
            // this.loading$.next(true);
            this.uploadQueue.start(files, params)
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(response => {
                    this.updateValue(response.fileEntry.url);
                });
        });
    }

        private updateValue(value?: string) {
   
        // this.propagateChange(value);
        // make sure new image is loaded by browser as path will be the same
        this.image$.next(value + `?v=${randomString(8)}`);
    }

        public writeValue(value: string) {
        // this.image$.next(value);
    }

    public registerOnChange(fn: Function) {
        this.propagateChange = fn;
    }

    public registerOnTouched() {}
}
