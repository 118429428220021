import {Injectable} from '@angular/core';
import {AppHttpClientService} from '../../core/http/app-http-client.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(private http: AppHttpClientService) {}

    //Add and Update SuccessStories
    public postSucessStories(payload){
       return this.http.post('addSucessStory',payload);
    }

    //Add and Update WelcomeVideos
    public postWelcomeVideos(payload){
    	return this.http.post('addWelcomeVideos',payload);
    }

    public getValues(){
        return this.http.get('getGeneralSettingsData');
    }
    public submitForm( payload: Object ){
        return this.http.post('submitGeneralSettings',payload);
    }

    public submitAuthnticationForm( payload: Object ){
        return this.http.post('submitAuthenticationSettings',payload);
    }
    public getAuthnticationSettings(){
        return this.http.get('getAuthenticationSettings');
    }
    public factoryReset(payload: Object ){
        return this.http.post('factoryReset',payload);
    }

}
