<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->
<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
     <div class="table-responsive TT-responsiveTable">
       <table class="table table-striped TT-table">
          <thead>  
               <tr>
                  <!-- <th trans="Main Amount">
                     Main Amount
                  </th> -->
                  <th trans="Amount">
                     Amount
                  </th>
                  <th trans="Discount Used">
                     Discount Used
                  </th>
                  <th trans="Discount Type">
                     Discount Type
                  </th>
                  <th trans="Discount Value">
                     Discount Value
                  </th>
                  <th trans="Source (FROM)">
                     Source (FROM)
                  </th>
                  <th trans="Recipient (TO)">
                     Recipient (TO)
                  </th>
                  <th trans="Purpose">
                     Purpose
                  </th>
                  <th trans="Gateway">
                     Gateway
                  </th>
                  <th trans="Time Stamp">
                     Time Stamp
                  </th>
                  <th trans="Related To">
                     Related To
                  </th>
                  <th trans="Booking Id">
                     Booking Id
                  </th>
                  <th trans="Actions">
                     Actions
                  </th>
               </tr>
            </thead>
               <tbody>
               <tr *ngFor="let tran of transactions$ | async">
                  <!-- <td *ngIf="tran.fee;else empty_record">
                     {{tran.fee}}
                  </td> -->
                  <td>
                     <span *ngIf="tran.final_paid_amount;else empty_record">{{tran.final_paid_amount}}</span>
                     <ng-template #empty_record>-</ng-template>
                  </td>
                  <td>
                     <span *ngIf="tran.payment_discount_used == 'admin_whole_discount'"
                        trans="Admin Whole Discount">Admin Whole Discount</span>
                     <span *ngIf="tran.payment_discount_used == 'first_lesson_discount'"
                        trans="First Lesson Discount">First Lesson Discount</span>
                  </td>
                  <td>
                     {{transform(tran.payment_discount_type)}}
                  </td>
                  <td>
                     {{tran.payment_discount_amount}}({{tran.current_payment_discount}})
                  </td>
                  <td>
                     <span *ngIf="tran.student else empty_record">{{tran.student.username}}</span>
                     <ng-template #empty_record>-</ng-template>
                  </td>
                  <td>
                     <span *ngIf="tran.tutor else empty_record">{{tran.tutor.username}}</span>
                     <ng-template #empty_record>-</ng-template>
                  </td>
                  <td>
                     <span *ngIf="tran.booking_payment;else empty_record">{{transform(tran.booking_payment.purpose)}}</span>
                     <ng-template #empty_record>-</ng-template>
                  </td>
                  <td>
                     <!-- <span *ngIf="transaction.payment_gateway == 'paypal'" trans="PayPal">PayPal</span>
                     <span *ngIf="transaction.payment_gateway == 'stripe'" trans="Stripe">Stripe</span> -->
                     <span *ngIf="tran.booking_payment;else empty_record">{{transform(tran.booking_payment.payment_gateway)}}</span>
                     <ng-template #empty_record>-</ng-template>
                  </td>
                  <td>
                     {{tran.created_at | date: 'MM-dd-yyyy h:mm a'}}
                  </td>
                  <td>
                     Student - Tutor
                  </td>
                  <td>
                     {{tran.booking_id}}
                  </td>
                  <td class="dropdown TT-tableDropDownMenuList">
                    <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                       <li class="TT-optionsList" (click)="messageModal(tran.student)">
                         <a href="#adminMessageModal" data-toggle="modal" class="TT-frameSet" trans="Message Student">Message Student</a></li>
                       <li class="TT-optionsList" (click)="messageModal(tran.tutor)">
                        <a href="#adminMessageModal" data-toggle="modal" class="TT-frameSet" trans="Message Tutor">Message Tutor</a></li>
                     </ul>
                  </td>
               </tr>
            </tbody>
         </table>
         
      </div>
   </div>
   <app-datatable-footer-new></app-datatable-footer-new>