import { Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { SuccessStories } from '../../../auth/sucess-stories';
import { ToastrService } from 'ngx-toastr';
import { VideomodalComponent } from '../../videomodal/videomodal.component';
declare var $;

@Component({
  selector: 'app-landing-page-settings',
  templateUrl: './landing-page-settings.component.html',
  styleUrls: ['./landing-page-settings.component.css']
})
export class LandingPageSettingsComponent implements OnInit {
   
  @ViewChild(VideomodalComponent) videoChild:VideomodalComponent;
  public reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  public storylinks = new Array();
  // WelcomeForm Validators and Fields
  public welcomeForm = this.fb.group({
        'welcome_video': ['',Validators.pattern(this.reg)],
        'how_it_works_video': ['',Validators.pattern(this.reg)]
  });

  // SuccessStoriesForm Validators and Fields
  public successStoriesForm = this.fb.group({
        'success_stories': ['',Validators.required ],
        'user_name':['',Validators.required],
  })  

  // GetStartedVidesForm Validator and Fiels
  public getStartedVideos = this.fb.group({
  	     'creatAnAccountLink':['',[Validators.required ,Validators.pattern(this.reg)]]
  })

  
  // GET Form Values HERE
  get welcome_video(){ return this.welcomeForm.get('welcome_video');}
  get how_it_works_video(){ return this.welcomeForm.get('how_it_works_video');}
  get success_stories(){ return this.successStoriesForm.get('success_stories');}
  get user_name(){ return this.successStoriesForm.get('user_name');}
  


  // Error Messages For Input Fields
  public errorMessages = {
                welcome_video      : [{type:'required',message:'Field Required'},{type:'pattern',message:'InValid Link'}], 
                how_it_works_video : [{type:'pattern',message:'InValid Link'}],
                success_stories    : [{type:'pattern',message:'Invalid Link'}],
                user_name          : [{type:'pattern',message:'Usr Name Required'}]
                        }

  constructor(private fb: FormBuilder,
  	          public service: SettingsService,
              public successStories: SuccessStories,
              private toastr: ToastrService) { }

  ngOnInit(): void {
  this.storylinks = this.successStories.successStoriesLinks();
  	// This will swith the tabs
  	$('.tab-link').click( function() {
        var tabID = $(this).attr('data-tab');
        $(this).addClass('active').siblings().removeClass('active');
        $('#tab-'+tabID).addClass('active').siblings().removeClass('active');
    })
  }



  // This method will add Success Stories Links
  public addSuccessStories(){
 
  	 let link = this.successStoriesForm.value.success_stories;
     let thumbnail = '';
     if(link.search('youtube') > 0){
       var videoid = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
       var strFirstSix = videoid[1].slice(0,6);
       var strSecond = videoid[1].slice(7,videoid[1].length);
      if(strFirstSix == 'embed/'){
      link = 'https://www.youtube.com/'+videoid[1];
      thumbnail = 'https://img.youtube.com/vi/'+strSecond+'/0.jpg';
      }else{
      link  = 'https://www.youtube.com/embed/'+videoid[1];
      thumbnail = 'https://img.youtube.com/vi/'+videoid[1]+'/0.jpg';
      } 
     }
     
     let linkData = Object();
     Object.assign(linkData,{url:link});
     Object.assign(linkData,{thumbnailLink:thumbnail});
     Object.assign(linkData,{user_name:this.successStoriesForm.value.user_name});
     this.service.postSucessStories(linkData).subscribe(response=>{
        if(response.status = 'success'){
          this.successStoriesForm.reset();
          this.toastr.success('Sucess Story Link Added');
          let newAddedLink = JSON.parse(atob(response['successStories']));
          this.storylinks.push(newAddedLink);        
        }
     })
  }

  // This method will add Welcome Video Links
  public addWelcomeVideos(){
  	let welcomeVideo = this.welcomeForm.value.welcome_video;
  	let howItWorksVideo = this.welcomeForm.value.how_it_works_video;
  	let payload = Object();
  	Object.assign(payload , {welcomeVideo:this.welcomeForm.value.welcome_video});
  	Object.assign(payload , {howItWorksVideo:this.welcomeForm.value.how_it_works_video});

  	this.service.postWelcomeVideos(payload).subscribe(response=>{
      
      // this.storylinks =  JSON.parse(atob(response['data']));
   
    });
  }

  // Preview Video Link
   public previewVideo(link){
    this.videoChild.link(link);
  }

}
