import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';

@Component({
  selector: 'app-un-accepted',
  templateUrl: './un-accepted.component.html',
  styleUrls: ['./un-accepted.component.css']
})
export class UnAcceptedComponent implements OnInit {
 
  public timezone:string;
  public Timings:any;
  public tutorAcceptAccess:boolean;
  public selectedBookingId:number;
  public studentTimeZone:any;
  public cancellationId:any;
  @Output() adminMessageModalData = new EventEmitter();
  @Output() scheduleTimings = new EventEmitter();
  @Output() directCancellation = new EventEmitter();
  @Output() scheduleModalData = new EventEmitter();
  public unAcceptedLessons$:any;
  @Input() 
  public set unAcceptedLessons(value){
    if(value){  
    this.unAcceptedLessons$ = value;
    }
  }

  constructor(private localetimezone: LocaleTimeZoneTS) { }

  ngOnInit(): void {
    this.timezone = this.localetimezone.timezone();
  }

    // Schedule Details
  public viewDetails(request , dates ,bookingID , timezone){
    this.Timings = dates;
    for(let time of this.Timings){
      let bookedSlot = this.convertTime(time.slot_date , time.booked_time);
      let currentDate = this.convertTime(this.localetimezone.onlyDate() , this.localetimezone.timeAMPM());
      if(bookedSlot >= currentDate){
          time.status = 'Slot_Active';
      }else{
          time.status = 'Slot_Expired'; 
       }
      }
    let acceptCheck = this.Timings.filter(function (time) { return time.status == 'Slot_Active'});
    if(acceptCheck.length){
       this.tutorAcceptAccess = true;
    }else{
       this.tutorAcceptAccess = false;
    }

    this.selectedBookingId = bookingID;
    this.studentTimeZone = timezone;
    this.scheduleTimings.emit(this.Timings);
  }

public convertTime(dateGiven , time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
let date = dateGiven + " " + time1;
return new Date(date).getTime();
  }

    public messageModal(userData , role){
    if(role == 'student'){
    this.adminMessageModalData.emit(userData);
    }else{
    this.adminMessageModalData.emit({username:userData.display_name , id:userData.user_id});
    }
  }

   public openScheduleDates(id , data){
    this.scheduleModalData.emit({id:id , data:data});
 }

   public directCancelation(bookingID){
    this.cancellationId = bookingID;
    this.directCancellation.emit(bookingID);
  }
}
