<div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="crupdateRoleModal" class="modal fade" style="display: none;">
   <div class="modal-dialog" style="margin-top: 200px;">
      <div class="modal-content" >
         <div class="modal-header">
            <button aria-hidden="true" (click)="closeModal()" data-dismiss="modal" class="close" type="button" #closeCurdRoleModal>×</button>
            <h4 class="modal-title linkLabel-green-dark" trans="Create a New Role">Create a New Role</h4>
         </div>
         <div class="modal-body">
            <form [formGroup]="roleForm">
            <div class="row formspace">
               <div class="col-sm-12">
                  <div class="form-group">
                     <label for="name" trans="Name">Name</label>
                     <input type="text"  formControlName="name"  class="form-control" placeholder="Name">
                     <div *ngFor= "let error of errorMessages.name">
                        <ng-container *ngIf="name.hasError(error.type) && (name.dirty || name.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row formspace">
               <div class="col-sm-12">
                  <div class="form-group">
                     <label for="description"  trans="Description">Description</label>
                     <textarea id="description" formControlName="description" class="form-control" placeholder="Type here"></textarea>
                     <div *ngFor= "let error of errorMessages.description">
                        <ng-container *ngIf="description.hasError(error.type) && (description.dirty || description.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row formspace permissions">
               <div class="form-group">
                  <div class="col-sm-6">
                     <label for="permissions" trans="Permissions">Permissions</label>
                  </div>
                  <div class="col-sm-6 TT-formGroup" style="margin-top:20px;">
                     <button  data-toggle="modal" data-target="#permissionsRoleModal" class="TT-primaryBTN" trans="Manage">Manage
                     </button>
                  </div>
               </div>
            </div>
            <div class="no-permissions" *ngIf="!(permissions$ | async)?.length" trans="No permissions yet.">No permissions yet.</div>
            <div class="row" style="margin:6px;" *ngIf="(permissions$ | async)?.length">
               <div class="col-12">
                  <div *ngFor="let permission of permissions$ | async" class="chip">
                     <span >{{permission.name}}</span>
                     <span (click)="removePermission(permission.name)" class="closebtn" onclick="this.parentElement.style.display='none'">&times;</span>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-12 TT-formGroup">
                  <button [disabled]="!roleForm.valid" (click)="confirm()" type="submit" class="TT-primaryBTN" trans="Create" style="width:auto;padding:8px 30px">Create</button>
               </div>
            </div>
            </form>
         </div>
      </div>
   </div>
</div>
<app-permissions-modal (selectedPermissions)="selectedPermissions($event)"></app-permissions-modal>

