<div class='col-sm-12 rightCol WalletStatus'>
   <div class="well well-sm teacher_head row">
            <h4 class="main-Head-bg flex_center">
               <div class="col-md-6">
               <span (click)="createPage()" class="cursor" trans="Add New Page">Add New Page</span> 
               </div>

               <div class="col-md-2">
               <span class="max-right w_2">
                  <select (input)="selectedPagesColumn($event.target.value , 'pageSearch')" class="form-control column w-auto">
                     <option value='' trans="Select Column">Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                     {{item.key}}
                     </option>
                  </select>
               </span>
               </div>
   

           
             <div class="col-md-4 flex">
         
               <span class="max-top">
               <input [formControl]="searchControl" title="Select Column" id="pageSearch" disabled type="text" class="form-control" placeholder="Search Here" trans-placeholder="Search Here" >
               <i class="fa fa-search" aria-hidden="true">
               </i>
               </span>
                    <span (click)="csvExort('Pages List')" class="csv-right mr-10">
               <img src="assets/images/csv.png" width="30">
               </span>
               </div>



            </h4>
   </div>

   <div class='customTabs'>
      <div class="mx_scroll">
      <table class="table lesson-table">
         <tr>
            <th trans="User">Title</th>
            <th trans="Page Route">Page Route</th>
            <th trans="Roles">Roles</th>
            <th trans="Category">Category</th>
            <th trans="Order">Order</th>
            <th trans="Created at">Created at</th>
            <th trans="Actions">Actions</th>
         </tr>
         <tr *ngFor="let page of pages | async">
            <td>{{page.title}}</td>
            <td>{{transform(page.page_route) || 'N\A'}}</td>
            <td>{{page.role.name}}</td>
            <td>{{page.category.title}}</td>
            <td>{{page.display_order}}</td>
            <td>{{page.created_at | date: 'MMM-dd-yyyy'}}</td>
            <td class="lesson-btn-actions dropdown" class="lesson-btn-actions dropdown">
               <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
               <ul class="dropdown-menu dropdown-new">
                  <li (click)="SetEditAction(page.id)" ><a trans="Edit">Edit</a></li>
                  <li class="divider"></li>
                  <li (click)="deletePage(page.id)"><a trans="Delete">Delete</a></li>
                  <li class="divider"></li>
               </ul>
            </td>
         </tr>
      </table>
      </div>
      <app-datatable-footer-new *ngIf="(pages | async).length > 0"></app-datatable-footer-new>
   </div>
</div>
