import { Component, OnInit ,Input } from '@angular/core';
import { User } from '../../../core/types/models/User';

@Component({
  selector: 'app-user-column',
  templateUrl: './user-column.component.html',
  styleUrls: ['./user-column.component.css']
})
export class UserColumnComponent implements OnInit {
	@Input() user: User;
    @Input() showEmail = false;

  constructor() { }

  ngOnInit(): void {
  }

}
