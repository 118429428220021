import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output} from '@angular/core';
import { SettingsService } from '../../settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-db-reset-modal',
  templateUrl: './db-reset-modal.component.html',
  styleUrls: ['./db-reset-modal.component.css']
})
export class DbResetModalComponent implements OnInit {


  public factoryResetForm = this.fb.group({
    resetKey: ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,4}$")]],
    resetType: ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,4}$")]],
  });

  @ViewChild('closeDbResetModal') closeDbResetModal: ElementRef;
  constructor(private fb: FormBuilder,
              public SettingsService: SettingsService,
              private toastr: ToastrService,
              public auth: AuthService,
              private router: Router
            ) { }

  ngOnInit(): void {

  }

  public CloseModal(){
  	this.closeDbResetModal.nativeElement.click();
  }
  
  public factoryReset() {
    this.factoryResetForm.markAllAsTouched();
    if( this.factoryResetForm.value.resetKey == '' || this.factoryResetForm.value.resetType == '') {
      this.toastr.error('Please fill the fields');
    } else {
          this.SettingsService.factoryReset(this.factoryResetForm.value)
              .subscribe(response=>{
                if(response['status'] == 'success')
                {
                  //alert(response['status'])
                  this.closeDbResetModal.nativeElement.click();
                  this.toastr.success('Your system reset was successfull');
                }else{
                  this.toastr.error('Reset has failed. '+response['status']);
                }
              });
          
    }
    console.log('coming from factory reset form', this.factoryResetForm.value)
  }


}
