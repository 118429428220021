<div class="container-fluid padding0">
<section id="findTutor-chat" class="pageSectionBg">
<div class="">
   <div class="container-fluid tutorChat lesson">
      <div class="row">
         <div class='col-sm-2 white_bg leftCol'>
            <div class="left-container">
               <div class="new-localization" *ngIf="currentUser.hasPermission('roles.create')" >
                  <!-- <h5 class="text-left btn btn-primary" data-toggle="modal" data-target="#crupdateRoleModal" trans="New Role"> <b>New Role</b></h5> -->

                  <button class="TT-AccUsers" data-toggle="modal" data-target="#crupdateRoleModal" trans="Add">
                     <i class="fa fa-plus" aria-hidden="true"></i> &nbsp;ADD
                   </button>

               </div>
               <ul class="lesson-list" >
                  <li *ngFor="let role of roles$ | async" (click)="selectRole(role)" class="hoverselected" [class.selected]="(selectedRole$ | async).id === role.id">
                  <div class="linkLabel dropdown">
                     <a> {{role.name}}
                     </a>
                     <i class="fa fa-cog settings dropdown-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu">
                        <li data-target="#crupdateRoleModal" (click)="updateRole(role)" data-toggle="modal" trans="Update"><a>Update</a></li>
                        <li (click)="maybeDeleteRole(role)" *ngIf="currentUser.hasPermission('roles.delete')" trans="Delete"><a >Delete</a></li>
                     </ul>
                  </div>
                  </li>
               </ul>
            </div>
         </div>
         <div class='col-sm-10 rightCol'>
            <div class="well well-sm chat-head">
               <div class="row">
                  <div  class="col-sm-9">
                     <h4 class="title">Users With Role {{transform(selectedRoleName$)}}
                     </h4>
                  </div>
               </div>
            </div>
            <div class='content-container customTabs'>
               <table class="table lesson-table">
                  <tr>
                     <th></th>
                     <th trans="User">User</th>
                     <th trans="Name">Name</th>
                  </tr>
                  <tr *ngFor="let user of users$ | async">
                     <td></td>
                     <td class="column-with-image">
                        <img [src]="user.avatar" alt="">
                        <div >
                           <div>{{user.display_name | json}}</div>
                           <div class="email secondary">{{user.email | json}}</div>
                        </div>
                     </td>
                     <td>{{user.full_name || '-'}}</td>
                     <td>{{user.created_at }}</td>
                  </tr>
               </table>
               <div *ngIf="(users$ | async)?.length==0"><span trans="No records found"> No records found.</span></div>
               <app-datatable-footer-new *ngIf="(users$ | async)?.length > 0"></app-datatable-footer-new>
            </div>
         </div>
      </div>
   </div>
</div>
<app-curd-role-modal (reloadRoles)="reloadRoles($event)"></app-curd-role-modal>