<div class='col-sm-12 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-10">
            <h4 class="title" trans="Create Page">Create Page</h4>
         </div>
         <div class="col-sm-2 flex_Center">
            <button class="btn-primary btn max-top flex_Center" [disabled]="pageLoader$" (click)="storePage()" trans="Add">Add</button>
         </div>
      </div>
   </div>
   <div  class='content-container customTabs'>
      <form [formGroup]="pageForm">
         <div class='customTabs'>
            <div class="row formspace">
               <div class="col-md-3">
                  <div class="form-group">
                     <label trans="Title">Title</label>
                     <input formControlName="title" type="text" class="form-control" placeholder="Page Title">
                     <div *ngFor= "let error of errorMessages.title">
                        <ng-container  *ngIf=" title.hasError(error.type) && ( title.dirty ||  title.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="form-group">
                     <label trans="Page Route">Page Route</label>
                     <input formControlName="page_route" type="text" class="form-control" placeholder="Page Route">
                     <div *ngFor= "let error of errorMessages.page_route">
                        <ng-container  *ngIf=" page_route.hasError(error.type) && (page_route.dirty || page_route.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="form-group">
                     <label trans="Category">Category</label>
                     <select formControlName="category_id" class="form-control iconArrowDown">
                        <option value="" trans="Please select">Please Select</option>
                        <option [value]="category.id" *ngFor="let category of categories">{{category.title}}</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.category_id">
                        <ng-container *ngIf=" category_id.hasError(error.type) && ( category_id.dirty ||  category_id.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="form-group">
                     <label trans="Users">Users</label>
                     <select formControlName="user_role" class="form-control iconArrowDown">
                        <option value="" trans="Please select">Please Select</option>
                        <option [value]="role.id" *ngFor="let role of user_roles">{{role.name}}</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.user_role">
                        <ng-container *ngIf=" user_role.hasError(error.type) && ( user_role.dirty ||  user_role.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row formspace">
               <div class="col-md-3">
                  <div class="form-group">
                     <label trans="Order">Order</label>
                     <input formControlName="display_order" type="text" class="form-control" placeholder="Order">
                     <div *ngFor= "let error of errorMessages.display_order">
                        <ng-container *ngIf=" display_order.hasError(error.type) && ( display_order.dirty ||  display_order.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="form-group BroadCas_new_changes">
                     <label trans="BroadCast">BroadCast</label>
                     <input formControlName="broadCast" type="checkbox" value="">
                     <span class="slider round"></span>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="form-group">
                     <label trans="Page Image">Page Image</label>
                     <input  formControlName="page_image" type="file"  class="form-control"  (change)="fileChangeEvent($event)" id="uplodfile" >
                     <div *ngFor= "let error of errorMessages.page_image">
                        <ng-container *ngIf=" page_image.hasError(error.type) && ( page_image.dirty ||  page_image.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
                     <img [src]="imageURL">
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-12">
                  <div class="form-group">
                     <label for="sub_title" trans="Sub-Title">Sub-Title</label>
                     <label class="pull-right">*30 - 40</label>
                     <textarea  maxlength="40" (input)="countCharacters($event.target.value)" id="textarea" class="form-control" formControlName="sub_title" placeholder="Sub Title"></textarea>
                     <div *ngFor= "let error of errorMessages.sub_title">
                        <ng-container *ngIf="sub_title.hasError(error.type) && (sub_title.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                     <span>
                     <span class="pull-right" trans="Characters Currently.">{{givenChracters}} Characters Currently.</span>
                     </span>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-12">
                  <div class="form-group">
                     <label trans="Content">Content</label> <br>
                     <ckeditor formControlName="content" [config]="{uiColor: '#F0F8FF' , height: '30em'}">
                     </ckeditor>
                     <div *ngFor= "let error of errorMessages.content">
                        <ng-container *ngIf="content.hasError(error.type) && (content.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </div>
</div>