<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <form [formGroup]="templateForm">
         <div class="row m-auto">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 clearfix">
               <h4 class="TT-AccSettings pull-left" trans="Teaching Profile">Template for {{selectedTemplateType}}</h4>
               <div class="TT-formGroup TT-responsiveTable pull-right">
                  <button (click)="backClicked()" class="TT-primaryBTN">Back</button>
               </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="row m-auto">

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" for="title">Title <span class="text-danger">*</span> </label>
                        <input formControlName="title" type="text" class="TT-FieldGroup"
                           placeholder="Specify the title of template" trans-placeholder="title should be unique" />
                        <div *ngFor="let error of errorMessages.title">
                           <ng-container *ngIf="title.hasError(error.type) && (title.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" for="key">Key <span class="text-danger">*</span> </label>
                        <input formControlName="key" type="text" class="TT-FieldGroup"
                           placeholder="key should be unique" trans-placeholder="key should be unique" />
                        <div *ngFor="let error of errorMessages.key">
                           <ng-container *ngIf="key.hasError(error.type) && (key.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable">Subject <span class="text-danger">*</span> </label>
                        <input formControlName="subject" type="text" placeholder="Subject" trans-placeholder="Subject"
                           class="TT-FieldGroup" />
                        <div *ngFor="let error of errorMessages.subject">
                           <ng-container *ngIf="subject.hasError(error.type) && (subject.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable">Sender Name <span class="text-danger">*</span> </label>
                        <input formControlName="from_name" type="text" placeholder="Sender's name"
                           trans-placeholder="from_name" class="TT-FieldGroup" />
                        <div *ngFor="let error of errorMessages.from_name">
                           <ng-container *ngIf="from_name.hasError(error.type) && (from_name.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable">Redirect URL <span class="text-danger">*</span> </label>
                        <input formControlName="redirect_url" type="text" placeholder="Redirect URL"
                           trans-placeholder="redirect_url" class="TT-FieldGroup" />
                        <div *ngFor="let error of errorMessages.redirect_url">
                           <ng-container *ngIf="redirect_url.hasError(error.type) && (redirect_url.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-responsiveTable">
                     <label class="TT-lable">Content </label>
                     <ckeditor formControlName="content"></ckeditor>
                     <div *ngFor="let error of errorMessages.content">
                        <ng-container *ngIf="content.hasError(error.type) && (content.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>

                  <div class="row m-auto">
                     <div class="col-xl-10 col-lg-10 col-md-10 col-sm-6 col-xs-12">&nbsp;</div>
                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                        <div class="form-group TT-formGroup TT-responsiveTable clearfix">
                           <button [disabled]="!templateForm.valid" type="submit" (click)="updateTemplate()"
                              class="btn TT-primaryBTN TT-marginRightEffect pull-right" trans="Update">Update </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </div>
</div>