import { Component, OnInit } from '@angular/core';
import { PagesService } from '../pages.service';
import { FormGroup, FormControl,FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-page-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.css']
})
export class PageSettingsComponent implements OnInit {
  
  public pageCategories$:any;
    public footerCategories = [];
    public pageSettingsForm = this.fb.group({
        'footerCategories':  '',
        'landing_page_category': ''
    });
  constructor(private service: PagesService,private fb: FormBuilder,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.service.getPageCategories()
    .subscribe(response => {
      this.pageCategories$ = response['data'];
    });
    this.service.getpageSettings().subscribe(response=>{
        let res = JSON.parse(atob(response['data']));
        if ( res.status === 'failed' ) {
            this.toastr.error(res.message);
        } else {
          res = res.data;
          this.footerCategories = JSON.parse(res.footerCategories);
          if(this.footerCategories.length>0){
    this.pageSettingsForm.patchValue({
      'footerCategories':this.footerCategories
    });
          }
          this.pageSettingsForm.patchValue({
        'landing_page_category': res.landing_page_category,
          });
        }
    });
  }

    public selectCategories(value){
    if(value){
    this.pageCategories$.find(category=>{
        if(category['id'] == value){
            let data = Object();
            Object.assign(data ,{id: category['id']});
            Object.assign(data ,{title: category['title']});
         if(this.footerCategories.some(el=>  el.id === category['id']) || this.footerCategories.length>2){
          const currentValue = this.footerCategories;
        }else{
            const currentValue = this.footerCategories;
            const updatedValue = [...currentValue, category['title']];
            this.footerCategories.push(data);
        }
      }
      });
    this.pageSettingsForm.patchValue({
      'footerCategories':this.footerCategories
    });
    }
  }

  public pageSettings(){
    let data = this.pageSettingsForm.value;
    this.service.savepageSettings(data).subscribe(response=>{
        let res = JSON.parse(atob(response['data']));
          this.toastr.success(res.message);
    });
  }

  removeCategory(category){
    const index = this.footerCategories.indexOf(category);
    if (index > -1) {
     let sliced_ilst = this.footerCategories.splice(index, 1);
     this.pageSettingsForm.patchValue({
      'footerCategories':this.footerCategories
    });
    }  
  }

}
