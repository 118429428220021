<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
     <div class="table-responsive TT-responsiveTable">
      <table class="table table-striped TT-table">
         <thead>
            <tr>
               <th trans="Username">Username</th>
               <th trans="Email">Email</th>
               <th trans="Deleted Date">Deleted Date</th>
               <th trans="Deleted By">Deleted By</th>
               <th trans="Location">Location</th>
               <th trans="Actions" colspan="2">Actions</th>
            </tr>
         </thead>
          <tbody>
            <tr *ngFor="let student of deletedStudents$">
               <td><span >{{student.username}}</span></td>
               <td><span >{{student.email}}</span></td>
               <td>{{student.suspended_date | date: 'MM-dd-yyyy h:mm a'}}</td>
               <td>{{student.deleted_by_name}}</td>
               <td>{{student.country}}</td>
               
               <td class="dropdown TT-tableDropDownMenuList">
                  <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                   <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                     <li class="TT-optionsList" trans="Restore"><a class="TT-frameSet" (click)="restoreuser(student.id, 'restore')" > Restore </a></li>
                     <li class="TT-optionsList" trans="Delete permanently"><a class="TT-frameSet" (click)="permanentdeleteuser(student.id, 'permanentdelete')" >Delete permanently</a></li>
                     <li class="TT-optionsList" trans="Message student"><a class="TT-frameSet" (click)="messageModal(student)" href="#adminMessageModal" data-toggle="modal"  trans="Message student">Message student</a></li>
                   </ul>
                  </td>
              </tr>
            <tr *ngIf="deletedStudents$ && deletedStudents$.length==0" class="TT-noRecorded" trans="No records found">No records found.</tr>
         </tbody>
      </table>
     </div>
   </div>
</div>
<app-datatable-footer-new *ngIf="deletedStudents$ && deletedStudents$.length>0" ></app-datatable-footer-new>