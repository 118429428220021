import { Component, OnInit , Input, ElementRef , ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Permission, PermissionRestriction } from '../../../core/types/models/Permission';
import { RoleService } from '../role.service';
import { BackendErrorResponse } from '../../../core/types/backend-error-response';
import { Role } from '../../../core/types/models/Role';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-curd-role-modal',
  templateUrl: './curd-role-modal.component.html',
  styleUrls: ['./curd-role-modal.component.css']
})
export class CurdRoleModalComponent implements OnInit {
  
  @Output() reloadRoles = new EventEmitter();
  @ViewChild('closeCurdRoleModal') closeCurdRoleModal: ElementRef;
  @Input() valueListKey = 'permissions';
  private propagateChange: Function;
  public permissions$ = new BehaviorSubject<Permission[]>([]);
  public errors$ = new BehaviorSubject<Partial<Role>>({});
  public role;
  get name(){ return this.roleForm.get('name');}
  get description(){ return this.roleForm.get('description');}

	public roleForm = this.fb.group({
        name: ['',Validators.required],
        description: ['',Validators.required],
        permissions: [],
        default: [false],
        guests: [false],
    });

  public loading$ = new BehaviorSubject<boolean>(false);
  public errorMessages = { name :[{ type:'required', message:'Name is required'}],
                           description :[{ type:'required',message:'Description is required'}] };

  constructor(
            private fb: FormBuilder,
            private roleService: RoleService,
            private toastr: ToastrService) {
  }

  ngOnInit(): void {
  }

      public selectedPermissions($event){
        this.setPermissions($event);
    }

      public setPermissions(permissions: Permission[]) {
        this.permissions$.next(permissions);
    }

    public resetTabs() {
      $('.tab').attr('expanded', 'false');
    }

      public confirm() {
      this.loading$.next(true);
        let request;
        this.roleForm.value.permissions = this.permissions$.value;
        if(this.role){
          request = this.roleService.update(this.role.id, this.roleForm.value);
       }else{
        request = this.roleService.createNew(this.roleForm.value);
       }

        request
        .pipe(finalize(() => this.loading$.next(false)))
        .subscribe(response => {
          this.toastr.success('Role ' + (this.role ? 'Updated' : 'Created'));
          this.reloadRoles.emit(true);
          this.closeModal();
            }, (errResponse: BackendErrorResponse) => this.errors$.next(errResponse.messages));

    }

      public closeModal(){
        this.closeCurdRoleModal.nativeElement.click();
        this.roleForm.reset();
      }

      public UpdateRoles(role){
        this.roleService.rolespermissions(role.permissions)
        this.permissions$.next(role.permissions);
        this.role = role
        this.roleForm.patchValue({
          name : role.name,
          description : role.description,
          permissions : role.permissions,
          default : role.default,
          guests : role.guests
      });
      }

      public removePermission(name: string) {
        const permissions = this.permissions$.value.filter(p => p.name !== name);
        this.setPermissions(permissions);
    }




}
