import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: AppHttpClientService) { }

      public adminDashboard() {
        return this.http.get('adminDashboard');
      }
}
