import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { User } from '../../core/types/models/User';
import { Users } from '../../auth/users.service';
import { CurrentUser } from '../../auth/current-user';
import { Settings } from '../../core/config/settings.service';
import { HttpErrors } from '../../core/http/errors/http-errors.enum';
import { Role } from '../../core/types/models/Role';
import { BackendErrorResponse } from '../../core/types/backend-error-response';
import { DatatableService } from '../../core/datatable/datatable.service';
import { Observable } from 'rxjs';
import { CurdUserModalComponent } from './curd-user-modal/curd-user-modal.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  public users$ = this.datatable.data$;

  @ViewChild(CurdUserModalComponent) curdChild: CurdUserModalComponent;

  constructor(
        public currentUser: CurrentUser,
        private userService: Users,
        public datatable: DatatableService<User>) { 
  	   this.datatable.init({
            uri: Users.BASE_URI,
        });
          }

  ngOnInit(): void {

  }

  public makeRolesList(roles: Role[]): string {
      return roles.slice(0, 3).map(role => role.name).join(', ');
  }

  /*
  Reest Users List
  */
  public userUpdate(value){
   this.datatable.reset();
  }

  /*
  Edit User
  */
  public EditApplication(user) {
      this.curdChild.userData(user);
  }

}
