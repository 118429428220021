<div class='col-sm-12 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Add Language Levels">Add Language Levels</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>      
      <form [formGroup]="languageLevelForm" class="col-md-4">
         <table class="table lesson-table noBorderTable">
            <thead>
               <tr>
                  <td>
                     <div class="form-group CCnr-height">
                        <input type="text" formControlName="language_level" class="form-control" placeholder="Language Level" style="border-radius:50px;">
                        <div *ngFor= "let error of errorMessages.language_level">
                           <ng-container *ngIf="language_level.hasError(error.type) && (language_level.dirty || language_level.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>

                     </div>
                  </td>
                  <td class="addRow">
                     <div class="TT-clickableField">
                     <button [disabled]="loading$ | async" (click)="addLanguageLevel()"><i class="fa fa-plus-circle tp_c TT-addRow" aria-hidden="true"></i></button>
                  </div>
                  </td>
               </tr>
               <tr>
                  <th width="94%" trans="Language Levels">Language Levels</th>
                  <th></th>
               </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let level of languageLevels">
                  <td>{{level.language_level}}</td>
                  <td>
                     <div class="TT-clickableField">
                      <i class="fa fa-times TT-delRow" (click)="deleteLevels(level.id , 'languageLevel')" aria-hidden="true"></i>
                     </div>
                  </td>
                  </tr>
            </tbody>
         </table>
      </form>
   </div>
</div>

