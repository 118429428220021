import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';

@Injectable({
    providedIn: 'root'
})

export class TeachersService {
	static BASE_URI = 'teachers';
	constructor(private http: AppHttpClientService){}

	public tutorsDataCount() {
		return this.http.get('tutorsDataCount');
	}

	public gettutorSettings() {
		return this.http.get('gettutorSettings');
	}

	public savetutorSettings(payload: Object) {
		return this.http.post('savetutorSettings' , payload);
	}

	public getTeacherVideoCallSettings() {
		return this.http.get('getTeacherVideoCallSettings');
	}

	public saveTeacherVideoCallSettings(payload: Object) {
		return this.http.post('storeTutuorVideoSettings' , payload)
	}

	public bulkloadmessages(credentials: Object){
        return this.http.post('bulkloadmsg', credentials);
    }
    
    public newApplication(tutorID , selectionType){
		return this.http.get('newApplication/' + tutorID + '/' + selectionType);
	}

    public updateProfile(payload: Object){
		return this.http.post('updateNewApplication' , payload);
	}

    public suspendUser(payload: Object){
		return this.http.post('suspendUser', payload);
	}

    public deleteUser(payload: Object){
		return this.http.post('deleteUser' , payload);
	}

    public viewApplication(tutorID){
		return this.http.get('viewApplication/' + tutorID);
	}

     public approveCompleteProfile(payload: Object){
		return this.http.post('approveCompleteProfile' , payload);
	}

	public getResumeDetails(id){
        return this.http.get('getResumeDetailsForAdmin/'+id);
	}

	public activateUser(payload: Object){
		return this.http.post('activateUser',payload);
	}

	public restoreUser(payload: Object){
		return this.http.post('restoreUser',payload);
	}


}