import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { StudentsService } from '../students.service';
import { ToastrService } from 'ngx-toastr';
declare var $;

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.css']
})
export class ComplaintsComponent implements OnInit {
  
  @Output() adminMessageModalData = new EventEmitter();
  @Output() viewCommentModal = new EventEmitter();
  @Output() reloadStudents = new EventEmitter();
  public complaints$:any;
  @Input()
  public set complaints(value){
    if(value){   
   this.complaints$ = value;
      console.log('compalints', this.complaints$);
    }
   }

  constructor(public service: StudentsService,private toastr: ToastrService) {}

   ngOnInit(): void {

   }

   public complaintAction(scheduleId , operation , complaintId){
    let data = Object();
    Object.assign(data,{complaintId:complaintId})
    Object.assign(data,{scheduleId:scheduleId});
    Object.assign(data,{operation:operation});
      this.service.compliantActon(data).subscribe(response=>{
       if(response['status'] == "success"){
       this.reloadStudents.emit(true);
       this.toastr.success('Complaint Closed'); 
       }else if(response['status'] == 'failed'){
        this.toastr.error(response.message);
       }
      });
   }

   public viewComment(comment){
   this.viewCommentModal.emit(comment);
   }

  public messageModal(userData , role){
    if(role == 'student'){
    this.adminMessageModalData.emit(userData);
    }else{
    this.adminMessageModalData.emit({username:userData.usernme , id:userData.id});
    }
  }
  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}
