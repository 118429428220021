<div aria-hidden="true" data-backdrop="false" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="rolesModal" class="modal fade" style="display: none;">
   <div class="modal-dialog modal-dialog-centered TT-modalMainArea">
      <div class="modal-content TT-modelCover" >
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button" #closeRolesModal>×</button>
            <h4 class="modal-title TT-AccUsers">Select Roles</h4>
         </div>
         <div class="permissions modal-body">
            <ul class="unstyled-list selection-list">
               <li class="list-item" *ngFor="let role of allRoles$ | async">
                  <div class="row list-item-header">
                     <div class="meta col-sm-9">
                        <div class="list-item-name">{{role.name}}</div>
                     </div>
                     <div class="col-sm-3">
                        <label class="switch">
                        <input type="checkbox" value="{{role.name}}">
                        <span class="slider round"></span>
                        </label>
                     </div>
                  </div>
               </li>
            </ul>
            <div class="TT-formGroup">
               <button (click)="confirm()" type="submit" class="btn TT-primaryBTN TT-topSpaceZero" >
                  Select
                  </button>
            </div>
          
         </div>
      </div>
   </div>
</div>

