<div class="container-padding TT-tabNavBar">
   <nav class="navbar navbar-default TT-navBarDefaultTab">
         
         <!-- Collect the nav links, forms, and other content for toggling -->
         <div class="collapse navbar-collapse TT-navbarCollapseTab" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav TT-navNavTabb">

               <li routerLink="/admin/finance/admintrans" *ngIf="currentuser.hasPermissions(['finance.access'])" routerLinkActive="selectedMENU" class="TT-listingArea">
                  <a class="TT-navLinkArea" style="display: flex !important;padding-top: 5px !important;padding-bottom: 10px;margin: 0px;">

                     <span>Transactions</span>
                  </a>
               </li>
         

               <li routerLink="stripe-settings" class="TT-listingArea" routerLinkActive="selectedMENU">
                  <a class="TT-navLinkArea" style="display: flex !important;padding-top: 5px !important;padding-bottom: 10px;margin: 0px;">

                     <span trans="Stripe Settings">Stripe Settings</span>
                  </a>
               </li>
            </ul>
         </div><!-- /.navbar-collapse -->
   </nav>
</div>