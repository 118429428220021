<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
         <table class="table table-striped TT-table">
            <thead>

               <tr>
                  <th trnas="Username">Username</th>
                  <th trnas="Email">Email</th>
                  <th trnas="Approved Date">Approved Date</th>
                  <th trnas="Approved By">Approved By</th>
                  <th trnas="Progress Check">Progress Check</th>
                  <th trnas="Actions" colspan="2">Actions</th>
               </tr>
            </thead>

            <tbody>
               <tr *ngFor="let tutor of acceptedTutors$">
                  <td><span>{{tutor.accepted_tutors.username}}</span></td>
                  <td><span>{{tutor.accepted_tutors.email}}</span></td>
                  <td>{{tutor.accepted_tutors.approved_date | date: 'MM-dd-yyyy h:mm a'}}</td>
                  <td>{{tutor.accepted_tutors.approved_by_name}}</td>
                  <td>
                     <app-progress-checks [progresschecks]="tutor.progress_checks"></app-progress-checks>
                  </td>
                  <td class="TT-formGroup">
                     <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel"
                        aria-hidden="true" href="#modalApproval"
                        (click)="viewApplication(tutor.user_id,'existingProfile')"
                        class="btn TT-primaryBTN TT-topSpaceZero" value="Attend" data-target="#modalApproval"
                        data-toggle="modal">
                        View</a>
                  </td>
                  <td class="dropdown TT-tableDropDownMenuList">
                     <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu dropdown-new">
                        <li class="TT-optionsList"><a class="TT-frameSet"
                              (click)="suspenduser(tutor.accepted_tutors.id, 'suspend')" trans="Suspend"> Suspend</a>
                        </li>
                        <li class="TT-optionsList"><a class="TT-frameSet"
                              (click)="deleteuser(tutor.accepted_tutors.id, 'delete')" trans="Delete">Delete</a></li>
                        <li class="TT-optionsList" (click)="messageModal(tutor.accepted_tutors)"><a class="TT-frameSet"
                              href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a></li>
                     </ul>
                  </td>
               </tr>

               <tr *ngIf="acceptedTutors$ && acceptedTutors$.length==0" class="textalign" trans="No records found">No
                  records found.</tr>
            </tbody>
         </table>
      </div>
      <app-datatable-footer-new *ngIf="acceptedTutors$ && acceptedTutors$.length>0"></app-datatable-footer-new>