<!-- =======================01-09-2022 navbar============================ -->
<div class="container-padding TT-tabNavBar">
  <nav class="navbar navbar-default TT-navBarDefaultTab">
    <!-- Brand and toggle get grouped for better mobile display -->
     <!-- <div class="navbar-header">
       <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
         data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
         <span class="sr-only">Toggle navigation</span>
         <span class="icon-bar"></span>
         <span class="icon-bar"></span>
         <span class="icon-bar"></span>
       </button>
       <a class="navbar-brand" href="#">Your Teaching Profile</a>
      </div> -->

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse TT-navbarCollapseTab" id="bs-example-navbar-collapse-1">
         <ul class="nav navbar-nav TT-navNavTabb">
            <li routerLink="create-pages" class="TT-listingArea" routerLinkActive="selectedSetting">
               <a class="TT-navLinkArea" trans="Create New Page"> Create New Page</a>
            </li>
            <li routerLink="create-category" class="TT-listingArea" routerLinkActive="selectedSetting">
               <a class="TT-navLinkArea" trans="New Category"> New Category </a>
            </li>
            <li routerLink="pages-list" class="TT-listingArea" routerLinkActive="selectedSetting">
               <a class="TT-navLinkArea" trans="Pages List"> Pages List </a>
            </li>
            <li routerLink="category-list" class="TT-listingArea" routerLinkActive="selectedSetting">
               <a class="TT-navLinkArea" trans="Categories List"> Categories List </a>
            </li>
            <li routerLink="page-settings" class="TT-listingArea" routerLinkActive="selectedSetting">
               <a class="TT-navLinkArea" trans="Page Settings"> Page Settings </a>
            </li>
         </ul>
      </div>
   </nav>
   <!-- =========== 01-09-2022  End================ -->
   <!-- <div class="left-container">
    <h5 class="text-left color2"> <b trans="Pages Manager">Pages Manager </b> </h5>
     <ul class="lesson-list">
      <li routerLink="create-pages" class="hoverselectedSetting" routerLinkActive="selectedSetting">
         <a  class="linkLabel" trans="Create New Page"> Create New Page</a>
      </li>
      <li routerLink="create-category" class="hoverselectedSetting" routerLinkActive="selectedSetting">
         <a  class="linkLabel" trans="New Category"> New Category </a>
      </li>
      <li routerLink="pages-list" class="hoverselectedSetting" routerLinkActive="selectedSetting">
         <a  class="linkLabel" trans="Pages List"> Pages List </a>
      </li>
      <li routerLink="category-list" class="hoverselectedSetting" routerLinkActive="selectedSetting">
         <a  class="linkLabel" trans="Categories List"> Categories List </a>
      </li>
      <li routerLink="page-settings" class="hoverselectedSetting" routerLinkActive="selectedSetting">
         <a  class="linkLabel" trans="Page Settings"> Page Settings </a>
      </li>
    </ul>
   </div> -->