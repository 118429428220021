import { Component, OnInit, ViewChild } from '@angular/core';
import { FinanceService } from '../finance.service';
import { DatatableService } from '../../../core/datatable/datatable.service';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { AdminMessagemodalComponent } from '../../admin-messagemodal/admin-messagemodal.component';
import { PayoutActionsComponent } from '../payout-actions/payout-actions.component';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { CsvService } from '@services/csv/csv.service'
import { Currencies } from '../../../auth/currencies';

declare var $;

@Component({
  selector: 'app-fintabs',
  templateUrl: './fintabs.component.html',
  styleUrls: ['./fintabs.component.css'] ,
  providers: [DatatableService]
})
export class FintabsComponent implements OnInit {
  
  @ViewChild(AdminMessagemodalComponent) adminMessageChild: AdminMessagemodalComponent;
  @ViewChild(PayoutActionsComponent) payoutActionsChild: PayoutActionsComponent;
  public tabName:any = 'Sale Transactions' ;
  public oldtabName:any = 'Sale Transactions';  
  public saleTransactions_count:number = 0;
  public lessonTransactions_count:number = 0;
  public tabsData:any;
  public saleTransactions:any;
  public lessonTransactions:any;
  public approvedPayouts:any;
  public selectedSaleTransactionFilter:any;
  public adminTrans$ = this.datatable.data$;

  public studentPayoutsRequests:any;


  public saleTransactionColumns = [{'Amount':'final_paid_amount'},
                                  {'Source (FROM)':['student','username']},
                                  {'Recipient (TO)':['tutor','username']},
                                  {'Purpose':['transaction','purpose']},
                                  {'Discount Used':'payment_discount_used'},
                                  {'Discount Type':'payment_discount_type'},
                                  {'Discount Value':'payment_discount_amount'},
                                  {'Booking Id':'booking_id'}];


  public lessonTransactionsColumns = [{'Main Amount':'tutor_amount'},
                                  {'Amount':'payment_amount'},
                                  {'Commission':'current_commission_percentage'},
                                  {'Standard Fee':'current_standard_fee'},                                  
                                  {'Lesson Id':'booking_dates_id'},
                                  {'Booking Id':'booked_id'}];

  public teachersPayoutColumns = [{'Amount':'Amount'}]

  public studentPayoutColumns = [{'Amount':'Amount'}]
  public refundRequestsColumns = [{'Amount':'Amount'}]
  public raisedIssuesColumns = [{'Amount':'Amount'}]

  public obj:any;
  public transactionColumn:any;
  public searchControl = new FormControl();

  public popularCurrencies;
  public GBP;

  constructor(public service: FinanceService,public datatable: DatatableService<TransactionLog>,public csvService: CsvService, public currencies: Currencies) {
    this.service.reload_records.subscribe(response=>{
        this.selectTab(this.tabName);
        });

    this.popularCurrencies = this.currencies.currencies();
    this.GBP = this.popularCurrencies.find( x => {
      if(x.code == "USD"){
        return x;
      }
     });
  }

  ngOnInit(): void {

            this.searchControl.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.datatable.addFilter('column',this.transactionColumn);
                this.datatable.addFilter('financeQuery', query);

                this.oldtabName = this.tabName;
                //this.transactionColumn = '';
                //console.log(query)
            });

           // this.queryParams = this.datable.paginator.currentQueryParams();



        this.datatable.init({
            uri: FinanceService.BASE_URI
        });

        this.datatable.addFilter('tab','Sale Transactions');

  	  	$('.tab-link').click( function() {
        var tabID = $(this).attr('data-tab');
        $(this).addClass('active').siblings().removeClass('active');
        $('#tab-'+tabID).addClass('active').siblings().removeClass('active'); 
       });


  this.tabsCount();
    
  this.adminTrans$.subscribe(response =>{
   if(this.tabName == 'Sale Transactions'){
    this.saleTransactions = this.adminTrans$;
    this.obj = Object.assign({}, ...this.saleTransactionColumns);
   }else if(this.tabName == 'Lesson Transactions'){
    this.lessonTransactions = this.adminTrans$;
    this.obj = Object.assign({}, ...this.lessonTransactionsColumns);
   }
  });
  }

  public selectTab(tabName){
    this.obj = '';
    if ( this.tabName != tabName ) { // Let us reset the form when tthe tab changed so that search willbe reset!
      this.searchControl.reset();
      $('.searchInput').attr('disabled','disabled');
      this.transactionColumn = '';
    }
  	this.tabName = tabName;
    this.datatable.addFilter('tab',this.tabName);
    this.datatable.addFilter('column','');
    this.datatable.addFilter('financeQuery', '');
    this.datatable.addFilter('page','1');
  }

  public tabsCount(){
  	this.service.financeDataCount().subscribe(response => {
    let res = JSON.parse(atob(response['data']));
    this.tabsData = res;
    
    this.saleTransactions_count = this.tabsData['saleTransactions_count'];
    this.lessonTransactions_count = this.tabsData['lessonTransactions_count'];
  	});
  }

  public messageModal(userData){
    this.adminMessageChild.userData(userData);
  }

  public payoutRequestId(id){
    this.payoutActionsChild.payoutSelectedId(id);
  }

  public selectSaleTransactionFilter(value){
   this.selectedSaleTransactionFilter = value;
   this.datatable.addFilter('filter',value);
  }

  public removeSaleTransactionFilter(){
    this.selectedSaleTransactionFilter = '';
    this.datatable.addFilter('filter',null);
  }

  public selectedTransactionColumn(column, id){
    if(column){
      this.transactionColumn = column;
      $('#'+id).removeAttr('disabled');
    }else{
      this.transactionColumn = '';
      this.datatable.addFilter('column',this.transactionColumn);
      $('#'+id).attr('disabled','disabled');
    }
   $('#'+id).val('');
  }


  public csvExort(name){
    let data = this.csvService.pivot(this.adminTrans$.value , true);
    new AngularCsv(data , name);
  }

  public reloadRecords(value){
    if(value == true){
      //this.datatable.addFilter('tab',this.tabName);
      //this.tabsCount();
      this.selectTab(this.tabName);
    }
   }

   public payoutRequestUser(id){
    this.payoutActionsChild.payoutSelectedUser(id);
  }
}

