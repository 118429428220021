import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-refunded-lessons',
  templateUrl: './refunded-lessons.component.html',
  styleUrls: ['./refunded-lessons.component.css']
})
export class RefundedLessonsComponent implements OnInit {

  @Output() adminMessageModalData = new EventEmitter();
  public refundedLessons$:any;
  @Input() 
  public set refundedLessons(value){
    if(value){  
    this.refundedLessons$ = value;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

    public messageModal(userData , role){
    if(role == 'student'){
    this.adminMessageModalData.emit(userData);
    }else{
    this.adminMessageModalData.emit({username:userData.display_name , id:userData.user_id});
    }
  }

  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
  
}
