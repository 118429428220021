<div class='col-sm-10 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">         
            <h4 class="title" trans="Send Bulk Messages/Notification">Send Bulk Messages/Notification</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>

      <form class="col-md-6">
         <table class="table lesson-table noBorderTable">
            <thead>
                  <tr>
                  	<div class="col-md-6">
                     <div class="form-group">
                     	<label for="title" trans="Title">Title</label>
                        <input type="text"  formControlName="title" class="form-control" 
                        placeholder="">                     
                     </div>
                    </div>
                  </tr> 
                   <tr>
                   	<div class="col-md-6">
                     <button class="btn btn-primary new" trans="Send">Send</button>
                     </div> 
                  </tr> 
            </thead>
         </table>
      </form>
   
   </div>
</div>


