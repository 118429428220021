<span *ngIf="progressChecks$">
   <!-- PROFILE -->
   <span tooltip="Resume" [ngClass]="{'Red_check': progressChecks$.resume_progress_count==0, 'Green_check' : progressChecks$.resume_progress_count==1 }">
   <i class="fa fa-check-circle" >
   </i>
   </span>
   <!-- AVAILABILITY CHECK -->
   <span tooltip="Availability" [ngClass]="{'Red_check': progressChecks$.availability_count==0, 'Green_check' : progressChecks$.availability_count>=1 }">
   <i class="fa fa-check-circle" >
   </i>
   </span>
   <!-- CERTIFICATE CHECK -->
   <span tooltip="Certificate" [ngClass]="{'Red_check': progressChecks$.certificate_count==0, 'Green_check' : progressChecks$.certificate_count>=1 }">
   <i class="fa fa-check-circle" >
   </i>
   </span>
   <!-- EDUCATION CHECK -->
   <span tooltip="Education" [ngClass]="{'Red_check': progressChecks$.education_count==0, 'Green_check' : progressChecks$.education_count>=1 }">
   <i class="fa fa-check-circle" >
   </i>
   </span>
   <!-- VIDEO CHECK -->
   <span tooltip="Video" [ngClass]="{'Red_check': progressChecks$.video_count==0, 'Green_check' : progressChecks$.video_count==1 }">
   <i class="fa fa-check-circle" >
   </i>
   </span>
</span>