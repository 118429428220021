import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators , FormControl,FormArray, FormBuilder } from '@angular/forms';
import { TeachersService } from '../teachers.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import  *  as  Countries from  '@athand/json/countries.json';

@Component({
  selector: 'app-teacher-settings',
  templateUrl: './teacher-settings.component.html',
  styleUrls: ['./teacher-settings.component.css']
})
export class TeacherSettingsComponent implements OnInit {
  public dailcodeData;
  public countries:any = (Countries as any).default;
  public countriesData = this.countries;
  public selectedCountries:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public tutorSettingsForm = this.fb.group({
        'tutor_min_per_hour':[''],
        'tutor_max_per_hour': [''],
        'tutor_min_discount_package': '',
        'tutor_max_discount_package': '',
        'tutor_allow_custom_subjects': '',
        'tutor_allow_custom_discounts': [''],
        'tutor_max_subjects_teach': [''],
        'tutor_max_lessons_day': [''],
        'tutor_hours_to_confirm_booking': [''],
        'auto_conirmation_hours':[''],
        'tutor_gender': [''],
        'tutor_banned_countries': [''],
        'selected_banned_countries':  '',
        't_custom_link': '',
        'tutor_required_approval': this.fb.group({
          'subjects': '',
          'education': '',
          'certificates': '',
          'packages': '',
          'withdraw': '',
          'profile': '',        
        }),
        'tutor_activation': 'auto'
    });

    get tutor_min_per_hour() { return this.tutorSettingsForm.get('tutor_min_per_hour'); }
    get tutor_max_per_hour() { return this.tutorSettingsForm.get('tutor_max_per_hour');}
    get tutor_min_discount_package() { return this.tutorSettingsForm.get('tutor_min_discount_package');}
    get tutor_max_discount_package(){ return this.tutorSettingsForm.get('tutor_max_discount_package'); }
    get tutor_activation(){ return this.tutorSettingsForm.get('tutor_activation'); }
    get t_custom_link(){ return this.tutorSettingsForm.get('t_custom_link'); }

      public errorMessages = {
           tutor_min_per_hour :  [ { type:'required',message:'Tutor Minimum Per Hour is Required'} ,
                                   { type:'max',message:'Less Than Tutor Max Per Hour'}], 
           tutor_max_per_hour :  [ { type:'required',message:'Tutor Maximum Per Hour is Required'} ,
                                   { type:'min',message:'More Than Min Allowed Price Per Hour'}], 
          tutor_min_discount_package :  [ { type:'required',message:'Tutor Minimum Discount is Required'} ,
                                   { type:'max',message:'Less Than Tutor Max Discount Package'}], 
          tutor_max_discount_package :  [ { type:'required',message:'Tutor Maximum Per Hour is Required'} ,
                                   { type:'min',message:'More Than Tutor Min Discount Package'}], 
       }

  constructor(private fb: FormBuilder,
              public service: TeachersService,
              private toastr: ToastrService,
              ) { }

  ngOnInit(): void {
  
  /*Custom Validators*/
  this.setValidator();

    $('.numberbox').keyup(function(){
  if ($(this).val() > 99){
    alert("No numbers above 99");
    $(this).val('');
  }
});

  $('.lessonAllowed').keyup(function(){
  if ($(this).val() > 24){
    alert("No numbers above 24");
    $(this).val('');
  }
});


    this.service.gettutorSettings().subscribe(response=>{
        let res = JSON.parse(atob(response['data']));
        if ( res.status === 'failed' ) {
            this.toastr.error(res.message);
        } else {
          res = res.data;
          
          this.tutorSettingsForm.patchValue({
            'tutor_min_per_hour': res.tutor_min_per_hour,
            'tutor_max_per_hour': res.tutor_max_per_hour,
            'tutor_min_discount_package': res.tutor_min_discount_package,
            'tutor_max_discount_package': res.tutor_max_discount_package,
            'tutor_max_subjects_teach': res.tutor_max_subjects_teach,
            'tutor_max_lessons_day': res.tutor_max_lessons_day,
            'tutor_hours_to_confirm_booking': res.tutor_hours_to_confirm_booking,
            'auto_conirmation_hours': res.auto_conirmation_hours,
            'tutor_gender': res.tutor_gender,
            'selected_banned_countries':res.selected_banned_countries,
            'tutor_banned_countries': res.tutor_banned_countries,
            'tutor_required_approval': JSON.parse(res.tutor_required_approval),
            'tutor_activation':  res.tutor_activation,
            't_custom_link':  res.t_custom_link,
          });
          if( res.tutor_allow_custom_subjects == 0)
          {
            this.tutorSettingsForm.patchValue({

            'tutor_allow_custom_subjects': false,

            });
          }
          else{
            this.tutorSettingsForm.patchValue({

            'tutor_allow_custom_subjects': true,

            });
          }
          if( res.tutor_allow_custom_discounts == 0)
          {
            this.tutorSettingsForm.patchValue({

            'tutor_allow_custom_discounts': false,

            });
          }
          else{
            this.tutorSettingsForm.patchValue({

            'tutor_allow_custom_discounts': true,

            });
          }

          if( res.t_custom_link == 0)
          {
            this.tutorSettingsForm.patchValue({

            't_custom_link': false,

            });
          }
          else{
            this.tutorSettingsForm.patchValue({

            't_custom_link': true,

            });
          }
          this.selectedCountries.next(JSON.parse(res.selected_banned_countries));
        }
    });
  }
  public removeItem(country){
  const index = this.selectedCountries.value.indexOf(country);
    if (index > -1) {
     let sliced_ilst = this.selectedCountries.value.splice(index, 1);
     // this.selectedCountries.next(sliced_ilst)
     this.tutorSettingsForm.patchValue({
      'selected_banned_countries':this.selectedCountries.value
    });
    }
  }

  public CountrySelect(value){
    if(value){
    this.countriesData.find(country=>{
        if(country['name'] == value){
         if(this.selectedCountries.value.includes(value)){
          const currentValue = this.selectedCountries.value;
        }else{
            const currentValue = this.selectedCountries.value;
            const updatedValue = [...currentValue, value];
            this.selectedCountries.next(updatedValue);
        }
      }
      });
    this.tutorSettingsForm.patchValue({
      'selected_banned_countries':this.selectedCountries.value
    });
  }
  }

  public savetutorSettings() {
    let data = this.tutorSettingsForm.value;
    this.tutorSettingsForm.markAllAsTouched();
    if(this.tutorSettingsForm.valid){
    this.service.savetutorSettings(data).subscribe(response=>{
        let res = JSON.parse(atob(response['data']));
          this.toastr.success(res.message);
    });
  }
  }

  /*
  Validators
  Maximum Houlry Price should not exceed Minimum Hourly Price
  Maximum Discount Package Price should nor exceed Minimum Discount Package Price
  */
  setValidator(){
    const tutor_min_per_hour_control = <FormControl>this.tutorSettingsForm.get('tutor_min_per_hour');
    const tutor_max_per_hour_control = <FormControl>this.tutorSettingsForm.get('tutor_max_per_hour');
    const tutor_min_discount_package = <FormControl>this.tutorSettingsForm.get('tutor_min_discount_package');
    const tutor_max_discount_package = <FormControl>this.tutorSettingsForm.get('tutor_max_discount_package');

    tutor_min_per_hour_control.valueChanges.subscribe(value => {
    if (value) {
        tutor_max_per_hour_control.setValidators([Validators.required, Validators.min(value)]);
      }
      else {
        tutor_max_per_hour_control.setValidators(null);
      }

      tutor_max_per_hour_control.updateValueAndValidity();
    });

  tutor_min_discount_package.valueChanges.subscribe(value => {
    if (value) {
        tutor_max_discount_package.setValidators([Validators.required, Validators.min(value)]);
      }
      else {
        tutor_max_discount_package.setValidators(null);
      }

      tutor_max_discount_package.updateValueAndValidity();
  });
  }
}
