import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckPermissionsGuard } from '@athand/guards/check-permissions-guard.service';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin.component';
import { SettingsComponent } from './settings/settings.component';
import { GeneralComponent } from './settings/general/general.component';
import { DbResetComponent } from './settings/db-reset/db-reset.component';
import { AuthenticationComponent } from './settings/authentication/authentication.component';
import { RolesComponent } from './roles/roles.component';
import { AuthGuard } from '../athand/guards/auth-guard.service';
import { UsersComponent } from './users/users.component';
import { PaymentsComponent } from './payments/payments.component';
import { BookingPaymentsComponent } from './payments/booking-payments/booking-payments.component';
import { LandingPageSettingsComponent } from './settings/landing-page-settings/landing-page-settings.component';
import { TransactionLogComponent } from './payments/transaction-log/transaction-log.component';


// Tutors
import { TeachersComponent } from './teachers/teachers.component';
import { RegistrationComponent } from './teachers/registration/registration.component';
import { TutorProfileComponent } from './teachers/tutor-profile/tutor-profile.component';
import { TeacherSettingsComponent } from './teachers/teacher-settings/teacher-settings.component';

// Students
import { StudentsComponent } from './students/students.component';
import { StudentsRegistrationComponent } from './students/students-registration/students-registration.component';
import { StudentsSettingsComponent } from './students/students-settings/students-settings.component';
import { StudentsMessagesComponent } from './students/students-messages/students-messages.component';


// Lessons
import { LessonsMenuComponent } from './lessons-menu/lessons-menu.component';
import { LessonsListingComponent } from './lessons-menu/lessons-listing/lessons-listing.component';
import { LessonsettingComponent } from './lessons-menu/lessonsetting/lessonsetting.component';
import { LessonsBoardSettingsComponent } from './lessons-menu/lessons-board-settings/lessons-board-settings.component';
import { ScheduleSettingsComponent } from './lessons-menu/schedule-settings/schedule-settings.component';
import { LevelsComponent } from './lessons-menu/levels/levels.component';
import { ActiveSubjectsComponent } from './lessons-menu/active-subjects/active-subjects.component';

import { AdminChatComponent } from './admin-chat/admin-chat.component';

import { AdminAlertsComponent } from './admin-alerts/admin-alerts.component';


import { TemplatesComponent } from './templates/templates.component';
import { ListComponent } from './templates/list/list.component';
import { AccountComponent } from './account/account.component';

import { CreateTemplateComponent } from './templates/create-template/create-template.component';
import { ProfileEditComponent } from '../athand/profile/profile-edit/profile-edit.component';

import { FinanceComponent } from './finance/finance.component';
import { FintabsComponent } from './finance/fintabs/fintabs.component';
// import { MoreComponent } from '@athand/more/more.component';

import { StripeSettingsComponent } from './finance/stripe-settings/stripe-settings.component';
import { PaypalSubscriptionPlansComponent } from './finance/paypal-subscription-plans/paypal-subscription-plans.component';


const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        canActivate: [AuthGuard, CheckPermissionsGuard],
        canActivateChild: [AuthGuard, CheckPermissionsGuard],
        data: {permissions: ['admin.access'], adminRoot: true},
        children: [
              { path:'', redirectTo: 'home', pathMatch: 'full'},
              // { path:'more/:page_route' , component: MoreComponent },
              // { path:'more' , component: MoreComponent },

              { path: 'home',
                component: HomeComponent,
                children:[
                { path: '', redirectTo: 'home' ,pathMatch: 'full'},
                { path:'edit-profile',component: ProfileEditComponent},
                ]},

              {
                path:'settings', component:SettingsComponent,
                data: {permissions: ['settings.access']},
                children:[

                { path: '', redirectTo: 'authentication', pathMatch: 'full'},
                { path: 'authentication', component: AuthenticationComponent},
                { path: 'landingpage', component: LandingPageSettingsComponent},
                { path: 'database-reset', component: DbResetComponent},
                ]
              },


              // ACCOUNT SETTINGS
              { path: 'account',
                component: AccountComponent,
                data: {permissions: ['account.access']},
                children:[
                { path:'', redirectTo: 'settings' , pathMatch: 'full' },
                { path:'settings',component: ProfileEditComponent }
                ]},


              {
                path:'tutors' ,
                component:TeachersComponent ,
                data: {permissions: ['teachers.access']},
                children:[
                  { path: '', redirectTo: 'registration'},
                  { path: 'registration', component: RegistrationComponent },
                  { path: 'profile' , component: TutorProfileComponent },
                  { path: 'settings', component: TeacherSettingsComponent},
                ]
              },

              {
                path:'students' ,
                component:StudentsComponent ,
                data: {permissions: ['students.access']},
                children:[
                  { path: '', redirectTo: 'registration', pathMatch: 'full'},
                  { path: 'registration', component: StudentsRegistrationComponent },
                  { path: 'students-settings', component: StudentsSettingsComponent},
                  { path: 'students-messages', component: StudentsMessagesComponent},
                ]
              },
              {
                path:'templates' ,
                component:TemplatesComponent ,
                data: {permissions: ['templates.access']},
                children:[
                  { path: '', redirectTo: 'list', pathMatch: 'full'},
                  { path: 'list' , component: ListComponent } ,
                  { path: 'create' , component: CreateTemplateComponent }
                ]
              },

               {
                path:'lessons' ,
                component:LessonsMenuComponent ,
                data: {permissions: ['lessons.access']},
                children:[
                  { path: '', redirectTo: 'lessons', pathMatch: 'full'},
                  { path: 'lessons', component: LessonsListingComponent , pathMatch: 'full'},
                  { path: 'lessonsetting', component: LessonsettingComponent},
                  { path: 'lessons-board-settings', component: LessonsBoardSettingsComponent},
                  { path: 'schedule-settings', component: ScheduleSettingsComponent},
                  { path: 'levels' , component : LevelsComponent },
                  { path: 'subjects' , component: ActiveSubjectsComponent},
                ]
              },
              { path:'payments',
                component:PaymentsComponent,
                children:[
                { path: '', redirectTo: 'transactions' ,pathMatch: 'full'},
                { path: 'transactions', component:BookingPaymentsComponent, pathMatch: 'full'},
                { path: 'transaction-log', component:TransactionLogComponent },
              ]},
              { path: 'chat', component: AdminChatComponent ,pathMatch: 'full',
                 data: {permissions: ['inbox.access']}},

              { path: 'roles',
                component: RolesComponent ,
                data: {permissions: ['roles.view']}},


              { path: 'users',
                component: UsersComponent ,
                data: {permissions: ['users.view'] }
              },
              // ALERTS MODULE
              { path:'alerts', component: AdminAlertsComponent ,
                data: {permissions: ['alerts.access'] }
              },
              { path:'finance', component: FinanceComponent, data: {permissions: ['finance.access'] } ,
                children:[
                  { path:'' , redirectTo: 'admintrans' ,pathMatch: 'full'},
                  { path:'admintrans', component: FintabsComponent },
                  { path:'stripe-settings',component: StripeSettingsComponent },
                  { path:'paypal-subscription-plans',component: PaypalSubscriptionPlansComponent },
                ]
               }
              

              ]
            }
              ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
