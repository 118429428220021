import {Injectable} from '@angular/core';
import {AppHttpClientService} from '../../core/http/app-http-client.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PaymentsService {
    constructor(private http: AppHttpClientService,) {}
    
    //Students 
    public studentBookingPayments(){
          return this.http.get('studentBookingPayments');
    }

    public TransactionLog(){
          return this.http.get('TransactionLog');
    }

}
