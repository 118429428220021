   <div class="TT-tutorWrapper">
         <ul class="TT-tutorTabs">
            <li (click)="selectTab('New Students')" class="tab-link TT-tutorList active" data-tab="1">
               <div class="teacher-badge TT-badgeRed">
                  <div class="TT-messageCount">
                     {{newStudentsCount}}
                  </div>
               </div>
               <span>New Students</span>
            </li>
            <li (click)="selectTab('Complaints')" class="tab-link TT-tutorList" data-tab="2">
               <div class="teacher-badge TT-badgeRed" >
                  <div class="TT-messageCount">
                     {{complaintsCount}}
                  </div>
               </div>
               <span>Complaints</span>
            </li>
            <li (click)="selectTab('Top Students')" class="tab-link TT-tutorList" data-tab="3">
               <div class="teacher-badge TT-badgeRed">
                  <div class="TT-messageCount">
                     {{topStudentsCount}}
                  </div>
               </div>
               <span>Top Students</span>
            </li>
            <li (click)="selectTab('Suspended Students')" class="tab-link TT-tutorList" data-tab="4">
               <div class="teacher-badge TT-badgeRed">
                  <div class="TT-messageCount">
                     {{suspendedStudentsCount}}
                  </div>
               </div>
               <span>Suspended Students</span>
            </li>
            <li (click)="selectTab('Deleted Students')" class="tab-link TT-tutorList" data-tab="5">
               <div class="teacher-badge TT-badgeRed">
                  <div class="TT-messageCount">
                     {{deletedStudentsCount}}
                  </div>
               </div>
              <span>Deleted Students</span>
            </li>
            <li (click)="selectTab('ALL Students')" class="tab-link TT-tutorList" data-tab="6">
               <div class="teacher-badge TT-badgeRed">
                  <div class="TT-messageCount">
                     {{allStudentsCount}}
                  </div>
               </div>
              <span> All Students </span>
            </li>
         </ul>

      <!-- NEW STUDENTS -->
      <div id="tab-1" class="tab-content active">
    
         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} in the last 24 hours </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                <select (input)="selectedStudentsColumn($event.target.value ,'newStudentsSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                  <option value=''>Select Column</option>
                  <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                   {{item.key}}
                  </option>
                </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="newStudentsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('New Students')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-new-students (viewStudentApplication)="viewStudentApplication($event)" (adminMessageModalData)="messageModal($event)" (reloadStudents)="reloadStudents($event)" [newStudents]="newStudents"></app-new-students>
      </div>

      <!-- COMPLAINTS -->
       <div id="tab-2" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedStudentsColumn($event.target.value ,'compliantsSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                     <option value=''>Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                     {{item.key}}
                     </option>
                  </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="compliantsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('Compliants')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-complaints (reloadStudents)="reloadStudents($event)" (adminMessageModalData)="messageModal($event)" (viewCommentModal)="viewCommentModal($event)" [complaints]="complaints"></app-complaints>
      </div>

       <!-- TOP STUDENTS -->
       <div id="tab-3" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers">  {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedStudentsColumn($event.target.value ,'compliantsSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                     <option value=''>Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                     {{item.key}}
                     </option>
                  </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="compliantsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('Top Students')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-top-students (viewStudentApplication)="viewStudentApplication($event)"  (adminMessageModalData)="messageModal($event)" [topStudents]="topStudents"></app-top-students>
      </div>

            <!-- SUSSPENDED STUDENTS -->
      <div id="tab-4" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedStudentsColumn($event.target.value ,'suspendedStudentsSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                     <option value=''>Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                     {{item.key}}
                     </option>
                  </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="suspendedStudentsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('Suspended Students')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-suspended-students (viewStudentApplication)="viewStudentApplication($event)" (adminMessageModalData)="messageModal($event)" (reloadStudents)="reloadStudents($event)" [suspendedStudents]="suspendedStudents"></app-suspended-students>
      </div>

      <!-- DELETED STUDENTS -->
      <div id="tab-5" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedStudentsColumn($event.target.value ,'deletedStudentsSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                     <option value=''>Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                     {{item.key}}
                     </option>
                  </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="deletedStudentsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('Deleted Students')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 
          
         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-deleted-students (viewStudentApplication)="viewStudentApplication($event)" (adminMessageModalData)="messageModal($event)" (reloadStudents)="reloadStudents($event)" [deletedStudents]="deletedStudents"></app-deleted-students>
      </div>

            <!-- ALL STUDENTS -->
      <div id="tab-6" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
              </div>
             <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedStudentsColumn($event.target.value ,'allStudentsSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                     <option value=''>Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                     {{item.key}}
                     </option>
                  </select>
               </div>
              </div>
               <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                   <input [formControl]="searchControl" title="Select Column" id="allStudentsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('ALL Students')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-all-students (viewStudentApplication)="viewStudentApplication($event)" (adminMessageModalData)="messageModal($event)" (reloadStudents)="reloadStudents($event)" [allStudents]="allStudents"></app-all-students>
      </div>

   </div>
<app-admin-messagemodal></app-admin-messagemodal>
<app-student-profile [selectedStudentId]="selectedStudentId"></app-student-profile>
<app-view-note></app-view-note>

