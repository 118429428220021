<!-- Modal Start -->
<div class="modal fade" id="studentProfile" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <button type="button" class="close" #closeStudentProfileModal data-dismiss="modal" aria-label="Close" style="margin-top:-11px;">
            <span aria-hidden="true">
            &times;
            </span>
            </button>
            <div class="modal-body" >
               <div class="container-fluid whiteBg">
                  <ul class="nav nav-tabs">
                     <li class="active">
                        <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                           <span *ngIf="studentProfile" trans="Profile">{{studentProfile.username}}</span> Profile
                        </h5>
                     </li>
                     <li *ngIf="studentProfile">
                        <app-user-impersonate [impersonateTutorId]="studentProfile.id" (closeModal)="closeModal()"></app-user-impersonate>
                     </li>
                  </ul>
                  <div class="row">
                     <div *ngIf="studentProfile" class="col-md-12 new_Scroll">
                        <span>
                           <b class="greyColor"  trans="User Name">User Name</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.username}}</span>
                           <br>
                           <hr>
                        </span>
                        <span>
                           <b class="greyColor" trans="Email">Email</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.email}}</span>
                           <br>
                           <hr>
                        </span>
                        <span>
                           <b class="greyColor" trans="Location">Location</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.country}}</span>
                           <br>
                           <hr>
                        </span>
                        <span *ngIf="studentProfile.default_currency">
                           <b class="greyColor" trans="Default Currency">Default Currency</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.default_currency}}</span>
                           <br>
                           <hr>
                        </span>
                        <span>
                           <b class="greyColor" trans="Phone">Phone</b>
                           <br> 
                           <span class="BrownFont small">{{studentProfile.phone}}</span>
                           <br>
                           <hr>
                        </span>
                        <span *ngIf="studentProfile.date_of_birth">
                           <b class="greyColor" trans="Date OF Birth">Date OF Birth</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.date_of_birth}}</span>
                           <br>
                           <hr>
                        </span>
                        <span>
                           <b class="greyColor" trans="Online Status">Online Status</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.isOnlineCheck ? 'Online':'Offline'}}</span>
                           <br>
                           <hr>
                        </span>
                        <span *ngIf="studentProfile.avatar_url">
                           <b class="greyColor" trans="Profile Image">Profile Image</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.avatar_url}}</span>
                           <br>
                           <hr>
                        </span>
                        <span *ngIf="studentProfile.approved_by_name">
                           <b class="greyColor" trans="Profile Image">Profile Image</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.approved_by_name}}</span>
                           <br>
                           <hr>
                        </span>
                        <span *ngIf="studentProfile.deleted_by_name">
                           <b class="greyColor" trans="Profile Image">Profile Image</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.deleted_by_name}}</span>
                           <br>
                           <hr>
                        </span>
                        <span *ngIf="studentProfile.suspended_by_name">
                           <b class="greyColor" trans="Profile Image">Profile Image</b>
                           <br>
                           <span class="BrownFont small">{{studentProfile.suspended_by_name}}</span>
                           <br>
                           <hr>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>