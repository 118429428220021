<div class='col-sm-10 rightCol'>
   <div class="wrapper">
      <div class="tab-wrapper">
         <ul class="tabs">
            <li class="tab-link active" data-tab="1" trans="Welcome Videos">Welcome Videos</li>
            <li class="tab-link" data-tab="2" trans="Get Started Videos">Get Started Videos</li>
         </ul>
      </div>
      <div id="tab-1" class="tab-content active">
         <div class='content-container customTabs'>
            <div class="row formspace">
               <div class="col-sm-12">
                  <div class="row formspace">
                     <form [formGroup]="welcomeForm">
                        <div class="col-md-5">
                           <div class="form-group">
                              <label trans="Welcome Video">Welcome Video</label>
                              <input formControlName="welcome_video" type="text" class="form-control" placeholder="Welcome Video Link">
                              <div *ngFor= "let error of errorMessages.welcome_video">
                                 <ng-container  *ngIf=" welcome_video.hasError(error.type) && ( welcome_video.dirty ||  welcome_video.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-5">
                           <div class="form-group">
                              <label trans="How it Works Video">How it Works Video</label>
                              <input formControlName="how_it_works_video" type="text" class="form-control" placeholder="How it works Video Link">
                              <div *ngFor= "let error of errorMessages.how_it_works_video">
                                 <ng-container *ngIf=" how_it_works_video.hasError(error.type) && ( how_it_works_video.dirty ||  how_it_works_video.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-2">
                           <div class="form-group">
                              <label></label>
                              <button class="btn-primary btn" [disabled]="!welcomeForm.valid" (click)="addWelcomeVideos()" trans="Update">Update</button>
                           </div>
                        </div>
                     </form>
                  </div>
                  <hr>
                  <div class="row formspace">
                     <form [formGroup]="successStoriesForm">
                        <div class="col-md-5">
                           <div class="form-group">
                              <label trans="Success Stories Videos">Success Stories Videos</label>
                              <input formControlName="success_stories" type="text" class="form-control" placeholder="Success Stories">
                              <div *ngFor= "let error of errorMessages.success_stories">
                                 <ng-container *ngIf="success_stories.hasError(error.type) && ( success_stories.dirty ||  success_stories.touched)">
                                    <small class="error-message">
                                    {{error.message}}
                                    </small>
                                 </ng-container>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-5">
                           <div class="form-group">
                              <label trans="User Name">User Name</label>
                              <input formControlName="user_name" type="text" class="form-control" placeholder="User Name">
                              <div *ngFor= "let error of errorMessages.user_name">
                                 <ng-container *ngIf="user_name.hasError(error.type) && ( user_name.dirty ||  user_name.touched)">
                                    <small class="error-message">
                                    {{error.message}}
                                    </small>
                                 </ng-container>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-5">
                           <div class="form-group">
                              <label></label>
                              <button class="btn-primary btn" [disabled]="!successStoriesForm.valid" (click)="addSuccessStories()" trans="Add">Add</button>
                           </div>
                        </div>
                     </form>
                  </div>
                  <div class="row">
                     <div clas="col-md-5">
                        <div class='content-container customTabs'>
                           <table class="table lesson-table">
                              <thead>
                                 <tr>
                                    <th width="40%" trans="Success-Story Link">Success-Story Link</th>
                                    <th width="20%" trans="User Name">User Name</th>
                                    <th width="10%" trans="Status">Status</th>
                                    <th width="20%" trans="Action">Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr *ngFor="let link of storylinks" >
                                    <td>{{link.link}}</td>
                                    <td>{{link.user_name}}</td>
                                    <td>{{link.status}}</td>
                                    <td><button class="btn-primary btn" href="#previewvideo" (click)="previewVideo(link.link)" data-toggle="modal" trans="Preview">Preview</button></td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div id="tab-2" class="tab-content">
         <div class='content-container customTabs'>
            <div class="row formspace">
               <div class="col-sm-12">
                  <!--                          <form >
                     <div class="row formspace">
                        <div class="col-md-6">
                              <label>Create an Account Link</label>
                              <input formControlName="creatAnAccountLink" type="text" class="form-control" placeholder="Create An Account Link">
                              <div *ngFor= "let error of errorMessages.creatAnAccountLink">
                                    <ng-container *ngIf="creatAnAccountLink.hasError(error.type) && ( creatAnAccountLink.dirty ||  creatAnAccountLink.touched)">
                                          <small class="error-message">
                                                {{error.message}}
                                          </small>
                                    </ng-container>
                              </div>
                        </div>
                        <div class="col-md-6">
                              <label>Create an Account Description</label>
                              <textarea class="form-control"></textarea>
                              <div *ngFor= "let error of errorMessages.creatAnAccountLink">
                                    <ng-container *ngIf="creatAnAccountLink.hasError(error.type) && ( creatAnAccountLink.dirty ||  creatAnAccountLink.touched)">
                                          <small class="error-message">
                                                {{error.message}}
                                          </small>
                                    </ng-container>
                              </div>
                        </div>
                     </div>
                     <hr>
                     <div class="row formspace">
                        <div class="col-md-6">
                              <label>Find a Tutor Link</label>
                              <input formControlName="findATutorLink" type="text" class="form-control" placeholder="Create An Account Link">
                              <div *ngFor= "let error of errorMessages.findATutorLink">
                                    <ng-container *ngIf="findATutorLink.hasError(error.type) && ( findATutorLink.dirty ||  findATutorLink.touched)">
                                          <small class="error-message">
                                                {{error.message}}
                                          </small>
                                    </ng-container>
                              </div>
                        </div>
                        <div class="col-md-6">
                              <label>Find a Tutor Description</label>
                              <textarea class="form-control"></textarea>
                              <div *ngFor= "let error of errorMessages.creatAnAccountLink">
                                    <ng-container *ngIf="creatAnAccountLink.hasError(error.type) && ( creatAnAccountLink.dirty ||  creatAnAccountLink.touched)">
                                          <small class="error-message">
                                                {{error.message}}
                                          </small>
                                    </ng-container>
                              </div>
                        </div>
                     </div>
                     <hr>
                     <div class="row formspace">
                        <div class="col-md-6">
                              <label>Manage Schedule Link</label>
                              <input formControlName="manageScheduleLink" type="text" class="form-control" placeholder="Create An Account Link">
                              <div *ngFor= "let error of errorMessages.manageScheduleLink">
                                    <ng-container *ngIf="manageScheduleLink.hasError(error.type) && ( manageScheduleLink.dirty ||  manageScheduleLink.touched)">
                                          <small class="error-message">
                                                {{error.message}}
                                          </small>
                                    </ng-container>
                              </div>
                        </div>
                        <div class="col-md-6">
                              <label>Manage Schedule Description</label>
                              <textarea class="form-control"></textarea>
                              <div *ngFor= "let error of errorMessages.find">
                                    <ng-container *ngIf="creatAnAccountLink.hasError(error.type) && ( creatAnAccountLink.dirty ||  creatAnAccountLink.touched)">
                                          <small class="error-message">
                                                {{error.message}}
                                          </small>
                                    </ng-container>
                              </div>
                        </div>
                     </div>
                     <hr>
                     <div class="row formspace">
                        <div class="col-md-6">
                              <label>Manage Wallet</label>
                              <input formControlName="findATutorLink" type="text" class="form-control" placeholder="Create An Account Link">
                              <div *ngFor= "let error of errorMessages.findATutorLink">
                                    <ng-container *ngIf="findATutorLink.hasError(error.type) && ( findATutorLink.dirty ||  findATutorLink.touched)">
                                          <small class="error-message">
                                                {{error.message}}
                                          </small>
                                    </ng-container>
                              </div>
                        </div>
                        <div class="col-md-6">
                              <label>Manage Wallet Description</label>
                              <textarea class="form-control"></textarea>
                              <div *ngFor= "let error of errorMessages.creatAnAccountLink">
                                    <ng-container *ngIf="creatAnAccountLink.hasError(error.type) && ( creatAnAccountLink.dirty ||  creatAnAccountLink.touched)">
                                          <small class="error-message">
                                                {{error.message}}
                                          </small>
                                    </ng-container>
                              </div>
                        </div>
                     </div>
                     <button class="btn btn-primary new" >Update</button> 
                     </form> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-videomodal></app-videomodal>

