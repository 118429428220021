<div class="container-padding TT-tabNavBar">
   <nav class="navbar navbar-default TT-navBarDefaultTab">     

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse TT-navbarCollapseTab" id="bs-example-navbar-collapse-1">
         <ul class="nav navbar-nav TT-navNavTabb">
            <li routerLink="lessons" routerLinkActive="selectedMENU" class="TT-listingArea">
               <a class="TT-navLinkArea" trans="Lesson Listing">Lesson Listing</a>
            </li>
            <li routerLink="lessonsetting" routerLinkActive="selectedMENU" class="TT-listingArea" >
               <a class="TT-navLinkArea" trans="Lessons Settings">Lessons Settings</a>
            </li>
            <li routerLink="lessons-board-settings" routerLinkActive="selectedMENU" class="TT-listingArea" >
               <a class="TT-navLinkArea" trans="Lesson Board Settings">Lesson Board Settings</a>
            </li>
            <li routerLink="schedule-settings" routerLinkActive="selectedMENU" class="TT-listingArea">
               <a  class="TT-navLinkArea"trans="Schedule Settings">Schedule Settings</a>
            </li>
            <li routerLink="levels" routerLinkActive="selectedMENU" class="TT-listingArea">
               <a class="TT-navLinkArea" trans="Levels">Levels</a>
            </li>
            <li routerLink="subjects" routerLinkActive="selectedMENU" class="TT-listingArea">
               <a class="TT-navLinkArea" trans="New Subjects">New Subjects</a>
            </li>
         </ul>
      </div><!-- /.navbar-collapse -->
   </nav>
</div>