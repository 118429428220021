import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent implements OnInit {

public pageForm = this.fb.group({
'title': ['',Validators.required],
});

get title(){ return this.pageForm.get('title');}

public errorMessages = {
	title: [{type:'required',message:'Field required'}],
}
public categoryId = null;

  constructor(private fb: FormBuilder,
  			private toastr: ToastrService,
  			private router: Router,
  			private service: PagesService,
        private route: ActivatedRoute) {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.categoryId = params.get('categoryId');
      this.service.getCategory(this.categoryId).subscribe(response => {
        this.pageForm.patchValue({
          title:response['data']['title']
        });
      });
    });
 }

  ngOnInit(): void {
  }

  public storeCategory() {
  	this.pageForm.markAllAsTouched();
    if(this.categoryId != null )
    {
      this.service.updatePageCategory(this.pageForm.value, this.categoryId)
  .subscribe(response => {
    if(response['status'] == 'success')
    {
      this.pageForm.reset();
      this.toastr.success('Category has been added successfull');
      this.router.navigate(['admin/pages/category-list']);
    }else{
      this.toastr.error(response.error);
    }
  });

    }
    else{

	this.service.storePageCategory(this.pageForm.value)
	.subscribe(response => {
		if(response.status == 'success')
		{
			this.pageForm.reset();
			this.toastr.success('Category has been added successfull');
			this.router.navigate(['admin/pages/category-list']);
		}else{
      this.toastr.error(response.error);
    }
	});
    }
  }

}
