import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '@athand/profile/profile.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-paypal-subscription-plans',
  templateUrl: './paypal-subscription-plans.component.html',
  styleUrls: ['./paypal-subscription-plans.component.css']
})
export class PaypalSubscriptionPlansComponent implements OnInit {

  public name;
  public description;
  public frequency_interval;
  public frquency;
  public amount;
  public plan;
  public status;

public paypalplans=this.fb.group({
  name :[''],
  description :[''],
  frequency_interval :[''],
  frquency :[''],
  amount :[''],
  plan :[''],
  status :[''],
})

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private profileService: ProfileService,
  ) { }


  ngOnInit(): void {
    // this.profileService.paypalsubscription().subscribe(response => {})
  }
  public onsubmit() {
    console.log('onsubmit')
    this.name = '';
    this.description = '';
    this.frequency_interval = '';
    this.frquency = '';
    this.amount = '';
    this.plan = '';
    this.status = '';
  }
}
