import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StudentsService } from '../students/students.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-direct-cancellation-modal',
  templateUrl: './direct-cancellation-modal.component.html',
  styleUrls: ['./direct-cancellation-modal.component.css']
})
export class DirectCancellationModalComponent implements OnInit {
  
  @Output() reloadLessons = new EventEmitter();
  public loading$ = new BehaviorSubject(false);
  public cancellationBookingId$:number;
  @Input()
  public set cancellationBookingId(value:number){
   if(value){    
   this.cancellationBookingId$ = value;
   console.log(this.cancellationBookingId$);
   }
  }

  constructor(private service:StudentsService) { }

  ngOnInit(): void {
  }

  public cancelCompleteBooking(){
    this.loading$.next(true);
    let data = Object(); 
    Object.assign(data , {bookingId : this.cancellationBookingId$});
    Object.assign(data , {from: 'admin'});
    this.service.cancelDirectBooking(data).subscribe(response => {
       if(response['status'] == 'success'){
       this.loading$.next(false);
       this.reloadLessons.emit(true);
       }
    });
  }
}
