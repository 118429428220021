import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { LessonsService } from '../../../student/lessons/lessons.service'; 

@Component({
  selector: 'app-pending-tutors',
  templateUrl: './pending-tutors.component.html',
  styleUrls: ['./pending-tutors.component.css']
})
export class PendingTutorsComponent implements OnInit {
 
  @Output() selectedTutorId = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  public pendingTutors$:any;
  public is_singleuser = 'No';
  public userdata;
  public pendinguserdatasingle;
  @Input() 
  public set pendingTutors(value){
    if(value){
    this.pendingTutors$ = value;
   }
  }

  constructor(public lessonService:LessonsService) { 

      this.lessonService.sharedsinglependinguser.subscribe(res=>{
                if( res ) {
                  this.singleuserdata(res);
                }
              }); 
  }

  ngOnInit(): void {
  }

   public viewApplication(id , $sectionType){
    this.selectedTutorId.emit({id:id,sectionType:$sectionType});
  }

  public singleuserdata(singledata) {
    let messagePush = Object();
    Object.assign(messagePush,  {singledata_id:""+singledata.id});

    this.lessonService.singlessspendingstutor(messagePush).subscribe(response=>{
      this.is_singleuser = 'Yes'
      this.pendinguserdatasingle =JSON.parse(atob(response['data'])) ;
      this.userdata = this.pendinguserdatasingle.pendingTutorsCount;
    });
  }

}
