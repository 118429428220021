   <div class="TT-tutorWrapper"> 
      <ul class="TT-tutorTabs">
         <li (click)="selectTab('Sale Transactions')" class="tab-link TT-tutorList active" data-tab="5">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{saleTransactions_count}}
               </div>
            </div>
         <span trans="Sale Transactions" >Sale Transactions</span>
         </li>
         <li (click)="selectTab('Lesson Transactions')" class="tab-link TT-tutorList" data-tab="7">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{lessonTransactions_count}}
               </div>
            </div>
            <span trans="Lesson Transactions">Lesson Transactions</span>   
         </li>           
      </ul>
      <!-- Banner End -->


      <!-- Sale Transactions -->
      <div id="tab-5" class="tab-content active">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedTransactionColumn($event.target.value ,'saleTransactionSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                    <option value='' trans="Select Column">Select Column</option>
                    <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                     {{item.key}}
                    </option>
                  </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="saleTransactionSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('Sales Transactions')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <app-saletrans [saleTransactions]="saleTransactions" (adminMessageModalData)="messageModal($event)"></app-saletrans>
      </div>

      <!-- Lesson Transactions -->
      <div id="tab-7" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedTransactionColumn($event.target.value,'lessonTransactionSearchInput')" id="lessonTransactions" class="TT-FieldGroup TT-SelectIcon">
                     <option value='' trans="Select Column">Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                     {{item.key}}
                    </option>
                 </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="lessonTransactionSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('Lesson Transactions')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <app-lesson-transactions [lessonTransactions]="lessonTransactions"></app-lesson-transactions>
      </div>
   </div>

<app-admin-messagemodal></app-admin-messagemodal>
<app-payout-actions></app-payout-actions>