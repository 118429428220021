import { Component, OnInit } from '@angular/core';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-videomodal',
  templateUrl: './videomodal.component.html',
  styleUrls: ['./videomodal.component.css']
})
export class VideomodalComponent implements OnInit {
  
  public demoLink;
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  public link(link){
  	this.demoLink = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

}
