<div class='col-sm-12 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Create Category">
            Create Category</h4>
         </div>
      </div>
   </div>
   <div  class='content-container customTabs'>
      <div class="row formspace">
         <div class="col-sm-12">
            <div class="row formspace">
                     <form [formGroup]="pageForm">
                        <div class='customTabs'>
                           <div class="row formspace">
                              <div class="col-md-4">
                                 <div class="form-group">
                              <label trans="Title">Title</label>
                              <input formControlName="title" type="text" class="form-control" placeholder="Category Title">
                              <div *ngFor= "let error of errorMessages.title">
                                 <ng-container  *ngIf=" title.hasError(error.type) && ( title.dirty ||  title.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row formspace">
                        <div class="col-md-4">
                           <div class="form-group">
                              <button class="btn-primary btn" [disabled]="!pageForm.valid" (click)="storeCategory()" trans="Add">Add</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>


