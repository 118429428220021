<div class="row m-auto">
 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <div class="table-responsive TT-responsiveTable">
      <table class="table table-striped TT-table">
         <thead>
            <tr>
               <th trans="Username">Username</th>
               <th trans="Email">Email</th>
               <th trans="Timestamp">Timestamp</th>
               <th trans="Type">Type</th>
               <th trans="Progress Check">Progress Check</th>
               <th trans="Actions">Actions</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tutor of pendingTutors$">
               <td><span>{{tutor.pending_tutors.username}}</span></td>
               <td><span>{{tutor.pending_tutors.email}}</span></td>
               <td>{{tutor.pending_tutors.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
               <td trans="New Application">New Application</td>
               <td style="min-width:120px; padding: 0px;">
                <app-progress-checks [progresschecks]="tutor.progress_checks"></app-progress-checks>
               </td>
               <td class="TT-formGroup">

                 <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" href="#modalApproval" (click)="viewApplication(tutor.user_id,'newProfile')" class="btn TT-primaryBTN pull-right TT-topSpaceZero" value="Attend" data-target="#modalApproval" data-toggle="modal"> View</a>

               </td>
              </tr>
              <tr *ngIf="pendingTutors$ && pendingTutors$.length==0" class="TT-noRecorded" trans="No records found">
                <td colspan="6" class="text-center"> No records found. </td>
              </tr>
           </tbody>
         </table>
        </div>
       </div>
     </div>
   <app-datatable-footer-new></app-datatable-footer-new>