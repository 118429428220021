import { Component, OnInit , Output , EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RoleService } from '../../roles/role.service';
import { BehaviorSubject } from 'rxjs';
import { Role } from '../../../core/types/models/Role';
import { flattenArray } from '../../../core/utils/flatten-array';
declare var $;

@Component({
  selector: 'app-role-manager',
  templateUrl: './role-manager.component.html',
  styleUrls: ['./role-manager.component.css']
})
export class RoleManagerComponent implements OnInit {
  
  @ViewChild('closeRolesModal') closeRolesModal: ElementRef;
  @Output() selectedRoles = new EventEmitter(); 
  public allRoles$ = new BehaviorSubject<{[key: string]: Role[]}>({}); 
  constructor(
  	private rolesService: RoleService) { }

  ngOnInit(): void {
  	this.fetchAllRoles();
  }

    private fetchAllRoles() {
        this.rolesService.getRoles()
            .subscribe(response => {
            	this.setAllRoles(response.pagination.data)});
    }

    private setAllRoles(allroles){
     this.allRoles$.next(allroles);
    }

    public confirm() { 
        this.closeRolesModal.nativeElement.click();
        this.getSelectedRoles();
    }

      private getSelectedRoles(): Role[] {
        var val=[];
        var roles=[];
        const allRoles = flattenArray(Object.values(this.allRoles$.value));
            allRoles.filter(role => {     
        $('input[type=checkbox]:checked').map(function(_, el) {
                val.push($(el).val());
        }).get();  
        val.find(i => {
          if(i === role.name){
            roles.push(role);
          }
        });
    });
            
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

      this.selectedRoles.emit(roles.filter(onlyUnique));
      $('#rolesModal').css('display','none');
      // $('#permissionsRoleModal').removeClass( "in" )
      $('input[type=checkbox]').removeAttr('checked');
        return roles.filter(onlyUnique);
    }

}
