<div class='col-sm-10 rightCol'>
   <div class="well well-sm chat-head">
      <h4 trans="Transactions Log">Transactions Log <span class="pull-right"> 
         </span>
      </h4>
   </div>
   <table class="table lesson-table">
      <tr>
         <th trans="Amount">Amount</th>
         <th trans="Purpose">Purpose</th>
         <th trans="Recipient">Recipient</th>
         <th trans="Timestamp">Timestamp</th>
         <th trans="Status">Status</th>
         <th trans="Actions">Actions</th>
      </tr>
      <tr *ngFor="let transaction of transactionlog">
         <td>{{transaction.amount}}</td>
         <td>{{transform(transaction.purpose)}}</td>
         <td>{{transaction.recipient}}</td>
         <td>{{transaction.time_stamp}}</td>
         <td>{{transaction.status}}</td>
         <td class="lesson-btn-actions">                                
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
         </td>
      </tr>
   </table>
</div>

