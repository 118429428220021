
<div class="container-fluid padding0">
<section id="findTutor-chat" class="pageSectionBg">
<div class="">
   <div class="container-fluid tutorChat lesson">
      <div class="row">
         <div class='col-sm-3 white_bg leftCol'>
            <div class="left-container">
               <div class="new-localization" *ngIf="currentUser.hasPermission('localizations.create')" >
                  <h5 class="text-left btn btn-primary new" data-toggle="modal" data-target="#crupdateTranslateModal"> <b>New</b>
                  </h5>
               </div>
               <ul class="lesson-list" >
                  <li *ngFor="let localization of localizations$ | async" [class.selectedLocalization]="selectedLocalization?.model.id == localization.model.id" class="hoverselectedLocalization"
                  (click)="setSelectedLocalization(localization)">
                  <div class="linkLabel dropdown">
                     <a > {{localization.model.name}}({{transform(localization.model.status)}})
                     </a>
                     <i class="fa fa-cog settings dropdown-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu">
                        <li data-toggle="modal" data-target="#crupdateTranslateModal" (click)="getLocalizationData(localization)"><a>Update</a></li>
                        <li *ngIf="localization.model.name !== 'english' && currentUser.hasPermission('localizations.delete')" data-toggle="modal" data-target="#statusTranslateModal" (click)="getLocalizationData(localization)"><a>Status</a></li>
                        <li *ngIf="localization.model.name !== 'english' && currentUser.hasPermission('localizations.delete')" (click)="confirmLocalizationDeletion(localization)"><a >Delete</a></li>
                     </ul>
                  </div>
                  </li>
               </ul>
            </div>
            <button   class="btn-primary new" (click)="updateLocalization()" [disabled]="loading$ | async" *ngIf="currentUser.hasPermission('localizations.update')" trans>Update Localization</button>
         </div>
         <div class='col-sm-9 rightCol'>
            <div class="well well-sm chat-head">
               <div class="row">
                  <div class="col-sm-6">
                     <button class="btn-primary new chat_head_top" data-toggle="modal" data-target="#new-translation-line">Add New Translation</button>
                  </div>
                  <div class="col-sm-3 input-container search-input-container">
                     <input type="text" [formControl]="searchQuery" class="form-control" placeholder="Search Translations">
                  </div>
               </div>
            </div>
            <div class='content-container customTabs'>
               <table class="table lesson-table">
                  <thead>
                     <tr>
                        <th width="48%">Source Text</th>
                        <th width="48%">Translation</th>
                        <th></th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let line of lines$ | async" >
                        <td>{{line.key}}</td>
                        <td>
                           <input class="translationInput" type="text"  id="{{line.key}}" [(ngModel)]="selectedLocalization.lines[line.key]">
                        </td>
                        <td >
                           <i class="fa fa-times" (click)="removeLine(line)" aria-hidden="true"></i>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</div>

<!--CRUPDATE_TRANSLATION_MODAL -->
<div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="crupdateTranslateModal" class="modal fade" style="display: none;">
   <div class="modal-dialog" style="margin-top: 200px;">
      <div class="modal-content" >
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button" (click)="resetTranslationForm()" #closeAddTranslationModal>×</button>
            <h4 class="modal-title linkLabel-green-dark" *ngIf="localizationData==null">Create Localization</h4>
            <h4 class="modal-title linkLabel-green-dark" *ngIf="localizationData!=null">Update Localization</h4>
         </div>
         <div class="modal-body">
            <form (ngSubmit)="confirm()" [formGroup]="translateForm">
            <div class="row formspace">
               <div class="col-sm-12">
                  <div class="form-group">
                     <label for="name">Name</label>
                     <input type="text" name="name" formControlName="name"  class="form-control" placeholder="Name">
                     <div *ngFor= "let error of translateFormerrorMessages.name">
                        <ng-container *ngIf="name.hasError(error.type) && (name.dirty || name.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row formspace">
               <div class="col-sm-12">
                  <div class="form-group">
                     <label for="language" trans>Language</label>
                     <select id="language" formControlName="language" class="form-control" *ngIf="localizationData==null">
                        <option value=""></option>
                        <option [value]="lang.code" *ngFor="let lang of languages$" trans>{{lang.name}}</option>
                     </select>
                        <select id="language" formControlName="language" class="form-control" *ngIf="localizationData!=null" disabled><option value=""></option>
                        <option [value]="lang.code" *ngFor="let lang of languages$" trans>{{lang.name}}</option>
                     </select>
                     <div *ngFor= "let error of translateFormerrorMessages.language">
                        <ng-container *ngIf="language.hasError(error.type) && (language.dirty || language.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
            <button *ngIf="localizationData==null" [disabled]="!translateForm.valid" type="submit" class="btn btn-primary new">
            Update
            </button>
            <button *ngIf="localizationData!=null" type="submit" class="btn btn-primary new">
            Update
            </button>
            </form>
         </div>
      </div>
   </div>
</div>
<!--CRUPDATE_TRANSLATION_MODAL -->

<!--STATUS_TRANSLATION_MODAL -->
<div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="statusTranslateModal" class="modal fade" style="display: none;">
   <div class="modal-dialog" style="margin-top: 200px;">
      <div class="modal-content" >
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button" #closeStatusTranslationModal>×</button>            
         </div>
         <div class="modal-body">
            <form (ngSubmit)="confirmStatus()" [formGroup]="statustranslateForm">
            <div class="row formspace">
               <div class="col-sm-12">
                  <div class="form-group">
                     <label for="status">Status</label>
                     <select id="status" formControlName="status" class="form-control">
                        <option value="" trans="Please select">Please select</option>
                        <option value="active" trans="Active">Active</option>
                        <option value="inactive" trans="In-Active">In-Active</option>
                     </select>
                     <div *ngFor= "let error of statusFormerrorMessages.status">
                        <ng-container *ngIf="status.hasError(error.type) && (status.dirty || status.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-sm-12">
                  <div class="form-group">
                     <label for="statuslanguage" trans="Language">Language</label>
                     <select id="statuslanguage" formControlName="statuslanguage" class="form-control">
                        <option value="" trans="Please select">Please select</option>
                        <option [value]="localization.model.id" *ngFor="let localization of localizations$ | async" trans>{{localization.model.name}}</option>
                     </select>
                     <div *ngFor= "let error of statusFormerrorMessages.statuslanguage">
                        <ng-container *ngIf="statuslanguage.hasError(error.type) && (statuslanguage.dirty || statuslanguage.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
            
            <button *ngIf="localizationData==null" [disabled]="!statustranslateForm.valid" type="submit" class="btn btn-primary new">
            Update
            </button>
            <button *ngIf="localizationData!=null" type="submit" class="btn btn-primary new">
            Update
            </button>
            </form>
         </div>
      </div>
   </div>
</div>
<!--STATUS_TRANSLATION_MODAL -->

<!-- NEW_TRANSLATION_MODAL -->
<div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="new-translation-line" class="modal fade" style="display: none;">
   <div class="modal-dialog" style="margin-top: 200px;">
      <div class="modal-content" >
         <div class="modal-header">
            <button aria-hidden="true" (click)="resetNewTranslationForm()" #closeNewTranslationModal class="close" data-dismiss="modal" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark">Add New Translation Line</h4>
         </div>
         <div class="modal-body">
            <form  [formGroup]="newtranslateForm">
               <div class="row formspace">
                  <div class="col-sm-12">
                     <div class="form-group">
                        <label for="name">Translate Key</label>
                        <input type="text" name="translationKey" formControlName="translationKey"  class="form-control" placeholder="Translate Key">
                        <div *ngFor= "let error of errorMessages.translationKey">
                           <ng-container *ngIf="translationKey.hasError(error.type) && (translationKey.dirty || translationKey.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row formspace">
                  <div class="col-sm-12">
                     <div class="form-group">
                        <label for="language" >Translate Value</label>
                        <input type="text" name="translationValue" formControlName="translationValue"  class="form-control" placeholder="Translate value">
                        <div *ngFor= "let error of errorMessages.translationValue">
                           <ng-container *ngIf="translationValue.hasError(error.type) && (translationValue.dirty || translationValue.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
               </div>
               <button (click)="addLine()"  [disabled]="!newtranslateForm.valid" type="submit" class="btn btn-primary new">
               Add
               </button>
            </form>
         </div>
      </div>
   </div>
</div>
<!-- NEW_TRANSLATION_MODAL -->
<app-toast></app-toast>

