import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LessonsMenuService } from '../lessons-menu.service';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '../../../core/types/models/User';
import { DatatableService } from '../../../core/datatable/datatable.service';
import { AdminMessagemodalComponent } from '../../admin-messagemodal/admin-messagemodal.component';
import { ViewReviewComponent } from '../../view-review/view-review.component';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { LessonsService } from '../../../student/lessons/lessons.service';
import { ScheduleModalComponent } from '../../../student/lessons/schedule-modal/schedule-modal.component';
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';
declare var $;

@Component({
  selector: 'app-lessons-listing',
  templateUrl: './lessons-listing.component.html',
  styleUrls: ['./lessons-listing.component.css'] ,
  providers: [DatatableService]
})
export class LessonsListingComponent implements OnInit {
  
  @ViewChild('closeSingleCancellation') closeSingleCancellation: ElementRef;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;  
  @ViewChild(AdminMessagemodalComponent) adminMessageChild: AdminMessagemodalComponent;
  @ViewChild(ViewReviewComponent) reviewChild: ViewReviewComponent;
  public badReviews_count:number = 0;
  public unAttended_count:number = 0;
  public topReviews_count:number = 0;
  public experincedErrors_count:number = 0;
  public refundedLessons_count:number = 0;
  public allLessons_count:number = 0;
  public unAcceptedLessons_count:number = 0;
  public tabName:any = 'Bad Reviews';
  public lessons$ = this.datatable.data$;
  public tabsData:any;
  public badReviews:any;
  public unAttended:any;
  public topReviews:any;
  public experiencedErrors:any;
  public refundedLessons:any;
  public allLessons:any;
  public unAcceptedLessons:any;
  public Timings:any;
  public cancellationBookingId:any;
  public tutorAvailability:any;
  public studentBookings:any;
  public timezone:string;
  public cancellationId:number;
  public bookingDateId:number;

  constructor(public service: LessonsMenuService,
    public datatable: DatatableService<TransactionLog>,
    private fb: FormBuilder,
    private lessonService: LessonsService,
    private localetimezone: LocaleTimeZoneTS) { }

  ngOnInit(): void {

     this.timezone = this.localetimezone.timezone();

  	    this.datatable.init({
            uri: LessonsMenuService.Lessons_BASE_URI
        });

        this.datatable.addFilter('tab',this.tabName);

  	  	$('.tab-link').click( function() {
        var tabID = $(this).attr('data-tab');
        $(this).addClass('active').siblings().removeClass('active');
        $('#tab-'+tabID).addClass('active').siblings().removeClass('active'); 
       });

  	   this.lessonTabsCount();

  this.lessons$.subscribe(response =>{
   if(this.tabName == 'Bad Reviews'){
    this.badReviews = response;
    }else if(this.tabName == 'Unattended'){
    this.unAttended = response;
   }else if(this.tabName == 'Top Reviews'){
    this.topReviews = response;
   }else if(this.tabName == 'Experienced Errors'){
    this.experiencedErrors = response;
   }else if(this.tabName == 'Refunded Lessons'){
    this.refundedLessons = response;
   }else if(this.tabName == 'All Lessons'){
    this.allLessons = response;
   }else if(this.tabName = 'Unaccepted'){
    this.unAcceptedLessons = response;
   }
  });
  }

  public selectTab(tabName){  	
    this.tabName = tabName;
    this.datatable.addFilter('tab',tabName);
    this.datatable.addFilter('page','1');
  }

  public lessonTabsCount(){
  	 this.service.lessonDataCount().subscribe(response => {
    let res = JSON.parse(atob(response['data']));
    this.tabsData = res;
    this.badReviews_count = this.tabsData['badReviews_count'];
    this.unAttended_count = this.tabsData['unAttended_count'];
    this.topReviews_count = this.tabsData['topReviews_count'];
    this.experincedErrors_count = this.tabsData['experincedErrors_count'];
    this.refundedLessons_count = this.tabsData['refundedLessons_count'];
    this.allLessons_count = this.tabsData['allLessons_count'];
    this.unAcceptedLessons_count = this.tabsData['unAcceptedLessons_count'];
  	});
  }


  public messageModal(userData){
    this.adminMessageChild.userData(userData);
  }

  public userReview(reviewData){
    this.reviewChild.reviewData(reviewData);
  }

  public scheduleTimings(timings){
    this.Timings = timings;
  }

  public directCancellation($booking_date_id){
    this.cancellationBookingId = $booking_date_id;
  }

 public reloadLessons(value){
   if(value == true){
     this.datatable.addFilter('tab',this.tabName);
     this.lessonTabsCount();
   }
  }

  public scheduleModalData(data){
    let bookingType;
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.lessonService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: data.id});
    if(data.data.tutor_id){
      Object.assign(availabilityData , {tutorId : data.data.tutor_id});
      bookingType = 'directCancelation';
    }else{ 
      Object.assign(availabilityData , {tutorId : data.data.tutor_id});
      bookingType = 'requestReschedule'; 
    }
    Object.assign(availabilityData , {timezone: this.timezone});
    this.lessonService.getStudentBooking(availabilityData).subscribe(response =>{
          let resposne =  JSON.parse(atob(response['studentBookings']));
          this.studentBookings = resposne['studentDates'];
          this.tutorAvailability = resposne['avaialbleTimings'];
          let packageDetails = '';
          this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings ,packageDetails, data.data, bookingType);
    });
  }

     public directSingleCancellation(data){
    this.cancellationId = data.bookingID;
    this.bookingDateId = data.bookingDateId;
  }

  public cancelSingleBooking(){
    let data = Object(); 
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {bookingDateId : this.bookingDateId});
    this.lessonService.cancelSingleStudentBooking(data).subscribe(response => {
        if(response.status == 'success'){
          this.closeSingleCancellation.nativeElement.click();
          this.reloadLessons(true);
        }
    });
  }
}
