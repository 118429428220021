<div class="row m-auto">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
       <table class="table table-striped TT-table">
        <thead>
        <tr>
            <th trans="Username">Username</th>
            <th trans="Name">Name</th>
            <th trans="Joined Date">Joined Date</th>
            <th trans="Progress Check">Progress Check</th>
            <th trans="Actions" colspan="2">Actions</th>
        </tr>
        </thead>

        <tbody>
          <tr *ngFor="let tutor of allTutors$">
            <td><span>{{tutor.tutor_profile?.username}}</span></td>
            <td>{{tutor.display_name}}</td>
            <td>{{tutor.created_at |  date: 'MM-dd-yyyy h:mm a'}}</td>
            <td>
            <app-progress-checks [progresschecks]="tutor.progress_checks"></app-progress-checks>
            </td>
            <td class="TT-formGroup">
              <a href="#modalApproval" (click)="viewApplication(tutor.user_id ,'existingProfile')" class="btn TT-primaryBTN pull-right TT-topSpaceZero"  value="Attend" data-target="#modalApproval" data-toggle="modal"> View</a>
            </td>
            <td class="dropdown TT-tableDropDownMenuList">
             <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
             <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
              
              <li class="TT-optionsList" *ngIf="tutor.tutor_profile?.registration_process != 'Suspended'"><a class="TT-frameSet" (click)="suspenduser(tutor.tutor_profile.id, 'suspend')" trans="Suspend"> Suspend</a></li>
              <li class="TT-optionsList" *ngIf="tutor.tutor_profile?.registration_process == 'Suspended'"><a class="TT-frameSet" (click)="activateUser(tutor.tutor_profile.id, 'suspend')" trans="Activate Tutor">Activate Tutor</a></li>

              <li class="TT-optionsList"><a class="TT-frameSet" (click)="deleteuser(tutor.tutor_profile.id, 'delete')" trans="Delete">Delete</a></li>
              <li class="TT-optionsList" (click)="messageModal(tutor.tutor_profile)"><a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a></li>
             </ul>
            </td>
          </tr>
        
          <tr *ngIf="allTutors$ && allTutors$.length == 0" class="textalign" trans="No records found">No records found</tr>
         </tbody>
       </table>
     </div>
  </div>
</div>

<app-datatable-footer-new></app-datatable-footer-new>