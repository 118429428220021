<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
     <div class="table-responsive TT-responsiveTable">
       <table class="table table-striped TT-table"> 
         <thead>
              <tr>
                  <th trans="Lesson Id">Lesson Id</th>
                  <th trans="Booking Id">Booking Id</th>
                  <th trans="Student">Student</th>
                  <th trans="Student Attendence">Student Attendence</th>
                  <th trans="Tutor">Tutor</th>
                  <th trans="Tutor Attendence">Tutor Attendence</th>
                  <th trans="Subject">Subject</th>
                  <th trans="Class Time">Class Time</th>
                  <th trans="Class Status">Class Status</th>
                  <th trans="Time">Time</th>                  
                  <th trans="Actions">Actions</th>
               </tr>
             </thead>
               
             <tbody>
               <tr *ngFor="let complaint of complaints$">
                  <td><span>{{complaint.schedule.booking_dates_id}}</span></td>
                  <td><span>{{complaint.booking.booking_id}}</span></td>
                  <td><span>{{complaint.student.username}}</span></td>
                  <td>{{transform(complaint.schedule.student_attendence)}}</td>
                  <td><span>{{complaint.tutor.username}}</span></td>
                  <td>{{transform(complaint.schedule.tutor_attendence)}}</td>
                  <td><span>{{complaint.booking.subject_with_trash.subject_name}}</span></td>
                  <td><span>{{complaint.schedule.booked_time}}</span></td>
                  <td>{{transform(complaint.schedule.class_status)}}</td>
                  <td><span>{{complaint.created_at | date: 'MM-dd-yyyy h:mm a'}}</span></td>
                  <td class="dropdown TT-tableDropDownMenuList">
                   <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                   <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                     <li class="TT-optionsList">
                      <a class="TT-frameSet" id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" data-target="#adminViewNote" data-toggle="modal" aria-hidden="true" href="#adminViewNote" (click)="viewComment(complaint.comments)"> View </a>
                     </li>
                     <li class="TT-optionsList" (click)="messageModal(complaint.student , 'student')">
                      <a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Student">Message Student</a></li>
                     <li class="TT-optionsList" (click)="messageModal(complaint.tutor , 'tutor')">
                      <a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a>
                     </li>
                   </ul>
                  </td>
               </tr>
               <tr *ngIf="complaints$ && complaints$.length==0" class="TT-noRecord" trans="No records found">
                 <td colspan="12"> No records found.</td>
              </tr>
            </tbody>
         </table>
    </div>
  </div>
</div>
<app-datatable-footer-new *ngIf="complaints$ && complaints$.length>0" ></app-datatable-footer-new>
