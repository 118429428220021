<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
     <div class="table-responsive TT-responsiveTable">
      <table class="table table-striped TT-table">
       <thead>
         <tr>
           <th trans="Username">Username</th>
           <th trans="Email">Email</th>
           <th trans="Suspended Date">Suspended Date</th>
           <th trans="Suspended By">Suspended By</th>
           <th trans="Location">Location</th>
           <th trans="Actions" colspan="2">Actions</th>
         </tr>
       </thead>

      <tbody>
      <tr *ngFor="let student of suspendedStudents$">
         <td><span >{{student.username}}</span></td>
         <td><span >{{student.email}}</span></td>
         <td>{{student.suspended_date | date: 'yyyy-MM-dd h:mm a'}}</td>
         <td>{{student.suspended_by_name}}</td>
         <td>{{student.country}}</td>
         <td class="dropdown TT-tableDropDownMenuLis TT-formGroup">

          <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" href="#studentProfile" (click)="viewApplication(student.id)" class="btn TT-primaryBTN TT-topSpaceZero" value="Attend" data-target="#studentProfile" data-toggle="modal">
            View</a>
         </td>
         <td class="dropdown TT-tableDropDownMenuLis TT-formGroup">
            <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
          <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
            <li class="TT-optionsList"><a class="TT-frameSet" (click)="activestudent(student.id, 'active')" trans="Active Student"> Active Student</a></li>
            <li class="TT-optionsList"><a class="TT-frameSet" (click)="deleteuser(student.id, 'delete')" trans="Delete">Delete</a></li>
            <li class="TT-optionsList" (click)="messageModal(student)"><a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Student">Message Student</a></li>
          </ul> 
         </td>
       </tr>

       <tr *ngIf="suspendedStudents$ && suspendedStudents$.length==0" class="TT-noRecorded" trans="No records found">No records found.</tr>
       </tbody>
      </table>
    </div>
  </div>
</div>
<app-datatable-footer-new *ngIf="suspendedStudents$ && suspendedStudents$.length>0" ></app-datatable-footer-new>