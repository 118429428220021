<div class="container-padding TT-tabNavBar">
   <nav class="navbar navbar-default TT-navBarDefaultTab">
         <div class="collapse navbar-collapse TT-navbarCollapseTab" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav TT-navNavTabb">
               <li routerLinkActive="selectedMENU" class="TT-listingArea">
                <a routerLink="registration" trans="Students Menu" class="TT-navLinkArea">Students Menu</a>
               </li>
             </ul>
           </div>
           

         </nav> 
      </div>