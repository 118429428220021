<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
     <div class="table-responsive TT-responsiveTable">
      <table class="table table-striped TT-table">
         <thead>            <tr>
               <th trans="Username">Username</th>
               <th trans="Email">Email</th>
               <th trans="Type">Type</th>
               <th trans="Timestamp">Timestamp</th>
               <th trans="Progress Check">Progress Check</th>
               <th trans="Actions">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let tutor of changesApproval$">
               <td><span *ngIf="tutor.tutor_profile_not_suspended_or_deleted">{{tutor.tutor_profile_not_suspended_or_deleted.username}}</span></td>
               <td><span *ngIf="tutor.tutor_profile_not_suspended_or_deleted">{{tutor.tutor_profile_not_suspended_or_deleted.email}}</span></td>
               <td>New Application</td>
               <td>{{tutor.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
               <td style="min-width: 120px; padding: 0px;">
               <app-progress-checks [progresschecks]="tutor.progress_checks"></app-progress-checks>
               </td>
               <td class="TT-formGroup clearfix">

                <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"  href="#modaldatepicker" (click)="viewApplication(tutor.user_id , 'existingProfile')" class="btn TT-primaryBTN pull-right TT-topSpaceZero" value="Attend" data-target="#modaldatepicker" data-toggle="modal">
                  View</a>

               </td>
            </tr>
            
            <tr *ngIf="changesApproval$ && changesApproval$.length==0" class="textalign" trans="No records found">No records found.</tr>
         </tbody>
      </table>
</div>
      



<app-datatable-footer-new></app-datatable-footer-new>