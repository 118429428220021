<div class="modal fade" id="adminDirectCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1" #closeCancellation>
   <div class="modal-dialog-centered TT-modalMainArea" role="document">
      <div class="modal-content TT-modelCover">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
             <h4 class="modal-title TT-AccUsers" id="modalLabel">
               <span trans="Direct Cancellation">Direct Cancellation</span>
            </h4>
            <button type="button" class="close" #closeViewModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p>Are you sure you want to cancel this complete booking and refund the amount to student wallet?</p>
               <div class="row">
                  <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">&nbsp;</div>
                  <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 clearfix">
                     <div class="TT-formGroup d-flex pull-right">
                      <button [disabled]="loading$ | async" type="button" class="TT-primaryBTN" (click)="cancelCompleteBooking()" trans="Cancel Booking">Cancel Booking</button>
                        &nbsp;&nbsp;                        
                      <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close" #closeViewModal trans="Close">Close</button>
                     </div>
                  </div>
                </div>               
            </div>
         </div>
      </div>
   </div>
</div>