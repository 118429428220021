<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <form [formGroup]="authenticationForm" class="account-info">
      <div class="row m-auto">
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4 class="TT-AccSettings" trans="Authentication">Settings</h4>
            <span class="TT-AccSubSettings"
               trans="Configure registration, social login and, related 3rd party integrations">Configure registration,
               social login and, related 3rd party integrations</span>
         </div>
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row m-auto">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h4 class="TT-AccSettings" trans="Google Login Settings">Google Login Settings</h4>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="google_client_ID" trans="Google Client ID"> Google Client ID </label>
                     <input type="text" class="TT-FieldGroup"
                        placeholder="Ex : xxxxx0755796-0t16pcisft5o7n5c0i0ekaf0fctgk3nl.apps.googleusercontent.com"
                        id="google_client_ID" formControlName="google_client_ID">
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="google_client_secret_key" trans="Google Client Secret"> Google Client
                        Secret </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="google_client_secret_key" formControlName="google_client_secret_key">
                  </div>
               </div>
            </div>

            <div class="row m-auto">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h4 class="TT-AccSettings" trans="Facebook Login Settings">Facebook Login Settings</h4>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="facebook_app_ID" trans="Facebook App ID"> Facebook App ID </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : 1990136563939889" id="facebook_app_ID"
                        formControlName="facebook_app_ID">
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="facebook_app_secret_key" trans="Facebook App Secrett key"> Facebook
                        App Secret key </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : 84t0560663635ea63e2c3fc04fb048at"
                        id="facebook_app_secret_key" formControlName="facebook_app_secret_key">
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="paypal_client_id" trans="paypal_client_id"> Paypal Client Id </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="paypal_client_id" formControlName="paypal_client_id">
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="open_exchane_rate_key" trans="Open Exchange Rate Key"> Open Exchange
                        Rate Key </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="open_exchane_rate_key" formControlName="open_exchane_rate_key">
                  </div>
               </div>

            </div>

            <div class="row m-auto">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h4 class="TT-AccSettings" trans="SMTP Settings">SMTP Settings</h4>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="mail_mailer" trans="Mail Mailer"> Mail Mailer </label>
                     <select class="TT-FieldGroup TT-SelectIcon" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="mail_mailer" formControlName="mail_mailer">
                        <option value="smtp">SMTP</option>
                     </select>
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="mail_host" trans="Mail Host"> Mail Host </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="mail_host" formControlName="mail_host">
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="mail_port" trans="Mail Port"> Mail Port </label>
                     <input type="number" class="TT-FieldGroup" placeholder="Ex : 2525" id="mail_port"
                        formControlName="mail_port">
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="mail_username" trans="Mail Username"> Mail Username </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="mail_username" formControlName="mail_username">
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="mail_password" trans="Mail Password"> Mail Password </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="mail_password" formControlName="mail_password" />
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="mail_encryption" trans="Mail Encryption"> Mail Encryption </label>
                     <select class="TT-FieldGroup TT-SelectIcon" id="mail_encryption" formControlName="mail_encryption">
                        <option value="SSL">SSL</option>
                        <option value="TLS">TSL</option>
                     </select>
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="mail_from_address" trans="Mail From Address"> Mail From Address
                     </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="mail_from_address" formControlName="mail_from_address" />
                  </div>
               </div>
            </div>

            <div class="row m-auto">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h4 class="TT-AccSettings" trans="SMS Settings">SMS Settings</h4>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="twilio_SID" trans="Twilio SID"> Twilio SID </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="twilio_SID" formControlName="twilio_SID" />
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="twilio_token" trans="Twilio Token"> Twilio Token </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="twilio_token" formControlName="twilio_token" />
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="twilio_from" trans="Twilio From"> Twilio From </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="twilio_from" formControlName="twilio_from" />
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="twilio_api_key" trans="Twilio API Key"> Twilio API Key </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="twilio_api_key" formControlName="twilio_api_key" />
                  </div>
               </div>

               <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group TT-formGroup">
                     <label class="TT-lable" for="twilio_api_secret" trans="Twilio API Secret"> Twilio API Secret
                     </label>
                     <input type="text" class="TT-FieldGroup" placeholder="Ex : vffvd565ybnjkbgerghg57657"
                        id="twilio_api_secret" formControlName="twilio_api_secret" />
                  </div>
               </div>
            </div>

            <div class="row m-auto">
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-6 col-xs-12">&nbsp;</div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                   <div class="form-group TT-formGroup clearfix">
                    <button (click)="updateAutheticationSettings()" type="submit" trans="Update" class="btn TT-primaryBTN pull-right"> Update </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </form>
</div>