import { Component, OnInit } from '@angular/core';
import { DatatableService } from '@core/datatable/datatable.service';
import { PageCategory } from '@core/types/models/PageCategory';
import { PagesService } from '../pages.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

	public pageCategories = this.datatable.data$;
  public page_action = null;

  constructor(public datatable: DatatableService<PageCategory>,
              public pageService: PagesService,
              private toastr: ToastrService,
              private router: Router)
  {

   this.datatable.init({
        uri: PagesService.BASE_URI_PC
        });
     }

  ngOnInit(): void {
  }

   public createPageCategory()
   {
     this.router.navigate(['admin/pages/create-category']);
   }


   public deleteCategory(id)
   {
     this.pageService.deleteCategory(id).subscribe(response => {
       if(response['status'] == 'success')
    {
      this.toastr.success('Page Category has been deleted successfully');
      this.datatable.addFilter('n','1');
    }
     });
   }

   public SetEditAction(categoryId)
   {
     this.router.navigate(['admin/pages/create-category/'+categoryId]);
   }

   public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}
