import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../../auth/current-user';

@Component({
  selector: 'app-teachers-sidenav',
  templateUrl: './teachers-sidenav.component.html',
  styleUrls: ['./teachers-sidenav.component.css']
})
export class TeachersSidenavComponent implements OnInit {

  constructor(public currentuser: CurrentUser) { }

  ngOnInit(): void {
  }

}
