import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LessonsMenuService } from '../lessons-menu.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
  selector: 'app-lessons-board-settings',
  templateUrl: './lessons-board-settings.component.html',
  styleUrls: ['./lessons-board-settings.component.css']
})
export class LessonsBoardSettingsComponent implements OnInit {
   public fileTypes: Object[] = [
        { Id: 'JPG', Type: 'JPG' },
        { Id: 'PNG', Type: 'PNG' },
        { Id: 'PDF', Type: 'PDF' },

    ];
   public selectedFileTypes:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    public boardSettingForm = this.fb.group({
	       file_type:['',Validators.required],
	       fileTypes: ['']
    });

  // public errorMessages = {
  //       file_type: [ { type:'required',message:'Select A File Type'}]
  //   }
  // get file_type(){ return this.boardSettingForm.get('file_type'); }

  constructor(private fb: FormBuilder,
  	          private service: LessonsMenuService,
  	          private toastr: ToastrService) { }

  ngOnInit(): void {
  	// this.fileTypes;
  	this.service.getlessonBoardSetting().subscribe(response=>{
        let res = JSON.parse(atob(response['data']));;
        if ( res.status === 'failed' ) {
            this.toastr.error(res.message);
        } else {
          res = res.data;
          console.log(JSON.parse(res.fileTypes)[0])
          this.boardSettingForm.patchValue({
            'file_type':JSON.parse(res.fileTypes)[0],
          })
         this.selectedFileTypes.next(JSON.parse(res.fileTypes));
         this.boardSettingForm.patchValue({
            'fileTypes':this.selectedFileTypes.value
          });
        }
    });
  }

  public UpdateboardSetting(){
  	// console.log(this.boardSettingForm.value)
  	 this.boardSettingForm.markAllAsTouched();
       if(this.boardSettingForm.valid){
         console.log(this.boardSettingForm.value)
		  	 this.service.lessonBoardSetting(this.boardSettingForm.value).subscribe(response=>{
			        let res = JSON.parse(atob(response['data']));
			        if ( res.status === 'failed' ) {
			            this.toastr.error(res.message);
			        } else {
			          this.toastr.success(res.message);
			        }
			    });
		  }
  }

  public selectFileType(value){
  	this.fileTypes.find(type=>{
       if(type['Type'] == value){
        if(this.selectedFileTypes.value.includes(value)){
          const currentValue = this.selectedFileTypes.value;
        }else{
            const currentValue = this.selectedFileTypes.value;
            const updatedValue = [...currentValue, value];
            this.selectedFileTypes.next(updatedValue);
        }
    }
    });
    this.boardSettingForm.patchValue({
      'fileTypes':this.selectedFileTypes.value
    });

  }

  public removeItem(types){
  	const index = this.selectedFileTypes.value.indexOf(types);
    if (index > -1) {
     let sliced_ilst = this.selectedFileTypes.value.splice(index, 1);
    this.boardSettingForm.patchValue({
      'fileTypes':this.selectedFileTypes.value
    });
    }

  }
}
