 <div class="TT-tutorWrapper">
   <ul class="TT-tutorTabs">
      <li (click)="selectTab('Bad Reviews')" class="tab-link TT-tutorList active" data-tab="1">
         <div class="teacher-badge TT-badgeRed">
            <div class="TT-messageCount">
               {{badReviews_count}}
            </div>
         </div>
         <span trans=" Bad Reviews"> Bad Reviews</span>  
      </li>

      <li (click)="selectTab('Top Reviews')" class="tab-link TT-tutorList" data-tab="2">
         <div class="teacher-badge TT-badgeRed">
            <div class="TT-messageCount">
               {{topReviews_count}}
            </div>
         </div>
         <span trans="Top Reviews">Top Reviews</span> 
      
      <li (click)="selectTab('Unattended')" class="tab-link TT-tutorList" data-tab="3">
         <div class="teacher-badge TT-badgeRed">
            <div class="TT-messageCount">
               {{unAttended_count}}
            </div>
         </div>
         <span trans="Unattended">Unattended</span>
      </li>

      <li (click)="selectTab('Refunded Lessons')" class="tab-link TT-tutorList" data-tab="5">
         <div class="teacher-badge TT-badgeRed">
            <div class="TT-messageCount">
               {{refundedLessons_count}}
            </div>
         </div>
         <span trans="Refunded Lessons">Refunded Lessons</span>  
      </li>

      <li (click)="selectTab('Unaccepted')" class="tab-link TT-tutorList" data-tab="6">
         <div class="teacher-badge TT-badgeRed">
            <div class="TT-messageCount">
               {{unAcceptedLessons_count}}
            </div>
         </div>
         Unaccepted
      </li>
      
      <li (click)="selectTab('All Lessons')" class="tab-link TT-tutorList" data-tab="7">
         <div class="teacher-badge TT-badgeRed">
            <div class="TT-messageCount">
               {{allLessons_count}}
            </div>
         </div>
         <span>All Lessons</span>
      </li>

   </ul>
      
      <div id="tab-1" class="tab-content active">
       <div class="TT-templateDraft">
         <div class="row m-auto">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
            </div>
         </div>
       </div> 

       <app-bad-reviews [badReviews]="badReviews" (reviewModalData)="userReview($event)" (adminMessageModalData)="messageModal($event)"></app-bad-reviews>
      </div>

      <div id="tab-2" class="tab-content">
         <div class="TT-templateDraft">
            <div class="row m-auto">
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                 <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
               </div>
            </div>
          </div>

         <app-top-reviews (reviewModalData)="userReview($event)" (adminMessageModalData)="messageModal($event)" [topReviews]="topReviews"></app-top-reviews>
      </div>

      <div id="tab-3" class="tab-content">
         <div class="TT-templateDraft">
         <div class="row m-auto">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
            </div>
         </div>
       </div>

       <app-un-attended [unAttended]="unAttended" (adminMessageModalData)="messageModal($event)"></app-un-attended>
      </div>

      <div id="tab-4" class="tab-content">
         <div class="TT-templateDraft">
            <div class="row m-auto">
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                 <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
               </div>
            </div>
          </div>

         <app-experienced-errors [experiencedErrors]="experiencedErrors"></app-experienced-errors>
      </div>

      <div id="tab-5" class="tab-content">
         <div class="TT-templateDraft">
            <div class="row m-auto">
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                 <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
               </div>
            </div>
          </div>

         <app-refunded-lessons [refundedLessons]="refundedLessons" (adminMessageModalData)="messageModal($event)"></app-refunded-lessons>
      </div>

      <div id="tab-6" class="tab-content">
        <div class="TT-templateDraft">
         <div class="row m-auto">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
            </div>
         </div>
       </div>

       <app-un-accepted (scheduleModalData)="scheduleModalData($event)" (directCancellation)="directCancellation($event)" (adminMessageModalData)="messageModal($event)" [unAcceptedLessons]="unAcceptedLessons" (scheduleTimings)="scheduleTimings($event)"></app-un-accepted>
      </div>

      <div id="tab-7" class="tab-content">
         <div class="TT-templateDraft">
            <div class="row m-auto">
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                 <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
               </div>
            </div>
          </div>
         <app-all-lessons (directSingleCancellation)="directSingleCancellation($event)" [allLessons]="allLessons"  (reviewModalData)="userReview($event)" (adminMessageModalData)="messageModal($event)"></app-all-lessons>
      </div>
   </div>

<app-admin-messagemodal></app-admin-messagemodal>
<app-direct-cancellation-modal [cancellationBookingId]="cancellationBookingId"></app-direct-cancellation-modal>
<!-- <app-schedule-modal (reloadLessons)="reloadLessons($event)"></app-schedule-modal> -->
<app-view-review></app-view-review>
 <div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog" role="document" style="margin-top: 100px;">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Booking Details"> Booking Details</h5>
            <button type="button" class="close" #closeViewDeatailsModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body" >
               <div class="container-fluid whiteBg">
                  <!-- <div class="tab-content"> -->
                     <table class="table lesson-table">
                        <tr>
                           <th trans="Date"> Date</th>
                           <th trans="Timings">Timings</th>
                           <th trans="Status">Status</th>
                           <th trans="Class Status">Class Status</th>
                        </tr>
                        <tr *ngFor="let time of Timings">
                           <td>{{time.slot_date}}</td>
                           <td>{{time.booked_time}}</td>
                           <td>{{time.status}}</td>
                           <td>{{time.class_status}}</td>
                        </tr>
                     </table>
                  <!-- </div> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="directSingleCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1" #closeSingleCancellation>
   <div class="modal-dialog" role="document" style="margin-top: 100px;">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span trans="Direct Cancellation">Direct Cancellation</span>
            </h5>
            <button type="button" class="close" #closeSingleCancellation data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body TT-formGroup" >
               <p style="color: #000;" trans="Are you sure you want to cancel this booking and refund the amount to wallet?">Are you sure you want to cancel this booking and refund the amount to wallet?</p>
               <button type="button" class="btn TT-primaryBTN" (click)="cancelSingleBooking()" trans="Cancel Booking" >Cancel Booking</button>
               &nbsp;&nbsp;
               <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close" #closeViewModal trans="Close">Close</button>
            </div>
         </div>
      </div>
   </div>
</div>

