import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../../auth/current-user';

@Component({
  selector: 'app-students-side-nav',
  templateUrl: './students-side-nav.component.html',
  styleUrls: ['./students-side-nav.component.css']
})
export class StudentsSideNavComponent implements OnInit {

  constructor(public currentuser: CurrentUser) { }

  ngOnInit(): void {
  }

}
