<div class='col-sm-10 rightCol'>
	<div class="well well-sm chat-head">
      <h4 class="title"trans="Database reset">Database reset</h4>
      <div class="subtitle"trans="This will format all your system data.">This will format all your system data.</div>
   </div>
   <div class='content-container customTabs'>
   	<button class="btn btn-primary new" trans="Reset" data-target="#modalConfirmation" data-toggle="modal">
                                                Reset
                                            </button>
   </div>
</div>
<app-db-reset-modal></app-db-reset-modal>
