import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatatableService } from '../../core/datatable/datatable.service';
import { User } from '../../core/types/models/User';
import { Users } from '../../auth/users.service';

@Component({
  selector: 'app-tutor-teaching',
  templateUrl: './tutor-teaching.component.html',
  styleUrls: ['./tutor-teaching.component.css']
})
export class TutorTeachingComponent implements OnInit {
  constructor( 
  	       private router : Router) { 
}

  ngOnInit(): void {

  }
}
