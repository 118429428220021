import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bad-reviews',
  templateUrl: './bad-reviews.component.html',
  styleUrls: ['./bad-reviews.component.css']
})
export class BadReviewsComponent implements OnInit {

  @Output() adminMessageModalData = new EventEmitter();
  @Output() reviewModalData = new EventEmitter();
  public badReviews$:any;
  @Input() 
  public set badReviews(value){
  	if(value){
    this.badReviews$ = value;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  public messageModal(userData , role){
    if(role == 'student'){
    this.adminMessageModalData.emit(userData);
    }else{
    this.adminMessageModalData.emit({username:userData.display_name , id:userData.user_id});
    }
  }

  public viewReview(review , username){
    this.reviewModalData.emit({review:review , name:username});
  }


}
