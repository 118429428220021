import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-saletrans',
  templateUrl: './saletrans.component.html',
  styleUrls: ['./saletrans.component.css']
})
export class SaletransComponent implements OnInit {
  
  @Output() adminMessageModalData = new EventEmitter();
  public transactions$:any;
  @Input() 
  public set saleTransactions(value){
    this.transactions$ = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

  public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }

  public transform(value) {
    if ( value ) {
      return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    } else {
      return value;
    }
  }
}
