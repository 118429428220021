import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-review',
  templateUrl: './view-review.component.html',
  styleUrls: ['./view-review.component.css']
})
export class ViewReviewComponent implements OnInit {
  
  public selectedStudentName:any;
  public review:any;
  constructor() { }

  ngOnInit(): void {
  }

  public reviewData(reviewData){
    this.selectedStudentName = reviewData.name;
    this.review = reviewData.review;
  }

  public closeReviewModal(){
    this.selectedStudentName = '';
    this.review = '';    
  }

}
