import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../template.service';
import { Router } from '@angular/router';
import { DatatableService } from '../../../core/datatable/datatable.service';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { Model } from '../../../core/types/models/Model';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'] ,
  providers: [DatatableService],
})
export class ListComponent implements OnInit {
 
  public obj:any;
  public selectedColumn:any;
  public searchControl = new FormControl();
 public form = this.fb.group({
        type: null,
  });
  public templates$ = this.datatable.data$;
  public selectedTemplate;
  public selectedTemplateId;
  public pagesColumn = [ {'Title':['title'] , 'Key':['key'] , 'Subject':['subject'] }];
  
  constructor(public templateService:TemplateService,
              public router: Router,
              public datatable: DatatableService<TransactionLog>,
              private datable: DatatableService<Model>,
              private fb: FormBuilder,
              private toastr: ToastrService) {

  this.form.valueChanges.subscribe(value => {
            this.datable.filters$.next(value);
  });

  this.datatable.init({
      uri: TemplateService.BASE_URI
  });

  this.templateService.sharedTemplate.subscribe(response => {
   this.selectedTemplate = response;
     this.form.patchValue({
        type:this.selectedTemplate,
    });
  });
  }

  ngOnInit(): void {
    this.obj = Object.assign({}, ...this.pagesColumn);  
    this.searchControl.valueChanges
    .pipe(debounceTime(400), distinctUntilChanged())
    .subscribe(query => {
        this.datatable.addFilter('column',this.selectedColumn);
        this.datatable.addFilter('templateQuery', query);
  });
  }

  public createTemplate(){
   this.router.navigateByUrl('admin/templates/create');
  }

  public edit(template){
   this.selectedTemplate = template;
   this.templateService.templateId(this.selectedTemplate);
   this.router.navigateByUrl('admin/templates/create');
  }

  public delete(id){
  this.templateService.removeTemplate(id).subscribe(response=>{
     if(response['status'] =='success'){
     this.form.patchValue({
        type:this.selectedTemplate,
    });
     this.toastr.success('Template deleted');
     }
  });

  }

    /*
  CSV Import of data
  */
  public csvExort(name){
    new AngularCsv(this.templates$.value , name);
  }


    /*
  Search by column
  */
  public selectedlistColumn(column  , id){
    if(column){
      this.selectedColumn = column;
      $('#'+id).removeAttr('disabled');
    }else{
      this.selectedColumn = '';
      this.datatable.addFilter('column',this.selectedColumn);
      $('#'+id).attr('disabled','disabled');
    }
   $('#'+id).val('');
  }

  public changeStatus(id, status) {
    this.templateService.changeStatus(id, status).subscribe(response=>{
      if(response['status'] =='success'){
        this.form.patchValue({
            type:this.selectedTemplate,
        });
        this.toastr.success('Template updated');
      }
      // this.router.navigateByUrl('admin/templates/list');
   });
  }

}
