<div class="modal fade" id="SuspensionNotes" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
    <div class="modal-dialog" role="document" style="margin-top: 100px;">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            	<h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                supension notes
            </h5>
            <button type="button" class="close" #closeDbResetModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
                <span aria-hidden="true">
                    &times;
                </span>
            </button>
            <div class="modal-body" >
            	 <!-- <div class="row formspace">
		            <div class="col-sm-9">
	                     <div class="form-group">
	                        <label trans="Site Description">Are you sure you want to reset databse? Once reset this action cannot be reverted.</label>
			                </div>
				        </div>
				    </div>
			    <div class="row">
	            	<div class="col-sm-3">
	            		<button (click)="CloseModal()" type="submit" class="btn btn-Orange btn-new" trans="Update">cancel</button>
	            	</div>
	            	<div class="col-sm-3"></div>
                    <div class="col-sm-3">
                    	<button (click)="resetDatabase()" type="submit" class="btn btn-primary btn-new" trans="Update">proceed</button>
                    </div>
        			</div> -->
	          	</div>
	        </div>
        </div>
    </div>
</div>

