import {Injectable} from '@angular/core';
import {AppHttpClientService} from '@core/http/app-http-client.service';
import {Observable} from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PagesService {
    static BASE_URI = 'pages';
    static BASE_URI_PC = 'page-categories-list';
    constructor(private http: AppHttpClientService) {}

    //Add and Update SuccessStories
    public getPages(){
       return this.http.get('pages');
    }
    public storePage( payload: Object ){
        return this.http.post('store-pages',payload);
    }

    public getPageCategories(){
        return this.http.get('page-categories');
    }
    public storePageCategory( payload: Object ){
        return this.http.post('store-page-categories',payload);
    }

    public getRoles(){
        return this.http.get('getRoles');
    }

    public getCategory(id)
    {
        return this.http.get('getCategory/'+id)
    }

    public deleteCategory(id)
    {
        return this.http.delete('page-categories/'+id)
    }

    public deletePage(id)
    {
        return this.http.delete('pages/'+id)
    }
    public getPage(id)
    {
        return this.http.get('getPage/'+id)
    }

    public updatePageCategory(value , id)
    {
        return this.http.post('updateCategory/'+id, value)
    }

    public updatePage(values , id)
    {
        return this.http.post('updatePage/'+id, values)
    }

    public savepageSettings(payload){
        return this.http.post('savetutorSettings',payload)
    }

    public getpageSettings() {
        return this.http.get('getPageSettings');
    }

}
