import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';
import { CurrentUser } from '../../auth/current-user';
import { randomString } from '../../core/utils/random-string';
import { ToastrService } from 'ngx-toastr';
import { FinanceService } from '../finance/finance.service';
declare var $;

@Component({
  selector: 'app-admin-messagemodal',
  templateUrl: './admin-messagemodal.component.html',
  styleUrls: ['./admin-messagemodal.component.css']
})

export class AdminMessagemodalComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;
  constructor(public currentuser: CurrentUser,
              private toastr: ToastrService,
              public finance:FinanceService) { }

  public selectedTutorName;
  public selecteduserData;
  public message;
  ngOnInit(): void {
  }

  public userData(userData){
   
    if ( userData ) {
    this.selectedTutorName = userData.username;
   }
   this.selecteduserData = userData;
  }

  public messageText(value){
  	if(value){
    this.message = value;
  		$("#sendBtn").prop('disabled',false);
  	}else{
      $("#sendBtn").prop('disabled',true);
  	} 
  }

  public sendMessage(){
    console.log(this.message);
    console.log(this.selecteduserData);
    if ( this.message != '' && this.selecteduserData ) {
        let time = new Date().getTime();
        let messageData = Object();
        Object.assign(messageData , {to_id:''+this.selecteduserData.id});
        Object.assign(messageData , {user:this.selecteduserData});
        Object.assign(messageData , {message:this.message});
        Object.assign(time , {time:time});
        Object.assign(messageData , {senderName:this.currentuser.userName()});
        Object.assign(messageData , {randomMsgId : randomString(13)});
        Object.assign(messageData , {sendAsNotification: 'Yes'});
        this.finance.sendMessage(messageData).subscribe(response =>{
         $('#message').val('');
         if(response.status == 'success'){
          this.toastr.success('Message Sent Successfully');
          this.closeModal.nativeElement.click();
          this.message = '';
         }
        });
      }else{
        if (this.selecteduserData == '') {
          this.toastr.error("Sorry! User record not exists in the system");
        } else {
          this.toastr.error("Please fill the Message field");
        }
      }
}
}
