import { Component, OnInit } from '@angular/core';
import { DatatableService } from '@core/datatable/datatable.service';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { Page } from '@core/types/models/Page';
import { PagesService } from '../pages.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
  selector: 'app-pages-list',
  templateUrl: './pages-list.component.html',
  styleUrls: ['./pages-list.component.css'] ,
  providers: [DatatableService],
})

export class PagesListComponent implements OnInit {
	
  public obj:any;
  public pages = this.datatable.data$;
  public pagesColumn = [ {'Title':['title'] , 'Page Route':['page_route'] , 'Category':['category','title'] }];
  public searchControl = new FormControl();
  public selectedColumn:any;

	constructor(public datatable: DatatableService<TransactionLog>,
				public pageService: PagesService,
        private toastr: ToastrService,
				private router: Router,
				)
	{
		this.datatable.init({
		uri: PagesService.BASE_URI
		});
    this.obj = Object.assign({}, ...this.pagesColumn);  
	}


	ngOnInit(): void {
           this.searchControl.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.datatable.addFilter('column',this.selectedColumn);
                this.datatable.addFilter('pQuery', query);
          });
  }


  /*
  Search by column
  */
  public selectedPagesColumn(column  , id){
    if(column){
      this.selectedColumn = column;
      $('#'+id).removeAttr('disabled');
    }else{
      this.selectedColumn = '';
      this.datatable.addFilter('column',this.selectedColumn);
      $('#'+id).attr('disabled','disabled');
    }
   $('#'+id).val('');
  }

  /*
  CSV Import of data
  */
  public csvExort(name){
    new AngularCsv(this.pages.value , name);
  }

  public createPage()
   {
     this.router.navigate(['admin/pages/create-pages']);
   }

   public deletePage(id)
   {
     this.pageService.deletePage(id).subscribe(response => {
       if(response['status'] == 'success')
    {
      this.toastr.success('Page has been deleted successfull');
      this.router.navigate(['admin/pages/pages-list']);
    }
     });
   }

   public SetEditAction(id)
   {
      this.router.navigate(['admin/pages/create-pages/'+id]);
   }
   public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}

