import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-un-attended',
  templateUrl: './un-attended.component.html',
  styleUrls: ['./un-attended.component.css']
})
export class UnAttendedComponent implements OnInit {

  @Output() adminMessageModalData = new EventEmitter();
  public unAttended$:any;
  @Input() 
  public set unAttended(value){
    this.unAttended$ = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

  public messageModal(userData , role){
    if(role == 'student'){
    this.adminMessageModalData.emit(userData);
    }else{
    this.adminMessageModalData.emit({username:userData.display_name , id:userData.user_id});
    }
  }
  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}
