<div class="container-fluid padding0">
  <section id="findTutor-chat" class="pageSectionBg">
    <div class="TT-outlineLayers"> 
   <app-datatable-header pluralResourceName="users">
     <ng-container leftContent>
       <button  id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" class="TT-AccUsers" data-toggle="modal" data-target="#crupdateUserModal" *ngIf="currentUser.hasPermission('users.create')" trans="Add">
        <i class="fa fa-plus" aria-hidden="true"></i> &nbsp;ADD
      </button>
     </ng-container>              
     <ng-template #tableFilters>
       <user-index-filters></user-index-filters>
     </ng-template>
   </app-datatable-header>
   
   <div class="table-responsive TT-responsiveTable">
    <table class="table table-striped TT-table TT-HeightTabbles">
      <thead>
        <tr>
         <th trans="User">User</th>
         <th trans="Roles">Roles</th>
         <th trans="Name">Name</th>
         <th trans="Phone">Phone</th>
         <th trans="Created at">Created At</th>
         <th trans="Actions">Actions</th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of users$ | async">
       <td id="{{user.id}}" class="d-flex TT-pictureGallery">
         <img [src]="user.avatar" alt="" class="TT-profileIco" />
           <div>
            <div>{{user.username}}</div>
            <div class="email secondary">{{user.email}}</div>
          </div>
        </td>
        <td>{{makeRolesList(user.roles)}}</td>
        <td> {{user.first_name}} {{user.last_name}}</td>
        <td>{{user.dail_code}} {{user.phone}}</td>
        <td>{{user.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
        <td class="TT-pictureGallery">
         <a class="TT-linkingDato" id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" href="#crupdateUserModal" (click)="EditApplication(user)" value="Attend" data-target="#crupdateUserModal" data-toggle="modal" *ngIf="currentUser.userId() != user.id"> Edit </a>
       </td>
      </tr>
     </tbody>
    </table>
   </div>
   <app-datatable-footer-new></app-datatable-footer-new>
    </div>
<app-curd-user-modal (userUpdate)="userUpdate($event)"></app-curd-user-modal>
</section>
</div>