<!-- Payout Request from tutor to admin -->
<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
         <table class="ttable table-striped TT-table TT-tableHeight">
            <thead>
               <tr>
                  <th trans="Amount">
                     Amount
                  </th>
                  <th trans="Tutor Id">
                     Tutor Id
                  </th>
                  <th trans="Timestamp">
                     Timestamp
                  </th>
                  <th trans="Tutor Comment">
                     Tutor Comment
                  </th>
                  <th trans="Status">
                     Status
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let request of approvedPayouts$ | async">
                  <td>
                     {{request.amount}}
                  </td>
                  <td>
                     {{request.user_id}}
                  </td>
                  <td>
                     {{request.created_at | date: 'MM-dd-yyyy h:mm a'}}
                  </td>
                  <td>
                     {{request.user_comment || 'N/A'}}{{request.transaction_id}}
                  </td>
                  <td>
                     {{request.status}}
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>