<div class='col-sm-12 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Schedule Settings">Schedule Settings</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <form [formGroup]="scheduleForm" class="col-md-6">
         <table class="table lesson-table noBorderTable">
            <thead>
               <h5 class="title form-group" trans="Standard Lesson - Student Payment">Standard Lesson - Student Payment
               </h5>
               <tr>
                  <div class="form-group">
                     <label for="admin_discount" trans=" Admin Whole Discount"> Admin Whole Discount</label>
                     <label class="pull-right small_small">[Percentage - %]</label>
                     <input value="0" type="number" formControlName="admin_discount" class="form-control"
                        placeholder="Admin Whole Discount">
                  </div>
               </tr>
               <tr>
                  <div class="form-group">
                     <label for="first_lesson_discount" trans="First lesson discount">First Lesson Discount</label>
                     <label class="pull-right small_small">[Student-Tutor - %]</label>
                     <input value="0" type="number" formControlName="first_lesson_discount" class="form-control"
                        placeholder="First Lesson Discount">
                  </div>
               </tr>
               <h5 class="title form-group" trans="Standard Lesson - Lesson Release">Standard Lesson - Lesson Release
               </h5>
               <tr>
                  <div class="form-group">
                     <label for="standard_fee" trans="Standard fee">Standard Fee</label>
                     <label class="pull-right small_small">[Value - USD]</label>
                     <input value="0" type="number" formControlName="standard_fee" class="form-control"
                        placeholder="Standard Fee">
                  </div>
               </tr>
               <tr>
                  <div class="form-group">
                     <label for="commission" trans="Commission">Commission</label>
                     <label class="pull-right small_small">[Percentage - %]</label>
                     <input value="0" type="number" formControlName="commission" class="form-control"
                        placeholder="Commission">
                  </div>
               </tr>
               <tr>
                  <div class="TT-formGroup">
                     <button (click)="updateScheduleSettings()" class="TT-primaryBTN" trans="Update"
                        style="width: auto;padding:8px 35px;">Update</button>
                  </div>

               </tr>
            </thead>
         </table>
      </form>
      <form [formGroup]="packageForm" class="col-md-6">
         <table class="table lesson-table noBorderTable">
            <thead>
               <h5 class="title form-group" trans="Package Booking - Student Payment">Package Booking - Student Payment
               </h5>
               <tr>
                  <div class="form-group">
                     <label for="admin_discount" trans="Admin Whole Discount"> Admin Whole Discount</label>
                     <label class="pull-right small_small">[Percentage - %]</label>
                     <input value="0" type="number" formControlName="package_admin_discount" class="form-control"
                        placeholder="Admin Whole Discount">
                  </div>
               </tr>
               <tr>
                  <div class="form-group">
                     <label for="first_lesson_discount" trans="First lesson discount">First Lesson Discount</label>
                     <label class="pull-right small_small">[Student-Tutor - %]</label>
                     <input value="0" type="number" formControlName="package_first_lesson_discount" class="form-control"
                        placeholder="First Lesson Discount">
                  </div>
               </tr>
               <h5 class="title form-group" trans="Direct Cancellation / Reschedule Access">Direct Cancellation /
                  Reschedule Access </h5>
               <tr>
                  <div class="form-group">
                     <label for="first_lesson_discount" trans="Hours For Direct Cancellation / Reschedule">Hours For
                        Direct Cancellation / Reschedule</label>
                     <label class="pull-right small_small">[Hours]</label>
                     <input value="0" type="number" formControlName="direct_cancellation_reschedule"
                        class="form-control" placeholder="Direct Cancellation and Reschedule Hours">
                  </div>
               </tr>
               <h5 class="title form-group" trans="Time To Calculate Attendence">Time To Calculate Attendence </h5>
               <tr>
                  <div class="form-group">
                     <label for="first_lesson_discount" trans="Time To Calculate Attendence">Time To Calculate
                        Attendence</label>
                     <label class="pull-right small_small">[Seconds]</label>
                     <input value="0" type="number" formControlName="minminum_time_for_attendence" class="form-control"
                        placeholder="Time To Calculate Attendence">
                  </div>
               </tr>
               <tr>
                  <div class="TT-formGroup">
                     <button (click)="updateSchedulePackageSettings()" class="TT-primaryBTN" trans="Update"
                        style="width: auto;padding:8px 35px;">Update</button>
                  </div>

               </tr>
            </thead>
         </table>
      </form>
      <form [formGroup]="subscriptionForm" class="col-md-6">
         <table class="table lesson-table noBorderTable">
            <thead>
               <h5 class="title form-group" trans="Subscription Settings">Subscription Settings</h5>
               <tr>
                  <div class="form-group">
                     <label for="admin_discount" trans="Free Trial Days"> Free Trial Days</label>
                     <label class="pull-right small_small" trans="Days">Days</label>
                     <input value="0" type="number" formControlName="free_trial_days" id="admin_discount"
                        class="form-control" placeholder="Free Trial Days">
                  </div>
               </tr>
               <tr>
                  <div class="form-group">
                     <label for="monthly_subscription_amount" trans="Subscription Amount">Subscription Amount</label>
                     <label class="pull-right small_small" trans="Monthly">Monthly</label>
                     <input value="0" type="number" formControlName="monthly_subscription_amount"
                        id="monthly_subscription_amount" class="form-control" placeholder="Subscription Amount">
                  </div>
               </tr>
               <tr>
                  <div class="TT-formGroup">
                     <button (click)="updateSubscriptionSettings()" class="TT-primaryBTN" trans="Update"
                        style="width: auto;padding:8px 35px;">Update</button>
                  </div>

               </tr>
            </thead>
         </table>
      </form>
   </div>
</div>