import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef} from '@angular/core';
import { StudentsService } from '../students.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-students',
  templateUrl: './new-students.component.html',
  styleUrls: ['./new-students.component.css'],
})
export class NewStudentsComponent implements OnInit {
  
  @Output() viewStudentApplication = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  public newStudents$:any;
  @Output() reloadStudents = new EventEmitter();
  @Input() 
  public set newStudents(value){
    if(value){
    this.newStudents$ = value;
   }
  }

  constructor(public service: StudentsService,private toastr: ToastrService) {}

  ngOnInit(): void {
  }

  public suspenduser(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
       this.reloadStudents.emit(true);
       this.toastr.success('User suspended');
    })
   }

   public deleteuser(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
       this.reloadStudents.emit(true);
       this.toastr.success('User deleted');
    })
   }

  public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }

  public viewApplication(studentId){
    this.viewStudentApplication.emit(studentId);
  }


}
