import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UserImpersonateService } from './user-impersonate.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service'

@Component({
  selector: 'app-user-impersonate',
  templateUrl: './user-impersonate.component.html',
  styleUrls: ['./user-impersonate.component.css']
})
export class UserImpersonateComponent implements OnInit {
  
  public tutorId$:number;
  @Input() 
  public set impersonateTutorId(value:number){
   this.tutorId$ = value;
  }

  @Output() closeModal = new EventEmitter();
  public profile:any;
  constructor(private router: Router, public service: UserImpersonateService,public auth: AuthService,private bootstrapper: Bootstrapper) { }

  ngOnInit(): void {
  }

  public loginAsUser(){
      this.service.loginAsUser(this.tutorId$).subscribe(response=>{
      this.profile = JSON.parse(atob(response['data']));
      this.bootstrapper.bootstrap(this.profile.languageChange);
                   this.closeModalIfAny();
                   this.router.navigate([this.auth.getRedirectUri()]).then(() => {
                   });
      });
  }

    public closeModalIfAny(){
    this.closeModal.emit(true);
  }

}
