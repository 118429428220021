import { Component, OnInit, Input, Output, EventEmitter, ViewChild , ElementRef} from '@angular/core';
import { TeachersService } from '../teachers.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $;

@Component({
  selector: 'app-view-application',
  templateUrl: './view-application.component.html',
  styleUrls: ['./view-application.component.css']
})
export class ViewApplicationComponent implements OnInit {
  
  public existingAppplicationLoader:boolean = false;
  public tutorApplicationId:number;
  public tutorProfile:any;
  public tutorCertificates:any;
  public tutorEducations:any;
  public tutorVideo:any;
  public tutorSubjects:any;
  public tutorPackages:any;
  public tutorIdProof:any;
  public newResumeUpdate:any;
  
  @Output() viewTutorResumeCom = new EventEmitter();
  @ViewChild('closeViewApplicationModal') closeViewApplicationModal: ElementRef;
  @Output() reloadTutors = new EventEmitter();
  @Input()
  public set selectedTutorApplicationId(val: number) {
  	if(val){
      this.tutorApplicationId = val;
      this.existingApplicationDetails(this.tutorApplicationId);
     }
  }

  public sendMessageForm = this.fb.group({
      message: ['', Validators.required],
  });

  get message(){ return this.sendMessageForm.get('message');}
  
  constructor(public service: TeachersService,private fb: FormBuilder,private toastr: ToastrService) { }

  ngOnInit(): void {
  }


   public existingApplicationDetails(id) {
     this.existingAppplicationLoader = true;
     	this.service.viewApplication(id).subscribe(response =>{
            response =  JSON.parse(atob(response['data']));
            this.updateValues(response);
            this.existingAppplicationLoader = false;
     	});
   }


  public updateValues(response){
    this.tutorProfile = response['userProfile'];
    this.tutorCertificates = response['certificates'];
    this.tutorEducations = response['educations'];
    this.tutorVideo = response['video'];
    this.tutorSubjects = response['subjects'];
    this.tutorPackages = response['packages'];
    this.tutorIdProof = response['idProof'];
    this.newResumeUpdate = response['newResumeUpdate'];
  }

    public closeModal(){
    this.closeViewApplicationModal.nativeElement.click();
  }



    public updateApplication(id, section, status){
    this.existingAppplicationLoader = true;
    let data = Object();
    Object.assign(data ,{ recordId: id, section:section, status: status, tutorId:this.tutorProfile.user_id });
    this.service.updateProfile(data).subscribe(response =>{
          let res =  JSON.parse(atob(response['data']));
          if(response.status == 'success'){
          this.toastr.success('Application Updated Successfully');
          this.updateValues(res);
          this.existingAppplicationLoader = false;
        }
    });
  }

  public viewImage(imagePath , type){
   let url = location.origin + '/secure/viewImage/' +imagePath;
   window.open(url,'_blank');
  }

  public approveCompleteProfile(){

  }

  public suspendUser(){
    this.existingAppplicationLoader = true;
    let data = Object();
    Object.assign(data , {tutorId: this.tutorProfile.user_id });
    this.service.suspendUser(data).subscribe(response =>{
      if(response.status == 'success'){
      this.closeViewApplicationModal.nativeElement.click();
      this.toastr.success('User suspended successfully');
      this.reloadTutors.emit(true);
      }
    });
  }

  public deleteUser(){
    this.existingAppplicationLoader = true;
    let data = Object();
    Object.assign(data , {tutorId:this.tutorProfile.user_id});
    this.service.deleteUser(data).subscribe(response =>{
      let res = JSON.parse(atob(response['data']));
      this.closeViewApplicationModal.nativeElement.click();
      this.toastr.success('User deleted successfully');
      this.reloadTutors.emit(true);
   });
  }

  public sendMessage() {

  }

  public viewTutorResume(tutorId , tutorDisplayName){
    this.closeViewApplicationModal.nativeElement.click();
    this.viewTutorResumeCom.emit({tutorId:tutorId , tutorName:tutorDisplayName});
  }
}
