<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->
<div class='content-container customTabs'>
<div class="row formspace">
   <div class="col-sm-12">
      <div class="table-responsive TT-responsiveTable">
         <table class="ttable table-striped TT-table TT-tableHeight">
            <thead>
            <tr>
                  <th trans="Student">Student</th>
                  <th trans="Tutor">Tutor</th>
                  <th trans="Subject">Subject</th>
                  <th trans="Date">Date</th>
                  <th trans="Lessons">Lessons</th>
                  <th trans="Type">Type</th>
                  <th trans="Schedule">Schedule</th>
                  <th trans="Actions">Actions</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let request of unAcceptedLessons$">
                  <td>
                  	<span *ngIf="request['student']">{{request['student'].username}}</span>
                  </td>
                  <td>
                  	{{request['tutor_resume'].display_name}}
                  </td>
                  <td>{{request['subject_with_trash'].subject_name}}</td>
                  <td>{{request.created_at | date: 'MM-dd-yyyy h:mm a' }}</td>
                  <td>{{request.total_lessons}}</td>
                  <td>{{request.booking_type}}</td>
                  <td *ngIf="request.booking_type =='schedule'" class="lesson-btn-actions">
                     <button type="button" *ngIf="request.status =='pending'" (click)="viewDetails(request , request['booking_dates'] , request.booking_id , request.booked_timezone )" href="#viewDetails" data-target="#viewDetails" data-toggle="modal" class="btn btn-primary" value="Attend">View Schedules</button>
                     <p  *ngIf="request.status !='pending'">{{request.status}}</p>
                  </td>
               <td trans="Schedule Not Set"><p *ngIf="request.booking_type =='package'">Schedule Not Set</p></td>
               <td class="lesson-btn-actions dropdown">
                  <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
                  <ul class="dropdown-menu dropdown-new dropdown-menu_top">
                     <!-- <li *ngIf="request.booking_type != 'package'" (click)="openScheduleDates(request.student_id , request)" href="#studentSchedules" data-target="#studentSchedules" data-toggle="modal"><a>Direct Reschedule</a></li> -->
                     <li class="divider"></li>
<!--                      <li href="#adminDirectCancellation" data-target="#adminDirectCancellation" data-toggle="modal" (click)="directCancelation(request.booking_id)" ><a>Direct Cancellation</a>
                     </li> -->
                     <li class="divider"></li>
                     <li (click)="messageModal(request.student , 'student')"><a href="#adminMessageModal" data-toggle="modal" trans="Message Student">Message Student</a></li>
                     <li class="divider"></li>
                     <li (click)="messageModal(request.tutor_resume , 'tutor')"><a href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a></li>
                  </ul>
               </td>
            </tr>
            <br>
            <tr *ngIf="unAcceptedLessons$ && unAcceptedLessons$.length==0" class="textalign" trans="No records found">No records found.</tr>
         </tbody>
      </table>
      </div>
      <app-datatable-footer-new *ngIf="unAcceptedLessons$ && unAcceptedLessons$.length > 0"></app-datatable-footer-new>
   </div>
</div>