import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StudentsService } from '../students.service';

@Component({
  selector: 'app-deleted-students',
  templateUrl: './deleted-students.component.html',
  styleUrls: ['./deleted-students.component.css']
})
export class DeletedStudentsComponent implements OnInit {
 
  @Output() adminMessageModalData = new EventEmitter();
  @Output() viewStudentApplication = new EventEmitter();
  @Output() reloadStudents = new EventEmitter();
 public deletedStudents$:any;
 @Input() 
  public set deletedStudents(value){
    if(value){
    this.deletedStudents$ = value;
   }
  }

  constructor(private toastr: ToastrService,public service: StudentsService) {}

  ngOnInit(): void {

  }
    public viewApplication(studentId){
    this.viewStudentApplication.emit(studentId);
  }

    public restoreuser(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
       this.reloadStudents.emit(true);
       this.toastr.success('User Restored');
    })
   }

   public permanentdeleteuser(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
       this.reloadStudents.emit(true);
       this.toastr.success('User Deleted');
    })
   }

  public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }

}
