import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { StudentsService } from '../students.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-suspended-students',
  templateUrl: './suspended-students.component.html',
  styleUrls: ['./suspended-students.component.css']
})
export class SuspendedStudentsComponent implements OnInit {

  @Output() adminMessageModalData = new EventEmitter();
  @Output() viewStudentApplication = new EventEmitter();
  public suspendedStudents$:any;
  @Output() reloadStudents = new EventEmitter();
  @Input() 
  public set suspendedStudents(value){
    if(value){
    this.suspendedStudents$ = value;
    console.log(this.suspendedStudents$);
   }
  }

  constructor(public service: StudentsService,
    private toastr: ToastrService) {}

  ngOnInit(): void {
  }

  public viewApplication(studentId){
    this.viewStudentApplication.emit(studentId);
  }


  public activestudent(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
       this.reloadStudents.emit(true);
       this.toastr.success('User activated');
    })
   }

   public deleteuser(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
       this.reloadStudents.emit(true);
       this.toastr.success('User deleted');
    })
   }

    public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }


}
