<div class="field-container" (click)="openModal()">
<label><ng-content></ng-content></label> 
<div class="img-container">
	<img [src]="image" *ngIf="image$ | async as image; else noImage">
	        <ng-template #noImage>
            <div class="no-image">
                <span class="text" trans="Upload">Upload</span>
            </div>
        </ng-template>

</div>
</div>