// import { Component, OnInit } from '@angular/core';
import { Component, OnInit , Output , EventEmitter } from '@angular/core';
import { DisplayEditor } from '../../../athand/display/display-editor.service';
// import { FormBuilder } from '@angular/forms';
import { GenericBackendResponse } from '../../../core/types/backend-response';
import { BehaviorSubject, fromEvent, Observable, ReplaySubject } from 'rxjs';
import { AppHttpClientService } from '../../../core/http/app-http-client.service';
import { SettingsService } from '../settings.service';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
declare var $;

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})

export class GeneralComponent implements OnInit {
// @Output() viewEducation: EventEmitter<any> = new EventEmitter();
  public site_logo_img = '';
  public site_favicon_img = '';
  public logo_url = null;
  public favicon_url = null;
  public liveIFrame:SafeResourceUrl;
  public img_name = '';
  public selectedimage = '';
  public fileToUpload: File = null;
  public generalForm = this.fb.group({
      site_name: ['',[Validators.required]],
      site_description: ['',[Validators.required]],
      site_logo:[null,[Validators.required,
                   RxwebValidators.extension({extensions:["jpg","jpeg","png"]})]
                  ],
      site_favicon:[null,[Validators.required,
                   RxwebValidators.extension({extensions:["jpg","jpeg","png"]})]
                  ],
      image:[''],
      favicon:[''],

  });

  get site_name(){ return this.generalForm.get('site_name');}
  get site_description(){ return this.generalForm.get('site_description');}
  get site_logo(){ return this.generalForm.get('site_logo');}
  get site_favicon(){ return this.generalForm.get('site_favicon');}
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  public errorMessages = {
           site_name :[{ type:'required',message:'Site Name is required'}],
           site_logo: [{ type:'required', message:'Upload site logo'},
                        { type:'extension',message:'Invalid file extension'}],
           site_favicon: [{ type:'required', message:'Upload site favicon'},
                        { type:'extension',message:'Invalid file extension'}]
         }

  constructor(public editor: DisplayEditor,
  	          private fb: FormBuilder,
  	          private http: AppHttpClientService,
              public SettingsService: SettingsService,
              private toastr: ToastrService,
              public auth: AuthService,
              private router: Router,
              public sanitizer: DomSanitizer,
              ) { }

  ngOnInit(): void {
    this.SettingsService.getValues()
    .subscribe(response => {
      this.site_logo_img = response['settings'].site_logo,
      this.site_favicon_img = response['settings'].site_favicon,
      this.logo_url = response['settings'].site_logo_url
      this.favicon_url = response['settings'].site_favicon_url
      this.generalForm.patchValue({
      site_name:response['settings'].site_name,
      site_description:response['settings'].site_description,
    });
      // this.site_name = response.settings.site_name;
      // this.site_description = response.settings.site_description;

    });
  }

       public defaultValue(key: string) {
        return this.editor.defaultSettings[key];
    }

    public updateSettings(){
       this.generalForm.markAllAsTouched();
       var myFormData = new FormData();
        myFormData.append('site_name', this.generalForm.get('site_name').value);
        myFormData.append('site_description', this.generalForm.get('site_description').value);
        myFormData.append('site_logo', this.generalForm.get('image').value);
        myFormData.append('site_favicon', this.generalForm.get('favicon').value);

       this.SettingsService.submitForm(myFormData)
       .subscribe(response => {
         if(response.status == 'success')
         {
           this.toastr.success('General settings update successfull');
           // this.router.navigate([this.auth.getRedirectUri()]).then(() => {

           //          });
         }
       });
    }

    public selectedImage( img_type:string ){

        if( img_type == 'logo'){
          this.img_name = this.site_logo_img;
          this.selectedimage = this.logo_url;
         }
         else
         {
          this.img_name = this.site_favicon_img;
          this.selectedimage = this.favicon_url;
         }
    }

    public fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;
            const reader = new FileReader();

            reader.onload = (e: any) => {
                let file_type = fileInput.target.files[0].type;
                if ( file_type == 'application/pdf' ) {
                  const imgBase64Path = e.target.result;
                  this.cardImageBase64 = imgBase64Path;
                  this.isImageSaved = true;
                  this.fileToUpload = fileInput.target.files[0];
                  this.generalForm.patchValue({
                    image: this.fileToUpload

                  });
                } else {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimensions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        this.fileToUpload = fileInput.target.files[0];
                        // this.previewImagePath = imgBase64Path;
                        this.generalForm.patchValue({
                          image: this.fileToUpload
                        });
                    }
                };
              }
            };

            reader.readAsDataURL(fileInput.target.files[0]);
            $('#removeImage').show();
        }
    }

    public file2ChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;
            const reader = new FileReader();

            reader.onload = (e: any) => {
                let file_type = fileInput.target.files[0].type;
                if ( file_type == 'application/pdf' ) {
                  const imgBase64Path = e.target.result;
                  this.cardImageBase64 = imgBase64Path;
                  this.isImageSaved = true;
                  this.fileToUpload = fileInput.target.files[0];
                  this.generalForm.patchValue({
                    favicon: this.fileToUpload

                  });
                } else {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimensions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        this.fileToUpload = fileInput.target.files[0];
                        // this.previewImagePath = imgBase64Path;
                        this.generalForm.patchValue({
                          favicon: this.fileToUpload
                        });
                    }
                };
              }
            };

            reader.readAsDataURL(fileInput.target.files[0]);
            $('#removeImage').show();
        }
    }

}
