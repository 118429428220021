<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <!-- <div class="well well-sm chat-head">
      <h4 class="title"trans="Tutors Settings">Tutors Settings</h4>
   </div> -->
      <form [formGroup]="tutorSettingsForm" class="account-info" (ngSubmit)="savetutorSettings()">
        <div class="row m-auto">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
           <h4 class="TT-AccSettings" trans="Tutors Settings">Tutors Settings</h4>
          </div>

         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
           <div class="row m-auto">
             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                  <label class="TT-lable" for="tutor_min_per_hour" trans="Min Allowed Price Per Hour ($)">
                    Min Allowed Price Per Hour ($)
                  </label>
                  <input min="0"  oninput="validity.valid||(value='');" type="number" placeholder="Min Allowed Price Per Hour" formControlName="tutor_min_per_hour" class="TT-FieldGroup" id="tutor_min_per_hour" />
                  <div *ngFor= "let error of errorMessages.tutor_min_per_hour">
                     <ng-container *ngIf="tutor_min_per_hour.hasError(error.type) && (tutor_min_per_hour.touched)">
                        <small class="TT-errorUpdate">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                  <label class="TT-lable" for="tutor_max_per_hour" trans="Max Allowed Price Per Hour ($)">
                     Max Allowed Price Per Hour ($)
                  </label>
                  <input min="0"  oninput="validity.valid||(value='');" type="number" placeholder="Max Allowed Price Per Hour" formControlName="tutor_max_per_hour" class="TT-FieldGroup" id="tutor_max_per_hour">
                  <div *ngFor= "let error of errorMessages.tutor_max_per_hour">
                     <ng-container *ngIf="tutor_max_per_hour.hasError(error.type) && (tutor_max_per_hour.touched)">
                        <small class="TT-errorUpdate">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div class="form-group TT-formGroup">
                <label class="TT-lable" for="tutor_min_discount_package">
                   Min Discount Package (% >= 0 and <= 100)
                  </label>
                  <input min="0"  oninput="validity.valid||(value='');" type="number" placeholder="Min Discount Package" formControlName="tutor_min_discount_package" class="TT-FieldGroup">
                   <div *ngFor= "let error of errorMessages.tutor_min_discount_package">
                    <ng-container *ngIf="tutor_min_discount_package.hasError(error.type) && (tutor_min_discount_package.touched)">
                     <small class="TT-errorUpdate">{{error.message}}</small>
                    </ng-container>
                  </div>
               </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                 <label class="TT-lable" for="tutor_max_discount_package">
                    Max Discount Package (% >= 0 and <= 100)
                   </label>
                   <input min="0"  oninput="validity.valid||(value='');" type="number" placeholder="Max Discount Package" formControlName="tutor_max_discount_package" class="TT-FieldGroup">
                    <div *ngFor= "let error of errorMessages.tutor_max_discount_package">
                      <ng-container *ngIf="tutor_max_discount_package.hasError(error.type) && (tutor_max_discount_package.touched)">
                         <small class="TT-errorUpdate">{{error.message}}</small>
                      </ng-container>
                   </div>
                </div>
             </div>

             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                <label class="TT-lable" trans="Max Number of Subjects To Teach" for="tutor_max_subjects_teach">
                  Max Number of Subjects To Teach
                 </label>
                 <input min="0" oninput="validity.valid||(value='');" type="number" placeholder="Max Number of Subjects To Teach" class="TT-FieldGroup" formControlName="tutor_max_subjects_teach" id="tutor_max_subjects_teach">
               </div>
             </div>

             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                <label class="TT-lable" trans="Max Number of Lessons Per Day" for="tutor_max_lessons_day">
                  Max Number of Lessons Per Day
                 </label>
                 <input min="0"  oninput="validity.valid||(value='');" type="number" placeholder="Max Number of Lessons Per Day" class="TT-FieldGroup" formControlName="tutor_max_lessons_day" id="tutor_max_lessons_day">
                </div>
             </div>

             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                <label class="TT-lable" for="tutor_hours_to_confirm_booking" trans="Number of Hours to Auto-Confirm Lesson (mins)">
                  Number of Hours to Auto-Confirm Lesson 
                 </label>
                 <input min="0"  oninput="validity.valid||(value='');" type="number" placeholder="Number of Hours to Confirm Bookings" trans-placeholder="Number of hours to confirm bookings" class="TT-FieldGroup" formControlName="auto_conirmation_hours" id="auto_conirmation_hours">
               </div>
             </div>

             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                <label class="TT-lable" trans="Tutor Activation" for="tutor_activation">
                  Tutor Activation
                 </label>
                 <select formControlName="tutor_activation" class="TT-FieldGroup TT-SelectIcon">
                  <option value="" trans="Please select"> Please Select</option>
                  <option value="auto" trans="Auto">Auto</option>
                  <option value="admin" trans="Admin">Admin</option>
               </select>
               </div>
             </div>

             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                <label class="TT-lable" trans="Banned Countries" for="tutor_banned_countries">
                  Banned Countries
                 </label>
                 <select formControlName="tutor_banned_countries" (click)="CountrySelect($event.target.value)" class="TT-FieldGroup TT-SelectIcon">
                  <option value="" trans="Please select"> Please select </option>
                  <option [value]="count.name" *ngFor="let count of countries"> {{count.name}} </option>
                </select>                
                <!-- <div class="col-md-6">
                  <label trans="Selected countires">Selected Countries</label>
               </div> -->
               <div class="row" style="margin:6px;" *ngIf="selectedCountries.value">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                     <div *ngFor="let country of selectedCountries.value" class="chip">
                        <span>{{country}}</span>
                        <span class="closebtn" onclick="this.parentElement.style.display='none'" (click)="removeItem(country)">&times;</span>
                     </div>
                  </div>
                </div>
              </div>
             </div>
            
             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                <label class="TT-lable" trans="All tutor to enter custom subject if disabled it will only allow them to select from list." for="tutor_allow_custom_subjects">
                  All tutor to enter custom subject if disabled it will only allow them to select from list.
                 </label>
                 <label class="switch">
                  <input type="checkbox" formControlName="tutor_allow_custom_subjects" id="tutor_allow_custom_subjects" value="yes" checked>
                 <span class="slider round"></span>
                 </label>
               </div>
             </div>

             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                <label class="TT-lable" trans="All tutors to create custom discount packages." for="tutor_allow_custom_discounts">
                  All tutors to create custom discount packages.
                 </label>
                 <label class="switch">
                  <input type="checkbox" formControlName="tutor_allow_custom_discounts" id="tutor_allow_custom_discounts" value="yes" checked>
                  <span class="slider round"></span>
                 </label>
               </div>
             </div>
           
             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                <label class="TT-lable" trans="Eanble Custom Link" for="t_custom_link">
                  Eanble Custom Link
                 </label>
                 <label class="switch">
                  <input type="checkbox" formControlName="t_custom_link" id="t_custom_link" value="yes" checked>
                  <span class="slider round"></span>
                 </label>
               </div>
             </div>

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h4 class="TT-AccSettings" trans="Sections that require approval if changed:">Sections that require approval if changed:</h4>
            </div>
              
            </div>
          
            <div class="row formspace">               
               
               <div class="col-md-6">
                  <div class="form-group" formArrayName="tutor_required_approval">
                     <!-- <label trans="Sections that require approval if changed:">Sections That Require Approval If Changed:</label> -->
                     <div class="form-group tab-content">
                        <div class="col-md-6">
                           <label class="title" trans="tutor profile" for="profile">Tutor Profile</label>
                        </div>
                        <div class="col-md-6">
                           <label class="switch">
                           <input type="checkbox" formControlName="profile" id="profile" value="yes" checked>
                           <span class="slider round"></span>
                           </label>
                        </div>
                     </div>
                     <div class="form-group tab-content">
                        <div class="col-md-6">
                           <label class="title" trans="Subjects List" for="subjects">Subjects List</label>
                        </div>
                        <div class="col-md-6">
                           <label class="switch">
                           <input type="checkbox" formControlName="subjects" id="subjects" value="yes" checked>
                           <span class="slider round"></span>
                           </label>
                        </div>
                     </div>
                     <div class="form-group tab-content">
                        <div class="col-md-6">
                           <label class="title" trans="Education List" for="education">Education List</label>
                        </div>
                        <div class="col-md-6">
                           <label class="switch">
                           <input type="checkbox" formControlName="education" id="education" value="yes" checked>
                           <span class="slider round"></span>
                           </label>
                        </div>
                     </div>
                     <div class="form-group tab-content">
                        <div class="col-md-6">
                           <label class="title" trans="Certification List" for="certificates">Certification List</label>
                        </div>
                        <div class="col-md-6">
                           <label class="switch">
                           <input type="checkbox" formControlName="certificates" id="certificates" value="yes" checked>
                           <span class="slider round"></span>
                           </label>
                        </div>
                     </div>
                     <div class="form-group tab-content">
                        <div class="col-md-6">
                           <label class="title" trans="Packages List" for="packages">Packages List</label>
                        </div>
                        <div class="col-md-6">
                           <label class="switch">
                           <input type="checkbox" formControlName="packages" id="packages" value="yes" checked>
                           <span class="slider round"></span>
                           </label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

       <div class="row m-auto">
         <div class="col-xl-10 col-lg-10 col-md-10 col-sm-6 col-xs-12"></div>
         <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
            <div class="form-group TT-formGroup">
               <button type="submit" class="btn TT-primaryBTN" trans="Update"> Update </button>
            </div>
         </div>
      </div>
      </form>
   </div>