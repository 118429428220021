import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class UserImpersonateService {

  constructor(private http: AppHttpClientService) { }

  public loginAsUser(tutorId){
  	if(tutorId){
      console.log(tutorId)
    return this.http.get('loginAsUser/' + tutorId);
     }
  }
}
