<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->
<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
         <table class="ttable table-striped TT-table TT-tableHeight">
            <thead>
               <tr>
                  <th trans="Tutor"> Tutor </th>
                  <th trans="Student"> Student </th>
                  <th trans="Subject"> Subject </th>
                  <th trans="Timestamp"> Timestamp </th>
                  <th trans="Rating"> Rating </th>
                  <th> View </th>
                  <th trans="Actions"> Actions </th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let review of badReviews$">
                  <td> {{review.booking_details.tutor_resume.display_name || 'N/A'}} </td>
                  <td> {{review.booking_details.student.username || 'N/A'}} </td>
                  <td> {{review.booking_details.subject.subject_name || 'N/A'}} </td>
                  <td> {{review.updated_at | date: 'MM-dd-yyyy h:mm a' || 'N/A'}} </td>
                  <td> {{review.rating || '0'}}/5 </td>
                  <td class="TT-formGroup">
                     <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel"
                        aria-hidden="true" href="#adminReviewModal"
                        (click)="viewReview(review.review , review.booking_details.student.username)"
                        class="btn TT-primaryBTN pull-right TT-topSpaceZero" value="Attend"
                        data-target="#adminReviewModal" data-toggle="modal" trans="View Review">
                        View</a>
                  </td>

                  <td class="dropdown TT-tableDropDownMenuList">
                     <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu dropdown-new">
                        <li class="TT-optionsList" (click)="messageModal(review.booking_details.student , 'student')">
                           <a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal"
                              trans="Message Student">Message Student</a>
                        </li>
                        <li class="TT-optionsList"
                           (click)="messageModal(review.booking_details.tutor_resume , 'tutor')">
                           <a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal"
                              trans="Message Tutor">Message Tutor</a>
                        </li>
                     </ul>
                  </td>
               </tr>

               <tr *ngIf="badReviews$.length==0" class="TT-noRecord" trans="No records found">
                  <td>No records found.</td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>