import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LessonsMenuService } from '../lessons-menu.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.css']
})
export class LevelsComponent implements OnInit {
   
   public educationLevels;
   public certificateLevels;
   public languageLevels;
   public loading$ = new BehaviorSubject(false);
	 public educationLevelForm = this.fb.group({
        education_level: ['',Validators.required]
    });

    public certificateLevelForm = this.fb.group({
        certificate_level: ['',Validators.required]
    });

     public languageLevelForm = this.fb.group({
        language_level: ['',Validators.required]
    });

    public teachingLoader:boolean = true;

    get education_level() {return this.educationLevelForm.get('education_level')};
    get certificate_level() {return this.certificateLevelForm.get('certificate_level')};
    get language_level() {return this.languageLevelForm.get('language_level')};

      public errorMessages = {
         education_level : [{ type:'required',message:'Education level is required'}],
         certificate_level :[{ type:'required',message:'Certificate level is required'}],
         language_level :  [{ type:'required',message:'Language level is required'}]
  }

  constructor(private fb: FormBuilder,
              public settings: LessonsMenuService,
              private toastr: ToastrService,) { }

  ngOnInit(): void {

    this.getLevelsList();
  }

  public addEducationLevel(){
  this.educationLevelForm.markAllAsTouched();
   if(this.educationLevelForm.valid){
   this.loading$.next(true);
   this.settings.addEducationLevel(this.educationLevelForm.value).subscribe(response =>{
    this.educationLevels = JSON.parse(atob(response.data));
    this.educationLevelForm.reset();
    this.loading$.next(false);
   }, err => {
              this.loading$.next(false);
              this.toastr.error(err.status , 'Invalid details');
            })
  }
}

public getLevelsList(){
  this.settings.getLevelsList().subscribe(response => {
    let levels = JSON.parse(atob(response['data']));
    this.assignLevels(levels);
  }, err => {
              this.toastr.error(err.status , 'Invalid details');
            });
}

public assignLevels(levels){
    this.educationLevels = levels['educationLevels'];
    this.certificateLevels = levels['certificateLevels'];
    this.languageLevels = levels['languageLevels'];
}

  public addLanguageLevel(){
  	this.languageLevelForm.markAllAsTouched();
   if(this.languageLevelForm.valid){
   this.loading$.next(true);
   this.settings.addLanguageLevel(this.languageLevelForm.value).subscribe(response =>{
    this.languageLevels = JSON.parse(atob(response.data));
    this.languageLevelForm.reset();
    this.loading$.next(false);
   }, err => {
              this.loading$.next(false);
              this.toastr.error(err.status , 'Invalid details');
            })
  }
  }

  public addCertificateLevel(){
  this.certificateLevelForm.markAllAsTouched();
   if(this.certificateLevelForm.valid){
   this.loading$.next(true);
   this.settings.addCertificateLevel(this.certificateLevelForm.value).subscribe(response =>{
    this.certificateLevels = JSON.parse(atob(response.data));
    this.certificateLevelForm.reset();
    this.loading$.next(false);
   }, err => {
              this.loading$.next(false);
              this.toastr.error(err.status , 'Invalid details');
            })
  }
  }


  public deleteLevels(id , levelName){
    let data = Object();
    Object.assign(data , {'id' : id});
    Object.assign(data , {'levelName' : levelName});
   this.settings.deleteLevel(data).subscribe(response =>{
    let levels = JSON.parse(atob(response['data']));
    this.assignLevels(levels);
   });
  }
}
