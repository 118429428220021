import { Component, OnInit } from '@angular/core';
import { AppHttpClientService } from '../../../core/http/app-http-client.service';
import { SettingsService } from '../settings.service';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {
 	public authenticationForm = this.fb.group({
		require_email: [true],
		disable_registration: [false],
		single_device_login: [true],
		google_login: [false],
		google_client_ID: [''],
		google_client_secret_key: [''],
		facebook_login: [false],
		facebook_app_ID: [''],
		facebook_app_secret_key: [''],
    zoom_client_key: [''],
    zoom_client_secret_key: [''],
    stripe_secret_key: [''],
    stripe_client_ID: [''],
    stripe_pk_key: [''],
    open_exchane_rate_key: [''],
    mail_mailer: [''],
    mail_host: [''],
    mail_port: [''],
    mail_username: [''],
    mail_password: [''],
    mail_encryption: [''],
    mail_from_address: [''],
    enable_2fa: [false],
    twilio_SID: [''],
    twilio_token: [''],
    twilio_from: [''],
    paypal_client_id: ['']
  });
		get require_email(){ return this.authenticationForm.get('require_email');}
		get disable_registration(){ return this.authenticationForm.get('disable_registration');}
		get single_device_login(){ return this.authenticationForm.get('single_device_login');}
		get google_login(){ return this.authenticationForm.get('google_login');}
		get google_client_ID(){ return this.authenticationForm.get('google_client_ID');}
		get google_client_secret_key(){ return this.authenticationForm.get('google_client_secret_key');}
		get facebook_login(){ return this.authenticationForm.get('facebook_login');}
		get facebook_app_ID(){ return this.authenticationForm.get('facebook_app_ID');}
		get facebook_app_secret_key(){ return this.authenticationForm.get('facebook_app_secret_key');}
    get zoom_client_key(){ return this.authenticationForm.get('zoom_client_key');}
    get zoom_client_secret_key(){ return this.authenticationForm.get('zoom_client_secret_key');}
    get stripe_secret_key(){ return this.authenticationForm.get('stripe_secret_key');}
    get stripe_client_ID(){ return this.authenticationForm.get('stripe_client_ID');}
    get stripe_pk_key(){ return this.authenticationForm.get('stripe_pk_key');}
    get open_exchane_rate_key(){ return this.authenticationForm.get('open_exchane_rate_key');}
    get mail_mailer(){ return this.authenticationForm.get ('mail_mailer');}
    get mail_host(){ return this.authenticationForm.get ('mail_host');}
    get mail_port(){ return this.authenticationForm.get ('mail_port');}
    get mail_username(){ return this.authenticationForm.get ('mail_username');}
    get mail_password(){ return this.authenticationForm.get ('mail_password');}
    get mail_encryption(){ return this.authenticationForm.get ('mail_encryption');}
    get mail_from_address(){ return this.authenticationForm.get ('mail_from_address');}
    get enable_2fa(){ return this.authenticationForm.get ('enable_2fa');}
    get twilio_SID(){return this.authenticationForm.get('twilio_SID');}
    get twilio_token(){return this.authenticationForm.get('twilio_token');}
    get twilio_from(){return this.authenticationForm.get('twilio_from');}
    get paypal_client_id(){return this.authenticationForm.get('paypal_client_id');}

  constructor(
  			  private fb: FormBuilder,
  	          private http: AppHttpClientService,
              public SettingsService: SettingsService,
              private toastr: ToastrService,
              public auth: AuthService,
              ) { }

  ngOnInit(): void {
  	this.SettingsService.getAuthnticationSettings()
  	.subscribe(response => {
      console.log(response['settings'].stripe_pk_key);
  		this.authenticationForm.patchValue({
			require_email:response['settings'].require_email,
			disable_registration:response['settings'].disable_registration,
			single_device_login:response['settings'].single_device_login,
			google_login:response['settings'].google_login,
			google_client_ID:response['settings'].google_client_ID,
			google_client_secret_key:response['settings'].google_client_secret_key,
			facebook_login:response['settings'].facebook_login,
			facebook_app_ID:response['settings'].facebook_app_ID,
			facebook_app_secret_key:response['settings'].facebook_app_secret_key,
      zoom_client_key: response['settings'].zoom_client_key,
      zoom_client_secret_key: response['settings'].zoom_client_secret_key,
      stripe_secret_key: response['settings'].stripe_secret_key,
      stripe_client_ID: response['settings'].stripe_client_ID,
      stripe_pk_key: response['settings'].stripe_pk_key,
      open_exchane_rate_key: response['settings'].open_exchane_rate_key,
      mail_mailer: response['settings'].mail_mailer,
      mail_host: response['settings'].mail_host,
      mail_port: response['settings'].mail_port,
      mail_username: response['settings'].mail_username,
      mail_password: response['settings'].mail_password,
      mail_encryption: response['settings'].mail_encryption,
      mail_from_address: response['settings'].mail_from_address,
      enable_2fa: response['settings'].enable_2fa,
      twilio_SID: response['settings'].twilio_SID,
      twilio_token: response['settings'].twilio_token,
      twilio_from: response['settings'].twilio_from,
      paypal_client_id: response['settings'].paypal_client_id,
    });
  	});

  }

  public updateAutheticationSettings() {
  	this.authenticationForm.markAllAsTouched();
  	this.SettingsService.submitAuthnticationForm(this.authenticationForm.value)
       .subscribe(response => {
         if(response.status == 'success')
         {
           this.toastr.success('Authentication settings update successfull');
           // this.router.navigate([this.auth.getRedirectUri()]).then(() => {

           //          });
         }
       });
  }

}
