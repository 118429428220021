import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StudentsService } from '../students.service';

@Component({
  selector: 'app-students-messages',
  templateUrl: './students-messages.component.html',
  styleUrls: ['./students-messages.component.css']
})
export class StudentsMessagesComponent implements OnInit {

public bulkmessages = this.fb.group({
    message_type: ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,4}$")]],
    title: ['',[Validators.required,Validators.minLength(6)]],
    message:['',Validators.minLength(4)],
    remember: [true],
});

get email(){ return this.bulkmessages.get('message_type');}
get password(){ return this.bulkmessages.get('title');}
get otp(){ return this.bulkmessages.get('message');}

  constructor(private fb: FormBuilder,
          private toastr: ToastrService,
          public service: StudentsService,) { }
          

  ngOnInit(): void {
  }

  public bulkload() {
    this.bulkmessages.markAllAsTouched();
    if( this.bulkmessages.value.message_type == '' || this.bulkmessages.value.message == '' || this.bulkmessages.value.title == '') {
      this.toastr.error('Please fill the fields');
    } else {
          this.service.bulkloadmessages(this.bulkmessages.value)
              .subscribe(response=>{
      this.bulkmessages.reset({
                  'message_type': '',
                  'message': '',
                  'title': ''
                 });
      this.toastr.success('Messages has been sent successfully');
            });
    }
    console.log('coming from bulkload form', this.bulkmessages.value)
  }
}
 