import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-refund-requests',
  templateUrl: './refund-requests.component.html',
  styleUrls: ['./refund-requests.component.css']
})
export class RefundRequestsComponent implements OnInit {

  @Output() payoutRequestModalData = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  public payoutsRequests$:any;
  public refundRequests_count$:number;
  @Input() 
  public set payoutsRequests(value){
    this.payoutsRequests$ = value;
  }

  @Input() 
  public set refundRequests_count(value){
    this.refundRequests_count$ = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

  public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }

  public payoutRequest(id){
   this.payoutRequestModalData.emit(id);
  }

}
