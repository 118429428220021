import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-approved-payout',
  templateUrl: './approved-payout.component.html',
  styleUrls: ['./approved-payout.component.css']
})
export class ApprovedPayoutComponent implements OnInit {
   
  public approvedPayouts$:any;
  @Input() 
  public set approvedPayouts(value){
    this.approvedPayouts$ = value;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
