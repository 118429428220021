import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-top-students',
  templateUrl: './top-students.component.html',
  styleUrls: ['./top-students.component.css']
})
export class TopStudentsComponent implements OnInit {
  
  @Output() viewStudentApplication = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  public topStudents$:any;
  @Input() 
  public set topStudents(value:any){
     if(value){
     this.topStudents$ = value;
     }
  }
  constructor() { }

  ngOnInit(): void {
  }

  public countLessons(bookings){
    let array = [];
    for (let i = 0; i < bookings.length; i++) {
     array.push(bookings[i].booking_dates_count.length);
     }
     return array.reduce((a, b) => a + b, 0);
  }

  public viewApplication(studentId){
    this.viewStudentApplication.emit(studentId);
  }

  public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }



}
