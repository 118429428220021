import { Component, OnInit, Input} from '@angular/core';
import { TeachersService } from '../teachers.service'; 
import { FormBuilder } from '@angular/forms';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
declare var $;

@Component({
  selector: 'app-tutor-profile',
  templateUrl: './tutor-profile.component.html',
  styleUrls: ['./tutor-profile.component.css']
})
export class TutorProfileComponent implements OnInit {
  
  public tutorIdForResumeView$:number;
  public resumeLoader:boolean = false;
  public liveIFrame:SafeResourceUrl;

  @Input()
  public set tutorIdForResumeView(val){
    if(val){
   this.tutorIdForResumeView$ = val;
   this.getResumeDetails(val);
   }
  }

    public resumeForm = this.fb.group({
         display_name: [''],
         hourly_rate: [''],
         description: [''],
         highlight:   [''],
         video_URL: ['']
    });

  constructor(public service:TeachersService,private fb: FormBuilder,public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  public getResumeDetails(id){
   this.resumeLoader =  true;
   this.service.getResumeDetails(id).subscribe(response =>{
     let data =  response['data'];
     let resume = data['resume'];
     let video = data['videoUrl'];
// console.log(video)
     this.resumeLoader =  false;
                this.resumeForm.patchValue({
                display_name:resume.display_name,
                hourly_rate:resume.hourly_rate,
                description:resume.description,
                highlight:resume.highlight,
                video_URL:video.video_Url,
            });
         if (video.video_Url) {
            this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(video.video_Url);
         }
   });
  }

}
