import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-credit-transactions',
  templateUrl: './credit-transactions.component.html',
  styleUrls: ['./credit-transactions.component.css']
})
export class CreditTransactionsComponent implements OnInit {
  
  @Output() adminMessageModalData = new EventEmitter();
  public creditTransactions$:any;
  @Input() 
  public set creditTransactions(value){
    this.creditTransactions$ = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

  public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }

}
