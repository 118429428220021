import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../payments.service';

@Component({
  selector: 'app-booking-payments',
  templateUrl: './booking-payments.component.html',
  styleUrls: ['./booking-payments.component.css']
})
export class BookingPaymentsComponent implements OnInit {

  public studentbookingPayments;
  constructor(private service: PaymentsService) { }

  ngOnInit(): void {
   this.service.studentBookingPayments().subscribe(response =>{
   	 this.studentbookingPayments =  JSON.parse(atob(response['studentbookingPayments']));
   });
  }

}
