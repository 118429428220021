import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { StudentsService } from '../students.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.css']
})
export class StudentProfileComponent implements OnInit {
  
  @ViewChild('closeStudentProfileModal') closeStudentProfileModal: ElementRef;
  public studentProfile:any;
  public selectedStudentId$:number;
  @Input()
  public set selectedStudentId(value:number){
    if(value){    
  this.selectedStudentId$ = value;
  this.getStudentProfile(value);
    }
  }

  constructor(public service: StudentsService,private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  public getStudentProfile(value){
   this.service.getStudentprofle(value).subscribe(response=>{
     let res = JSON.parse(atob(response['data']));
     this.studentProfile = res;
   });
  }

  public closeModal(){
    this.closeStudentProfileModal.nativeElement.click();
  }

}
