<!-- Modal Start -->
<div class="modal fade" id="modaldatepicker" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
<div class="modal-dialog" role="document" style="margin-top: 100px;">
<div class="modal-content">
   <div class="modal-header" style="border-bottom: 1px solid transparent;
      padding-bottom: 0px;">
      <div *ngIf="existingAppplicationLoader" class="loader_Main">
         <div class="loader-cal"></div>
      </div>
      <h5 *ngIf="tutorProfile" class="modal-title linkLabel-green-dark" id="modalLabel">
         {{tutorProfile.display_name}} Profile Update
      </h5>
      <button type="button" class="close" #closeViewApplicationModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
      <span aria-hidden="true">
      &times;
      </span>
      </button>
      <div class="modal-body" >
         <div class="container-fluid whiteBg">
            <ul class="nav nav-tabs">
               <li class="active">
                  <a data-toggle="tab" href="#approval" trans="Approval">
                  Approval
                  </a>
               </li>
               <li>
                  <a data-toggle="tab" href="#changes" trans="See Changes">
                  See Changes
                  </a>
               </li>
               <li *ngIf="tutorProfile">
                  <app-user-impersonate [impersonateTutorId]="tutorProfile.user_id" (closeModal)="closeModal()"></app-user-impersonate>
                  <button (click)="viewTutorResume(tutorProfile.user_id , tutorProfile.display_name)" class="btn btn-modal-log1">
                  <i class="fa fa-user">
                  </i>
                  </button>
               </li>
            </ul>
            <div class="tab-content">
               <div id="approval" class="tab-pane fade in active Fcolor">
                  <label trans="Changes List">
                  Changes List
                  </label>
                  <div class="row">
                     <div class="col-md-12 mar-lf BBL-Block">
                        <ul>
                           <li *ngIf="newResumeUpdate && newResumeUpdate.display_name != null" trans="Updated Profile About Me">
                              Updated Profile About Me
                              <span class="colorCC verySmall">
                              (
                              <a (click)="updateApplication(newResumeUpdate.user_id,'profile','Approved')" trans="Approve"> 
                              Approve
                              </a>
                              |
                              <a (click)="updateApplication(newResumeUpdate.user_id,'profile','Rejected')" trans="Reject">
                              Reject
                              </a>
                              )
                              </span>
                           </li>
                           <li *ngIf="tutorCertificates && tutorCertificates.length > 0">
                              <div *ngFor="let certificate of tutorCertificates">
                                 Added Certificate - <b>{{certificate.certificate_name}}</b>
                                 <span *ngIf="certificate.admin_status === 'Under-Review'" class="colorCC verySmall">
                                 (
                                 <a href="javascript:void(0)" (click)="updateApplication(certificate.id,'certificate','approved')" trans="Approve">
                                 Approve
                                 </a>
                                 |
                                 <a href="javascript:void(0)" (click)="updateApplication(certificate.id,'certificate','rejected')" trans="Reject">
                                 Reject
                                 </a>
                                 )
                                 </span>
                                 <span *ngIf="certificate.admin_status === 'Approved'" class="GColor" trans="Approved">
                                 - Approved
                                 </span>
                              </div>
                           </li>
                           <li *ngIf="tutorEducations && tutorEducations.length > 0">
                              <div *ngFor="let education of tutorEducations">
                                 Added Education - <b>{{education.degree_name}}</b>
                                 <span *ngIf="education.admin_status === 'Under-Review'" class="colorCC verySmall">
                                 (
                                 <a href="javascript:void(0)" (click)="updateApplication(education.id,'education','Approved')" trans="Approve">
                                 Approve
                                 </a>
                                 |
                                 <a href="javascript:void(0)" (click)="updateApplication(education.id,'education','Rejected')" trans="Reject">
                                 Reject
                                 </a>
                                 )
                                 </span>
                                 <span *ngIf="education.admin_status === 'Approved'" class="GColor" trans="Approved">
                                 - Approved
                                 </span>
                              </div>
                           </li>

                           <li *ngIf="tutorSubjects && tutorSubjects.length > 0">
                              <div *ngFor="let subject of tutorSubjects">
                                 Added New Subject - <b>{{subject.subject_data.subject_name}}</b> ,
                                 <span *ngIf="subject.admin_status === 'Under-Review'" class="colorCC verySmall">
                                 (
                                 <a href="javascript:void(0)" (click)="updateApplication(subject.id,'subject','Active')" trans="Approve">
                                 Approve
                                 </a>
                                 |
                                 <a href="javascript:void(0)" (click)="updateApplication(subject.id,'subject','In-Active')" trans="Reject">
                                 Reject 
                                 </a>
                                 )
                                 </span>
                                 <span *ngIf="subject.admin_status === 'Active'" class="GColor" trans="Active">
                                 - Active
                                 </span>
                              </div>
                           </li>
                           <li *ngIf="tutorPackages && tutorPackages.length > 0">
                              <div *ngFor="let package of tutorPackages">
                                 Added Package - <b>{{package.no_of_classes}} Classes</b>
                                 <span *ngIf="package.admin_status === 'Under-Review'" class="colorCC verySmall">
                                 (
                                 <a href="javascript:void(0)" (click)="updateApplication(package.id,'package','Approved')" trans="Approve">
                                 Approve
                                 </a>
                                 |
                                 <a href="javascript:void(0)" (click)="updateApplication(package.id,'package','Rejected')" trans="Reject">
                                 Reject
                                 </a>
                                 )
                                 </span>
                                 <span *ngIf="package.admin_status === 'Active'" class="GColor" trans="Active">
                                 - Active
                                 </span>
                              </div>
                           </li>

                        </ul>
                     </div>
                  </div>
                  <label trans="Missing Details">
                  Missing Details
                  </label>
                  <div class="row">
                     <div class="col-md-12 mar-lf BBL-Block missindDetails">
                        <ul>
                           <li *ngIf="!tutorProfile" trans="Tutor profile missing">
                              Tutor Profile Missing
                           </li>
                           <li *ngIf="!tutorCertificates || tutorCertificates.length == 0" trans="Certitificate">
                              Certitificate
                           </li>
                           <li *ngIf="!tutorEducations || tutorEducations.length == 0" trans="Education">
                              Education
                           </li>
                           <li *ngIf="!tutorVideo" trans="video URL">
                              video URL
                           </li>
                           <li *ngIf="!tutorSubjects || tutorSubjects.length == 0" trans="Subjects">
                              Subjects
                           </li>
                           <li *ngIf="!tutorPackages || tutorPackages.length == 0" trans="Packages">
                              Packages
                           </li>

                        </ul>
                     </div>
                  </div>
                  <div class="row pt-10">
                     <div class="col-md-12 button-group text-center TT-formGroup">
<!--                         <button id="approveAll" disabled class="btn btn-primary btn-new" (click)="approveCompleteProfile()" >
                        Accept
                        </button> -->
                        <button  class="btn TT-primaryBTN" (click)="suspendUser()" trans="Suspend" style="background-color: #e29579;width:auto;padding:8px 35px;">
                        Suspend
                        </button>
                        <button  class="btn TT-primaryBTN" (click)="deleteUser()" trans="Delete" style="background-color: #ff6b6b;width:auto;padding:8px 35px;">
                        Delete
                        </button>
                     </div>
                  </div>
                  <form (ngSubmit)="sendMessage()" [formGroup]="sendMessageForm" role="form" class="form-horizontal">
                  <div class="row pt-10">
                     <div class="col-md-12">
                        <label trans="Send Message">
                        Send Message
                        </label>
                        <textarea formControlName="message" rows="6" cols="10" class="form-control" id="" name="" placeholder="Type here"></textarea>
                     </div>
                     <!-- <div *ngFor= "let error of errorMessages.message">
                        <ng-container *ngIf="message.hasError(error.type) && (message.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                        </div> -->
                  </div>
                  <br>
                  <div class="row">
                     <div class="form-group">
                        <div class="TT-formGroup" style="text-align: center;">
                           <button class="btn TT-primaryBTN" type="submit" trans="Send" style="width:auto;padding:7px 35px">
                           Send
                           </button>
                        </div>
                     </div>
                  </div>
                  </form>
               </div>
               <div id="changes" class="tab-pane fade">
                  <div class="row">
                     <table class="Changes_Table" *ngIf="tutorProfile">
                        <thead>
                           <tr>
                              <th trans="New Application">New Application</th>
                              <th trans="Existing Application">Existing Application</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngIf="newResumeUpdate && newResumeUpdate.display_name">
                              <td>
                                 <b class="greyColor" trans="Name">Name</b>
                                 <br>
                                 <span class="BrownFont small" >{{newResumeUpdate.display_name || 'N/A'}}</span>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trans="Name">Name</b><br>
                                 <span class="greyColor small">{{tutorProfile.display_name}}</span>
                              </td>
                           </tr>
                           <tr *ngIf="newResumeUpdate && newResumeUpdate.display_name">
                              <td>
                                 <b class="greyColor" trans="HeadLine Section">HeadLine Section</b>
                                 <br>
                                 <span class="BrownFont small" >{{newResumeUpdate.highlight || 'N/A'}}</span>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trans="HeadLine Section">HeadLine Section</b>
                                 <br>
                                 <span class="greyColor small">{{tutorProfile.highlight}}</span>
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 <b class="greyColor" trans="Price">Price</b>
                                 <br>
                                 <span class="BrownFont small" >{{tutorProfile.default_currency}}{{tutorProfile.hourly_rate}}</span>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trance="Price">Price</b>
                                 <br>
                                 <span class="greyColor small" trans="N/A">"N/A"</span>
                              </td>
                           </tr>
                           <tr *ngIf="newResumeUpdate && newResumeUpdate.description">
                              <td>
                                 <b class="greyColor" trans="Bio">Bio</b>
                                 <br>
                                 <span class="BrownFont small" >{{newResumeUpdate.description || 'N/A'}}</span>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trans="HeadLine Section">HeadLine Section</b>
                                 <br>
                                 <span class="greyColor small">{{tutorProfile.description}}</span>
                              </td>
                           </tr>
                           <tr *ngIf="tutorCertificates">
                              <td>
                                 <b class="greyColor" trans="Certificates List">Certificates List</b>
                                 <br>
                                 <div *ngFor="let certificate of tutorCertificates">
                                       {{certificate.certificate_name}}
                                       <span class="GreenColor" trans="View">View
                                       <i (click)="viewImage(certificate.certificate_proof , 'cer')" class="fa fa-external-link Ext_link"></i>
                                       </span>
                                 </div>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trans="Certificates List">Certificates List</b>
                                 <br>
                                 <div *ngFor="let certificate of tutorCertificates">
                                    <span *ngIf="certificate.admin_status == 'Approved'" class="BrownFont small" >
                                    {{certificate.certificate_name || 'N/A'}} - View
                                    <i (click)="viewImage(certificate.certificate_proof , 'cer')" class="fa fa-external-link Ext_link"></i>
                                    </span>
                                 </div>
                              </td>
                           </tr>
                           <tr *ngIf="tutorEducations && tutorEducations.length > 0">
                              <td>
                                 <b class="greyColor" trans="Education">Education</b>
                                 <br>
                                 <div *ngFor="let education of tutorEducations">
                                       {{education.degree_name || "N\A"}}
                                       <span class="GreenColor">View
                                       <i (click)="viewImage(education.education_proof , 'edu')" class="fa fa-external-link Ext_link"></i>
                                       </span>
                                 </div>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trans="Education">Education</b>
                                 <br>
                                 <div *ngFor="let education of tutorEducations">
                                    <span *ngIf="education.admin_status == 'Approved'" class="BrownFont small" >
                                    {{education.degree_name || 'N/A'}} - View 
                                                                        <i (click)="viewImage(education.education_proof , 'edu')" class="fa fa-external-link Ext_link"></i>
                                    </span>
                                 </div>
                              </td>
                           </tr>
                           <tr *ngIf="newResumeUpdate && newResumeUpdate.video_URL">
                              <td>
                                 <b class="greyColor" trans="Video">Video</b>
                                 <br>
                                 <span class="BrownFont small" >{{newResumeUpdate.video_URL || 'N/A'}}
                                 </span>
                                                                       <span class="GreenColor" trans="View">View
                                       <a href="{{newResumeUpdate['video_URL']}}" target="_blank">
                                       <i class="fa fa-external-link Ext_link"></i>
                                       </a>
                                       </span>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trans="Video">Video</b>
                                 <br>
                                 <span class="greyColor small">{{tutorProfile.video_URL}}</span>
                                                                       <span class="GreenColor" trans="View">View
                                       <a href="{{tutorVideo['video_URL']}}" target="_blank">
                                       <i class="fa fa-external-link Ext_link"></i>
                                       </a>
                                       </span>
                              </td>
                           </tr>
                           <tr *ngIf="tutorSubjects && tutorSubjects.length > 0">
                              <td>
                                 <b class="greyColor" trans="Subjects">Subjects</b>
                                 <br>
                                 <div *ngFor="let subject of tutorSubjects">
                                    <span *ngIf="subject.admin_status == 'Under-Review'" class="BrownFont small" >
                                    {{subject.subject_data.subject_name}} - {{subject.tutor_specialities.level_name}} 
                                    </span>
                                 </div>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trans="Subjects">Subjects</b>
                                 <br>
                                 <div *ngFor="let subject of tutorSubjects">
                                    <span  *ngIf="subject.admin_status == 'Active'" class="BrownFont small" >
                                    {{subject.subject_data.subject_name}} - {{subject.tutor_specialities.level_name}} 
                                    </span>
                                 </div>
                              </td>
                           </tr>
                           <tr *ngIf="tutorPackages && tutorPackages.length > 0">
                              <td>
                                 <b class="greyColor" trans="Packages">Packages</b>
                                 <br>
                                 <div *ngFor="let package of tutorPackages">
                                    <span *ngIf="package.admin_status == 'Under-Review'" class="BrownFont small" >
                                    {{package.no_of_classes || 'N/A'}} Lessons
                                    </span>
                                 </div>
                              </td>
                              <td class="ML_20">
                                 <b class="greyColor" trans="Packages">Packages</b>
                                 <br>
                                 <div *ngFor="let package of tutorPackages">
                                    <span *ngIf="package.admin_status == 'Active'" class="BrownFont small" >
                                    {{package.no_of_classes || 'N/A'}} Lessons
                                    </span>
                                 </div>
                              </td>
                           </tr>

                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>