<section id="tutorDashboard" class="pageSectionBg main">
    <div class="container-fluid" id="dashboard-content">
       <div id="dashboardContainer">
          <div *ngIf="!isDataLoaded" class="preloading">
             <div class="wrap-preload">
                <div class="cssload-loader"></div>
             </div>
          </div> 
          <div *ngIf="isDataLoaded">
             <div class="row">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
                 <h2 class="TT-mailHeadlineTitle">Dashboard</h2> 
               </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
                   <div *ngIf="currentuser.hasPermissions(['account.access'])" class="TT-cardds">
                     <div class="Table-Height TT-tableHeight">
                        <h4 class="TT-title" trans="My Profile">My Profile<span class="led-Green"></span>
                           <!-- <img src="{{currentuser.dashboardcountryFlag()}}" class="pull-right ttr-logo " width="35" /> -->
                        </h4>
                        <div class="TT-innerCover">
                           <div class="TT-overlayLayer">
                              <h4 class="TT-title1 TT-areaTitles clearfix">
                                <b class="TT-textHeadline pull-left">{{currentuser.fullName()}}</b> 
                                <span class="TT-tagLineText pull-right">
                                  <span> </span>
                                </span>
                              </h4>
                           </div>
                           <div class="TT-overlayLayer">
                            <div class="TT-title1 TT-titleExtra">
                             <span trans="Access level:" class="TT-tagLineText"> Access level: </span> 
                             <b class="TT-textHeadline" trans="Super Admin"> Super Admin </b>
                            </div>
                           </div>
                           <div class="TT-overlayLayer">
                             <div class="TT-title1 TT-titleExtra"> 
                              <span trans="From:" class="TT-tagLineText">From:</span>
                              <b class="TT-textHeadline">{{currentuser.country()}}</b>
                             </div>
                           </div>
                           <div class="TT-overlayLayer">
                             <div class="TT-title1 TT-titleExtra"> 
                              <span trans="Email:" class="TT-tagLineText">Email: </span>
                              <b class="TT-textHeadline">{{currentuser.userEmail()}}</b>
                             </div>
                           </div>
                           <div (click)="editProfile()" class="TT-clickable TT-toppers">
                             <a class="TT-click" trans="Edit>"> Edit >>> </a>
                           </div>
                        </div>
                     </div>
                   </div>                   
                </div> 

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
                  <div class="TT-cardds">
                   <div class="Table-Height TT-tableHeight">
                      <h4 class="TT-title" trans="Top Subjects"> Top Subjects </h4>
                      <table class="table TT-table">
                         <thead>
                            <tr>
                               <th trans="Subject name">Subject Name</th>
                               <th trans="No.of bookings">No. of Bookings</th>
                            </tr>
                         </thead>
                         <tr class="TT-noRecord" *ngIf="top_subjects_count == 0">
                            <td colspan="5" align="center" trans="No Records Found">No Records Found</td>
                         </tr>
                         <tbody>
                            <tr *ngFor="let subject of top_subjects">
                               <td>
                                  {{subject.subject}}
                               </td>
                               <td>
                                  {{subject.count}}
                               </td>
                            </tr>
                         </tbody>
                      </table>                     
                   </div>
                  </div>  
                </div>     
                
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
                  <div *ngIf="currentuser.hasPermissions(['teachers.access','teachers.registration.access'])" class="TT-cardds">
                    <div class="Table-Height TT-tableHeight">
                     <h4 class="TT-title" trans="Top Tutors">Top Tutors</h4>
                     <table class="table TT-table">
                        <thead>
                           <tr>
                              <th trns="Username">Username</th>
                              <th trans="Ratings">Ratings</th>
                           </tr>
                        </thead>
                        <tr class="TT-noRecord" *ngIf="top_tutors_count == 0">
                           <td colspan="5" trans="No Records Found">No Records Found</td>
                        </tr>
                        <tbody>
                           <tr *ngFor="let request of top_tutors">
                              <td>
                                 {{request.display_name}}
                              </td>
                              <td>
                                 {{request.rating}}
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <div class="TT-innerCover">
                       <div class="TT-clickable">
                         <a class="TT-click" trans="View all" routerLink="/admin/tutors/registration"> View All >>> </a>
                       </div>
                     </div>
                   </div>
                 </div>
               </div> 

               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
                 <div class="TT-cardds" *ngIf="currentuser.hasPermissions(['students.access','students.registration.access'])">
                   <div class="Table-Height TT-tableHeight">
                     <h4 class="TT-title" trans="Open Complaints">Open Complaints</h4>
                     <table class="table TT-table TT-autoWidthTable">
                        <thead>
                           <tr>
                              <th trans="Student">Student</th>
                              <th trans="Student Attendence">Student Attendence</th>
                              <th trans="Tutor">Tutor</th>
                              <th trans="Tutor Attendence">Tutor Attendence</th>
                              <th trans="Subject">Subject</th>
                              <th trans="Class Status">Class Status</th>
                              
                              <th trans="Actions">Actions</th>
                           </tr>
                        </thead>
                        <tr *ngIf="open_complaints_count == 0">
                           <td colspan="5" align="center" class="TT-notify TT-noRecorded">
                              <span trans="No Records Found" class="TT-noRecords">
                                 <!-- <img src="../../../../assets/images/nodata.jpg" alt="no data found" class="img-responsive w-100 m-auto TT-noData" /> -->
                                 <div class="text-center">No Records Found</div>
                                </span>
                           </td>
                        </tr>
                        <tbody>
                           <tr *ngFor="let complaint of open_complaints">
                              <td><span>{{complaint.student.username}}</span></td>
                              <td>{{transform(complaint.schedule.student_attendence)}}</td>
                              <td><span>{{complaint.tutor.username}}</span></td>
                              <td>{{transform(complaint.schedule.tutor_attendence)}}</td>
                              <td><span>{{complaint.booking.subject_with_trash.subject_name}}</span></td>
                              <td>{{transform(complaint.schedule.class_status)}}</td>

                              <td class="dropdown TT-tableDropDownMenuList">
                                 <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                                 <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                                   <li class="TT-optionsList">
                                     <a class="TT-frameSet" id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" data-target="#adminViewNote" data-toggle="modal" aria-hidden="true" href="#adminViewNote" (click)="viewComment(complaint.comments)" trans="View Comment"> 
                                       View Comment 
                                     </a>
                                   </li>                                   
                                   <li class="TT-optionsList" (click)="messageModal(complaint.student , 'student')">
                                    <a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Student">
                                      Message Student
                                    </a>
                                    </li>
                                   <li class="TT-optionsList" (click)="messageModal(complaint.tutor, 'tutor')">
                                    <a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">
                                      Message Tutor
                                    </a>
                                    </li>
                                 </ul>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <div class="TT-innerCover">
                       <div (click)="viewAllCompliants()" class="TT-clickable">
                        <a class="TT-click" trans="View all">View All >>></a>
                       </div>
                      </div>
                     </div>
                    </div>
                   </div>

               <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
                 <div class="TT-cardds" *ngIf="currentuser.hasPermissions(['students.access','students.registration.access'])">
                  <div class="Table-Height TT-tableHeight">
                     <h4 class="TT-title" trans="Highest Bookings">Highest Bookings</h4>
                     <table class="table TT-table">
                        <thead>
                           <tr>
                              <th trans="Username">Username</th>
                              <th trans="No.of bookings">No.of Bookings</th>
                           </tr>
                        </thead>
                        <tr class="TT-noRecord" *ngIf="top_students_count == 0">
                           <td colspan="5" align="center" trans="Highest Bookings Will Appear Here">Highest Bookings Will Appear Here</td>
                        </tr>
                        <tbody>
                           <tr *ngFor="let student of top_students">
                              <td>
                                 {{student.student}}
                              </td>
                              <td>
                                 {{student.count}}
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <div class="TT-innerCover">
                       <div (click)="viewTopStudents()" class="TT-clickable">
                        <a class="TT-click" trans="View all">View All >>></a>
                      </div>
                    </div>
                  </div>
                 </div>  
               </div>  
                
               <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
                 <div class="TT-cardds" *ngIf="currentuser.hasPermissions(['alerts.access'])">
                  <div class="Table-Height TT-tableHeight">
                    <h4 class="TT-title" trans="Recent Alerts">Recent Alerts</h4>                     
                    <ul class="TT-listOrders">
                      <li class="TT-notify" *ngFor="let alert of recent_alerts">
                        <h4 class="TT-headlineList" (click)="viewAlert(alert)">
                          {{humanize(alert.purpose)}}
                         <span class="TT-innerHeadline">
                          {{alert.created}}123
                         </span>
                        </h4>
                        <p class="TT-wrapTexting">
                         <small [innerHtml] ="displayTextLimit(alert.notification)" class="TT-limitingText"> </small>
                        </p>
                      </li>
                      <li class="TT-notify TT-noRecorded" *ngIf="recent_alerts_count == 0" trans="No Records">No Records</li>
                    </ul>
                    <div class="TT-innerCover">
                    <div  (click)="openAlerts()" class="TT-clickable" id="alerts">
                      <a class="TT-click" trans="Alerts">Alerts >>></a>
                    </div>
                   </div>
                  </div>
                 </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
               <div class="TT-cardds" *ngIf="currentuser.hasPermissions(['inbox.access'])">
                 <div class="Table-Height TT-tableHeight">
                   <h4 class="TT-title" trans="Recent Messages">Recent Messages</h4>
                     <div class="TT-chatAreas">
                      <!-- chat 1 start -->
                      <div class="TT-chatCover" *ngFor="let messages of recent_messages">
                        <div class="chat__person-box chat__person-box--active" data-id="2" data-is_group="0" id="user-2" data-is_my_contact="1">
                         <div class="position-relative chat__person-box-status-wrapper">
                              <div class="chat__person-box-status  chat__person-box-status--online ">
                              </div>
                              <div class="chat__person-box-avtar chat__person-box-avtar--active">
                                <img *ngIf="messages.user" [src]="messages.user.avatar" class="user-avatar-img">
                              </div>
                           </div>
                           <div (click)="viewChat()" class="chat__person-box-detail">
                              <h5 class="mb-1 chat__person-box-name contact-name">
                                 {{messages.displayName}}
                                 <span class="contact-status">
                                 <i class="nav-icon user-status-icon" data-toggle="tooltip" data-placement="top" title="" data-original-title="ghljghj">
                                 </i>
                                 </span>
                              </h5>
                              <p *ngIf="messages.is_online == 1" class="mb-0 chat-message" >
                                 i'm online...
                              </p>
                              <p *ngIf="messages.is_online != 1" class="mb-0 chat-message" >
                                 i'm offline...
                              </p>
                           </div>
                         </div>
                        </div>
                        <div *ngIf="recent_messages_count == 0">
                           <td colspan="5" align="center"><span trans="No Records Found">No Records Found</span></td>
                        </div>
                        <!-- chat 1 end -->
                     </div>
                     <div class="TT-innerCover">
                      <div (click)="viewChat()" class="TT-clickable">
                        <a class="TT-click" trans="Messages >>>">Messages >>></a>
                      </div>
                    </div>
                  </div>               
                </div>
              </div>
            </div>
          </div>
       </div>
    </div>
 </section>

 <app-admin-messagemodal></app-admin-messagemodal>
 <app-view-note></app-view-note>