import { ChangeDetectionStrategy, Component, OnInit , Input } from '@angular/core';
import { CurrentUser } from '@auth/current-user';
import { LocalizationWithLines } from '@core/types/localization-with-lines';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Localizations } from '@core/translations/localizations.service';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { Settings } from '@core/config/settings.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Translations } from '@core/translations/translations.service';
import { Validators } from '@angular/forms';
import { BackendErrorResponse } from '@core/types/backend-error-response';
import { Role } from '@core/types/models/Role';
import { ViewChild, ElementRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import  *  as  Languages from  '@athand/json/languages.json';
declare var jQuery;

interface TranslationLine {
    key: string;
    translation: string;
}

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({opacity: 0}),
                animate('325ms ease-in', style({
                    opacity: 1,
                }))
            ])
        ]),
    ]
})

export class TranslationsComponent implements OnInit {
    @ViewChild('closeAddTranslationModal') closeAddTranslationModal: ElementRef;
    @ViewChild('closeNewTranslationModal') closeNewTranslationModal: ElementRef;
    @ViewChild('closeStatusTranslationModal') closeStatusTranslationModal: ElementRef;
    public loading$ = new BehaviorSubject<boolean>(false);
    public loadingLines$ = new BehaviorSubject<boolean>(false);
    public searchQuery = new FormControl();
    public selectedLocalization: LocalizationWithLines;
    public localizations$ = new BehaviorSubject<LocalizationWithLines[]>([]);
    public lines$ = new BehaviorSubject<TranslationLine[]>([]);
    public languages$ = (Languages as any).default;
    public errors$ = new BehaviorSubject<Partial<Role>>({});
    public newtranslateForm = this.fb.group({
        translationKey: ['',Validators.required],
        translationValue: ['',Validators.required]
    });
    public localizationData = null;
    public action = null;
    public translateForm = this.fb.group({
        name: ['',Validators.required],
        language: ['',Validators.required]
    });
    public errorMessages = { translationKey :[{ type:'required', message:'Translation-Key is required'}],
                             translationValue :[{ type:'required',message:'Translation-Value is required'}] };
    public translateFormerrorMessages = { name :[{ type:'required', message:'Name is required'}],
                             language :[{ type:'required',message:'Select Language'}]}

 get translationKey(){ return this.newtranslateForm.get('translationKey'); }
 get translationValue(){ return this.newtranslateForm.get('translationValue'); }
 get name(){ return this.translateForm.get('name'); }
 get language(){ return this.translateForm.get('language'); }

 public statustranslateForm = this.fb.group({
    status: ['',Validators.required],
    statuslanguage: ['',Validators.required]
});
public statusFormerrorMessages = { status :[{ type:'required', message:'Status is required'}],
statuslanguage :[{ type:'required',message:'Select Language'}]}
get status(){ return this.statustranslateForm.get('status'); }
get statuslanguage(){ return this.statustranslateForm.get('statuslanguage'); }

  constructor(
  	public currentUser: CurrentUser,
    private localizationsApi: Localizations,
    private route: ActivatedRoute,
    private settings: Settings,
    private i18n: Translations,
    private fb: FormBuilder,
    private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
            this.localizationsApi.all()
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
                this.setLocalizations(response.localizations);
                this.bindSearchQuery();
            });
  }

      private setLocalizations(localizations: LocalizationWithLines[]) {
        this.localizations$.next(localizations);
        const active = localizations.find(l => l.model['language'] === this.settings.get('i18n.default_localization')) || localizations[0];
        if (active) {
            this.setSelectedLocalization(active);
        }
    }

        public setSelectedLocalization(localization: LocalizationWithLines) {
        if (this.selectedLocalization?.model.id === localization.model.id) return;
        this.selectedLocalization = localization;
        this.searchQuery.setValue(null);
        // if lang lines are already fetched for this localization, bail
        if (localization.lines) {
            this.lines$.next(this.originalLines());
        } else {
            this.selectedLocalization.lines = {};
            this.loadingLines$.next(true);
            this.localizationsApi.get(this.selectedLocalization.model.name)
                .pipe(finalize(() => this.loadingLines$.next(false)))
                .subscribe(response => {
                    this.selectedLocalization = response.localization;
                    const localizations = [...this.localizations$.value];
                    const i = localizations.findIndex(loc => loc.model.id === localization.model.id);
                    localizations[i] = response.localization;
                    this.localizations$.next(localizations);
                    this.lines$.next(this.originalLines());
                });
        }
    }

        private originalLines() {
        return this.selectedLocalization.lines ?
            this.linesToArray(this.selectedLocalization.lines) :
            [];
    }

        private linesToArray(lines: {[key: string]: string}): TranslationLine[] {
        const transformed = [];
        for (const key in lines) {
            transformed.push({key, translation: lines[key]});
        }
        return transformed;
    }

        public removeLine(line: TranslationLine) {
        delete this.selectedLocalization.lines[line.key];
        this.lines$.next(this.originalLines());
    }

        public addLine() {
            this.addlines(this.newtranslateForm.value);
            this.newtranslateForm.reset();
    }

        public addlines(line?: {translationKey: string, translationValue: string}){
                 this.selectedLocalization.lines[line.translationKey] = line.translationValue;
                this.lines$.next(this.originalLines());
                this.closeNewTranslateModal()
     }

     public getLocalizationData( localization )
     {
        this.localizationData = localization;
        this.localizationsApi.getLocalizationData(localization.model.name)
        .subscribe(response => {
            let res = response['localization']['model']
            this.translateForm.patchValue({
                name:res['name'],
                language:res['language'],
            });
        });
     }

        public updateLocalization() {
        this.loading$.next(true);
        this.localizationsApi.update(this.selectedLocalization.model.id, this.selectedLocalization)
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(() => {
                this.toastr.success('Localizations updated');
                if (this.selectedLocalization.model.id === this.i18n.getActive().model.id) {
                    this.i18n.setLocalization(this.selectedLocalization);
                }
            });
    }

    public resetNewTranslationForm(){
        this.newtranslateForm.reset();
        }
    public resetTranslationForm(){
        this.localizationData = null;
        this.translateForm.reset();
    }
    public closeNewTranslateModal(){
    this.closeNewTranslationModal.nativeElement.click();
    }
    public closeTranslateModal(){
    this.closeAddTranslationModal.nativeElement.click();
    }

        private bindSearchQuery() {
        this.searchQuery
            .valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(query => {
                const lines = this.originalLines();
                this.lines$.next(query ? lines.filter(l => this.filterPredicate(l, query)) : lines);
            });
    }

        private filterPredicate(data: object, query: string) {
        const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
            // Use an obscure Unicode character to delimit the words in the concatenated string.
            // This avoids matches where the values of two columns combined will match the user's query
            // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
            // that has a very low chance of being typed in by somebody in a text field. This one in
            // particular is "White up-pointing triangle with dot" from
            // https://en.wikipedia.org/wiki/List_of_Unicode_characters
            return currentTerm + data[key] + '◬';
        }, '').toLowerCase();

        // Transform the filter by converting it to lowercase and removing whitespace.
        const transformedFilter = query.trim().toLowerCase();

        return dataStr.indexOf(transformedFilter) != -1;
    }

       public confirmLocalizationDeletion(language: LocalizationWithLines) {
        if (this.localizations$.value.length < 2) {
           this.toastr.warning('There must be at least one localization.');
            return;
        }

            this.deleteLocalization(language);
    }

        private deleteLocalization(language: LocalizationWithLines) {
        this.localizationsApi.delete(language.model.id).subscribe(() => {
             this.toastr.success('Localization deleted');
            const localizations = [...this.localizations$.value];
            localizations.splice(localizations.indexOf(language), 1);
            this.localizations$.next(localizations);

            if (this.selectedLocalization.model.id === language.model.id) {
                this.setSelectedLocalization(localizations[0]);
            }
        });
    }

        public confirm(localization?: LocalizationWithLines) {
        const request = this.createNewLocalization();
        this.closeTranslateModal()
        request
            .subscribe(response => {
               let newLocalization = response.localization;
                               if ( ! newLocalization) return;
                let localizations = [...this.localizations$.value];
                if (localization) {
                    localizations = localizations.map(l => {
                        return l.model.id === newLocalization.model.id ? newLocalization : l;
                    });
                }
                else if(this.action == 'edit')
                {
                    for (var i = 0; i < localizations.length; ++i) {
                        if( localizations[i].model.id == newLocalization['id'] )
                        {
                            localizations[i].model.name = newLocalization['name']
                        }
                    }
                }
                else {
                    localizations.push(newLocalization);
                    this.setSelectedLocalization(newLocalization);
                }
                this.localizations$.next(localizations);
            });
    }

    public confirmStatus() {
        this.statustranslateForm.markAllAsTouched();

        this.localizationsApi.changeStatus(this.statustranslateForm.value)
            .subscribe(response => {
                if(response['status'] == 'success')
                {
                    this.closeStatusTranslationModal.nativeElement.click();
                    this.statustranslateForm.reset();
                    this.toastr.success('Localization status changed');

                    const localizations = [...this.localizations$.value];
                    this.localizations$.next(localizations);
                }else{
                    this.toastr.error(response.error);
                }
            });
    }

    public transform(value) {
        return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
      }

        public createNewLocalization(): Observable<{localization: LocalizationWithLines}> {
            if( this.localizationData !== null )
            {
                this.action = 'edit';
                return this.localizationsApi.updatelocalization(this.localizationData.model.id, this.translateForm.value)
            }
            else{
                return this.localizationsApi.create(this.translateForm.value);
            }
    }

    trackByFn = (i: number, localization: LocalizationWithLines) => localization.model.id;
}
