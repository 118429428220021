import { Component, OnInit, Input } from '@angular/core';
import { Settings } from '../../core/config/settings.service';

@Component({
  selector: 'app-media-image',
  templateUrl: './media-image.component.html',
  styleUrls: ['./media-image.component.css']
})
export class MediaImageComponent implements OnInit {
  @Input() media;
  public src: string;
  constructor(private settings: Settings) { }
  
  ngOnInit(): void {
  	// const src = this.getSrc(this.media);
   //  this.src = this.settings.getBaseUrl(true) + src;
   //  console.log(this.src);
  }

    //   private getSrc(media) {
    //     console.log(media);
    // }


}
