import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-checks',
  templateUrl: './progress-checks.component.html',
  styleUrls: ['./progress-checks.component.css']
})
export class ProgressChecksComponent implements OnInit {
  
  public progressChecks$:any;
  @Input() 
  public set progresschecks(value){
    if(value){
  this.progressChecks$ = value;
     }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
