<div class="modal fade" id="paymentOutActions" role="dialog" aria-labelledby="modalLabel" tabindex="-1" style="z-index: 9999;">
   <div class="modal-dialog" role="document" style="margin-top: 100px;">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Payout Actions">Payout Actions<span *ngIf="selectedRequestUser"> - {{selectedRequestUser.full_name}}</span></h5>
            <button type="button" class="close" #closeActionModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            
            <div class="modal-body">
               <form role="form" [formGroup]="payoutStatusForm" class="form-horizontal">
                  <input type="hidden" name="selectedRequestId" formControlName="selectedRequestId">
                  <div class="row formspace">
                     <div class="col-sm-12">
                        <div class="row formspace">
                           <div class="col-md-12">
                              <div class="form-group">
                                 <label trans="Status" for="status">Status</label>
                                 <select formControlName="status" class="form-control">
                                    <option value="" trans="Please select">Please Select</option>
                                    <option value="accept">Accept (Stripe)</option>
                                    <option value="manual">Accept (Manual)</option>
                                    <option value="reject">Reject</option>
                                 </select>
                              </div>
                              <div class="form-group" *ngIf="selectedRequestUser">
                                 <label trans="Payout Account Details" for="status">Payout Account Details</label>
                                 <p class="greycolor">Payout Account Type : {{selectedRequestUser.payout_account_type}}</p>
                                 <p class="greycolor">Payouts Enabled? : {{selectedRequestUser.payouts_enabled}}</p>
                                 
                                 <p class="greycolor" *ngIf="selectedRequestUser.payout_account_type == 'stripe'">Payout Account : {{selectedRequestUser.payout_account}}</p>
                                 <p class="greycolor" *ngIf="selectedRequestUser.payout_account_type == 'iban' || selectedRequestUser.payout_account_type == 'paypal'">Name on card : {{selectedRequestUser.name_on_card}}</p>
                                 <p class="greycolor" *ngIf="selectedRequestUser.payout_account_type == 'iban'">IBAN : {{selectedRequestUser.paypal_iban}}</p>
                                 <p class="greycolor" *ngIf="selectedRequestUser.payout_account_type == 'paypal'">Paypal Address : {{selectedRequestUser.paypal_iban}}</p>
                              </div>
                              <div *ngFor= "let error of errorMessages.status">
                                 <ng-container *ngIf="status.hasError(error.type) && (status.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <div class="col-md-12">
                              <div class="form-group">
                                 <label trans="Comments" for="comments">Comments</label>
                                 <textarea id="comments" rows="4"  class="form-control" formControlName="comments"></textarea>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>
               <button class="btn btn-primary new" trans="Update" (click)="updatePayout()">Update</button>
            </div>
         </div>
      </div>
   </div>
</div>