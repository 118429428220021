import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-teachers-payout',
  templateUrl: './teachers-payout.component.html',
  styleUrls: ['./teachers-payout.component.css']
})
export class TeachersPayoutComponent implements OnInit {
  
  @Output() payoutRequestModalData = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  @Output() reloadRecords = new EventEmitter();
  @Output() payoutRequestModalDataUser = new EventEmitter();
  public payoutsRequests$:any;
  @Input() 
  public set payoutsRequests(value){
    this.payoutsRequests$ = value;
  }
  constructor() { }

  ngOnInit(): void {
    
  }

  public messageModal(userData){
    this.adminMessageModalData.emit({selectedTutorName:userData.username , id:userData.id});
  }

  public payoutRequest(id, user=''){
   this.payoutRequestModalData.emit(id);
   this.payoutRequestModalDataUser.emit(user);
  }

}
