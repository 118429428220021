<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
     <div class="table-responsive TT-responsiveTable">
      <table class="table table-striped TT-table">
       <thead>
         <tr>
            <th trans="Username">Username</th>
            <th trans="Email">Email</th>
            <th trans="Timestamp">Timestamp</th>
            <th trans="Location">Location</th>
            <th trans="Actions">Actions</th>
         </tr>
         </thead>
         
         <tbody>
         <tr *ngFor="let student of newStudents$">
            <td><span >{{student.username}}</span></td>
            <td><span >{{student.email}}</span></td>
            <td>{{student.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
            <td>{{student.country}}</td>
            <td class="TT-formGroup">

               <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" href="#studentProfile" (click)="viewApplication(student.id)" class="btn TT-primaryBTN TT-topSpaceZero" value="Attend" data-target="#studentProfile" data-toggle="modal" style="width:auto;padding:8px 35px;">
               View</a>&nbsp;

            <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
               <ul class="TT-dropDownMenu dropdown-menu dropdown-new" style="top: 42%;
               left: 83%;">
                  <li class="TT-optionsList"><a class="TT-frameSet" (click)="suspenduser(student.id, 'suspend')" trans="Suspend"> Suspend</a></li>
                  <li class="TT-optionsList"><a class="TT-frameSet" (click)="deleteuser(student.id, 'delete')" trans="Delete">Delete</a></li>
                  <li class="TT-optionsList" (click)="messageModal(student)"><a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Student"> Message Student</a></li>
               </ul>
            </td>
         </tr>
         <tr *ngIf="newStudents$ && newStudents$.length==0" class="TT-noRecorded" trans="No records found">
           <td colspan="5" class="text-center"> No records found. </td>
         </tr>
       </tbody>
      </table>
    </div>
  </div>
</div>
<app-datatable-footer-new *ngIf="newStudents$ && newStudents$.length>0" ></app-datatable-footer-new>