<div class='col-sm-10 rightCol adminteachers'>
    <div class="well well-sm chat-head">
        <h4 class="title" trans="Paypal Subscription Plans">Paypal Subscription Plans</h4>
    </div>
    <!-- <div>
        <a href="#paypalform">

        <button class="right" (click)="onsubmit()">
            Add Plan
        </button>
        </a>
    </div> -->
    <!-- <div id="paypalform" class="tab-pane fade">
        <div class="row">
            <div class="col-sm-6 mb-10"> -->
    <div class='content-container customTabs'>
        <form [formGroup]="paypalplans" class="account-info" >
            <div class="row formspace">
                <div class="col-sm-12">
                    <label for="name">Name</label>
                    <input type="text" name="name" placeholder="Enter Name" trans-placeholder="Enter Name">
                </div>
                <div class="col-sm-12">
                    <label for="description">Description</label>
                    <textarea type="text" name="description" placeholder="Enter Description"
                        trans-placeholder="Enter Description"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-10">
                    <label for="frequency_interval">Frequency Interval</label>
                    <select name="frequency_interval">
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>
                </div>
                <div class="col-md-6 mb-10">
                    <label for="frequency">Frequency</label>
                    <select name="frequency">
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                        <option value="day">Day</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-10">
                    <label for="amount">Amount</label>
                    <input type="text" name="amount">
                </div>
                <div class="col-md-6 mb-10">
                    <label for="plan">Plan  </label>
                    <input type="text" name="plan">
                </div>
                <div class="col-md-6 mb-10">
                    <label for="status">Status  </label>
                    <input type="text" name="status">
                </div>
            </div>
        </form>

    </div>


    <div class='content-container customTabs'>
        <table class="table lesson-table">
            <tr>
                <th trans="Name">Name</th>
                <th trans="Description">Description</th>
                <th trans="Frequency Interval">Frequency Interval</th>
                <th trans="Frequency">Frequency</th>
                <th trans="amount">Amount</th>
                <th trans="Plan">Plan</th>
                <th trans="Status">Status</th>
            </tr>
            <!-- <tr>    <!-- <tr *ngFor="let plan of plans"> 
            <td>{{plan.name}}</td>
            <td>{{plan.description}}</td>
            <td>{{plan.frequency_interval}}</td>
            <td>{{plan.frequency}}</td>
            <td>{{plan.amount}}</td>
            <td>{{plan.status}}</td>
        </tr> -->
            <!-- <tr *ngFor="let page of pageCategories | async">
             <td>{{transform(page.title)}}</td>
             <td>{{page.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
             <td class="lesson-btn-actions dropdown" class="lesson-btn-actions dropdown">
                <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
                <ul class="dropdown-menu dropdown-new">
                   <li (click)="SetEditAction(page.id)" ><a trans="Edit">Edit</a></li>
                   <li class="divider"></li>
                   <li (click)="deleteCategory(page.id)"><a trans="Delete">Delete</a></li>
                   <li class="divider"></li>
                </ul>
             </td>
          </tr> -->
        </table>
        <!-- <app-datatable-footer-new *ngIf="(pageCategories | async).length > 0"></app-datatable-footer-new> -->
    </div>
</div>