<div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="crupdateUserModal" class="modal fade" style="display: none;">
   <div class="modal-dialog modal-dialog-centered TT-modalMainArea">
      <div class="modal-content TT-modelCover">
         <div class="modal-header">
           <button aria-hidden="true" (click)="close()" data-dismiss="modal" class="close" type="button" #closeUserModal>×</button>
            <h4 class="modal-title TT-AccUsers" *ngIf="!user_id">Create a New User</h4>
            <h4 class="modal-title TT-AccUsers" *ngIf="user_id">Edit user</h4>
         </div> 
         <form [formGroup]="userForm">
            <div class="modal-body">
               <div class="row m-auto">
                  <div class="col-sm-12">
                     <div class="form-group TT-formGroup">
                        <label for="email" class="TT-lable">Email</label>
                        <input [attr.readonly]="user_id ? '' : null" type="email" formControlName="email"
                           class="TT-FieldGroup" placeholder="Email" />
                        <!-- <input [attr.readonly]="user_id ? '' : null" type="text"  formControlName="email"  class="form-control" (blur)="selectEmail(email)"> -->
                        <div *ngFor="let error of errorMessages.email">
                           <ng-container *ngIf="email.hasError(error.type) && (email.dirty || email.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row m-auto">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label for="password" class="TT-lable">Password</label>
                        <div class="TT-eyeCover">
                           <span (click)="togglePassword('password')"
                              class="fa fa-fw fa-eye-slash TT-eyeIconMoon"></span>
                           <input id="password" type="password" formControlName="password" class="TT-FieldGroup"
                              placeholder="Password" />
                        </div>
                        <div *ngFor="let error of errorMessages.password">
                           <ng-container *ngIf="password.hasError(error.type) && (password.dirty || password.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label for="crupdate-user-new-password" class="TT-lable">Confirm Password</label>
                        <div class="TT-eyeCover TT-formGroup">
                           <button class="btn TT-primaryBTN TT-BTNOverlays" type="" (click)="generateNewPassword()"
                              trans>Generate</button>
                           <input type="password" id="crupdate-user-new-password" formControlName="password"
                              class="TT-FieldGroup" placeholder="New Password" />
                        </div>
                     </div>
                  </div>
               </div>

               <div class="row m-auto">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label for="first_name" class="TT-lable">First Name</label>
                        <input type="text" formControlName="first_name" class="TT-FieldGroup" placeholder="First Name ">
                        <div *ngFor="let error of errorMessages.first_name">
                           <ng-container
                              *ngIf="first_name.hasError(error.type) && (first_name.dirty || first_name.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                     <div class="form-group TT-formGroup">
                        <label for="last_name" class="TT-lable">Last Name</label>
                        <input type="text" formControlName="last_name" class="TT-FieldGroup" placeholder="Last Name">
                        <div *ngFor="let error of errorMessages.last_name">
                           <ng-container
                              *ngIf="last_name.hasError(error.type) && (last_name.dirty || last_name.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
               </div>
               <div>

                  <div class="row m-auto">
                     <div class="col-sm-12">
                        <div class="TT-formGroup clearfix" *ngIf="user_id != 1">
                           <button data-toggle="modal" data-target="#rolesModal"
                              class="TT-primaryBTN TT-autoCustomStyle pull-right"> Manage </button>
                        </div>
                     </div>
                  </div>

                  <div class="row m-auto">
                     <div class="col-sm-12">
                        <div class="form-group TT-formGroup">
                           <label for="roles" class="TT-lable">Roles</label>
                        </div>
                     </div>
                  </div>
                  <div class="TT-lable" *ngIf="!(roles$ | async)?.length">No roles yet.</div>
                  <div class="row m-auto" *ngIf="(roles$ | async)?.length">
                    <div class="col-sm-12">
                      <div class="TT-formGroups" *ngFor="let role of roles$ | async">
                        <span class="TT-badgeList"> {{role.name}} </span>
                        <span class="TT-closeing" onclick="this.parentElement.style.display='none'" *ngIf="user_id != 1">&times;</span>
                       </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="form-group TT-formGroup clearfix">
                        <button *ngIf="user_id" [disabled]="!userForm.valid" (click)="confirm()" type="submit"
                           class="btn TT-primaryBTN TT-autoCustomStyle pull-right">
                           Update User
                        </button>
                        <button *ngIf="!user_id" [disabled]="!userForm.valid" (click)="confirm()" type="submit"
                           class="btn TT-primaryBTN TT-autoCustomStyle pull-right">
                           Create User
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </form>
      </div>
   </div>
</div>
<app-role-manager (selectedRoles)="selectedRoles($event)"></app-role-manager>
<app-permissions-modal (selectedPermissions)="selectedPermissions($event)"></app-permissions-modal>