<div class='col-sm-12 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Uploaded File Types for board setting">Uploaded File Types For Board Setting</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>

      <form [formGroup]="boardSettingForm"class="col-md-6" (ngSubmit)="UpdateboardSetting()">
         <table class="table lesson-table noBorderTable">
            <thead>
                  <!--  <tr> -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="file_type" trans="File Types">
                                File Types  (JPG, PNG, PDF)
                                 </label>
                            <select formControlName="file_type" (click)="selectFileType($event.target.value)" class="form-control iconArrowDown">
                                <option value="" trans="Please select"> Please Select</option>
                                <option [value]="type.Type" *ngFor="let type of fileTypes">{{type.Type}}</option>
                            </select>
		                  <!--   <div *ngFor= "let error of errorMessages.file_type">
		                        <ng-container *ngIf="file_type.hasError(error.type) && (file_type.touched)">
		                           <small class="error-message">{{error.message}}</small>
		                        </ng-container>
		                     </div> -->
                       </div>

                    </div>
                   <!--  </tr>   -->
                   <div class="col-md-8">
		            <label trans="Selected File Types">Selected File Types</label><br>
		            <div class="row" style="margin:6px;" *ngIf="selectedFileTypes.value">
		               <div class="col-12">
		                  <div *ngFor="let types of selectedFileTypes.value" class="chip">
		                     <span>{{types}}</span>
		                     <span class="closebtn" onclick="this.parentElement.style.display='none'" (click)="removeItem(types)">&times;</span>
		                  </div>
		               </div>
		            </div>
		         </div>

                   <tr>
                   	<div class="col-md-6 TT-formGroup">
                     <button class="TT-primaryBTN" trans="Update" style="width:auto;padding:8px 35px">Update</button>
                     </div>
                  </tr>
            </thead>
         </table>
      </form>

   </div>
</div>


