import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { StudentsService } from '../students.service';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '../../../core/types/models/User';
import { DatatableService } from '../../../core/datatable/datatable.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdminMessagemodalComponent } from '../../admin-messagemodal/admin-messagemodal.component';
import { ViewNoteComponent } from '../../view-note/view-note.component';
import { FormControl } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-students-registration',
  templateUrl: './students-registration.component.html',
  styleUrls: ['./students-registration.component.css'] ,
  providers: [DatatableService] ,
})
export class StudentsRegistrationComponent implements OnInit {
  
  @ViewChild(ViewNoteComponent) noteChild: ViewNoteComponent;
  @ViewChild(AdminMessagemodalComponent) adminMessageChild: AdminMessagemodalComponent;
  public tabName:any = 'New Students';
  public tabsData:any;
  public newStudentsCount:number;
  public complaintsCount:number;
  public topStudentsCount:number;
  public suspendedStudentsCount:number;
  public deletedStudentsCount:number;
  public allStudentsCount:number;
  public newStudents:any;
  public complaints:any;
  public topStudents:any;
  public suspendedStudents:any;
  public deletedStudents:any;
  public allStudents:any;
  public students$ = this.datatable.data$;
  public searchControl = new FormControl();
  public studentColumn:any;
  public obj:any;
  public selectedStudentId:any;
  public newStudentsColumn = [ {'Username':['username'] , 'Email':['email'] , 'Country':['country'] }];
  public compliantsColumns = [ {'Lesson Id':['schedule','booking_dates_id'] , 'Boking Id':['booking','booking_id'] ,'Student':['student','username'] , 'Tutor':['tutor','username'] , 'Status':['status']}];
  public suspendedStudentsColumns = [ {'Username':['username'] , 'Email':['email'] , 'Country':['country'] } ];
  public deletedStudentsColumns = [ {'Username':['username'] , 'Email':['email'] , 'Country':['country'] } ];
  public allStudentsColumns = [ {'Username':['username'] , 'Email':['email'] , 'Country':['country'] } ];
  
  constructor(public service: StudentsService,
    public datatable: DatatableService<User>,
    private fb: FormBuilder) { }

  ngOnInit(): void {

          this.searchControl.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.datatable.addFilter('column',this.studentColumn);
                this.datatable.addFilter('tQuery', query);
          });

      this.studentsTabsCount();

      this.studentsList();

      this.datatable.addFilter('tab',this.tabName);

      $('.tab-link').click( function() {
        var tabID = $(this).attr('data-tab');
        $(this).addClass('active').siblings().removeClass('active');
        $('#tab-'+tabID).addClass('active').siblings().removeClass('active');
       });

    this.students$.subscribe(response =>{
      
   if(this.tabName == 'New Students'){
    this.newStudents = response;
    this.obj = Object.assign({}, ...this.newStudentsColumn);

   }else if(this.tabName == 'Complaints'){

    this.complaints = response;
    this.obj = Object.assign({}, ...this.compliantsColumns);

   }else if(this.tabName == 'Top Students'){

    this.topStudents = response;
    // this.obj = Object.assign({}, ...this.acceptedTutorsColumn);

   }else if(this.tabName == 'Suspended Students'){

    this.suspendedStudents = response;
    this.obj = Object.assign({}, ...this.suspendedStudentsColumns);

   }else if(this.tabName == 'Deleted Students'){

    this.deletedStudents = response;
    this.obj = Object.assign({}, ...this.deletedStudentsColumns);

   }else if(this.tabName == 'ALL Students'){
    this.allStudents = response;
    this.obj = Object.assign({}, ...this.allStudentsColumns);
   }
  });

  }

  public studentsList(){
        this.datatable.init({
            uri: StudentsService.BASE_URI
        });
  }

  public studentsTabsCount(){
    this.service.allstudentsCount().subscribe(response=>{
    let res = JSON.parse(atob(response['data']));
    this.tabsData = res;
    this.newStudentsCount = this.tabsData['newStudentsCount'];
    this.complaintsCount = this.tabsData['complaintsCount'];
    this.topStudentsCount = this.tabsData['topStudentsCount'];
    this.suspendedStudentsCount = this.tabsData['suspendedStudentsCount'];
    this.deletedStudentsCount = this.tabsData['deletedStudentsCount'];
    this.allStudentsCount = this.tabsData['allStudentsCount'];
    });
  }

  public selectTab(tabName){
    this.obj = '';
    
    if ( this.tabName != tabName ) { // Let us reset the form when tthe tab changed so that search willbe reset!
      this.searchControl.reset();
      $('.searchInput').attr('disabled','disabled');
      this.studentColumn = '';
    }
    this.datatable.data$.next(null);
    this.tabName = tabName;
    this.datatable.addFilter('tab',this.tabName);
    this.datatable.addFilter('column','');
    this.datatable.addFilter('tutorsQuery', '');
  }

  public csvExort(name){
    if( name == 'Compliants' ) {  
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        title: name,
        showLabels: true, 
        showTitle: true,
        useBom: true,
        noDownload: false,
        headers: ["schedule_id", "booking_id", "student_name", "student_attendence", "tutor_name", "tutor_attendence", "student_name", "class_time", "class_status", "created_time", "status"],
        useHeader: true,
        nullToEmptyString: true,
      };
      new AngularCsv(this.students$.value , name, options);
    } else {
       var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        title: name,
        showLabels: true, 
        showTitle: true,
        useBom: true,
        noDownload: false,
        headers: ["id", "username", "full_name", "email", "registration_process", "country", "created_at", "approved_by_name", "suspended_by_name", "suspended_date", "deleted_by_name", "roles_name"],
        useHeader: true,
        nullToEmptyString: true,
      };
      new AngularCsv(this.students$.value , name, options);
    }
  }

  public selectedStudentsColumn(column  , id){
    if(column){
      this.studentColumn = column;
      $('#'+id).removeAttr('disabled');
    }else{
      this.studentColumn = '';
      this.datatable.addFilter('column',this.studentColumn);
      $('#'+id).attr('disabled','disabled');
    }
   $('#'+id).val('');
  }

 public reloadStudents(value){
   if(value == true){
     this.datatable.addFilter('tab',this.tabName);
     this.studentsTabsCount();
   }
  }

 public messageModal(userData){
    this.adminMessageChild.userData(userData);
  }

  public viewCommentModal(comment){
    this.noteChild.noteData(comment);
  }

  public viewStudentApplication(id){
    this.selectedStudentId = id; 
  }

}
