<div aria-hidden="true" aria-labelledby="adminReviewModal" role="dialog" #closeModal tabindex="-1" id="adminReviewModal" class="modal fade" style="display: none;">
   <div class="modal-dialog" style="margin-top: 200px;">
      <div class="modal-content" style="border-radius: 25px;">
         <div class="modal-header">
            <button (click)="closeReviewModal()" aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark">Review From {{selectedStudentName}}</h4>
         </div>
         <div class="modal-body">
            <form role="form" class="form-horizontal">
               <div class="form-group">
                  <div class="col-lg-12">
                     <textarea readonly value="{{review}}" rows="10" cols="30" class="form-control"></textarea>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>