import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LessonsMenuService } from '../lessons-menu.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lessonsetting',
  templateUrl: './lessonsetting.component.html',
  styleUrls: ['./lessonsetting.component.css']
})
export class LessonsettingComponent implements OnInit {

    public fieldArray: Array<any> = [];
    public teachingSpecialities;
    public studentAges;
    public newAttribute: any = {};
    public specialitiesForm = this.fb.group({
        speciality: ['',Validators.required]
    });

    public studentAgeForm = this.fb.group({
        student_age: ['',Validators.required]
    });

    public teachingLoader:boolean = true;

    get speciality() {return this.specialitiesForm.get('speciality')};
    get student_age() {return this.studentAgeForm.get('student_age')};

  public errorMessages = {
         speciality : [{ type:'required',message:'Speciality is required'}],
         student_age :  [{ type:'required',message:'Grade Level is required'}]
  }

  constructor(private fb: FormBuilder,
              public settings: LessonsMenuService,
              private toastr: ToastrService) { }

      public addSpeciality() {
        this.specialitiesForm.markAllAsTouched();
        if(this.specialitiesForm.valid){
        this.teachingLoader = true;
        this.settings.createSpeciality(this.specialitiesForm.value).subscribe(response =>{
        let resposne = JSON.parse(atob(response['data']));
        if(response['status'] == 'success'){
        this.teachingLoader = false;
        this.specialitiesForm.reset();
        this.setTeachingLevels(resposne['teachinglevel']);
            }
        });
      }
    }

    public addStudentAge(){
      this.studentAgeForm.markAllAsTouched();
        if(this.studentAgeForm.valid){
        this.teachingLoader = true;
        this.settings.createStudentAge(this.studentAgeForm.value).subscribe(response =>{
        let resposne = JSON.parse(atob(response['data']));
        if(response['status'] == 'success'){
        this.teachingLoader = false;
        this.studentAgeForm.reset();
        this.setStudentAge(resposne['studentAges']);
            }
        });
      }
    }


      public deleteSpecialityValue(index) {
        this.fieldArray.splice(index, 1);
    }

         public setStudentAge(data){
       this.studentAges = data;
    }

      public setTeachingLevels(data){
       this.teachingSpecialities = data;
    }

      public deleteSpeciality(id){
        this.teachingLoader = true;
        let data = Object();
        Object.assign( data ,{specialityId : id});
       this.settings.deleteSpeciality(data).subscribe(response =>{
        if(response['status'] == 'success'){
        let resposne = JSON.parse(atob(response['data']));
        this.teachingLoader = false;
        this.setTeachingLevels(resposne['teachinglevel']);
            }else if(response['status'] == 'failed'){
             this.teachingLoader = false;
             this.toastr.error(response['message']); 
            }
       })
      }

      public deleteStudentAge(id){
        this.teachingLoader = true;
        let data = Object();
        Object.assign( data ,{specialityId : id});
       this.settings.deleteStudentAge(data).subscribe(response =>{
        if(response['status'] == 'success'){
        let resposne = JSON.parse(atob(response['data']));
        this.teachingLoader = false;
        this.setStudentAge(resposne['studentAges']);
            }else if(response['status'] == 'failed'){
             this.teachingLoader = false;
             this.toastr.error(response['message']); 
            }
       })
      }

  ngOnInit(): void {
      this.settings.teachingLevels()
            .subscribe(response => {
        let resposne = JSON.parse(atob(response['data']));
        if(response['status'] == 'success'){
        this.teachingLoader = false;
        this.setTeachingLevels(resposne['teachinglevel']);
        this.setStudentAge(resposne['studentAges']);
            }
          });
  }
}
