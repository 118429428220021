 <!-- =======================01-09-2022 navbar============================ -->
 <div class="container-padding TT-tabNavBar">
   <nav class="navbar navbar-default TT-navBarDefaultTab">
      <!-- Brand and toggle get grouped for better mobile display -->
      <!-- <div class="navbar-header">
         <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
         </button>
         <a class="navbar-brand" href="#">Templates</a>
      </div> -->

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse TT-navbarCollapseTab" id="bs-example-navbar-collapse-1">
         <ul class="nav navbar-nav TT-navNavTabb">
            <li (click)="templateType('Email')" id="Email" class="TT-listingArea">
              <a class="TT-navLinkArea" trans="Landing-Page Settings"> Email </a>
            </li>
            <li (click)="templateType('Sms')" id="Sms" class="TT-listingArea">
              <a class="TT-navLinkArea" trans="General"> SMS </a>
            </li>
            <li (click)="templateType('Alerts')" id="Alerts" class="TT-listingArea">
              <a class="TT-navLinkArea" trans="Authentication"> Alerts </a>
            </li>
         </ul>
      </div>
   </nav>
</div>
 <!-- =========== 01-09-2022  End================ -->
 
 <!-- <div class="left-container">
   <h5 class="text-left color2"> <b trans="Settings">Templates </b> </h5>
   <ul class="lesson-list">
      <li (click)="templateType('Email')" id="Email" class="hoverselectedSetting">
         <a class="linkLabel" trans="Landing-Page Settings"> Email </a>
      </li>
      <li (click)="templateType('Sms')" id="Sms" class="hoverselectedSetting">
         <a  class="linkLabel" trans="General"> SMS </a>
      </li>
      <li (click)="templateType('Alerts')" id="Alerts" class="hoverselectedSetting">
         <a  class="linkLabel" trans="Authentication"> Alerts </a>
      </li>
   </ul>
</div> -->