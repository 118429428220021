<div class='col-sm-12 rightCol'>
   <div  class='content-container customTabs'>
      <div class="row formspace">
         <div class="col-sm-12">
            <div class="row formspace">
               <form [formGroup]="resumeForm">
               <div class=' customTabs'>
                  <div class="row formspace">
                     <div class="col-sm-12">
                        <div class="row formspace">
                           <div class="col-md-4 mobile-group">
                              <div class="form-group mobile-form">
                                 <label for="first" trans="Display Name">Display Name</label>
                                 <input readonly formControlName="display_name" type="text" class="form-control r" placeholder="Display Name" trans-placeholder="Display Name">
                              </div>
                           </div>
                           
                           <div class="col-md-12">
                              <div class="form-group mobile-form">
                                 <label for="highlight" trans="High-Light">High-Light</label>
                                 <label class="pull-right">*80 - 90</label>
                                 <textarea  readonly maxlength="90"  id="textarea" class="form-control" formControlName="highlight" placeholder="Highlight" trans-placeholder="High-Light"></textarea>
                              </div>
                           </div>
                           <div class="col-md-12">
                              <div class="form-group mobile-form">
                                 <label trans="About the you">About the you</label> <br>
                                 <ckeditor [config]="{readOnly: 'true'}" formControlName="description">
                                 </ckeditor>
                              </div>
                           </div>
                           <hr>
                           <div class="col-md-12">
                              <div class="row">
                                 <div class="col-md-6">
                                    <p trans="Paste a link of your video" class="pl-6">
                                       Paste a link of your video
                                    </p>
                                    
                                    <input readonly formControlName="video_URL" type="text" class="form-control mobile-group">
                                 </div>
                                 
                                 <div *ngIf="liveIFrame" class="col-md-4 ">
                                    <div class="videoContainer">
                                       <iframe  width="250" height="200"  class="responsive-iframe" [src]="liveIFrame"></iframe>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               </form>
            </div>
         </div>
      </div>
      <div class="clearfix"></div>
   </div>
</div>