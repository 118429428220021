import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin.component';
import { SettingsComponent } from './settings/settings.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { GeneralComponent } from './settings/general/general.component';
import { AuthenticationComponent } from './settings/authentication/authentication.component';
import { LocalizationComponent } from './settings/localization/localization.component';
import { SettingsSidenavComponent } from './settings/settings-sidenav/settings-sidenav.component';
import { SettingsImageInputComponent } from './settings/settings-image-input/settings-image-input.component';
import { TranslationsComponent } from './translations/translations.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RolesComponent } from './roles/roles.component';
import { DatatableService } from '../core/datatable/datatable.service';
import { TeachersService } from './teachers/teachers.service';
import { CurdRoleModalComponent } from './roles/curd-role-modal/curd-role-modal.component';
import { PermissionsModalComponent } from './roles/permissions-modal/permissions-modal.component';
import { UsersComponent } from './users/users.component';
import { CurdUserModalComponent } from './users/curd-user-modal/curd-user-modal.component';
import { RoleManagerComponent } from './users/role-manager/role-manager.component';
import { UserColumnComponent } from './users/user-column/user-column.component';
import { TutorTeachingComponent } from './tutor-teaching/tutor-teaching.component';
import { TutorModule } from '../tutor/tutor.module';
import { UiModule } from '../core/ui/ui.module';
import { PaymentsComponent } from './payments/payments.component';
import { PaymentsSidenavComponent } from './payments/payments-sidenav/payments-sidenav.component';
import { BookingPaymentsComponent } from './payments/booking-payments/booking-payments.component';
import { LandingPageSettingsComponent } from './settings/landing-page-settings/landing-page-settings.component';
import { TransactionLogComponent } from './payments/transaction-log/transaction-log.component';
import { VideomodalComponent } from './videomodal/videomodal.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { NewMessagemodalComponent } from './students/newmessagemodal/newmessagemodal.component';

import { DatatableModule } from '../core/datatable/datatable.module';


import { StudentsComponent } from './students/students.component';
import { StudentsSideNavComponent } from './students/students-side-nav/students-side-nav.component';
import { StudentsRegistrationComponent } from './students/students-registration/students-registration.component';
import { StudentsSettingsComponent } from './students/students-settings/students-settings.component';
import { StudentsMessagesComponent } from './students/students-messages/students-messages.component';
import { NewStudentsComponent } from './students/new-students/new-students.component';
import { ComplaintsComponent } from './students/complaints/complaints.component';
import { TopStudentsComponent } from './students/top-students/top-students.component';
import { SuspendedStudentsComponent } from './students/suspended-students/suspended-students.component';
import { DeletedStudentsComponent } from './students/deleted-students/deleted-students.component';
import { AllStudentsComponent } from './students/all-students/all-students.component';
import { LessonsMenuComponent } from './lessons-menu/lessons-menu.component';
import { LessonsSideNavComponent } from './lessons-menu/lessons-side-nav/lessons-side-nav.component';
import { LessonsListingComponent } from './lessons-menu/lessons-listing/lessons-listing.component';
import { LessonsBoardSettingsComponent } from './lessons-menu/lessons-board-settings/lessons-board-settings.component';
import { ScheduleSettingsComponent } from './lessons-menu/schedule-settings/schedule-settings.component';
import { LessonsettingComponent } from './lessons-menu/lessonsetting/lessonsetting.component';
import { PagesComponent } from './pages/pages.component';
import { PagesSideNavComponent } from './pages/pages-side-nav/pages-side-nav.component';
import { CreatePagesComponent } from './pages/create-pages/create-pages.component';
import { CreateCategoryComponent } from './pages/create-category/create-category.component';
import { PagesListComponent } from './pages/pages-list/pages-list.component';
import { CategoryListComponent } from './pages/category-list/category-list.component';

import { AdminChatComponent } from './admin-chat/admin-chat.component';
import { MediaImageComponent } from './media-image/media-image.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AdminAlertsComponent } from './admin-alerts/admin-alerts.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateSidenavComponent } from './templates/template-sidenav/template-sidenav.component';
import { CreateTemplateComponent } from './templates/create-template/create-template.component';
import { ListComponent } from './templates/list/list.component';
import { AccountComponent } from './account/account.component';
import { AthandModule } from '../athand/athand.module';
import { LevelsComponent } from './lessons-menu/levels/levels.component';
import { FinanceComponent } from './finance/finance.component';
import { FinsidenavComponent } from './finance/finsidenav/finsidenav.component';
import { FintabsComponent } from './finance/fintabs/fintabs.component';
import { SaletransComponent } from './finance/saletrans/saletrans.component';
import { DbResetComponent } from './settings/db-reset/db-reset.component';
import { DbResetModalComponent } from './settings/db-reset/db-reset-modal/db-reset-modal.component';
import { AdminMessagemodalComponent } from './admin-messagemodal/admin-messagemodal.component';
import { TeachersPayoutComponent } from './finance/teachers-payout/teachers-payout.component';
import { ApprovedPayoutComponent } from './finance/approved-payout/approved-payout.component';
import { PayoutActionsComponent } from './finance/payout-actions/payout-actions.component';
import { CreditTransactionsComponent } from './finance/credit-transactions/credit-transactions.component';
import { LessonTransactionsComponent } from './finance/lesson-transactions/lesson-transactions.component';
import { TeachersComponent } from './teachers/teachers.component';
import { TeachersSidenavComponent } from './teachers/teachers-sidenav/teachers-sidenav.component';
import { RegistrationComponent } from './teachers/registration/registration.component';
import { PendingTutorsComponent } from './teachers/pending-tutors/pending-tutors.component';
import { TopTutorsComponent } from './teachers/top-tutors/top-tutors.component';
import { AcceptedTutorsComponent } from './teachers/accepted-tutors/accepted-tutors.component';
import { SuspendedTutorsComponent } from './teachers/suspended-tutors/suspended-tutors.component';
import { DeletedTutorsComponent } from './teachers/deleted-tutors/deleted-tutors.component';
import { AllTutorsComponent } from './teachers/all-tutors/all-tutors.component';
import { TeacherSettingsComponent } from './teachers/teacher-settings/teacher-settings.component';
import { NewApplicationComponent } from './teachers/new-application/new-application.component';
import { MessagesComponent } from './teachers/messages/messages.component';
import { TutorProfileComponent } from './teachers/tutor-profile/tutor-profile.component';
import { NotesComponent } from './teachers/notes/notes.component';
import { ProgressChecksComponent } from './teachers/progress-checks/progress-checks.component';
import { ViewApplicationComponent } from './teachers/view-application/view-application.component';
import { ActiveSubjectsComponent } from './lessons-menu/active-subjects/active-subjects.component';
import { BadReviewsComponent } from './lessons-menu/bad-reviews/bad-reviews.component';
import { UnAttendedComponent } from './lessons-menu/un-attended/un-attended.component';
import { TopReviewsComponent } from './lessons-menu/top-reviews/top-reviews.component';
import { ExperiencedErrorsComponent } from './lessons-menu/experienced-errors/experienced-errors.component';
import { RefundedLessonsComponent } from './lessons-menu/refunded-lessons/refunded-lessons.component';
import { AllLessonsComponent } from './lessons-menu/all-lessons/all-lessons.component';
import { ViewReviewComponent } from './view-review/view-review.component';
import { UnAcceptedComponent } from './lessons-menu/un-accepted/un-accepted.component';
import { ChangesApprovalComponent } from './teachers/changes-approval/changes-approval.component';
import { UserImpersonateComponent } from './user-impersonate/user-impersonate.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ViewNoteComponent } from './view-note/view-note.component';
import { StudentProfileComponent } from './students/student-profile/student-profile.component';
import { DirectCancellationModalComponent } from './direct-cancellation-modal/direct-cancellation-modal.component';
import { PageSettingsComponent } from './pages/page-settings/page-settings.component';
import { StudentPayoutComponent } from './finance/student-payout/student-payout.component';
import { RefundRequestsComponent } from './finance/refund-requests/refund-requests.component';
import { StripeSettingsComponent } from './finance/stripe-settings/stripe-settings.component';
import { PaypalsubscriptionComponent } from './paypalsubscription/paypalsubscription.component';
import { PaypalSubscriptionPlansComponent } from './finance/paypal-subscription-plans/paypal-subscription-plans.component';
import { HeaderComponent } from './header/header.component';


@NgModule({
  declarations: [
         HomeComponent,
         AdminComponent,
         SettingsComponent,
         SidenavComponent,
         GeneralComponent,
         AuthenticationComponent,
         LocalizationComponent,
         SettingsSidenavComponent,
         SettingsImageInputComponent,
         TranslationsComponent,
         DirectCancellationModalComponent,
         RolesComponent,
         CurdRoleModalComponent,
         PermissionsModalComponent,
         UsersComponent,
         MediaImageComponent,
         CurdUserModalComponent,
         RoleManagerComponent,
         UserColumnComponent,
         TutorTeachingComponent,
         PaymentsComponent,
         PaymentsSidenavComponent,
         BookingPaymentsComponent,
         LandingPageSettingsComponent,
         TransactionLogComponent,
         VideomodalComponent,
         NewMessagemodalComponent,
         StudentsComponent,
         StudentsSideNavComponent,
         StudentsRegistrationComponent,
         StudentsSettingsComponent,
         StudentsMessagesComponent,
         NewStudentsComponent,
         ComplaintsComponent,
         TopStudentsComponent,
         SuspendedStudentsComponent,
         DeletedStudentsComponent,
         AllStudentsComponent,
         LessonsMenuComponent,
         LessonsSideNavComponent,
         LessonsListingComponent,
         LessonsBoardSettingsComponent,
         ScheduleSettingsComponent,
         LessonsettingComponent,
         PagesComponent,
         PagesSideNavComponent,
         CreatePagesComponent,
         CreateCategoryComponent,
         PagesListComponent,
         CategoryListComponent,
         AdminChatComponent,
         AdminAlertsComponent,
         TemplatesComponent,
         CreateTemplateComponent,
         ListComponent,
         TemplateSidenavComponent,
         AccountComponent,
         LevelsComponent,
         FinanceComponent,
         FinsidenavComponent,
         FintabsComponent,
         SaletransComponent,
         DbResetComponent,
         DbResetModalComponent,
         AdminMessagemodalComponent,
         ApprovedPayoutComponent,
         PayoutActionsComponent,
         CreditTransactionsComponent,
         LessonTransactionsComponent,
         TeachersPayoutComponent,
         TeachersComponent,
         TeachersSidenavComponent,
         RegistrationComponent,
         PendingTutorsComponent,
         TopTutorsComponent,
         AcceptedTutorsComponent,
         SuspendedTutorsComponent,
         DeletedTutorsComponent,
         AllTutorsComponent,
         TeacherSettingsComponent,
         NewApplicationComponent,
         MessagesComponent,
         TutorProfileComponent,
         NotesComponent,
         ProgressChecksComponent,
         ViewApplicationComponent,
         ActiveSubjectsComponent,
         BadReviewsComponent,
         UnAttendedComponent,
         TopReviewsComponent,
         ExperiencedErrorsComponent,
         RefundedLessonsComponent,
         AllLessonsComponent,
         ViewReviewComponent,
         UnAcceptedComponent,
         ChangesApprovalComponent,
         UserImpersonateComponent,
         ViewNoteComponent,
         StudentProfileComponent,
         PageSettingsComponent,
         StudentPayoutComponent,
         RefundRequestsComponent,
         StripeSettingsComponent,
         PaypalsubscriptionComponent,
         PaypalSubscriptionPlansComponent,
         HeaderComponent,
         ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AthandModule,
    TooltipModule,
    CKEditorModule,
    TutorModule,
    NgxDropzoneModule,
    UiModule,
    DatatableModule,
  ],
  exports: [MediaImageComponent],
  providers:[
  DatatableService , TeachersService, AdminChatComponent
  ]
})
export class AdminModule { }
