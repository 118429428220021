import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../template.service';
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-template-sidenav',
  templateUrl: './template-sidenav.component.html',
  styleUrls: ['./template-sidenav.component.css']
})
export class TemplateSidenavComponent implements OnInit {

  constructor(public templateService:TemplateService,
              public router: Router) { }

  ngOnInit(): void {
  this.templateType('Email');
  }

  public templateType(value){
   this.templateService.templateId(null);
   this.router.navigateByUrl('admin/templates/list');
   this.templateService.template(value);
   $('#'+value).addClass("selectedSetting").siblings().removeClass("selectedSetting");
  }

}
