import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-lesson-transactions',
  templateUrl: './lesson-transactions.component.html',
  styleUrls: ['./lesson-transactions.component.css']
})
export class LessonTransactionsComponent implements OnInit {

  @Output() adminMessageModalData = new EventEmitter();
  public lessonTransactions$:any;
  @Input() 
  public set lessonTransactions(value){
    this.lessonTransactions$ = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
