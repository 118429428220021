import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { ChatService } from '@athand/chat/chat.service';
import { CurrentUser } from '@auth/current-user';
import { WebSocketService } from '@core/services/web-socket.service';
import { randomString } from '@core/utils/random-string';
import { Echo } from 'laravel-echo-ionic';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Directive } from '@angular/core';
import { Settings } from '@core/config/settings.service';
import { Injectable, Injector } from '@angular/core';
import { Bootstrapper } from '@core/bootstrapper.service';
import { LessonsService } from '@student/lessons/lessons.service'; 
import { BehaviorSubject } from 'rxjs';
import { PusherService } from 'app/pusher.service';
declare var $;

@Component({
  selector: 'app-admin-chat',
  templateUrl: './admin-chat.component.html',
  styleUrls: ['./admin-chat.component.css']
})

export class AdminChatComponent implements OnInit {
@ViewChild('closeViewDetailsModal') closeViewDetailsModal: ElementRef;
@ViewChild('scrollMe') private myScrollContainer: ElementRef;
@ViewChild('closeFileUploader') closeFileUploader: ElementRef;

  echo: any = null;
  $scope: any;
  public conversations;
  public conversationLists;
  public adminDetails;
  protected settings: Settings;
  public messages;
  public showOriginal:boolean = false;
  public statuses;
  public myId = this.currentuser.userId();
  public newMessage;
  public rescheduleRequest;
  public archeiveStatus:boolean = false;
  public selectedUserName;
  public archeiveChats;
  public isDataLoaded:boolean = false;
  public converstaionsLoader:boolean = false;
  public chatLoader:boolean = false;
  public selectedUserID:number;
  public imageLoader$ = new BehaviorSubject(false);
  public userLists;
  public authuserid;
  public host_socket;
  public searchmessages;
  public sentmsg = 'no';
  public showconversation = 'no';
  public readcount;
  public unread_count;
  public studentsconversations;
  public allUsersForSearch;
  public loading;
  public readcountstatus = 'no';
  public messagesconversations;
  public unreadCount =0;
  public searching = 'no';
  public useravatar;
  public currentId = 0;
  public emptyspace = 'no';
  public conversationsplice;
  public responsereadcount = 1;
  public heightofscroll;
  public conversationtype;
  public previous_created_at:'';
  public currentTab = 'studentsTab';
  title = 'dropzone';
  files: File[] = [];

  formData = new FormData();
  constructor(public chat: ChatService,
              public currentuser: CurrentUser,
              private router: Router,
              private toastr: ToastrService,
              protected injector: Injector,
              private bootstrapper: Bootstrapper,
              private lessonService: LessonsService,
              private pusherService: PusherService) {

       this.settings = this.injector.get(Settings);

            this.lessonService.shareduserdata.subscribe(res=>{
            //console.log('resss', res)
            //console.log('res length', res.length)
                if( res ) {
                  //this.selectUser(res.id, res.full_name, res);
                }
                if( res.length > 0 ) {
                  //this.selectUser(res.id, res.full_name, res);
                }
              }); 

  }

  ngOnInit(): void {
    
    this.studentsall();

    this.chat.chatInfo().subscribe(response=>{
       this.conversations = JSON.parse(atob(response['chatInfo']));
       this.converstaionsLoader = true;
       this.chatLoader = false;
    })
  }

public initializeItems(){
  (this.currentTab == 'studentsTab') ? this.studentsconversations = this.userLists : this.archeiveChats = this.userLists;
}

filterUserList(searchTerm: string) {
 this.initializeItems();
  if (!searchTerm) {
    return;
  }
  let Users = (this.currentTab == 'studentsTab') ? this.studentsconversations : this.archeiveChats;
  Users = Users.filter(currentGoal => {
    if (currentGoal.username && searchTerm) {
      if (currentGoal.username.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        return true;
      }
      return false;
    }
  });

  (this.currentTab == 'studentsTab') ? this.studentsconversations  = Users : this.archeiveChats = Users;

}

public studentsall() {
  this.chat.AdminStudentsConversation().subscribe(response => {
      this.studentsconversations = JSON.parse(atob(response['students-conversations']));
      // console.log(this.studentsconversations);
      this.userLists = this.studentsconversations;
      this.allUsersForSearch = JSON.parse(atob(response['all-users']));
      this.studentsconversations = this.studentsconversations.slice(0, 10)
      let z = 0;
      for (var i = 0; i < this.studentsconversations.length; i++) {
           if ( this.studentsconversations[i].conversations_all.length > 0) {
              
              for (var j = (this.studentsconversations[i].conversations_all.length)-1; j >= 0; j--) {
                if( this.studentsconversations[i].id != this.studentsconversations[i].conversations_all[j].to_id ) {
                  if ( this.studentsconversations[i].conversations_all[j].status == 0) {
                      this.unreadCount = z++;
                    }  else {
                      this.unreadCount = 0;
                    }  
                  Object.assign(this.studentsconversations[i] , {unreadCount: this.unreadCount});
                }
                      this.conversationsplice = this.studentsconversations[i].conversations_all[(this.studentsconversations[i].conversations_all.length)-1].message;
                      this.conversationtype = this.studentsconversations[i].conversations_all[(this.studentsconversations[i].conversations_all.length)-1].message_type;
                      Object.assign(this.studentsconversations[i] , {conversationsplice: this.conversationsplice});
                      Object.assign(this.studentsconversations[i] , {conversationtype: this.conversationtype});
                  }
              }
          else {
                this.unreadCount = 0;
            }
      }
    })
}
searchconversations() {
  this.searching = 'yes';
}

filtermessages(searchTerm: string, id) {
 this.conversationsuserid(id);
  if (!searchTerm) {
    this.chat.seletedUserConversation(id).subscribe(response=>{
      response = JSON.parse(atob(response['data']));
      this.messages = response['messages'];
    })
  }
  if ( this.messages['conversations'] != '' ) {
    if ( this.messagesconversations ) {
      if ( this.messagesconversations['conversations'] ) {
        this.messages['conversations'] = this.messagesconversations['conversations'];
      }
    }

    this.messages['conversations'] = this.messages['conversations'].filter(currentGoal => {
    if (currentGoal.message && searchTerm && currentGoal.message_type == 0) {
      if (currentGoal.message.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        return true;
      }
      return false;
    }
  });
  } else {
     this.chat.seletedUserConversation(id).subscribe(response=>{
      response = JSON.parse(atob(response['data']));
      this.messagesconversations = response['messages'];
      this.messages['conversations'] = this.messagesconversations['conversations'];
      this.filtermessages( searchTerm, id );
    })
  }
}

public conversationsuserid(id){
    this.selectedUserID = id;
  }

public keyPressHandler(e, id) {
  //
  if ( (e.keyCode === 13) || (27 === e.which) || (13 === e.which) ) {
     this.sendMessage(id)
     e.preventDefault();
        $("[id='sendMessage']").each(function(){
            $(this).val("");
        });
  }
}

  // This Method is used to get the messages of the selected
  public userid(id){
    this.selectedUserID = id;
    this.chat.seletedUserConversation(id).subscribe(response=>{
      response = JSON.parse(atob(response['data']));
      this.messages = response['messages'];
      this.showconversation = 'yes';
       $("div.activeUser").removeClass("activeUser chat__person-box--active");
      $('#'+id).addClass('activeUser chat__person-box--active');
      this.scrollToBottom(id);
      if(response['rescheduleRequest']){
       this.rescheduleRequest = response['rescheduleRequest'];
       }
    })
  }



  public loadMore() {
    this.loading = 'up';
  }

  public archievedstatus(id){
    this.selectedUserID = id;
    this.chat.archievestatuses(id).subscribe(response=>{
      response = response['archive-statuses'];
      this.statuses = response;
    })
  }

  public message(messageValue){
  this.newMessage = messageValue;
  }

  // This Method is used to view the detalils of tyhe request
  public viewRequestSource(){
    this.closeViewDetailsModal.nativeElement.click();
    this.router.navigate(['tutor/tutor-lessons/pending-actions']);
  }

  // This method is uded to send the message
  public sendMessage(id){
    if($("#sendMessage").val()){
    let time = new Date().getTime();
    let messagePush = Object();
    Object.assign(messagePush,  {to_id:""+id});
    Object.assign(messagePush,  {from_id:this.myId});
    Object.assign(messagePush,  {message:this.newMessage});
    Object.assign(messagePush,  {created_at:time});
    Object.assign(messagePush,  {time:time});
    Object.assign(messagePush,  {senderName:this.currentuser.userName()});
    Object.assign(messagePush,  {randomMsgId : randomString(13)});
    Object.assign(messagePush,  {message_type :0});
    Object.assign(messagePush,  {sendAsNotification: 'Yes'});
      $('#sendMessageBtn').hide();
     this.messages['conversations'].push(messagePush);
     this.chat.sendMessage(messagePush).subscribe(response=>{
      // this.conversations = JSON.parse(atob(response['chatInfo']));
        $("#sendMessage").val('');
        $('#sendMessageBtn').show();
    })
     this.scrollToBottom(id);
     this.studentsall();
   }
  }

  // This Method is used to archeive the user
  public archieve(userId){
    this.chat.archiveUser(userId).subscribe(response =>{
      if(response['status'] == 'success'){
        this.conversations = JSON.parse(atob(response['chatInfo']));
        this.toastr.success('Chat Archieved Successfully');
        this.showconversation = 'no';
      }
    })
  }

  // This Method is used to unarcheive the user
  public unArchieve(userId){
    this.chat.unArchiveUser(userId).subscribe(response =>{
      if(response['status'] == 'success'){
        this.conversations = JSON.parse(atob(response['chatInfo']));
        this.toastr.success('Chat Un Archieved Successfully');
        this.getArchiveConversations();
        this.showconversation = 'no';
      }
    })
  }


  public archiveOptions(status){
    if(status == true){
    this.archeiveStatus = true;
    }else{
    this.archeiveStatus = false;
    }
  }


  public  createChannelPusher(selectedUserId){

    // channel name convention is being followed here as reach_chat_StudentId_TutorId
    // add student Id first and Admin/Tutor Id next
    // For admin, his id will always be last
    
    this.pusherService.createChannel(selectedUserId,this.myId);
    
    this.pusherService.channel.bind('tutor.message', data => {

     // console.log(data) ;
     // console.log('data.conversation.from_id') ;
      
      if(this.myId!=data.from_id && this.selectedUserID==data.from_id){ 
          let time = new Date().getTime();

          if(this.previous_created_at == data.created_at){
            return
          }else{
            this.previous_created_at = data.created_at
          }
          //console.log(time);
          let messagePush = Object();
          Object.assign(messagePush,  {to_id:""+data.to_id});
          Object.assign(messagePush,  {from_id:data.from_id});
          Object.assign(messagePush,  {message:data.message});
          Object.assign(messagePush,  {created_at:time});
          Object.assign(messagePush,  {time:time});
          Object.assign(messagePush,  {senderName:data.sender.username});
          Object.assign(messagePush,  {randomMsgId : data.id});
          if(data.file_name == null){
            Object.assign(messagePush,  {message_type :0});
          }else{
            Object.assign(messagePush,  {message_type :1});
          }
          Object.assign(messagePush,  {sendAsNotification: 'No'});
          this.messages['conversations'].push(messagePush);
      }
    });
  }


    // This Method will select the user to chat
  public selectUser(userId , name = '', userlist){
    this.createChannelPusher(userId);

    this.selectedUserName = name;
    if ( userlist.avatar ) {
      this.useravatar = userlist.avatar;
    } else {
      this.useravatar = userlist.avatar_url;
    } 
    this.unread_count = 0;
    this.getreadcount(userId, this.unread_count);
    $("div.activeUser").removeClass("activeUser chat__person-box--active");
    $('#'+userId).addClass('activeUser chat__person-box--active');
    this.userid(userId);
    this.archievedstatus(userId);
  }

  public getreadcount(userId, readcount ){
    this.chat.adminSideReadCountOfSelectedUser(userId, readcount).subscribe(response=>{
      this.responsereadcount = response['readcount'];
      this.currentId = userId;
    })
  }

  // This Method will select the Admin Conversation 
  public getActiveConversations(name ,tabName:string = ''){
    this.chatLoader = false;
    this.showconversation = 'no';
    this.currentTab = tabName;
    this.studentsall();
    $('#filterInput').val('');
  } 

  // This Method is used to get the Archive Conversations
  public getArchiveConversations(tabName:string = ''){
    $('#filterInput').val('');
    this.currentTab = tabName;
    this.chatLoader = false;
    this.showconversation = 'no';
    this.chat.AdminTeachersConversation().subscribe(response => {
      this.archeiveChats = JSON.parse(atob(response['teachers-conversations']));
      this.userLists = this.archeiveChats;
      this.allUsersForSearch = JSON.parse(atob(response['all-users']));
      this.archeiveChats = this.archeiveChats.slice(0, 10)
      let z = 0;
      for (var i = 0; i < this.archeiveChats.length; i++) {
           if ( this.archeiveChats[i].conversations_all.length > 0) {
              
              for (var j = (this.archeiveChats[i].conversations_all.length)-1; j >= 0; j--) {
                if( this.archeiveChats[i].id != this.archeiveChats[i].conversations_all[j].to_id ) {
                  if ( this.archeiveChats[i].conversations_all[j].status == 0) {
                      this.unreadCount = z++;
                    }  else {
                      this.unreadCount = 0;
                    }  
                  Object.assign(this.archeiveChats[i] , {unreadCount: this.unreadCount});
                }
                      this.conversationsplice = this.archeiveChats[i].conversations_all[(this.archeiveChats[i].conversations_all.length)-1].message;
                      this.conversationtype = this.archeiveChats[i].conversations_all[(this.archeiveChats[i].conversations_all.length)-1].message_type;
                      Object.assign(this.archeiveChats[i] , {conversationsplice: this.conversationsplice});
                      Object.assign(this.archeiveChats[i] , {conversationtype: this.conversationtype});
                  }
              }
          else {
                this.unreadCount = 0;
            }
      }
    })
  }

   // This Method is used to select the Image is drag and drop
    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
    }

   // This Method is used to drop the image selected
    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
        this.disableUpload();
    }


    public scrollToBottom(id){
      this.chat.getscrolldata(id).subscribe(response=>{
        this.heightofscroll = response['scheight'];
        if ( response['scheight'] != 0 ) {
          this.heightofscroll = $('#conversation-container').prop("scrollHeight")
          $("#conversation-container").animate({ scrollTop: this.heightofscroll}, 100);
        } else {
          this.heightofscroll = $('#conversation-container').prop("scrollHeight")
          $("#conversation-container").animate({ scrollTop: $('#conversation-container').prop("scrollHeight")}, 100)
        }
        window.scrollBy(0, this.heightofscroll);
      })

      this.chat.scrollmethod(this.heightofscroll, id).subscribe(response=>{
      })
    }

    // This Method is used to upload the Files in the chat
    public sendFile(){
      this.imageLoader$.next(true);
      this.chat.UploadFiles(this.formData).subscribe(response => {
      if(response['status'] == 'success'){
        let fileUploaded = JSON.parse(atob(response['fileData']));
        this.fileUploadMessages(fileUploaded.data);
        }
      })
    }

    // This Method is used to upload the Files
    public disableUpload(){
      if(this.files.length>0){
        $('#upload').prop("disabled", false);
      }else{
        $('#upload').prop("disabled", true);
      }
    }

    // This Method send the uploaded files as messages
    public fileUploadMessages(files){
       for(var file of files){
        this.filesSendMessage(file);
       }
    }

    public removeAllFiles(){
     this.files = [];
     this.formData.delete("file[]");
   }

   public filesSendMessage(file){
      let fileData = Object();
      Object.assign(fileData , {to_id:this.selectedUserID+""});
      Object.assign(fileData , {message:file.attachment});
      Object.assign(fileData , {message_type:'1'});
      Object.assign(fileData , {file_name: file.file_name});
      Object.assign(fileData , {is_my_contact:'1'});
      Object.assign(fileData , {from_id:this.selectedUserID});
      Object.assign(fileData,  {sendAsNotification: 'No'});
      this.chat.sendMessage(fileData).subscribe(response => {
       this.scrollToBottom(this.selectedUserID);
       if(response['status'] == 'success'){
       this.closeFileUploader.nativeElement.click();
        this.imageLoader$.next(false);
       this.removeAllFiles();
       this.messages['conversations'].push(response['message']);
       }
      })
      this.sentmsg = 'yes';
   }

  public closeFileUploaderModal(){
      this.removeAllFiles(); 
    }

  public viewschedule( myid ){
    this.chat.seletedUserConversation(myid).subscribe(response=>{
      response = JSON.parse(atob(response['data']));
      if( response['authuserid'] == 8 ) {
        this.router.navigate(['tutor/tutor-lessons/schedule/scht']);
      } else if( response['authuserid'] == 9 ) {
        this.router.navigate(['student/lessons/scheduled/sch']);
      }
    })
  }
}
