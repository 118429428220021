import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-top-tutors',
  templateUrl: './top-tutors.component.html',
  styleUrls: ['./top-tutors.component.css']
})
export class TopTutorsComponent implements OnInit {
  
  @Output() selectedApplicationTutorId = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  public topTutors$:any;
  @Input() 
  public set topTutors(value){
    if(value){
    this.topTutors$ = value;
     }
  }

  constructor() { }

  ngOnInit(): void {
  }

    public viewApplication(id , $sectionType){
    this.selectedApplicationTutorId.emit({id:id,sectionType:$sectionType});
  }

}
