<div class="row m-auto">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
       <table class="table table-striped TT-table">
          <thead>
            <tr>
                <th trans="Username">Username</th>
                <th trans="Name">Name</th>
                <th trans="Close Date">Close Date</th>
                <th trans="Closed By">Closed By</th>
                <th trans="Progress Check">Progress Check</th>
                <th trans="Actions" colspan="2">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let tutor of deletedTutors$">
                <td><span>{{tutor.deleted_tutors.username}}</span></td>
                <td>{{tutor.display_name}}</td>
                <td><span>{{tutor.deleted_tutors.deleted_at | date: 'MM-dd-yyyy h:mm a' }}</span></td>
                <td><span>{{tutor.deleted_tutors.deleted_by_name}}</span></td>
                <td style="min-width: 120px; padding: 0px;">
                 <app-progress-checks [progresschecks]="tutor.progress_checks"></app-progress-checks>
                </td>
               <td class="dropdown TT-tableDropDownMenuList TT-formGroup">

                 <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" href="#modalApproval" (click)="viewApplication(tutor.user_id,'existingProfile')" class="btn TT-primaryBTN TT-topSpaceZero" value="Attend" data-target="#modalApproval" data-toggle="modal">
                     View</a>
              </td>
              <td class="dropdown TT-tableDropDownMenuList TT-formGroup">
                  <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                  <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                   <li class="TT-optionsList"><a class="TT-frameSet" (click)="restoreUser(tutor.deleted_tutors.id, 'suspend')" trans="Restore">Restore</a></li>
                   <li class="TT-optionsList"><a class="TT-frameSet" (click)="deleteUserPermanently(tutor.deleted_tutors.id, 'delete')" trans="Delete Permanently">Delete Permanently</a></li>
                   <li class="TT-optionsList" (click)="messageModal(tutor.deleted_tutors)"><a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Tutor">Message Tutor</a></li>
                </ul> 
             </td>
            </tr>
        <tr *ngIf="deletedTutors$ && deletedTutors$.length == 0" class="textalign" trans="No records found">No records found</tr>
    </tbody>
</table>
</div>
</div>
</div>
