<div class="modal fade" id="modalApproval" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog" role="document" style="margin-top: 100px;">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <div *ngIf="newAppplicationLoader" class="loader_Main">
               <div class="loader-cal"></div>
            </div>
            <h5 *ngIf="tutorProfile" class="modal-title linkLabel-green-dark" id="modalLabel">
               {{tutorProfile.display_name}}
               <span *ngIf="selectedSection$ == 'existingProfile'" trans="New Profile">
                  Profile
               </span>New Profile
               <span *ngIf="selectedSection$ == 'newProfile'" trans=" New Application">
                  New Application
               </span>
            </h5>
            <button type="button" class="close" #closeNewApplicationModal data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">
                  &times;
               </span>
            </button>
            <div class="modal-body">
               <div class="container-fluid whiteBg">
                  <ul class="nav nav-tabs">
                     <li class="active">
                        <a data-toggle="tab" href="#new-approval" trans="Approval">
                           Approval
                        </a>
                     </li>
                     <li>
                        <a data-toggle="tab" href="#application" trans="Application">
                           Application
                        </a>
                     </li>
                     <li *ngIf="tutorProfile">
                        <app-user-impersonate [impersonateTutorId]="tutorProfile.user_id" (closeModal)="closeModal()">
                        </app-user-impersonate>
                        <button (click)="viewTutorResume(tutorProfile.user_id , tutorProfile.display_name)"
                           class="btn btn-modal-log1">
                           <i class="fa fa-user">
                           </i>
                        </button>
                     </li>
                  </ul>
                  <div class="tab-content">
                     <div id="new-approval" class="tab-pane fade in active Fcolor">
                        <label trans="Changes List">
                           Changes List
                        </label>
                        <div class="row">
                           <div class="col-md-12 mar-lf BBL-Block">
                              <ul style="word-break: break-word;">
                                 <li *ngIf="tutorProfile" trans="Updated Profile About Me">
                                    Updated Profile About Me
                                    <span
                                       *ngIf="tutorProfile.admin_status === 'Pending' && tutorProfile['display_name']"
                                       class="colorCC verySmall">
                                       (
                                       <a href="javascript:void(0)"
                                          (click)="updateApplication(tutorProfile.user_id,'profile','Approved')"
                                          trans="Approve">
                                          Approve
                                       </a>
                                       |
                                       <a href="javascript:void(0)"
                                          (click)="updateApplication(tutorProfile.user_id,'profile','Rejected')"
                                          trans="Reject">
                                          Reject
                                       </a>
                                       )
                                    </span>
                                    <span *ngIf="tutorProfile.admin_status === 'Approved'" class="GColor"
                                       trans="Approved">
                                       - Approved
                                    </span>
                                    <span
                                       *ngIf="tutorProfile.admin_status !== 'Approved' && tutorProfile.admin_status !== 'Pending'"
                                       class="GColor" trans="Rejected">
                                       - Rejected
                                    </span>
                                 </li>
                                 <li *ngIf="tutorAvailability" trans="Availability">
                                    Availability
                                    <span *ngIf="tutorAvailability.admin_status === 'Pending'"
                                       class="colorCC verySmall">
                                       (
                                       <a href="javascript:void(0)"
                                          (click)="updateApplication(tutorAvailability.id,'availability','Approved')"
                                          trans="Approve">
                                          Approve
                                       </a>
                                       |
                                       <a href="javascript:void(0)"
                                          (click)="updateApplication(tutorAvailability.id,'availability','Rejected')"
                                          trans="Reject">
                                          Reject
                                       </a>
                                       )
                                    </span>
                                    <span *ngIf="tutorAvailability.admin_status === 'Approved'" class="GColor"
                                       trans="Approved">
                                       - Approved
                                    </span>
                                 </li>
                                 <li *ngIf="tutorCertificates && tutorCertificates.length > 0">
                                    <div *ngFor="let certificate of tutorCertificates">
                                       Added Certificate - <b>{{certificate.certificate_name}}</b>
                                       <span *ngIf="certificate.admin_status === 'Under-Review'"
                                          class="colorCC verySmall">
                                          (
                                          <a href="javascript:void(0)"
                                             (click)="updateApplication(certificate.id,'certificate','Approved')"
                                             trans="Approved">
                                             Approve
                                          </a>
                                          |
                                          <a href="javascript:void(0)"
                                             (click)="updateApplication(certificate.id,'certificate','Rejected')"
                                             trans="Reject">
                                             Reject
                                          </a>
                                          )
                                       </span>
                                       <span *ngIf="certificate.admin_status === 'approved'" class="GColor"
                                          trans="Approved">
                                          - Approved
                                       </span>
                                    </div>
                                 </li>
                                 <li *ngIf="tutorEducations && tutorEducations.length > 0">
                                    <div *ngFor="let education of tutorEducations">
                                       Added Education - <b>{{education.degree_name}}</b>
                                       <span *ngIf="education.admin_status === 'Under-Review'"
                                          class="colorCC verySmall">
                                          (
                                          <a href="javascript:void(0)"
                                             (click)="updateApplication(education.id,'education','Approved')"
                                             trans="Approved">
                                             Approve
                                          </a>
                                          |
                                          <a href="javascript:void(0)"
                                             (click)="updateApplication(education.id,'education','Rejected')"
                                             trans="Reject">
                                             Reject
                                          </a>
                                          )
                                       </span>
                                       <span *ngIf="education.admin_status === 'Approved'" class="GColor"
                                          trans="Approved">
                                          - Approved
                                       </span>
                                    </div>
                                 </li>
                                 <li *ngIf="tutorVideo" trans="Added Video">
                                    Added Video
                                    <span *ngIf="tutorVideo.admin_status === 'pending'" class="colorCC verySmall">
                                       (
                                       <a href="javascript:void(0)"
                                          (click)="updateApplication(tutorVideo.id,'video','approved')" trans="Approve">
                                          Approve
                                       </a>
                                       |
                                       <a href="javascript:void(0)"
                                          (click)="updateApplication(tutorVideo.id,'video','rejected')" trans="Reject">
                                          Reject
                                       </a>
                                       )
                                    </span>
                                    <span *ngIf="tutorVideo.admin_status === 'approved'" class="GColor"
                                       trans="Approved">
                                       - Approved
                                    </span>
                                 </li>
                                 <li *ngIf="tutorLanguages && tutorLanguages.length > 0">
                                    <div *ngFor="let language of tutorLanguages">
                                       Added Language - <b>{{language.language}}</b>
                                       <span class="GColor" trans="Approval(NOT REQUIRED)">
                                          - Approval(NOT REQUIRED)
                                       </span>
                                    </div>
                                 </li>
                                 <li *ngIf="tutorSubjects && tutorSubjects.length > 0">
                                    <div *ngFor="let subject of tutorSubjects">
                                       Added New Subject - <b>{{subject.subject_data.subject_name}}</b> ,
                                       <span *ngIf="subject.admin_status === 'Under-Review'" class="colorCC verySmall">
                                          (
                                          <a href="javascript:void(0)"
                                             (click)="updateApplication(subject.id,'subject','Active')" trans="Approve">
                                             Approve
                                          </a>
                                          |
                                          <a href="javascript:void(0)"
                                             (click)="updateApplication(subject.id,'subject','Rejected')"
                                             trans="Reject">
                                             Reject
                                          </a>
                                          )
                                       </span>
                                       <span *ngIf="subject.admin_status === 'Active'" class="GColor" trans="Active">
                                          - Active
                                       </span>
                                    </div>
                                 </li>
                                 <li *ngIf="tutorPackages && tutorPackages.length > 0">
                                    <div *ngFor="let package of tutorPackages">
                                       Added Package - <b>{{package.no_of_classes}} Classes</b>
                                       <span *ngIf="package.admin_status === 'Under-Review'" class="colorCC verySmall">
                                          (
                                          <a href="javascript:void(0)"
                                             (click)="updateApplication(package.id,'package','Active')" trans="Approve">
                                             Approve
                                          </a>
                                          |
                                          <a href="javascript:void(0)"
                                             (click)="updateApplication(package.id,'package','Rejected')"
                                             trans="Reject">
                                             Reject
                                          </a>
                                          )
                                       </span>
                                       <span *ngIf="package.admin_status === 'Active'" class="GColor" trans="Approved">
                                          - Approved
                                       </span>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <label trans="Missing Details">
                           Missing Details
                        </label>
                        <div class="row">
                           <div class="col-md-12 mar-lf BBL-Block missindDetails">
                              <ul>
                                 <li *ngIf="!tutorProfile" trans="Tutor profile missing">
                                    Tutor Profile Missing
                                 </li>
                                 <li *ngIf="!tutorAvailability" trans=" Availability">
                                    Availability
                                 </li>
                                 <li *ngIf="!tutorCertificates || tutorCertificates.length == 0" trans=" Certitificate">
                                    Certitificate
                                 </li>
                                 <li *ngIf="!tutorEducations || tutorEducations.length == 0" trans="Education">
                                    Education
                                 </li>
                                 <li *ngIf="!tutorVideo" trans="video URL">
                                    video URL
                                 </li>
                                 <!-- <li *ngIf="!tutorLanguages || tutorLanguages.length == 0" trans="Languages">
                                    Languages
                                 </li> -->
                                 <li *ngIf="!tutorSubjects || tutorSubjects.length == 0" trans="Subjects">
                                    Subjects
                                 </li>
                                 <li *ngIf="!tutorPackages || tutorPackages.length == 0" trans="Packages">
                                    Packages
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div *ngIf="selectedSection$ == 'newProfile'" class="row pt-10">
                           <div class="col-md-12 button-group text-center TT-formGroup">
                              <button id="approveAll" disabled class="btn btn-primary btn-new"
                                 (click)="approveCompleteProfile()" trans="Accept"
                                 class="btn TT-primaryBTN" style="width:auto;padding:8px 35px;">
                                 Accept
                              </button>
                              <button class="btn TT-primaryBTN" (click)="suspendUser()" trans="Suspend" style="background-color: #e29579;width:auto;padding:8px 35px;">
                                 Suspend
                              </button>
                              <button class="btn TT-primaryBTN" (click)="deleteUser()" trans="Delete" style="background-color: #ff6b6b;width:auto;padding:8px 35px;">
                                 Delete
                              </button>
                           </div>
                        </div>
                        <form *ngIf="selectedSection$ == 'newProfile'" (ngSubmit)="sendMessage()"
                           [formGroup]="sendMessageForm" role="form" class="form-horizontal">
                           <div class="row pt-10">
                              <div class="col-md-12">
                                 <label trans="Send Message">
                                    Send Message
                                 </label>
                                 <textarea formControlName="message" rows="6" cols="10" class="form-control"
                                    placeholder="Type here"></textarea>
                              </div>
                              <div *ngFor="let error of errorMessages.message">
                                 <ng-container *ngIf="message.hasError(error.type) && (message.touched)">
                                    <small class="missindDetails">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <br>
                           <div class="row">
                              <div class="form-group">
                                 <div class="TT-formGroup" style="text-align: center;">
                                    <button class="btn TT-primaryBTN" type="submit" trans="Send" style="width:auto;padding:8px 35px">
                                       Send
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                     <div id="application" class="tab-pane fade Fcolor">
                        <div class="row">
                           <div *ngIf="tutorProfile" class="col-md-12 new_Scroll">
                              <span>
                                 <b class="greyColor" trans="Display Name">Display Name</b>
                                 <br>
                                 <span class="BrownFont small">{{tutorProfile.display_name}}
                                 </span>
                              </span>
                              <br>
                              <hr>
                              <span>
                                 <b class="greyColor" trans="Health Line Section">Health Line Section</b>
                                 <br>
                                 <span class="BrownFont small">{{tutorProfile.highlight}}
                                 </span>
                              </span>
                              <br>
                              <hr>
                              <span>
                                 <b class="greyColor" trans="Price">Price</b>
                                 <br>
                                 <span
                                    class="BrownFont small">{{tutorProfile.default_currency}}{{tutorProfile.hourly_rate}}
                                 </span>
                              </span>
                              <br>
                              <hr>
                              <span>
                                 <b class="greyColor" trans="Bio">Bio</b>
                                 <br>
                                 <span [innerHtml]="tutorProfile.description" class="BrownFont small">
                                 </span>
                              </span>
                              <br>
                              <span *ngIf="tutorCertificates && tutorCertificates.length > 0">
                                 <hr>
                                 <b class="greyColor" trans="Certificates">Certificates</b>
                                 <br>
                                 <ul *ngIf="tutorCertificates">
                                    <li *ngFor="let certificate of tutorCertificates">
                                       {{certificate.certificate_name}}
                                       <span *ngIf="certificate.certificate_proof"
                                          (click)="viewImage(certificate.certificate_proof , 'cer')" class="GreenColor">
                                          - View
                                          <i class="fa fa-external-link Ext_link"></i>
                                       </span>
                                    </li>
                                 </ul>
                              </span>
                              <span *ngIf="tutorEducations && tutorEducations.length > 0">
                                 <hr>
                                 <b class="greyColor" trans="Educations">Educations</b>
                                 <br>
                                 <ul *ngIf="tutorEducations">
                                    <li *ngFor="let education of tutorEducations">
                                       {{education.degree_name}}
                                       <span *ngIf="education.education_proof"
                                          (click)="viewImage(education.education_proof , 'edu')" class="GreenColor"> -
                                          View
                                          <i class="fa fa-external-link Ext_link"></i>
                                       </span>
                                    </li>
                                 </ul>
                              </span>
                              <span *ngIf="tutorVideo">
                                 <hr>
                                 <b class="greyColor" trans="Video">Video</b><br>
                                 <ul>
                                    <li *ngIf="tutorVideo['video_URL'] != ''">
                                       <span class="GreenColor" trans="View">View
                                          <a href="{{tutorVideo['video_URL']}}" target="_blank">
                                             <i class="fa fa-external-link Ext_link"></i>
                                          </a>
                                       </span>
                                    </li>
                                 </ul>
                              </span>
                              <span *ngIf="tutorLanguages && tutorLanguages.length > 0">
                                 <hr>
                                 <b class="greyColor" trans="Languages">Languages</b>
                                 <br>
                                 <ul>
                                    <li *ngFor="let language of tutorLanguages">
                                       {{language.language}} - {{language.level_name.language_level}}
                                    </li>
                                 </ul>
                              </span>
                              <span *ngIf="tutorSubjects && tutorSubjects.length > 0">
                                 <hr>
                                 <b class="greyColor" trans="Subjects">Subjects</b>
                                 <br>
                                 <ul *ngIf="tutorSubjects">
                                    <li *ngFor="let subject of tutorSubjects">
                                       {{subject.subject_data.subject_name}} - {{subject.tutor_specialities.level_name}}
                                    </li>
                                 </ul>
                              </span>
                              <span *ngIf="tutorPackages && tutorPackages.length > 0">
                                 <hr>
                                 <b class="greyColor" trans="Packages">Packages</b>
                                 <br>
                                 <ul *ngIf="tutorPackages">
                                    <li *ngFor="let package of tutorPackages">
                                       {{package.no_of_classes}} - {{package.price}}USD - {{package.final_price}}USD
                                    </li>
                                 </ul>
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>