import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-changes-approval',
  templateUrl: './changes-approval.component.html',
  styleUrls: ['./changes-approval.component.css']
})
export class ChangesApprovalComponent implements OnInit {

  @Output() selectedTutorId = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  public changesApproval$:any;
  @Input()
  public set changesApproval(value){
    if(value){
    this.changesApproval$ = value;
     }
  }

  constructor() { }

  ngOnInit(): void {
  }

  public viewApplication(id){
    this.selectedTutorId.emit(id);
  }

}
