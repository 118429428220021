  <div class="TT-tutorWrapper">  
      <ul class="TT-tutorTabs">
         <li (click)="selectTab('Pending Tutors')" class="tab-link TT-tutorList active" data-tab="1">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{pendingTutorsCount}}
               </div>
            </div>
            <span trans="New Tutors">New Tutors</span>
         </li>
         <li (click)="selectTab('Top Tutors')" class="tab-link TT-tutorList" data-tab="2">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{topTutorsCount}}
               </div>
            </div>
            <span trans="Top Tutors">Top Tutors</span>
         </li>
         <li (click)="selectTab('Accepted Tutors')" class="tab-link TT-tutorList" data-tab="3">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{acceptedTutorsCount}}
               </div>
            </div>
            <span trans="Accepted Tutors">Accepted Tutors</span>
         </li>
         <li (click)="selectTab('Suspended Tutors')" class="tab-link TT-tutorList" data-tab="4">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{suspendedTutorsCount}}
               </div>
            </div>
            <span trans="Suspended Tutors">Suspended Tutors</span>
         </li>
         <li (click)="selectTab('Deleted Tutors')" class="tab-link TT-tutorList" data-tab="5">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{deletedTutorsCount}}
               </div>
            </div>
            <span trans="Deleted Tutors">Deleted Tutors</span>
         </li>
         <li (click)="selectTab('Changes Approval')" class="tab-link TT-tutorList" data-tab="6">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{changesApprovalCount}}
               </div>
            </div>
            <span trans="Changes Approval">Changes Approval</span>
         </li>
         <li (click)="selectTab('All Tutors')" class="tab-link TT-tutorList" data-tab="7">
            <div class="teacher-badge TT-badgeRed">
               <div class="TT-messageCount">
                  {{allTutorsCount}}
               </div>
            </div>
            <span>All Tutors</span>
         </li>
      </ul>
      <!-- New Tutor Applications -->
      <div id="tab-1" class="tab-content active">
        
         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> New Tutor Applications </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedTutorsColumn($event.target.value ,'pendingTutorsSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                     <option value=" " trans="Select Column">Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                        {{item.key}}
                     </option>
                  </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="pendingTutorsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort('New Tutors')" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

          <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-pending-tutors [pendingTutors]="pendingTutors" (selectedTutorId)="tutorId($event)"></app-pending-tutors>
      </div>

      <!-- End of tab one  -->           
               
      <!-- topTutors -->
      <div id="tab-2" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers">  {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedTutorsColumn($event.target.value ,'topTutorsSearchInput')" class="TT-FieldGroup TT-SelectIcon">
                     <option value='' trans="Select Column">Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                        {{item.key}}
                     </option>
                  </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" type="text" class="TT-FieldGroup" id="topTutorsSearchInput" disabled placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort(tabName)" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-top-tutors [topTutors]="topTutors" (selectedApplicationTutorId)="tutorId($event)"></app-top-tutors>
      </div>

      <!-- acceptedTutors -->
      <div id="tab-3" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers">  {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                  <select (input)="selectedTutorsColumn($event.target.value ,'acceptedTutorsSearchInput')"
                     class="TT-FieldGroup TT-SelectIcon">
                     <option value='' trans="Select Column">Select Column</option>
                     <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                        {{item.key}}
                     </option>
                  </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" title="Select Column" id="acceptedTutorsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort(tabName)" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-accepted-tutors (adminMessageModalData)="messageModal($event)" (reloadTutors)="reloadTutors($event)"
            [acceptedTutors]="acceptedTutors" (selectedApplicationTutorId)="tutorId($event)"></app-accepted-tutors>
      </div>
      <!-- suspendedTutors -->
      <div id="tab-4" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="d-flex TT-formGroup">
               <select (input)="selectedTutorsColumn($event.target.value ,'suspendedTutorsSearchInput')" id="saleTransactions" class="TT-FieldGroup TT-SelectIcon">
                <option value=''>Select Column</option>
                <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                 {{item.key}}
                </option>
               </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">                  
                  <input [formControl]="searchControl" title="Select Column" id="suspendedTutorsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort(tabName)" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-suspended-tutors (adminMessageModalData)="messageModal($event)" (reloadTutors)="reloadTutors($event)"
            (selectedApplicationTutorId)="tutorId($event)" [suspendedTutors]="suspendedTutors"></app-suspended-tutors>
      </div>
      <!-- COMING SOON -->
      <div id="tab-5" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="d-flex TT-formGroup">
               <select (input)="selectedTutorsColumn($event.target.value ,'deletedTutorsSearchInput')" id="saleTransactions" class="TT-FieldGroup TT-SelectIcon">
                <option value=''>Select Column</option>
                <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                 {{item.key}}
                </option>
               </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">                  
                  <input [formControl]="searchControl" title="Select Column" id="deletedTutorsSearchInput" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here" />
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort(tabName)" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-deleted-tutors (adminMessageModalData)="messageModal($event)" (reloadTutors)="reloadTutors($event)"
            [deletedTutors]="deletedTutors"></app-deleted-tutors>
      </div>

      <div id="tab-6" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers">  {{tabName}} </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="d-flex TT-formGroup">
               <select (input)="selectedTutorsColumn($event.target.value ,'tutorChangesApproval')" class="TT-FieldGroup TT-SelectIcon">
                 <option value='' trans="Select Column">Select Column</option>
                 <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                   {{item.key}}
                 </option>
                </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" type="text" class="TT-FieldGroup" id="tutorChangesApproval" placeholder="Search Here" trans-placeholder="Search here">
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort(tabName)" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-changes-approval (selectedTutorId)="tuorViewApplicationId($event)" [changesApproval]="changesApproval">
         </app-changes-approval>
      </div>

      <div id="tab-7" class="tab-content">

         <div class="TT-templateDraft">
            <div class="row m-auto">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h4 class="TT-AccSettings TT-spacers"> List of All Tutors </h4>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
               <div class="d-flex TT-formGroup">
                <select (input)="selectedTutorsColumn($event.target.value ,'allTutors')" class="TT-FieldGroup TT-SelectIcon">
                 <option value='' trans="Select Column">Select Column</option>
                 <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
                  {{item.key}}
                 </option>
                </select>
               </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 clearfix">
               <div class="d-flex TT-formGroup TT-searchFind pull-right">
                 <div class="TT-formGroup">
                  <input [formControl]="searchControl" id="allTutors" disabled type="text" class="TT-FieldGroup" placeholder="Search Here" trans-placeholder="Search here"> 
                  <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                 </div>
                 <span (click)="csvExort(tabName)" class="TT-csvIcon">
                  <img src="assets/images/csv.png" width="30" alt="" class="img-responsive" />
                </span>
               </div>
              </div>
            </div>
          </div> 

         <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <app-all-tutors (reloadTutors)="reloadTutors($event)" (adminMessageModalData)="messageModal($event)"
            (selectedApplicationTutorId)="tutorId($event)" [allTutors]="allTutors"></app-all-tutors>
      </div>

      <div id="profileTab" class="tab-content">
         <div class="well well-sm teacher_head">
            <h4 class="main-Head-bg">
               <span (click)="selectTab(tabName)" class="tab-link active cursor" data-tab="1">{{tabName}}</span> >
               {{tutorName}}
            </h4>
         </div>
         <app-tutor-profile [tutorIdForResumeView]="tutorIdForResumeView"></app-tutor-profile>
      </div>
   </div>

<app-new-application (viewTutorResumeCom)="viewTutorResume($event)" (reloadTutors)="reloadTutors($event)"
   [selectedTutorId]="selectedTutorId"></app-new-application>
<app-view-application (reloadTutors)="reloadTutors($event)" (viewTutorResumeCom)="viewTutorResume($event)"
   [selectedTutorApplicationId]="selectedTutorApplicationId"></app-view-application>
<app-notes></app-notes>
<app-admin-messagemodal></app-admin-messagemodal>