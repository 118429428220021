<div class='col-sm-12 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
         <div *ngIf="teachingLoader" class="loader_Main_comp">
            <div class="loader-cal_comp"></div>
         </div>           
            <h4 class="title" trans="Add Teaching Specialities and Grade Levels">Add Teaching Specialities and Grade Levels</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <form [formGroup]="specialitiesForm" class="col-md-6">
         <table class="table lesson-table noBorderTable">
            <thead>
               <tr>
                  <td>
                     <div class="form-group CCnr-height">
                        <input type="text"  formControlName="speciality" class="form-control"  placeholder="Speciality Name" style="border-radius:50px;">
                              <div *ngFor= "let error of errorMessages.speciality">
                                 <ng-container *ngIf="speciality.hasError(error.type) && (speciality.dirty || speciality.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>                        
                     </div>
                  </td>
                  <td class="addRow">
                     <div class="TT-clickableField plus_top">
                     <button (click)="addSpeciality()"><i class="fa fa-plus-circle TT-addRow" aria-hidden="true"></i></button>
                  </div>
                  </td>                  
               </tr>
               <tr>
                  <th width="94%" trans="Speciality Name">Speciality Name</th>
                  <th></th>
               </tr>
            </thead>
            <tbody>
               <tr  *ngFor="let level of teachingSpecialities">
                  <td>{{level.level_name}}</td>
                  <td>
                     <div class="TT-clickableField">
                      <i class="fa fa-times TT-delRow" (click)="deleteSpeciality(level.id)" aria-hidden="true"></i>
                      </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </form>
      <form [formGroup]="studentAgeForm" class="col-md-6">
         <table class="table lesson-table noBorderTable">
            <thead>
               <tr>
                  <td>
                     <div class="form-group CCnr-height">
                        <input type="text" formControlName="student_age" class="form-control" placeholder="Grade Level" trans-placeholder="Grade Level" style="border-radius:50px;">
                              <div *ngFor= "let error of errorMessages.student_age">
                                 <ng-container *ngIf="student_age.hasError(error.type) && (student_age.dirty || student_age.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>  
                     </div>
                  </td>
                  <td class="addRow">
                     <div class="TT-clickableField plus_top">
                     <button (click)="addStudentAge()"><i class="fa fa-plus-circle TT-addRow" aria-hidden="true"></i></button>
                     </div>
                  </td>                  
               </tr>
               <tr>
                  <th width="94%" trans="Grade Level">Grade Level</th>
                  <th></th>
               </tr>
            </thead>
            <tbody>
               <tr  *ngFor="let age of studentAges">
                  <td>{{age.student_age}}</td>
                  <td>
                     <div class="TT-clickableField">
                      <i class="fa fa-times TT-delRow" (click)="deleteStudentAge(age)" aria-hidden="true"></i>
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </form>
   </div>
</div>

