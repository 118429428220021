

<div class='col-sm-10 rightCol'>
   <div class="well well-sm chat-head">
      <h4 class="title" trans="Localization">Localization</h4>
      <div class="subtitle" trans="Manage localization settings for the site">Manage localization settings for the site.</div>
   </div>
   <div class='content-container customTabs'>
      <form class="account-info">
         <div class="row formspace">
            <div class="col-sm-9">
               <div class="row formspace">
                  <div class="col-md-9">
                     <div class="form-group">
                        <label trans="TimeZone">TimeZone</label>
                        <select id="selectbasic" name="choose your timezone" class="form-control">
                           <option trans="Select">Select</option>
                           <option value="">1</option>
                           <option value="">2</option>
                        </select>
                        <div class="subtitle1" trans="Choose either a city in the same timezone as you or a UTC timezone offset"> Choose either a city in the same timezone as you or a UTC timezone offset.</div>
                     </div>
                  </div>
                  <div class="col-md-9">
                     <div class="form-group">
                        <label trans="Site Language">Site Language</label>
                        <select id="selectbasic" name="choose your timezone" class="form-control">
                           <option trans="Select">Select</option>
                           <option value="">1</option>
                           <option value="">2</option>
                        </select>
                        <div class="subtitle1" trans=" Which translation should be selected by default for new users"> Which translation should be selected by default for new users.</div>
                     </div>
                  </div>
                  <div class="col-md-9">
                     <div class="form-group">
                        <label trans="Date format">Date Format</label>
                        <select id="selectbasic" name="choose your timezone" class="form-control">
                           <option trans="Select">Select</option>
                           <option value="">1</option>
                           <option value="">2</option>
                        </select>
                        <div class="subtitle1" trans="Default format for dates on the site"> Default format for dates on the site. </div>
                        <div class="subtitle1" trans="Preview:"> Preview: 11/11/2020</div>
                     </div>
                  </div>
               </div>
               <button type="submit" class="btn btn-primary new" trans="Update">Update</button> 
            </div>
         </div>
      </form>
   </div>
</div>

