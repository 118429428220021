<div class='content-container customTabs max_Scroll'>

   <div class="row formspace">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
         <div class="table-responsive TT-responsiveTable">
         <table class="table table-striped TT-table">
            <thead>
               <tr>
                  <th trans="Username">Username</th>
                  <th trans="Email">Email</th>
                  <th trans="Timestamp">Timestamp</th>
                  <th trans="Location">Location</th>
                  <th trans="Registration Status">Registration Status</th>
                  <th trans="Actions" colspan="2">Actions</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let student of allStudents$">
                  <td><span>{{student.username}}</span></td>
                  <td><span>{{student.email}}</span></td>
                  <td>{{student.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
                  <td>{{student.country}}</td>
                  <td>{{student.registration_process}}</td>
                  <td class="TT-formGroup">
                     <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel"
                        aria-hidden="true" href="#studentProfile" (click)="viewApplication(student.id)"
                        class="btn TT-primaryBTN TT-topSpaceZero" value="Attend" data-target="#studentProfile" data-toggle="modal"
                        trans="View Application">
                        View</a>                  
                     </td>
                  <td class="dropdown TT-tableDropDownMenuList">                   
                     <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                        <li class="TT-optionsList" *ngIf="student.registration_process != 'Suspended'">
                           <a class="TT-frameSet" (click)="suspenduser(student.id, 'suspend')" trans="Suspend Student"> Suspend Student</a>
                        </li>
                        <li class="TT-optionsList" *ngIf="student.registration_process == 'Suspended'">
                          <a class="TT-frameSet" (click)="activestudent(student.id, 'active')" trans="Activate Student"> Activate
                              Student</a></li>
                        <li class="TT-optionsList">
                           <a class="TT-frameSet" (click)="deleteuser(student.id, 'delete')" trans="Delete Student">Delete Student</a></li>
                        <li class="TT-optionsList" (click)="messageModal(student)">
                           <a class="TT-frameSet" href="#adminMessageModal" data-toggle="modal" trans="Message Student">Message Student</a></li>
                     </ul>
                  </td>
               </tr>
               <br>
               <tr *ngIf="allStudents$ && allStudents$.length==0" class="textalign" trans="No records found">No records
                  found.</tr>
            </tbody>
         </table>
      </div>
   </div>

</div>
<app-datatable-footer-new *ngIf="allStudents$ && allStudents$.length > 0"></app-datatable-footer-new>