<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->
<div class='content-container customTabs'>
   <div class="row formspace">
      <div class="col-sm-12">
         <div class="table-responsive TT-responsiveTable">
         <table class="ttable table-striped TT-table TT-tableHeight">
            <thead>
               <tr>
                  <th trans="Tutor">
                     Tutor
                  </th>
                  <th trans="Student">
                     Student
                  </th>
                  <th trans="Subject">
                     Subject
                  </th>
                  <th trans="Timestamp">
                     Timestamp
                  </th>
                  <th trans="Rating">
                     Rating
                  </th>
                  <th>View</th>
                  <th trans="Actions">
                     Actions
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let review of topReviews$">
                  <td>
                     {{review.booking_details.tutor_resume.display_name || 'N/A'}}
                  </td>
                  <td>
                     <span *ngIf="review.booking_details && review.booking_details.student">
                        {{review.booking_details.student.username || 'N/A'}}
                     </span>
                  </td>
                  <td>
                     {{review.booking_details.subject.subject_name || 'N/A'}}
                  </td>
                  <td>
                     {{review.updated_at | date: 'MM-dd-yyyy h:mm a' || 'N/A'}}
                  </td>
                  <td>
                     {{review.rating || '0'}}/5
                  </td>
                  <td class="lesson-btn-actions dropdown TT-formGroup">
                     <a id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel"
                        aria-hidden="true" href="#adminReviewModal"
                        (click)="viewReview(review.review , review.booking_details.student.username)"
                        class="btn btn-teacher_admin" value="Attend" data-target="#adminReviewModal" data-toggle="modal"
                        trans=" View Review">
                        View</a>
                  </td>
                  <td class="dropdown TT-tableDropDownMenuList">
                     <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul class="dropdown-menu dropdown-new">
                        <li (click)="messageModal(review.booking_details.student , 'student')"><a
                              href="#adminMessageModal" data-toggle="modal" trans="Message Student" class="TT-frameSet">Message Student</a>
                        </li>
                        <li class="divider"></li>
                        <li (click)="messageModal(review.booking_details.tutor_resume , 'tutor')"><a
                              href="#adminMessageModal" data-toggle="modal" trans="Message Tutor"class="TT-frameSet" >Message Tutor</a></li>
                     </ul>
                  </td>
               </tr>
               <br>
               <tr *ngIf="topReviews$ && topReviews$.length==0" class="textalign" trans="No records found">No records
                  found.</tr>
            </tbody>
         </table>
      </div>
      </div>
   </div>