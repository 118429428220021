f<div class='col-sm-12 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title">
               <span class="title" trans="Page Settings">Page Settings</span>
            </h4>
         </div>
      </div>
   </div>
<div class='content-container customTabs'>
	 <form [formGroup]="pageSettingsForm" class="account-info" (ngSubmit)="pageSettings()">
	    <div class="row formspace">
         <div class="col-sm-12">
            <div class="row formspace">
              <div class="col-md-6">
                  <div class="form-group">
                     <label for="Footer Categories" trans="GUESTS Footer Categories">GUESTS Footer Categories</label>
                     <label class="pull-right small_small">[Only 3]</label>
                     <select formControlName="footerCategories" (click)="selectCategories($event.target.value)" class="form-control">
                        <option value="" trans="Please select"> Please Select</option>
                        <option [value]="category.id" *ngFor="let category of pageCategories$">{{category.title}}</option>
                     </select>
                  </div>
                  <div class="row" style="margin:6px;" *ngIf="footerCategories.length">
                     <div class="col-12">
                     <label trans="Selected Footer Categories">Selected Footer Categories</label>
                        <div *ngFor="let category of footerCategories" class="chip">
                           <span>{{category.title}}</span>
                           <span class="closebtn" onclick="this.parentElement.style.display='none'" (click)="removeCategory(category)">&times;</span>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-6">
                  <div class="form-group">
                     <label for="Footer Categories" trans="GUESTS Landing Page Category">GUESTS Landing Page Category</label>
                     <select formControlName="landing_page_category" class="form-control">
                        <option value="" trans="Please select"> Please Select</option>
                        <option [value]="category.id" *ngFor="let category of pageCategories$">{{category.title}}</option>
                     </select>
                  </div>
               </div>

           </div>
       </div>
   </div>
  <button type="submit" class="btn btn-primary new" trans="Update">Update</button>
</form>
</div>