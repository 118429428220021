import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from '../pages.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Bootstrapper } from '../../../core/bootstrapper.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-create-pages',
  templateUrl: './create-pages.component.html',
  styleUrls: ['./create-pages.component.css']
})
export class CreatePagesComponent implements OnInit {
  
  pageLoader$: boolean;
  imageURL: string;
  // WelcomeForm Validators and Fields
  public pageForm = this.fb.group({
    'title': ['',Validators.required],
    'category_id': ['',Validators.required],
    'user_role': ['', Validators.required],
    'display_order': ['', Validators.required],
    'content': ['', Validators.required],
    'sub_title':['',Validators.required],
    'page_route':['',Validators.required],
    'page_image':['',RxwebValidators.extension({extensions:["jpg","jpeg","png"]})],
    'broadCast':[''],
    'image':['']
  });


  // GET Form Values HERE
  get title(){ return this.pageForm.get('title');}
  get category_id(){ return this.pageForm.get('category_id');}
  get user_role(){ return this.pageForm.get('user_role');}
  get display_order(){ return this.pageForm.get('display_order');}
  get content(){ return this.pageForm.get('content');}
  get sub_title(){ return this.pageForm.get('sub_title');}
  get page_route(){ return this.pageForm.get('page_route');}
  get page_image(){ return this.pageForm.get('page_image');}
  get broadCast(){ return this.pageForm.get('broadCast');}

  public categories;
  public user_roles;
  public pageId;
  public givenChracters:number;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  public fileToUpload: File = null;



  // Error Messages For Input Fields
  public errorMessages = {
                title: [{type:'required',message:'Field required'}],
                category_id: [{type:'required',message:'Field required'}],
                user_role: [{type:'required',message:'Field required'}],
                display_order: [{type:'required',message:'Field required'}],
                content: [{type:'required',message:'Field required'}],
                sub_title: [{type:'required',message:'Sub title is required'}],
                page_route: [{type:'required',message:'Page route is required'}],
                page_image: [{ type:'required', message:'Provide image'},
                                  { type:'extension',message:'Invalid extension'}]
                }

  constructor(private fb: FormBuilder,
  			private toastr: ToastrService,
  			private service: PagesService,
  			private bootstrapper: Bootstrapper,
  			private _location: Location,
  			private router: Router,
        private route: ActivatedRoute
  	) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.pageId = params.get('pageId');
      this.service.getPage(this.pageId).subscribe(response => {
        this.imageURL = response['data']['page_image_url'];
        this.pageForm.patchValue({
          title:response['data']['title'],
          category_id:response['data']['category']['id'],
          user_role:response['data']['role']['id'],
          display_order:response['data']['display_order'],
          content:response['data']['content'],
          sub_title:response['data']['sub_title'],
          page_route:response['data']['page_route'],
          page_image:response['data']['page_image'],
        });
      });
    });
    
  	this.user_roles = this.bootstrapper.data.user_roles;
  }

  ngOnInit(): void {
  	this.service.getPageCategories()
  	.subscribe(response => {
  		this.categories = response['data'];
  	});
  }

   public countCharacters(values){
    if(values){
       let charactersEntered = values;
       this.givenChracters = charactersEntered.length;
    }
  }


  // This method will add Success Stories Links
  public storePage(){
	this.pageForm.markAllAsTouched();
  if(this.pageForm.valid){
    this.pageLoader$ = true;
    var myFormData = new FormData();
    myFormData.append('title', this.pageForm.value.title);
    myFormData.append('category_id',this.pageForm.value.category_id);
    myFormData.append('user_role',this.pageForm.value.user_role);
    myFormData.append('display_order',this.pageForm.value.display_order);
    myFormData.append('content',this.pageForm.value.content);
    myFormData.append('sub_title',this.pageForm.value.sub_title);
    myFormData.append('page_route',this.pageForm.value.page_route);
    myFormData.append('broadCast', this.pageForm.value.broadCast);
    myFormData.append('page_image',this.pageForm.get('image').value);
  if(this.pageId != null)
  {
    this.service.updatePage(myFormData,this.pageId)
  .subscribe(response => {
    if(response.status == 'success')
    {
      this.pageForm.reset();

      this.toastr.success('Page has been added successfull');

      this.router.navigate(['admin/pages/pages-list']);
    }  else if (response.status == 'danger'){
      this.pageLoader$ = false;
      this.toastr.error(response.error);
      }
  });
  }

  else{

	this.service.storePage(myFormData)
	.subscribe(response => {
		if(response.status == 'success')
		{
			this.pageForm.reset();
			this.toastr.success('Page has been added successfull');
			this.router.navigate(['admin/pages/pages-list']);
		}  else if (response.status == 'danger'){
      this.pageLoader$ = false;
      this.toastr.error(response.error);
    }
	});
  }
  }
}

        public fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            const reader = new FileReader();

            reader.onload = (e: any) => {
                let file_type = fileInput.target.files[0].type;
                if ( file_type == 'application/pdf' ) {
                  const imgBase64Path = e.target.result;
                  this.cardImageBase64 = imgBase64Path;
                  this.isImageSaved = true;
                  this.fileToUpload = fileInput.target.files[0];
                  this.pageForm.patchValue({
                    image: this.fileToUpload

                  });
                } else {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimentions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        this.fileToUpload = fileInput.target.files[0];
                        this.pageForm.patchValue({
                          image: this.fileToUpload
                        });
                    }
                };
              }
            };

            reader.readAsDataURL(fileInput.target.files[0]);
            $('#removeImage').show();
        }
    }


}
