import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { StudentsService } from '../students.service';
import { ToastrService } from 'ngx-toastr';
declare var $;

@Component({
  selector: 'app-all-students',
  templateUrl: './all-students.component.html',
  styleUrls: ['./all-students.component.css']
})
export class AllStudentsComponent implements OnInit {

  @Output() adminMessageModalData = new EventEmitter();
  @Output() reloadStudents = new EventEmitter();
  @Output() viewStudentApplication = new EventEmitter();
 public allStudents$:any;
 @Input() 
  public set allStudents(value){
    if(value){
    this.allStudents$ = value;
   }
  }

  constructor(public service: StudentsService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

    public viewApplication(studentId){
    this.viewStudentApplication.emit(studentId);
  }

  public suspenduser(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
       this.toastr.success('User suspended successfully');
       this.reloadStudents.emit(true);
    })
   }

   public deleteuser(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
    this.toastr.success('User deleted successfully');
    this.reloadStudents.emit(true);
    })
   }

  public activestudent(studentid, operation){
    let data = Object();
    
    Object.assign(data , {studentid: studentid});
    Object.assign(data , {operation: operation});
     this.service.suspendordeleteuser(data).subscribe(response => {
       this.reloadStudents.emit(true);
       this.toastr.success('User activated');
    })
   }

   public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }
}
