import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../payments.service';

@Component({
  selector: 'app-transaction-log',
  templateUrl: './transaction-log.component.html',
  styleUrls: ['./transaction-log.component.css']
})
export class TransactionLogComponent implements OnInit {
  public transactionlog;
  constructor(private service: PaymentsService) { }

  ngOnInit(): void {
  	this.service.TransactionLog().subscribe(response =>{
      this.transactionlog =  JSON.parse(atob(response['transactionlog']));
    });		
  }
  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}
