<div class="modal fade" id="modalConfirmation" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
    <div class="modal-dialog" role="document" style="margin-top: 100px;">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            	<h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans=" Database reset"> 
                Database reset
            </h5>
            <button type="button" class="close" #closeDbResetModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
                <span aria-hidden="true">
                    &times;
                </span>
            </button>
            <div class="modal-body" >
            	 <div class="row formspace">
		            <div class="col-sm-12">
						<div class="form-group">
							<label trans="Are you sure you want to reset databse? Once reset this action cannot be reverted">Are you sure you want to reset databse? Once reset this action cannot be reverted.</label>
						</div>
					</div>
				
				
				<form class="col-md-12" [formGroup]="factoryResetForm">
					<div class="row col-md-12">
						<div class="form-group col-sm-6">
						   <label for="message" trans="Reset Key">Reset Key</label>
						   <input   id="resetKey" class="form-control" formControlName="resetKey" placeholder="Reset Key" trans-placeholder="resetKey">
						</div>
						<div class="form-group col-sm-6">
						   <label for="message" trans="Reset Type">Reset Type</label>
						   <input  id="resetType" class="form-control" formControlName="resetType" placeholder="Reset Type" trans-placeholder="resetType">
						</div>
					</div>
				
					<div class="row col-md-12">
						<div class="form-group col-sm-4">
							<button (click)="CloseModal()" type="submit" class="btn btn-primary btn-new" trans="Cancel">Cancel</button>
						</div>
						<div class="form-group col-sm-4"></div>
						<div class="form-group col-sm-4">
							<button (click)="factoryReset()" type="submit" class="btn  btn-Orange btn-new" trans="Reset">Reset</button>
						</div>
					</div>
				</form>
			</div>
	        </div>
        </div>
    </div>
</div>
