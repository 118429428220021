<div class="left-container">
   <div class="new-localization" >
      <h5><b trans="Payments">Payments</b> 
      </h5>
   </div>
   <ul class="lesson-list" >
      <li routerLink="transactions" routerLinkActive="selectedTeaching" class="hoverselectedTeaching">
         <a trans="Booking Payments">Booking Payments</a>
      </li>
       <li routerLink="transaction-log" routerLinkActive="selectedTeaching" class="hoverselectedTeaching">
         <a trans="Transaction Log">Transaction Log</a>
      </li>
   </ul>
</div>

