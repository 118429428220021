import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LessonsService } from '../../../student/lessons/lessons.service';

@Component({
  selector: 'app-all-lessons',
  templateUrl: './all-lessons.component.html',
  styleUrls: ['./all-lessons.component.css']
})
export class AllLessonsComponent implements OnInit {
  
  @Output() directSingleCancellation = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  @Output() reviewModalData = new EventEmitter();
  public allLessons$:any;
  @Input() 
  public set allLessons(value){
    this.allLessons$ = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

  public viewReview(review , username){
    this.reviewModalData.emit({review:review , name:username});
  }

  public messageModal(userData , role){
    if(role == 'student'){
    this.adminMessageModalData.emit(userData);
    }else{
    this.adminMessageModalData.emit({username:userData.display_name , id:userData.user_id});
    }
  }

    public directSingleCancellation$(bookingID,bookingDateId){
    this.directSingleCancellation.emit({bookingID:bookingID , bookingDateId:bookingDateId});
  } 

  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}
