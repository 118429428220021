<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->
<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
         <table class="ttable table-striped TT-table TT-tableHeight"> 
            <thead>
               <tr>
                  <th trans="Amount">
                     Amount
                  </th>
                  <th trans="Payment Method">
                     Payment Method
                  </th>
                  <th trans="Transaction Id">
                     Transaction Id
                  </th>
                  <th trans="Status">
                     Status 
                  </th>
                  <th trans="Student">
                     Student
                  </th>
                  <th trans="Time Stamp">
                     Time Stamp
                  </th>
<!--                   <th>
                     Actions
                  </th> -->
               </tr>
               </thead>
               <tbody>
               <tr *ngFor="let tran of creditTransactions$ | async">
                  <td *ngIf="tran.amount;else empty_record">
                     {{tran.amount}}
                  </td>
                  <td>
                     {{tran.gateway}}
                  </td>
                  <td>
                     {{tran.transaction_id}}
                  </td>
                  <td>
                     OUT
                  </td>
                  <td *ngIf="tran.student;else empty_record">
                     {{tran.student.username}}
                  </td>
                   <td>
                     {{tran.created_at | date: 'MM-dd-yyyy h:mm a'}}
                  </td>
<!--                <td class="lesson-btn-actions dropdown">
                  <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
                  <ul class="dropdown-menu dropdown-new ">
                     <li (click)="messageModal(tran.student)"><a href="#adminMessageModal" data-toggle="modal">Message Student</a></li>
                  </ul>
               </td> -->
               </tr>
            </tbody>
         </table>
         <app-datatable-footer-new></app-datatable-footer-new>
   </div>
  </div>
</div>
<ng-template #empty_record>-</ng-template>