import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentsService {

 static BASE_URI = 'students';
  constructor(private http: AppHttpClientService
  			  ) { }


  	public refreshedvalues = new BehaviorSubject(Array);
    sharedrefreshedvalues = this.refreshedvalues.asObservable();


    public allvaluesrefresh(displayname){
     this.refreshedvalues.next(displayname);
    }

    // pendingTutors
	public allstudentsCount(){
		return this.http.get('allstudentsCount');
	}

	public saveStudentVideoCallSettings(payload: Object){
		return this.http.post('saveStudentVideoCallSettings', payload);
	}

	public getStudentVideoCallSettings() {
		return this.http.get('getStudentVideoCallSettings');
	}

	public suspendordeleteuser(payload) {
		return this.http.post('changestatussuspendordelete', payload);
	}

	public bulkloadmessages(credentials: Object){
        return this.http.post('studentbulkloadmsg', credentials);
    }

    public compliantActon(payload: Object){
        return this.http.post('compliantActon',payload);
    }

    public getStudentprofle(studentId){
      if(studentId){
    	return this.http.get('studentnewApplication/'+studentId);
       }
    }

    public cancelDirectBooking(payload){
    	return this.http.post('adminCancelDirectBooking', payload);
    }
}
