import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TeachersService } from '../teachers.service';

@Component({
  selector: 'app-accepted-tutors',
  templateUrl: './accepted-tutors.component.html',
  styleUrls: ['./accepted-tutors.component.css']
})
export class AcceptedTutorsComponent implements OnInit {
  
  @Output() reloadTutors = new EventEmitter();
  @Output() selectedApplicationTutorId = new EventEmitter();
  @Output() adminMessageModalData = new EventEmitter();
  public acceptedTutors$:any;
  @Input() 
  public set acceptedTutors(value){
    if(value){
    this.acceptedTutors$ = value;
     }
  }

  constructor(public service: TeachersService,private toastr: ToastrService
    ) { }

  ngOnInit(): void {
  }

  public viewApplication(id , $sectionType){
    this.selectedApplicationTutorId.emit({id:id,sectionType:$sectionType});
  }


  public suspenduser(tutorId, operation){
    let data = Object();
  
    Object.assign(data , {tutorId:tutorId});
     this.service.suspendUser(data).subscribe(response => {
       this.reloadTutors.emit(true);
       this.toastr.success('User activated');
    })
   }

   public deleteuser(tutorId, operation){
    let data = Object();
    
    Object.assign(data , {tutorId:tutorId});
     this.service.deleteUser(data).subscribe(response => {
       this.reloadTutors.emit(true);
       this.toastr.success('User deleted');
    })
   }

    public messageModal(userData){
    this.adminMessageModalData.emit(userData);
  }


}
