<div class="container-padding TT-tabNavBar">
 <nav class="navbar navbar-default TT-navBarDefaultTab">  
     
      <div class="collapse navbar-collapse TT-navbarCollapseTab" id="bs-example-navbar-collapse-1">
         <ul class="nav navbar-nav TT-navNavTabb">
            <li *ngIf="currentuser.hasPermissions(['teachers.registration.access'])" routerLink="registration" routerLinkActive="selectedMENU" class="TT-listingArea">
               <a class="TT-navLinkArea">Tutor Registrations</a>
            </li>
            <li *ngIf="currentuser.hasPermissions(['teachers.settings.access'])"  routerLink="settings" routerLinkActive="selectedMENU" class="TT-listingArea" >
               <a class="TT-navLinkArea">Tutor Settings</a>
            </li>                                      
         </ul>
      </div><!-- /.navbar-collapse -->  
   </nav>
</div>