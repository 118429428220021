<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->
<div class='content-container customTabs'>
    <div class="row formspace">
       <div class="col-sm-12">
        <a class="btn btn-success pull-right" (click)="clearAll()" style="background-color: #371084;" trans="Clear all">Clear all</a>  
        <table class="table lesson-table">
             <tbody>
                <tr>
                   <th trans="Timestamp">
                      Timestamp
                   </th>
                   <th trans="Env">
                      Env
                   </th>
                   <th trans="Type">
                      Type
                   </th>
                   <th trans="Message">
                      Message
                   </th>
                   <th trans="Actions">
                      Actions
                   </th>
                </tr>
                <div *ngIf="experiencedErrors$ && experiencedErrors$.length>0">
                <tr *ngFor="let row of experiencedErrors$">
                   <td >
                      {{row.timestamp | date: 'MM-dd-yyyy h:mm a' || 'N/A'}}
                   </td>
                   <td >
                        {{row.env || 'N/A'}}
                   </td>
                   <td>
                    <span [ngClass]="{'btn btn-danger': (row.type == 'ERROR' || row.type == 'EMERGENCY' || row.type == 'CRITICAL'), 'btn btn-warning' : row.type === 'WARNING', 'btn btn-info' : (row.type === 'INFO' || row.type === 'ALERT' || row.type === 'NOTICE' || row.type === 'DEBUG') }">
                    {{row.type || 'N/A'}}
                    </span>  
                   </td>
                   <td>
                      {{row.message || 'N/A'}}
                   </td>
                   <td>
                       <a (click)="deleteFile()">{{row.filename}}</a></td>
                </tr>
              </div>
                      <br>
       <tr *ngIf="experiencedErrors$ && experiencedErrors$.length==0" class="textalign" trans="No records found">No records found.</tr>
             </tbody>
          </table>
    </div>
 </div>