<div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" #closeModal tabindex="-1" id="adminMessageModal" class="modal fade" style="display: none;">
   <div class="modal-dialog-centered TT-modalMainArea" role="document">
      <div class="modal-content TT-modelCover">
         <div class="modal-header">            
            <h4 class="modal-title TT-AccUsers">Messaging {{selectedTutorName}}</h4>
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button" style="margin-top:-30px;">×</button>
         </div>
         <div class="modal-body">
            <form role="form" class="form-horizontal">
               <div class="form-group">
                  <p class="greycolor"> &nbsp; <span trans="Your Message">Your Message </span>(300)</p>
                  <div class="col-lg-12">
                     <textarea id="message" (input)="messageText($event.target.value)" rows="10" cols="30" class="form-control" placeholder="Type Something Here..." trans-placeholder="Type Something Here..."></textarea>
                  </div>
               </div>
               <div class="form-group">
                  <div class="TT-formGroup" style="text-align: center;">
                     <button disabled (click)="sendMessage()" id="sendBtn" class="TT-primaryBTN" type="submit" trans="Send" style="width:auto;padding:8px 35px;margin-top:20px;">Send</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>