<!-- All the money that comes to admin through lesson purchase i.e IN  and OUT -->

<div class="row m-auto">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="table-responsive TT-responsiveTable">
         <table class="ttable table-striped TT-table TT-tableHeight"> 
            <thead>
               <tr>
                  <th trans="Main Amount"> Main Amount </th>
                  <th trans="Amount"> Amount </th>
                  <th trans="Commission"> Commission </th>
                  <th trans="Standard Fee"> Standard Fee </th>
                  <th trans="Lesson Id"> Lesson Id </th>
                  <th trans="Booking Id"> Booking Id </th>
                  <th trans="Booking Time"> Booking Time </th>
                  <th trans="Booking Date"> Booking Date </th>
                  <th trans="Time Stamp"> Time Stamp </th>
               </tr>
               </thead>
               <tbody>
               <tr *ngFor="let tran of lessonTransactions$| async">
                  <td>
                     {{tran.tutor_amount}}
                  </td>
                  <td>
                     {{tran.payment_amount || "N/A"}}
                  </td>
                  <td>
                     {{tran.current_commission_percentage || "N/A"}}
                  </td>
                  <td>
                     {{tran.current_standard_fee || "N/A"}}
                  </td>
                  
                  <td>
                     {{tran.booking_dates_id}}
                  </td>
                  <td>
                     {{tran.booked_id}}
                  </td>
                  <td>
                     {{tran.booked_time}}
                  </td>
                  <td>
                     {{tran.slot_date}}
                  </td>
                   <td>
                     {{tran.created_at | date: 'MM-dd-yyyy h:mm a'}}
                  </td>
               </tr>
            </tbody>
         </table>
         <app-datatable-footer-new></app-datatable-footer-new>
   </div>
   </div>
   </div>