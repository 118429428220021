import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { TeachersService } from '../teachers.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CurrentUser } from '../../../auth/current-user';
import { randomString } from '../../../core/utils/random-string';
import { FinanceService } from '../../finance/finance.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-new-application',
  templateUrl: './new-application.component.html',
  styleUrls: ['./new-application.component.css']
})
export class NewApplicationComponent implements OnInit {
 
  public tutorId:number;
  public tutorProfile:any;
  public tutorAvailability:any;
  public tutorCertificates:any;
  public tutorEducations:any;
  public tutorVideo:any;
  public tutorSubjects:any;
  public tutorPackages:any;
  public tutorIdProof:any;
  public tutorLanguages:any;
  public newAppplicationLoader:boolean = false;
  public username;
  public display_tutor_profile;
  public selectedSection$:string;
   
  @Output() viewTutorResumeCom = new EventEmitter();
  @Output() reloadTutors = new EventEmitter();
  @ViewChild('closeNewApplicationModal') closeNewApplicationModal: ElementRef;
 @Input()
  public set selectedTutorId(val) {
  	if(val){
      this.tutorId = val.id;
      this.selectedSection$ = val.sectionType;
      this.newApplicationDetails(val.id , val.sectionType);
     }
  }


  public sendMessageForm = this.fb.group({
      message: ['', Validators.required],
  });

  get message(){ return this.sendMessageForm.get('message');}

    public errorMessages = {
           message :[{ type:'required',message:'Message is required'}],
         }

  constructor(private router: Router, public service: TeachersService,public finance:FinanceService,private fb: FormBuilder,private toastr: ToastrService,public currentuser: CurrentUser) { }

  ngOnInit(): void {
  }

    public newApplicationDetails(id , type){
       this.newAppplicationLoader = true;
  	   this.service.newApplication(id , type).subscribe(response=>{
          response =  JSON.parse(atob(response['data']));
          this.updateValues(response);
          this.newAppplicationLoader = false;
       });
  }

  public updateValues(response){
    this.tutorProfile = response['userProfile'];
    this.tutorAvailability = response['availabilitys'];
    this.tutorCertificates = response['certificates'];
    this.tutorEducations = response['educations'];
    this.tutorVideo = response['video'];
    this.tutorLanguages = response['languages'];
    this.tutorSubjects = response['subjects'];
    this.tutorPackages = response['packages'];
    this.tutorIdProof = response['idProof'];
    this.checkStatus();
  }

  public viewImage(imagePath , type){
   let url = location.origin + '/secure/viewImage/' +imagePath;
   window.open(url,'_blank');
  }

  public checkStatus(){
    if(this.tutorProfile.admin_status == 'Approved' && 
       // this.tutorAvailability && this.tutorAvailability.admin_status == 'Approved' && 
       this.tutorVideo && this.tutorVideo.admin_status == 'approved' &&
       this.tutorLanguages && this.tutorSubjects){
          $('#approveAll').prop('disabled',false);
    }
  }

    public displayProfile() {
      // this.service.SharedId(this.tutor);
      // this.service.IsDisplayProfile('yes')
      // if(this.display_tutor_profile == 'yes')
      // {

      //   this.closeNewApplicationModal.nativeElement.click();

      // }
    }

  public updateApplication(id, section, status){
    this.newAppplicationLoader = true;
    let data = Object();
    Object.assign(data ,{ recordId: id, section:section, status: status, tutorId:this.tutorProfile.user_id });
    this.service.updateProfile(data).subscribe(response =>{
          let res =  JSON.parse(atob(response['data']));
          if(response.status == 'success'){
          this.toastr.success('Application Updated Successfully');
          this.updateValues(res);
          this.newAppplicationLoader = false;
        }
    });
  }

  public approveCompleteProfile(){
    this.newAppplicationLoader = true;
    let data = Object();
    Object.assign(data , {tutorId: this.tutorId});
    this.service.approveCompleteProfile(data).subscribe(response =>{
      let res = JSON.parse(atob(response['data']));
      this.closeNewApplicationModal.nativeElement.click();
      this.toastr.success('User accepted successfully');
      this.reloadTutors.emit(true);
    });
  }

  public suspendUser(){
    this.newAppplicationLoader = true;
    let data = Object();
    Object.assign(data , {tutorId: this.tutorProfile.user_id });
    this.service.suspendUser(data).subscribe(response =>{
      if(response.status == 'success'){
      this.closeNewApplicationModal.nativeElement.click();
      this.toastr.success('User suspended successfully');
      this.reloadTutors.emit(true);
      }
    });
  }

  public deleteUser(){
    this.newAppplicationLoader = true;
    let data = Object();
    Object.assign(data , {tutorId:this.tutorProfile.user_id});
    this.service.deleteUser(data).subscribe(response =>{
      let res = JSON.parse(atob(response['data']));
      this.closeNewApplicationModal.nativeElement.click();
      this.toastr.success('User deleted successfully');
      this.reloadTutors.emit(true);
   });
  }

  public sendMessage() {
          this.sendMessageForm.markAllAsTouched();
        if ( this.sendMessageForm.value.message != '' ) {
          if(this.sendMessageForm.valid){
        let messageData = Object();
        Object.assign(messageData , {to_id:''+this.tutorId});
        Object.assign(messageData , {user:this.tutorProfile});
        Object.assign(messageData , {message:this.sendMessageForm.value.message});
        Object.assign(messageData , {senderName:this.currentuser.userName()});
        Object.assign(messageData , {randomMsgId : randomString(13)});
        Object.assign(messageData , {sendAsNotification: 'Yes'});
        this.finance.sendMessage(messageData).subscribe(response =>{
         $('#message').val('');
         if(response.status == 'success'){
          this.toastr.success('Message Sent Successfully');
          this.sendMessageForm.reset();
         }
        });
      }
      }else{
        this.toastr.error("Please fill the Message field");
      }
  }

  public viewTutorResume(tutorId , tutorDisplayName){
    this.closeNewApplicationModal.nativeElement.click();
    this.viewTutorResumeCom.emit({tutorId:tutorId , tutorName:tutorDisplayName});
  }

  public closeModal(){
    this.closeNewApplicationModal.nativeElement.click();
  }

}
