import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Role } from '../../core/types/models/Role';
import { DatatableService } from '../../core/datatable/datatable.service';
import { User } from '../../core/types/models/User';
import { Users } from '../../auth/users.service';
import { CurrentUser } from '../../auth/current-user';
import { RoleService } from './role.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Permission } from '../../core/types/models/Permission';
import { ValueLists } from '../../core/services/value-lists.service';
import { groupBy } from '../../core/utils/group-by';
import { flattenArray } from '../../core/utils/flatten-array';
import { SelectionModel } from '../../core/utils/SelectionModel';
import { Router } from '@angular/router';
import { TutorTeachingService } from '../../tutor/teaching/teaching.service';
import { CurdRoleModalComponent } from './curd-role-modal/curd-role-modal.component';
declare var $;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css'] ,
  providers: [DatatableService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesComponent implements OnInit {
@ViewChild(CurdRoleModalComponent) curdChild: CurdRoleModalComponent;
    public roles$ = new BehaviorSubject<Role[]>([]);
    public users$ = this.datatable.data$ as Observable<User[]>;
    public selectedRole$ = new BehaviorSubject<Role>(new Role());
    public allPermissions$ = new BehaviorSubject<{[key: string]: Permission[]}>({});
    public selectedRoleName$:string;

  constructor(
  	        public datatable: DatatableService<User>,
            public currentUser: CurrentUser,
            private roleApi: RoleService,
            private fb: FormBuilder,
            private values: ValueLists,
            private router: Router,
            public tutorteachingservice: TutorTeachingService
) { }

  ngOnInit(): void {
        this.refreshRoles().then(() => {
            this.datatable.init({
                uri: Users.BASE_URI,
                staticParams: {
                    role_id: this.selectedRole$.value?.id
                }
            });
        });
        this.fetchAllPermissions();
  }
    public tutorProfile(userId)
    {
        this.tutorteachingservice.selectedUserId$ = userId;
    }

   private fetchAllPermissions() {
        const valueListKey = 'permissions';
        this.values.get([valueListKey])
            .subscribe(response => {
           this.allPermissions$.next(groupBy<Permission>(response[valueListKey], 'group'));
            });
    }

    public refreshRoles() {
        return new Promise<void>(resolve => {
            this.roleApi.getRoles().subscribe(response => {
                this.roles$.next(response.pagination.data);
                if (this.roles$.value.length) {
                    // if no role is currently selected, select first
                    if ( ! this.selectedRole$.value.id) {
                        this.selectRole(this.roles$.value[0]);

                    // if role is selected, try to re-select it with the one returned from server
                    } else {
                        const role = this.roles$.value.find(r => r.id === this.selectedRole$.value.id);
                        if (role) {
                            this.selectedRole$.next(role);
                        }
                    }
                }
                resolve();
            });
        });
    }

        public selectRole(role: Role) {
        if (this.selectedRole$.value.id !== role.id) {
            this.datatable.addFilter('role_id',role.id);
            this.selectedRole$.next(role);
            this.selectedRoleName$ = role.name;
            this.refreshRoleUsers(role);
        }
    }

    public updateRole(role: Role) {
        this.curdChild.UpdateRoles(role)
    }

       public tutorRole(){
       if(this.selectedRole$['_value']['name']==='Tutor'){
        return true;
       }
       }


        public refreshRoleUsers(role: Role) {
        if (role.type === 'sitewide' && !role.guests) {
            this.datatable.reset({role_id: role.id});
        } else {
            this.datatable.reset({role_id: 999});
        }
    }

        public maybeDeleteRole(role: Role) {
        // this.modal.open(ConfirmModalComponent, {
        //     title: 'Delete Role',
        //     body:  'Are you sure you want to delete this role?',
        //     ok:    'Delete'
        // }).afterClosed().subscribe(confirmed => {
        //     if ( ! confirmed) return;
        // });
            this.deleteRole(role);
    }

        public deleteRole(role: Role) {
        this.roleApi.delete(role.id).subscribe(() => {
            this.selectedRole$.next(new Role());
            this.refreshRoles().then(() => {
                this.refreshRoleUsers(this.selectedRole$.value);
            });
        });
    }

     public reloadRoles(value){
      if(value == true){
        this.refreshRoles();
      }
     }
     public transform(value) {
        return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
      }
}
