<div aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="messageModal" class="modal fade">
   <div class="modal-dialog" style="margin-top: 200px;">
      <div class="modal-content" style="border-radius: 25px;">
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark">Messaging {{selectedTutorName}}</h4>
         </div>
         <div class="modal-body">
            <form role="form" class="form-horizontal">
               <div class="form-group">
                  <p class="greycolor"> &nbsp; <span trans="Your Message">Your Message </span>(300)</p>
                  <div class="col-lg-12">
                     <textarea id="message" (input)="messageText($event.target.value)" rows="10" cols="30" class="form-control" placeholder="Type Something Here..." trans-placeholder="Type Something Here..."></textarea>
                  </div>
               </div>
               <div class="form-group">
                  <div style="text-align: center;">
                     <button disabled (click)="sendMessage()" id="sendBtn" class="btn btn-primary btn-resize" type="submit" trans="Send">Send</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
<!-- <a #openLoginModal data-toggle="modal" data-target="#loginModal"></a> -->
<!-- <app-login></app-login> -->