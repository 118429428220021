<div aria-hidden="true" data-backdrop="false" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="permissionsRoleModal" class="modal fade" style="display: none;">
   <div class="permissions modal-dialog" >
      <div class="modal-content" >
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark" trans="Select Permissions">Select Permissions</h4>
         </div>
         <div class="permissions modal-body">
            <div *ngIf="permissions" class="tabs">
               <div class="tab"  *ngFor="let pair of allPermissions$ | async | keyvalue" [expanded]="false">
               <input class="permissionsInput" type="checkbox" id="{{pair.key}}">
               <label class="tab-label permissionsLabel" for="{{pair.key}}">{{pair.key}}</label>
               <div class="tab-content">
                  <ul class="unstyled-list selection-list">
                     <li class="list-item" *ngFor="let permission of pair.value">
                        <div class="row list-item-header">
                           <div class="meta col-sm-9">
                              <div class="list-item-name" >{{permission.display_name || permission.name}}</div>
                              <p *ngIf="permission.description" class="list-item-description" >{{permission.description}}</p>
                           </div>
                           <div class="col-sm-3">
                              <label class="switch">
                              <input type="checkbox" value="{{permission.name}}" [checked]="getValue(permission)">
                              <span class="slider round"></span>
                              </label>
                           </div>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="TT-formGroup">
         <button (click)="confirm()" type="submit" class="TT-primaryBTN" trans="Select">
         Select
         </button>
         
      </div>
      </div>
   </div>
</div>
</div>

