<div class="container-fluid padding0">
   <section id="findTutor-chat" class="pageSectionBg">
      <div class="TT-outlineLayers">
         <div class="row h-100">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xl-12">
               <!-- loader start-->
               <div *ngIf="!converstaionsLoader" class="loader_Main_comp">
                  <div class="loader-cal_comp"></div>
               </div>
               <!-- loader end -->
               <form class="TT-searchFind">
                  <div class="TT-formGroup">
                     <input id="filterInput" (input)="filterUserList($event.target.value)" type="text"
                        class="TT-FieldGroup" placeholder="Search" trans-placeholder="Search">
                     <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                  </div>
               </form>

               <div class="TT-chatBoxing">
                  <ul class="nav nav-tabs TT-tabbing" role="tablist" id="chatTabs">
                     <li class="nav-item TT-concept active">
                        <a class="TT-navLinkArea" data-toggle="tab" id="activeChatTab" href="#ActiveChat"
                           (click)="getActiveConversations(conversations['admin'].first_name , 'studentsTab')"
                           trans="Students">
                           Students
                        </a>
                     </li>
                     <li class="nav-item TT-concept">
                        <a class="TT-navLinkArea" data-toggle="tab" id="archiveChatTab" href="#ArchiveChat"
                           (click)="getArchiveConversations('tutorsTab')" trans="Tutors">
                           Tutors
                        </a>
                     </li>
                  </ul>
                  <div class="tab-content">
                     <div id="ActiveChat" class="chat__tab-content tab-pane active">
                        <div *ngIf="conversations" class="contact-area">
                           <div *ngFor="let list of studentsconversations">
                              <span ng-click="gotobottom()">
                                 <div id="{{list.id}}" (click)="selectUser(list.id , list.full_name, list)"
                                    class="chat__person-box" data-id="2" data-is_group="0" id="user-2"
                                    data-is_my_contact="1">
                                    <div class="position-relative chat__person-box-status-wrapper">
                                       <div *ngIf="list.isOnlineCheck==true"
                                          class="chat__person-box-status  chat__person-box-status--online">
                                       </div>
                                       <div *ngIf="list.isOnlineCheck==false"
                                          class="chat__person-box-status  chat__person-box-status--offline">
                                       </div>
                                       <div class="chat__person-box-avtar chat__person-box-avtar--active"
                                          *ngIf="list.avatar">
                                          <img src="{{list.avatar}}" alt="" class="user-avatar-img">
                                       </div>
                                       <div class="chat__person-box-avtar chat__person-box-avtar--active" *ngElse>
                                          <img src="{{list.avatar_url}}" alt="" class="user-avatar-img">
                                       </div>
                                    </div>
                                    <div class="chat__person-box-detail">
                                       <h5 class="mb-1 chat__person-box-name contact-name">
                                          {{list.username}}
                                       </h5>
                                       <p class="mb-0 chat-message"
                                          *ngIf="list.conversationsplice && list.conversationtype == '0'">
                                          {{list.conversationsplice}}
                                       </p>
                                       <p class="mb-0 chat-message" *ngIf="list.conversationtype == '1'">
                                          <img src="assets/images/photoimage.png" height="20px">&nbsp;Photo
                                       </p>
                                    </div>
                                    <div class="chat__person-box-msg-time">
                                       <div class="chat__person-box-time">
                                          {{list.updated_at| date : 'HH:mm'}}
                                       </div>
                                    </div>
                                 </div>
                              </span>
                           </div>
                        </div>
                     </div>
                     <div id="ArchiveChat" class="chat__tab-content tab-pane fade">
                        <div class="contact-area">
                           <div *ngFor="let list of archeiveChats">
                              <span ng-click="gotobottom()">
                                 <div id="{{list.id}}" (click)="selectUser(list.id , list.full_name, list)"
                                    class="chat__person-box" data-id="2" data-is_group="0" id="user-2"
                                    data-is_my_contact="1" ng-model="showscroll">
                                    <div class="position-relative chat__person-box-status-wrapper">
                                       <div *ngIf="list.isOnlineCheck==true"
                                          class="chat__person-box-status  chat__person-box-status--online">
                                       </div>
                                       <div *ngIf="list.isOnlineCheck==false"
                                          class="chat__person-box-status  chat__person-box-status--offline">
                                       </div>
                                       <div class="chat__person-box-avtar chat__person-box-avtar--active"
                                          *ngIf="list.avatar">
                                          <img src="{{list.avatar}}" alt="" class="user-avatar-img">
                                       </div>
                                       <div class="chat__person-box-avtar chat__person-box-avtar--active" *ngElse>
                                          <img src="{{list.avatar_url}}" alt="" class="user-avatar-img">
                                       </div>
                                    </div>
                                    <div class="chat__person-box-detail">
                                       <h5 class="mb-1 chat__person-box-name contact-name">
                                          {{list.username}}
                                       </h5>
                                       <p class="mb-0 chat-message"
                                          *ngIf="list.conversationsplice && list.conversationtype == '0'">
                                          {{list.conversationsplice}}
                                       </p>
                                       <p class="mb-0 chat-message" *ngIf="list.conversationtype == '1'">

                                          <img src="assets/images/photoimage.png" height="20px">&nbsp;Photo
                                       </p>
                                    </div>
                                    <div class="chat__person-box-msg-time">
                                       <div class="chat__person-box-time">
                                          {{list.updated_at| date : 'HH:mm'}}
                                       </div>
                                       <div class="chat__person-box-count"
                                          *ngIf="list.conversations_with_status.length != 0">
                                          <span>{{list.conversations_with_status.length}}</span>
                                       </div>
                                    </div>
                                 </div>
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-xs-12 TT-situation" *ngIf="showconversation == 'yes'">              
                <div *ngIf="messages" class="TT-boxChatPrior clearfix" id="scrollMe">

               <div *ngIf="!chatLoader" class="loader_Main_comp">
                 <div class="loader-cal_comp"></div>
               </div>
          
                 <div class="d-flex TT-profoling TT-noMoreBottom">
                   <img src="{{useravatar}}" class="img-responsive TT-chatBoxHeadline" alt="">
                    <h4 class="TT-AccSettings TT-spacers"> {{selectedUserName}} </h4>
                      <form class="TT-formGroup" *ngIf="searching == 'yes'"> 
                       <input (input)="filtermessages($event.target.value,messages.user.id)" type="text" class="TT-formField" placeholder="Search">
                       <i class="fa fa-search" aria-hidden="true"></i>
                     </form>
          
                   
                </div>
          
                <div class="row m-auto" id="conversation-container">
                 <cdk-virtual-scroll-viewport itemSize="2">
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngFor="let message of messages['conversations']">
                  <div class="row m-auto TT-chatArial" *ngIf="message.from_id==myId">
                     <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-xs-10">
                        <p *ngIf="message.message_type===0 && message.bookingId == null && message.bookingDateId == null"
                           class="TT-textMSG text-right">
                           {{message.message}}
                        </p>
                        <div *ngIf="message.message_type===0 && (message.bookingId != null || message.bookingDateId != null)" class="TT-messageMessGE">
                          <div class="TT-chatViewList clearfix">
                           <h5 class="TT-chatHighlight text-right"> {{message.message}} </h5>
                           <button
                              (click)="getSpecificViewDetails(message.bookingId , message.bookingDateId)"
                              class="btn TT-customBTNView pull-right full-width-chat" data-toggle="modal"
                              data-target="#viewModal" trans="View Details">
                              view Details
                           </button>
                          </div>
                          <!-- <p class="TT-chatttingTime text-right">
                           <small>{{message.created_at| date : 'MM-dd-yyyy HH:mm'}}</small>
                          </p>                                 -->
                        </div>
                        
                        <p class="TT-chatttingTime text-right">
                           <small>{{message.created_at | date : 'MM-dd-yyyy HH:mm'}}</small>
                         </p>
                        <p *ngIf="message.message_type==1" class="chat-highlight3 last3 pull-right thumbnail-wrap">
                           <img src="{{message.message}}" class="img-responsive" alt=""/>
                        </p>
                     </div>
                     <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-xs-2 TT-profoling">
                       <img src="{{currentuser.userAvater()}}" class="img-responsive TT-chatAvatar" alt="" />
                     </div>
                  </div>
                  <div class="row m-auto TT-chatArial" *ngIf="message.to_id==myId">
                     <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-xs-2 TT-profoling">
                        <img src="{{useravatar}}" class="img-responsive TT-chatAvatar" alt="" />
                     </div>
                     <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-xs-10">
                        <p *ngIf="message.message_type===0 && message.bookingId == null && message.bookingDateId == null" class="TT-textMSG">
                           {{message.message}}
                        </p>
                        
                        <p *ngIf="message.message_type==1" class="TT-chatPicture">
                           <img src="{{message.message}}" alt="" class="TT-pictureHeight"/>
                        </p>
                        <!-- <p class="TT-chatttingTime">
                          <small>{{message.created_at | date : 'MM-dd-yyyy HH:mm'}}</small>
                        </p> -->
                        <div *ngIf="message.message_type===0 && (message.bookingId != null || message.bookingDateId != null)"
                           class="row m-auto" style="padding-right:5px;">
                              <div class="TT-chatArial clearfix">
                                 <h5 class="TT-textMSG text-left"> {{message.message}} </h5>
                                 <button
                                    (click)="getSpecificViewDetails(message.bookingId , message.bookingDateId)"
                                    class="btn TT-customBTNView pull-left full-width-chat" data-toggle="modal"
                                    data-target="#viewModal" trans="View Details">
                                    View Details
                                 </button>
                              </div>
                              <!-- <p class="text-left">
                                <small>{{message.created_at | date : 'MM-dd-yyyy HH:mm'}}</small>
                              </p> -->
                        </div>
                        <p class="TT-chatttingTime">
                           <small>{{message.created_at | date : 'MM-dd-yyyy HH:mm'}}</small>
                         </p>
                     </div>
                  </div>         
               </div>
                </cdk-virtual-scroll-viewport>
                </div>
             
                <div class="row clearfix TT-chatFixed">
                  <div class="d-flex TT-formGroup TT-searchFind" style="margin: 15px;">
                     <button class="chat__area-media-btn btn" data-toggle="modal" data-target="#viewModalpaperclip">
                        <i class="fa fa-paperclip" aria-hidden="true"></i>
                       </button>
                    <form class="TT-formGroup" style="width:100%;">
                     <input (keydown)="keyPressHandler($event, messages.user.id)" (input)="message($event.target.value)" id="sendMessage" type="text" class="TT-FieldGroup" placeholder="Type Message..." trans-placeholder="Type message...">
                     <i (click)="sendMessage(messages.user.id)" class="fa fa-paper-plane-o TT-searchIcon" aria-hidden="true" id="sendMessageBtn"></i>
                    </form>
                  </div>
               </div>
             </div>            
          </div>
         </div>
      </div>

      <div class="modal fade" id="viewModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
         aria-hidden="true">
         <div class="modal-dialog topMargin">
            <div *ngIf="rescheduleRequest" class="modal-content content-new">
               <div class="modal-header">
                  <button type="button" class="close" #closeViewDetailsModal data-dismiss="modal" aria-hidden="true">
                     ×
                  </button>
                  <h4 class="modal-title md-title" id="myModalLabel">
                     {{rescheduleRequest.first_name}}.{{rescheduleRequest.last_name}} Reschedule Request
                  </h4>
               </div>
               <div class="modal-body">
                  <p>
                     This message is related to <b>Lesson {{rescheduleRequest.subject_name}}</b> that was scheduled on
                     <b>date {{rescheduleRequest.SlotDate}}</b> by <b>Student {{rescheduleRequest.first_name}}</b>
                     at<b> {{rescheduleRequest.previousSlotTime}}</b>.
                     <br /> <br />
                     The student is requesting a reschedule from <b>date {{rescheduleRequest.previousSlotDate}}</b> to
                     <b>date {{rescheduleRequest.requestedSlotDate}}</b>
                     Please visit the <b>lesson x</b> page to confirm
                  </p>
               </div>
               <div class="modal-footer text-model-center">
                  <button (click)="viewRequestSource()" type="button" class="btn btn-primary new btn-sm text-center">
                     <span trans="View Source">View Source</span>
                  </button>
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade" id="viewModalpaperclip" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
         aria-hidden="true">
         <div class="modal-dialog topMargin">
            <div class="modal-content content-new">
               <div class="modal-header">
                  <button #closeFileUploader (click)="closeFileUploaderModal()" type="button" class="close"
                     data-dismiss="modal" aria-hidden="true">
                     ×
                  </button>
                  <h4 class="modal-title md-title" id="myModalLabel" trans="File Upload">
                     File Upload
                  </h4>
                  <br />
                  <ngx-dropzone accept="image/jpeg,image/jpg,image/png,image/gif" (change)="onSelect($event)">
                     <ngx-dropzone-label><span trans="Select a file or drag here">Select a file or drag here</span>
                     </ngx-dropzone-label>
                     <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                        [removable]="true" (removed)="onRemove(f)">
                     </ngx-dropzone-image-preview>
                  </ngx-dropzone>
                  <br>
                  <div class="text-right">
                     <button *ngIf="files.length > 0 && (imageLoader$ | async) == false" (click)="sendFile()"
                        class="btn btn-primary text-right" trans="Send">Send</button>
                     <button *ngIf="(imageLoader$ | async) == true" class="btn btn-primary text-right">
                        <app-loader></app-loader>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

   </section>
</div>