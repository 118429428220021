import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FinanceService } from '../finance.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payout-actions',
  templateUrl: './payout-actions.component.html',
  styleUrls: ['./payout-actions.component.css']
})
export class PayoutActionsComponent implements OnInit {
  
    @ViewChild('closeActionModal') closeActionModal: ElementRef;
    public selectedPayout:any;
    public payoutStatusForm = this.fb.group({
        'status': ['', [
          Validators.required
        ]],
        'comments': [''],
        'selectedRequestId': ['']
    });
    public selectedRequestId:number;
    public selectedRequestUser:any;

    @Output() reloadRecords = new EventEmitter();

  get status(){ return this.payoutStatusForm.get('status')}
  get comments(){ return this.payoutStatusForm.get('comments')}

      public errorMessages = {
         status :[{ type:'required',message:'Status is required'}],
      }

  constructor(private fb: FormBuilder,public service: FinanceService,private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  public payoutSelectedId(id){
  	this.selectedRequestId = id;
  }

  public updatePayout() {    
    this.payoutStatusForm.markAllAsTouched();
    if(this.payoutStatusForm.valid){
    this.payoutStatusForm.patchValue({
      selectedRequestId:this.selectedRequestId,
    });
    
    this.service.updatePayout(this.payoutStatusForm.value)
        .subscribe(response => {
           response = JSON.parse(atob(response['data']));

           this.service.resetRecords(true);

           if ( response.status == 'failed' ) {
             this.toastr.error(response.message);
           } else {
             this.closeActionModal.nativeElement.click();
             this.toastr.success(response.message);             
           }
        });
    }
  }

  public payoutSelectedUser(user){
  	this.selectedRequestUser = user;
  }

}
