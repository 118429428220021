<div class="container-fluid padding0">
	<section id="findTutor-chat" class="pageSectionBg">
		<div class="TT-outlineLayers">
			<div class="container-padding TT-tabNavBar">

				<div class="TT-chatBoxing">
					<ul class="nav nav-tabs TT-tabbing" role="tablist" id="chatTabs">
						<li class="nav-item TT-concept active">
							<a class="TT-navLinkArea" data-toggle="tab" id="unreadTab" href="#unread">Unread Notifications</a>
						</li>
						<li class="nav-item TT-concept">
							<a class="TT-navLinkArea" data-toggle="tab" id="previousTab" href="#previous">Previous Notifications</a>
						</li>
					</ul>

					<div class="tab-content">
						<div id="unread" class="tab-pane active">
							<span *ngIf="usernotifications_unread_count > 0 else no_records_unread">
								<ul *ngFor="let notification of usernotifications_unread" class="TT-listingInform"
									style="list-style:none;">

									<div class="line_right">
										<div (click)="viewAlert(notification)" class="TT-textLisst clearfix">
											<h4 class="pull-left1">{{humanize(notification.purpose)}}</h4>
											<p class="pull-right1"> {{notification.read_at}} </p>											
											<p [innerHtml]="displayTextLimit(notification.notification)"
													class="TT-mainText1" style="font-size: 15px;
													font-weight: 400;"> </p>										
										</div>
									</div>
								</ul>
							</span>
							<ng-template #no_records_unread>
								<ul class="TT-listingInform">
									<li class="TT-textLisst clearfix">
										<p>
											<small trans="No records"> No records </small>
										</p>
									</li>
								</ul>
							</ng-template>
						</div>

						<div id="previous" class="tab-pane fade">
							<span *ngIf="usernotifications_read_count > 0 else no_records_previous">
								<ul *ngFor="let notification of usernotifications_read" class="TT-listingInform" style="list-style:none;">
									<div class="line_right">
									<div  (click)="viewAlert(notification)" class="TT-textLisst clearfix">
										<h4 class="pull-left1">{{humanize(notification.purpose)}} <span class="pull-right" style="margin-top:-5px;"> {{notification.read_at | date : 'HH:mm'}}
											{{notification.read_at | date: 'MM/dd/yyyy'}} </span></h4>
										
									
											<p [innerHtml]="displayTextLimit(notification.notification)"
												class="TT-mainText" style="font-size: 15px;
												font-weight: 400;"> </p>
								
									</div>
									</div>
								</ul>
							</span>
							<ng-template #no_records_previous>
								<ul class="TT-listingInform">
									<li class="TT-textLisst clearfix">
										<p>
											<small trans="No records"> No records </small>
										</p>
									</li>
								</ul>
							</ng-template>
						</div>
					</div>
				</div>

			</div>

		</div>
	</section>
</div>