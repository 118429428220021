<div class='col-sm-12 rightCol WalletStatus'>

   <div class="well well-sm teacher_head row">
      <h4 class="main-Head-bg flex_center">

         <div class="col-md-4 mb-4">
         <span class="cursor" trans="Subjects">Subjects</span> 
         </div>

         <div class="col-12 col-md-2 mb-4">
         <span class="max-right w_2">
            <select (input)="selectedPagesColumn($event.target.value , 'pageSearch')" class="form-control column form-control_column_width w-auto">
               <option value='' trans="Select Column">Select Column</option>
               <option [value]="item.value" *ngFor="let item of obj | keyvalue; let i = index;">
               {{item.key}}
               </option>
            </select>
         </span>
         </div>
         <div class="col-md-2 d-flex">
         <span class="max-top">
         <input [formControl]="searchControl" title="Select Column" id="pageSearch" disabled type="text" class="form-control form-control_width form-control_padding" placeholder="Search Here" trans-placeholder="Search here">
         <i class="fa fa-search" aria-hidden="true">
         </i>
         </span>
         <span (click)="csvExort('Subjects List')" class="csv-right mr-10">
         <img src="assets/images/csv.png" width="30">
         </span>
         </div>

         <div class="col-md-4 d-flex">
            <form [formGroup]="subjectForm" class="pull-right max-top d-flex">
               <input formControlName="subject_name" type="text" class="form-control" placeholder="Add Subject">
               <button (click)="addSubject()"><i class="fa fa-plus-circle" aria-hidden="true"></i></button>
            </form>
            <div *ngFor= "let error of errorMessages.subject_name">
            <ng-container *ngIf="subject_name.hasError(error.type) && (subject_name.touched)">
               <small class="error-message">{{error.message}}</small>
            </ng-container>
            </div>
         </div>
      </h4>
</div>


<div class='content-container customTabs'>
   <div class="row formspace">
      <div class="col-sm-12">
         <table class="table lesson-table">
            <tbody>
               <tr>
                  <th trans="Subject Name">
                     Subject Name
                  </th>
                  <th trans="Status">
                     Status
                  </th>
                  <th trans="Created By">
                     Created By
                  </th>
                  <th trans="Bookings">
                     Bookings
                  </th>
                  <th trans="Created Time">
                     Created Time
                  </th>
                  <th trans="Active/In-Active">
                     Active/In-Active
                  </th>
                  <th trans="Delete">
                     Delete
                  </th>
               </tr>
               <tr *ngFor="let subject of subjects$ | async">
                  <td >
                     {{subject.subject_name || 'N/A'}}
                  </td>
                  <td>
                     {{subject.status || 'N/A'}}
                  </td>
                  <td>
                     <span *ngIf="subject.creator">{{subject.creator.username || 'N/A'}}</span>
                     <span *ngIf="!subject.creator">'N/A'</span>
                  </td>
                  <td>
                     {{subject.total_bookings_count || 'N/A'}}
                  </td>
                  <td>
                     {{subject.created_at | date: 'MM-dd-yyyy h:mm a'}}
                  </td>
                  <td>
                     <label class="switch">
                     <input (change)="onCheckboxChange($event,subject.id)" id="{{subject.id}}" type="checkbox" 
                     [attr.checked]="subject.status == 'Active' ? '' : null">
                     <span class="slider round"></span>
                  </label>
                  </td>
                  <td class="form-group delRow">
                     <i (click)="deleteSubject(subject.id)" class="fa fa-trash" aria-hidden="true"></i>
                  </td>
               </tr>
            </tbody>
         </table>
         <app-datatable-footer-new></app-datatable-footer-new>
   </div>
</div>
</div>