import { Component, OnInit, Output , EventEmitter} from '@angular/core';
import { ValueLists } from '../../../core/services/value-lists.service';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { groupBy } from '../../../core/utils/group-by';
import { flattenArray } from '../../../core/utils/flatten-array';
import { Permission } from '../../../core/types/models/Permission';
import { SelectionModel } from '../../../core/utils/SelectionModel';
import { RoleService } from '../role.service';
declare var $;

export interface SelectPermissionsModalData {
    initialPermissions: Permission[];
    valueListKey: string;
    hideRestrictions: boolean;
}

@Component({
  selector: 'app-permissions-modal',
  templateUrl: './permissions-modal.component.html',
  styleUrls: ['./permissions-modal.component.css']
})

export class PermissionsModalComponent implements OnInit {
      @Output() selectedPermissions = new EventEmitter();
	    public loading$ = new BehaviorSubject(false);
	    public allPermissions$ = new BehaviorSubject<{[key: string]: Permission[]}>({});
      public permissions = new Array();
      public permissions$ = new BehaviorSubject<Permission[]>([]);

  constructor(private values: ValueLists,
    private roleService: RoleService,
) {
    this.roleService.updatepermissions.subscribe(data => {
      if(data && data.length>0){  
      this.permissions = data;     
      }
            });
    }

  ngOnInit(): void {
  	this.fetchAllPermissions();
  }

      private fetchAllPermissions() {
        const valueListKey = 'permissions';
        this.values.get([valueListKey])
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
                this.allPermissions$.next(groupBy<Permission>(response[valueListKey], 'group'));
            });
    }

      public confirm() {
        this.getSelectedPermissions();
    }

    public resetTabs() {
      $('.tab').attr('expanded', 'false');
    }

    getValue( permission ){
       for (let i = 0; i < this.permissions.length; i++) {
          // console.log(permission.name, this.permissions[i]['name']);
         if(permission.name == this.permissions[i]['name']){
          return permission.name;
         }
        }
    }

      private getSelectedPermissions(): Permission[] {
        var val=[];
        var permissions=[];
        const allPermissions = flattenArray(Object.values(this.allPermissions$.value));
            allPermissions.filter(permission => {
        $('input[type=checkbox]:checked').map(function(_, el) {
                val.push($(el).val());
        }).get();
        val.find(i => {
          if(i === permission.name){
            permissions.push(permission);
          }
        });
    });

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

      this.selectedPermissions.emit(permissions.filter(onlyUnique));
      $('#permissionsRoleModal').css('display','none');
      // $('#permissionsRoleModal').removeClass( "in" )
      $('input[type=checkbox]').removeAttr('checked');
        return permissions.filter(onlyUnique);
    }

}
