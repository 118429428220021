<div class='col-sm-12 rightCol WalletStatus'>
   <div class="well well-sm teacher_head">
      <div class="row">
       <div class="col-sm-10">
             <h4 class="title" trans="Pending Action"><span> Page Categories </span></h4>
      </div>
      <div (click)="createPageCategory()" class="col-sm-2 input-container search-input-container flex_Center">
            <button class="btn-primary new chat_head_top" trans="Create">Create</button>
      </div>
      </div>
   </div>

   <div class='customTabs'>
      <table class="table lesson-table">
         <tr>
            <th trans="Title">Title</th>
            <th trans="Created at">Created At</th>
            <th trans="Actions">Actions</th>
         </tr>
         <tr *ngFor="let page of pageCategories | async">
            <td>{{transform(page.title)}}</td>
            <td>{{page.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
            <td class="lesson-btn-actions dropdown" class="lesson-btn-actions dropdown">
               <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
               <ul class="dropdown-menu dropdown-new">
                  <li (click)="SetEditAction(page.id)" ><a trans="Edit">Edit</a></li>
                  <li class="divider"></li>
                  <li (click)="deleteCategory(page.id)"><a trans="Delete">Delete</a></li>
                  <li class="divider"></li>
               </ul>
            </td>
         </tr>
      </table>
      <app-datatable-footer-new *ngIf="(pageCategories | async).length > 0"></app-datatable-footer-new>
   </div>
</div>
