<div class='col-sm-12 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-12">
            <h4 class="title">Send Bulk Messages/Notification</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <form class="col-md-12" [formGroup]="bulkmessages" (ngSubmit)="bulkload()">
      <table class="table lesson-table noBorderTable">
         <thead>
            <tr>
               <div class="col-md-6">
                  <div class="form-group">
                     <label for="title" trans="Standard fee">Title</label>
                     <input type="text"  formControlName="title" class="form-control" name="title" 
                        placeholder="">                     
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="form-group">
                     <label for="message_type" trans="Message Type">
                     Message Type  (Email, Push, SMS)    
                     </label>
                     <select formControlName="message_type" name="message_type" class="form-control iconArrowDown BG-white">
                        <option value="">Please Select</option>
                        <option value="Email" trans="Email">
                           Email
                        </option>
                        <option value="Push" trans="Push">
                           Push
                        </option>
                        <option value="SMS" trans="SMS">
                           SMS
                        </option>
                     </select>
                  </div>
               </div>
            </tr>
            <!-- <tr>
               <div class="col-md-6">
                  <div class="form-group">
                     <label for="message_type" trans="Message Type">
                     Message Type  (Email, Push, SMS)    
                     </label>
                     <select formControlName="message_type" name="message_type" class="form-control iconArrowDown BG-white">
                        <option value="">Please Select</option>
                        <option value="Email" trans="Email">
                           Email
                        </option>
                        <option value="Push" trans="Push">
                           Push
                        </option>
                        <option value="SMS" trans="SMS">
                           SMS
                        </option>
                     </select>
                  </div>
               </div>
            </tr> -->
            <tr>
               <div class="col-md-12">
                  <div class="form-group">
                     <label for="message" trans="message">Message</label>
                     <ckeditor formControlName="message" [config]="{uiColor: '#F0F8FF' , height: '30em'}"></ckeditor>
                  </div>
               </div>
            </tr>
            <tr>
               <div class="col-md-6">
                  <button class="btn btn-primary new" trans="Send">Send</button>
               </div>
            </tr>
         </thead>
      </table>
      </form>
   </div>
</div>